import { render, staticRenderFns } from "./AlertBannerManagement.vue?vue&type=template&id=5c1e2330&"
import script from "./AlertBannerManagement.vue?vue&type=script&lang=js&"
export * from "./AlertBannerManagement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/project/releases/10508/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5c1e2330')) {
      api.createRecord('5c1e2330', component.options)
    } else {
      api.reload('5c1e2330', component.options)
    }
    module.hot.accept("./AlertBannerManagement.vue?vue&type=template&id=5c1e2330&", function () {
      api.rerender('5c1e2330', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/AlertBannerManagement.vue"
export default component.exports