<template>
<LibraryAddEditBase id="libraryAddEditBase" ref="libraryAddEditBase"
    :item="gun"

    :updateAction="saveGun"
    :createAction="createGun"
>
    <template #title>
        {{ isEditMode(gun) ? 'Edit Gun' : 'Add New Gun'}}
    </template>

    <template #leftBody>
        <div class="field-container">
            <div class="w-100">
                <label for="nameField">Name <template v-if="!editName">(Auto Generated)</template> *</label>
                <span v-if="!editName" id="edit-name-container" class="clickable" @click="editName = true">
                    <i class="fas fa-pen" style="margin-right: 5px"></i> Modify
                </span>
                <span v-else id="edit-name-container" class="clickable" @click="editName = false">
                    <i class="fab fa-autoprefixer" style="margin-right: 5px"></i> Use Generated Name
                </span>
            </div>

            <iws-input ref="nameInput"
                :value.sync="!editName ? generatedName : gun.name"
                :placeholder="!editName ? 'Gun Name will auto generate as you fill in this form' : 'Provide a custom Gun Name'"
                :disabled="!editName"
                required="true"
                error-message="Please provide a name"
            />
        </div>

        <div v-if="enableCustomerCol && !!customers && customers.length > 1" class="field-container">
            <iws-select
                ref="customerName"
                label="Customer Name *"
                :value.sync="gun.customer_id"
                :options="customers"
                value-name="id"
                display-name="name"
                required="required"
            />
        </div>

        <div class="row">
            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="sizeInput"
                        label="Size (in) *"
                        :value.sync="gun.size"
                        required="true"
                        error-message="Please provide a size"
                        type="number" min="0"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="lengthInput"
                        label="Gun Barrel *"
                        :value.sync="gun.length"
                        required="true"
                        error-message="Please provide a gun barrel length"
                        type="number" min="0"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="cluster_lengthInput"
                        label="Cluster Length (in) *"
                        :value.sync="gun.cluster_length"
                        required="true"
                        error-message="Please provide a cluster length"
                        type="number" min="0"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="shot_densityInput"
                        label="Shot Density (Shots/ft) *"
                        :value.sync="gun.shot_density"
                        required="true"
                        error-message="Please provide a shot density"
                        type="number" min="0"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="shots_plannedInput"
                        label="Number of Shots Planned *"
                        :value.sync="gun.shots_planned"
                        required="true"
                        error-message="Please provide a shots planned"
                        type="number" min="0"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="nominal_penetrationInput"
                        label="Nominal Penetration (in) *"
                        :value.sync="gun.nominal_penetration"
                        required="true"
                        error-message="Please provide a nominal penetration"
                        type="number" min="0"
                    />
                </div>
            </div>
        </div>
    </template>

    <template #rightBody>
        <div class="field-container">
            <iws-input ref="descriptionInput"
                label="Description"
                :value.sync="gun.description"
            />
        </div>

        <div class="row">
            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="estimated_hole_diameterInput"
                        label="Estimated Hole Diameter (in)"
                        :value.sync="gun.estimated_hole_diameter"
                        type="number" min="0"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="metallurgyInput"
                        label="Metallurgy"
                        :value.sync="gun.metallurgy"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <label for="customerNameField">Centralized</label>
                    <select class="form-control" v-model="gun.centralized">
                        <option>Yes</option>
                        <option>No</option>
                    </select>
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="conveyance_methodInput"
                        label="Conveyance Method"
                        :value.sync="gun.conveyance_method"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="carrier_makeInput"
                        label="Carrier Make"
                        :value.sync="gun.carrier_make"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="carrier_descriptionInput"
                        label="Carrier Description"
                        :value.sync="gun.carrier_description"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-4">
                <div class="field-container">
                    <iws-input ref="charge_sizeInput"
                        label="Charge Size"
                        :value.sync="gun.charge_size"
                        type="number" min="0"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-4">
                <div class="field-container">
                    <iws-input ref="charge_makeInput"
                        label="Charge Make"
                        :value.sync="gun.charge_make"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-4">
                <div class="field-container">
                    <iws-input ref="carrier_typeInput"
                        label="Charge Type"
                        :value.sync="gun.carrier_type"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="explosive_typeInput"
                        label="Explosive Type"
                        :value.sync="gun.explosive_type"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="phasingInput"
                        label="Phasing"
                        :value.sync="gun.phasing"
                        type="number" min="0"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="orientationInput"
                        label="Orientation"
                        :value.sync="gun.orientation"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="orientation_methodInput"
                        label="Orientation Method"
                        :value.sync="gun.orientation_method"
                    />
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="balanceInput"
                        label="Balance"
                        :value.sync="gun.balance"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="fluidTypeInput"
                        label="Fluid Type"
                        :value.sync="gun.fluid_type"
                    />
                </div>
            </div>
        </div>
    </template>
</LibraryAddEditBase>
</template>

<script>
import GlobalFunctions from '../GlobalFunctions.js';

import LibraryAddEditBase from './LibraryAddEditBase.vue';

export default {
    extends: LibraryAddEditBase,

    props: {
        id: {
            type: String,
            required: true
        },
        enableCustomerCol: Boolean
    },

    components: { LibraryAddEditBase },

    data: () => ({
        gun: {},
        editName: false
    }),

    computed: {
        // We auto gen a name for the gun based on the other fields
        // They can choose to use this auto generated or custom name
        generatedName() {
            let str = '';

            if (!GlobalFunctions.isFalsy(this.gun.size))
                str += `${this.gun.size}" OD, `;
            if (!GlobalFunctions.isFalsy(this.gun.estimated_hole_diameter))
                str += `${this.gun.estimated_hole_diameter}" EHD, `;
            if (!GlobalFunctions.isFalsy(this.gun.shot_density))
                str += `${this.gun.shot_density} spf, `;
            if (!GlobalFunctions.isFalsy(this.gun.charge_make))
                str += `${this.gun.charge_make} `;
            if (!GlobalFunctions.isFalsy(this.gun.carrier_type))
                str += `${this.gun.carrier_type} `;
            if (!GlobalFunctions.isFalsy(this.gun.charge_make) || !GlobalFunctions.isFalsy(this.gun.carrier_type))
                str += `charge, `;
            if (!GlobalFunctions.isFalsy(this.gun.phasing))
                str += `${this.gun.phasing} deg phasing`;

            return str;
        }
    },

    methods: {
        createGun() {
            return $.ajax({
                url: `/guns`,
                method: 'POST',
                data:
                {
                    '_token': GlobalFunctions.getCSRFToken(),
                    '_method': 'POST',
                    'originPage': `${location.origin}/guns`,
                    ...this.gun,
                    name: this.editName ? this.gun.name : this.generatedName,
                    id: 0
                },
                dataType: 'json'
            }).done((msg) => {
                if (msg.error) {
                    console.warn('Failed to save chart header state:' + JSON.stringify(msg));
                    if (msg.status == 401)
                        console.warn('unauthorized');
                } else {
                    location.reload();
                }
            });
        },
        saveGun() {
            return $.ajax({
                url: `/guns/${this.gun.id}`,
                method: 'POST',
                data:
                {
                    '_token': GlobalFunctions.getCSRFToken(),
                    '_method': 'PUT',
                    'originPage': `${location.origin}/guns`,
                    ...this.gun,
                    name: this.editName ? this.gun.name : this.generatedName
                },
                dataType: 'json'
            }).done((msg) => {
                if (msg.error) {
                    console.warn('Failed to save chart header state:' + JSON.stringify(msg));
                    if (msg.status == 401)
                        console.warn('unauthorized');
                } else {
                    location.reload();
                }
            });
        },

        open(gun) {
            this.fetchCustomers().done((response) => {
                const _customers = response || [];

                // For new guns, if there is only 1 customer (i.e. a customer is viewing this), just default to that single option
                if (!this.isEditMode(gun) && _customers.length == 1) {
                    this.gun.customer_id = _customers[0].id;
                    this.gun.customerName = _customers[0].name;
                }

                this.customers = _customers;
            });

            this.gun = { ...gun };
            this.editName = false;

            if (this.isEditMode(gun)) {
                // for existing guns, do not override the name with the pre generated name by default
                this.editName = gun.name != this.generatedName;
                this.addMoreSelection = true;
            }

            return this.$refs.libraryAddEditBase.open();
        }
    }
};
</script>
