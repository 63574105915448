var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.showChildren
      ? _c(
          "div",
          { attrs: { id: "CameraViewer" } },
          [
            _c("camera-navigation", {
              attrs: {
                user: _vm.user,
                device: _vm.device,
                "work-order-id": _vm.workOrderId,
                permissions: _vm.permissions,
                "is-home": "",
              },
            }),
            _vm._v(" "),
            !_vm._isNullOrEmpty(_vm.device?.cameras)
              ? [
                  _c("video-scrubber", {
                    attrs: {
                      user: _vm.user,
                      workOrderId: _vm.workOrderId,
                      cameraList: _vm.device.cameras,
                      vodList: _vm.vods,
                      alertList: _vm.alerts,
                      clipList: _vm.clips,
                      hourOffset: _vm.job?.hourOffset,
                      permissions: _vm.permissions,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _vm._l(_vm.device.cameras, function (camera, index) {
                        return [
                          !_vm._isFalsy(camera?.primaryStream)
                            ? _c(
                                "div",
                                {
                                  key: "player_" + index,
                                  staticClass: "col-6",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "full-height" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "camera-details title-row",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                camera.displayName || "name"
                                              ) +
                                              "\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("video-player", {
                                        ref: `videoPlayer_${index}`,
                                        refInFor: true,
                                        staticClass: "clickable",
                                        staticStyle: {
                                          height: "calc(100% - 40px)",
                                        },
                                        attrs: {
                                          user: _vm.user,
                                          workOrderId: _vm.workOrderId,
                                          device: _vm.device,
                                          permissions: _vm.permissions,
                                          vods: _vm.vods,
                                          index: index,
                                          control: false,
                                          "video-only": true,
                                        },
                                        on: {
                                          clicked: function ($event) {
                                            return _vm.jumpToCamera(camera)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              : _c("div", { staticClass: "error-message" }, [
                  _vm._v(
                    "\n            No cameras found for this job!\n        "
                  ),
                ]),
          ],
          2
        )
      : _c(
          "div",
          { staticClass: "player-spinner" },
          [_c("b-spinner", { attrs: { label: "Loading..." } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }