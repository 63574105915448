var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-fluid flex-1" },
    [
      _c("div", { staticClass: "page-title mt-2 mb-4 px-5 pt-0" }, [
        _vm._v("\n            Wireline Plug and Perf History\n        "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pt-3" },
        [
          _c("activity-alert-component", {
            attrs: { serverCall: _vm.updateAlerts },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row justify-content-between pt-2 align-items-center" },
        [
          _c("div", { staticClass: "col" }, [
            _c(
              "div",
              { staticStyle: { display: "inline-block" } },
              [
                _c("select-component", {
                  attrs: {
                    title: "Well: ",
                    options: _vm.wells,
                    "on-change": _vm.onWellChanged,
                    "init-selected": _vm.wellName,
                    isCustomClassEnabled: true,
                    customClass: _vm.reportPageSelectStyle,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { display: "inline-block" } },
              [
                _c(
                  "b-button",
                  {
                    staticStyle: { "margin-top": "10px" },
                    attrs: { type: "button", variant: "primary" },
                    on: { click: _vm.onClusterConfigPress },
                  },
                  [_vm._v("Spacing Alerts")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col" }, [
            _c(
              "div",
              { staticStyle: { display: "inline-block" } },
              [
                _c("select-component", {
                  ref: "stageRangeStart",
                  attrs: {
                    title: "Start:",
                    options: _vm.validStages,
                    "on-change": _vm.onStartStageChanged,
                    "init-selected": _vm.startStage,
                    isCustomClassEnabled: true,
                    customClass: _vm.reportPageSelectStyle,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { display: "inline-block" } },
              [
                _c(
                  "b-button",
                  {
                    staticStyle: { "margin-top": "10px" },
                    attrs: {
                      type: "button",
                      variant: "primary",
                      disabled: _vm.startStage == 1,
                    },
                    on: {
                      click: function ($event) {
                        _vm.startStage = 1
                        _vm.onStartStageChanged(1)
                      },
                    },
                  },
                  [_vm._v("First stage")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col" }, [
            _c(
              "div",
              { staticStyle: { display: "inline-block" } },
              [
                _c("select-component", {
                  ref: "stageRangeEnd",
                  attrs: {
                    title: "End:",
                    options: _vm.endStageCalculation,
                    "on-change": _vm.onEndStateChanged,
                    "init-selected": _vm.endStage,
                    isCustomClassEnabled: true,
                    customClass: _vm.reportPageSelectStyle,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { display: "inline-block" } },
              [
                _c(
                  "b-button",
                  {
                    staticStyle: { "margin-top": "10px" },
                    attrs: {
                      type: "button",
                      variant: "primary",
                      disabled: _vm.endStage == _vm.lastStage,
                    },
                    on: {
                      click: function ($event) {
                        _vm.endStage = _vm.lastStage
                        _vm.onEndStateChanged(_vm.lastStage)
                      },
                    },
                  },
                  [_vm._v("Last stage")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c("div", { staticClass: "d-flex flex-row mt-2 w-100" }, [
                  _c(
                    "a",
                    {
                      staticClass: "col",
                      attrs: { target: "_blank" },
                      on: { click: () => _vm.onExportPressed("exportPerf") },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "border border-secondary rounded bg-transparent btn text-dark exportButton",
                          staticStyle: {
                            "line-height": "1.2",
                            height: "60px",
                            width: "100%",
                          },
                        },
                        [
                          _vm._v(
                            "\n                                    Export Perforations\n                                "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "col",
                      attrs: { target: "_blank" },
                      on: { click: () => _vm.onExportPressed("exportPlug") },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "border border-secondary rounded bg-transparent btn text-dark exportButton",
                          staticStyle: {
                            "line-height": "1.2",
                            height: "60px",
                            width: "100%",
                          },
                        },
                        [
                          _vm._v(
                            "\n                                    Export Plugs\n                                "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "col",
                      attrs: { target: "_blank" },
                      on: { click: () => _vm.onExportPressed("exportBoth") },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "border border-secondary rounded bg-transparent btn text-dark exportButton",
                          staticStyle: {
                            "line-height": "1.2",
                            height: "60px",
                            width: "100%",
                          },
                        },
                        [
                          _vm._v(
                            "\n                                    Export Both\n                                "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-center mt-2" },
                  [
                    _c("select-component", {
                      ref: "fileType",
                      attrs: {
                        title: "Export File Type: ",
                        customTitleClass: _vm.fileExportTitleClass,
                        options: _vm.fileExportOptions,
                        "on-change": _vm.onFileExportTypeChanged,
                        "init-selected": "XML",
                        isCustomClassEnabled: true,
                        customClass: _vm.reportPageSelectStyle,
                        displayPropName: "display",
                        propValue: "value",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "pb-2" }, [
        _c("div", { staticClass: "h5 pb-1 pt-2" }, [_vm._v("Job Information")]),
        _vm._v(" "),
        _c("div", { staticClass: "row px-3 justify-content-between" }, [
          _c("div", { staticClass: "col-4" }, [
            _c(
              "div",
              {
                staticClass:
                  "pl-3 row justify-content-start align-items-center",
              },
              [
                _c("select-component", {
                  attrs: {
                    title: "Contractor:",
                    options: ["Select Contractor"].concat(
                      _vm.contractors.map((c) => c.name)
                    ),
                    "on-change": _vm.onContractorChanged,
                    "init-selected": _vm.defaultContractorId
                      ? _vm.contractors.find(
                          (c) => c.id == _vm.defaultContractorId
                        ).name
                      : "Select Contractor",
                    isCustomClassEnabled: true,
                    customClass: _vm.reportPageSelectStyle,
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isAdmin || _vm.isCompanyAdmin,
                        expression: "isAdmin || isCompanyAdmin",
                      },
                    ],
                    staticClass: "m-2 pt-1 align-items-center",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.sendingDefaultContractor,
                          expression: "!sendingDefaultContractor",
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.defaultContractorSelected,
                          expression: "defaultContractorSelected",
                        },
                      ],
                      staticClass: "mr-1 ml-2",
                      attrs: { type: "checkbox", id: "defaultContractor" },
                      domProps: {
                        checked: Array.isArray(_vm.defaultContractorSelected)
                          ? _vm._i(_vm.defaultContractorSelected, null) > -1
                          : _vm.defaultContractorSelected,
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$a = _vm.defaultContractorSelected,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.defaultContractorSelected = $$a.concat([
                                    $$v,
                                  ]))
                              } else {
                                $$i > -1 &&
                                  (_vm.defaultContractorSelected = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.defaultContractorSelected = $$c
                            }
                          },
                          function ($event) {
                            return _vm.onContractorDefaultChanged()
                          },
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.sendingDefaultContractor,
                            expression: "!sendingDefaultContractor",
                          },
                        ],
                        attrs: { for: "defaultContractor" },
                      },
                      [_vm._v("Default")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.sendingDefaultContractor,
                            expression: "sendingDefaultContractor",
                          },
                        ],
                      },
                      [_vm._v("Saving...")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-dark ml-3",
                    attrs: { type: "button" },
                    on: { click: () => _vm.onAddContractorPressed() },
                  },
                  [_c("i", { staticClass: "fas fa-plus" })]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.isAdmin || _vm.isIwsTech
            ? _c("div", { staticClass: "col-3 form-group" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "row justify-content-center flex flex-col w-full",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.checkMissedShots()
                          },
                        },
                      },
                      [_vm._v("Check Missed Shots")]
                    ),
                    _vm._v(" "),
                    _vm.isClearAlertsFeatureFlagEnabled
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-primary mx-2",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.getTotalAlertsCounts()
                              },
                            },
                          },
                          [_vm._v("Clear Missing Shots Alerts")]
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row mt-2", staticStyle: { height: "2vh" } },
                  [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.checkMissedShotsSettings.showValidationBool,
                              expression:
                                "checkMissedShotsSettings.showValidationBool",
                            },
                          ],
                          staticClass: "validation-text",
                          class:
                            _vm.checkMissedShotsSettings.validationColorClass,
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.checkMissedShotsSettings.validationText
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-2 px-1 text-right",
              staticStyle: { "margin-left": "8vw" },
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-secondary grey-button save-changes-buttons",
                  attrs: { type: "button", disabled: !_vm.tableUpdated },
                  on: { click: () => _vm.discardChanges() },
                },
                [_vm._v("Discard")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-2 px-1 text-right",
              staticStyle: { "max-width": "160px" },
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-success green-button save-changes-buttons",
                  attrs: { type: "button", disabled: !_vm.tableUpdated },
                  on: { click: () => _vm.saveChanges() },
                },
                [_vm._v("Save changes")]
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      !_vm.errorMessage
        ? _c(
            "div",
            {
              staticStyle: {
                "max-height": "70vh",
                display: "flex",
                flexDirection: "column",
              },
            },
            [
              _c("div", [
                _c("div", { staticClass: "pb-3" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  !_vm.isFirstIntervalPerforationsPresent &&
                  !_vm.onFirstIntervalPressed &&
                  _vm.startStage == 1
                    ? _c("span", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary ml-2 text-left",
                            staticStyle: { height: "50%" },
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.onFirstIntervalPressed = true
                              },
                            },
                          },
                          [_vm._v("Enter 1st Interval Perforation Depths")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.onFirstIntervalPressed
                    ? _c("span", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger ml-2 text-left",
                            staticStyle: { height: "50%" },
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.onFirstIntervalPressed = false
                              },
                            },
                          },
                          [_vm._v("Cancel 1st Interval Perforation Depths")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.onFirstIntervalPressed
                    ? _c("span", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary ml-2 text-left",
                            staticStyle: { height: "100%" },
                            attrs: { type: "button" },
                            on: { click: _vm.saveWirelineRow },
                          },
                          [_vm._v("Save")]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _vm.onFirstIntervalPressed == true
                ? _c("div", { staticClass: "data-table" }, [
                    _c("div", { staticClass: "d-flex flex-1" }, [
                      _c(
                        "div",
                        {
                          ref: "perforationsTableContainer",
                          style: { "max-height": _vm.maxTableHeight + "px" },
                        },
                        [
                          _c(
                            "table",
                            { ref: "perfTable", staticClass: "reportTable" },
                            [
                              _c("thead", { ref: "perfTableHead" }, [
                                _c(
                                  "tr",
                                  {
                                    staticStyle: {
                                      "z-index": "1000",
                                      position: "relative",
                                    },
                                  },
                                  [
                                    _vm._l(
                                      _vm.updatedFirstPerfFields,
                                      function (perfField, index) {
                                        return _c(
                                          "th",
                                          {
                                            key: index,
                                            staticClass: "secondary-bg-dark",
                                          },
                                          [_vm._v(_vm._s(perfField))]
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _vm._m(1),
                                  ],
                                  2
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(
                                  _vm.rowPerfVals,
                                  function (perfRow, rowIndex) {
                                    return _c(
                                      "tr",
                                      {
                                        key: rowIndex,
                                        ref: "perfTableRow",
                                        refInFor: true,
                                      },
                                      _vm._l(
                                        perfRow.slice(
                                          0,
                                          _vm.EXTRA_INFO_INDEXES * -1
                                        ),
                                        function (perfValue, index) {
                                          return _c(
                                            "td",
                                            { key: index },
                                            [
                                              _vm.perfFields[index] ===
                                              "Gun Left In Hole?"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "disabled-cell",
                                                      staticStyle: {
                                                        "background-color":
                                                          "white",
                                                        "border-top":
                                                          "2px solid #919191",
                                                        "border-left":
                                                          "2px solid #919191",
                                                        "border-right":
                                                          "2px solid #dadada",
                                                        "border-bottom":
                                                          "2px solid #dadada",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            right: "0px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "select-component",
                                                            {
                                                              class:
                                                                _vm.resolveStatusClass(
                                                                  perfRow
                                                                ),
                                                              staticStyle: {
                                                                padding: "1px",
                                                              },
                                                              attrs: {
                                                                disabled: true,
                                                                options: [
                                                                  "Yes",
                                                                  "No",
                                                                ],
                                                                "on-change": (
                                                                  value
                                                                ) =>
                                                                  _vm.onGunDataChange(
                                                                    {
                                                                      target: {
                                                                        value:
                                                                          value,
                                                                      },
                                                                    },
                                                                    index,
                                                                    rowIndex
                                                                  ),
                                                                "init-selected":
                                                                  perfValue,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm.perfFields[index] ===
                                                  "Alert"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-row",
                                                      staticStyle: {
                                                        "text-align": "center",
                                                        "align-items": "center",
                                                        "justify-content":
                                                          "center",
                                                      },
                                                    },
                                                    [
                                                      _vm.getAlertIconStatus(
                                                        perfRow
                                                      ) === 0
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "p-1",
                                                              staticStyle: {
                                                                position:
                                                                  "absolute",
                                                                color: "black",
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                ref: "commentInputRef",
                                                                refInFor: true,
                                                                staticClass:
                                                                  "fas fa-exclamation-circle",
                                                                staticStyle: {
                                                                  cursor:
                                                                    "pointer",
                                                                },
                                                                on: {
                                                                  click: () =>
                                                                    _vm.onCommentFocused(
                                                                      {
                                                                        value:
                                                                          perfValue,
                                                                        colIndex:
                                                                          index +
                                                                          1,
                                                                        rowIndex,
                                                                        type: "shot",
                                                                        extraInfo:
                                                                          perfRow[
                                                                            perfRow.length -
                                                                              1
                                                                          ],
                                                                        isAlertColumn: true,
                                                                      }
                                                                    ),
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.getAlertIconStatus(
                                                        perfRow
                                                      ) === 1
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "p-1",
                                                              staticStyle: {
                                                                position:
                                                                  "absolute",
                                                                color: "black",
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                ref: "commentInputRef",
                                                                refInFor: true,
                                                                staticClass:
                                                                  "fas fa-check-circle",
                                                                staticStyle: {
                                                                  cursor:
                                                                    "pointer",
                                                                },
                                                                on: {
                                                                  click: () =>
                                                                    _vm.onCommentFocused(
                                                                      {
                                                                        value:
                                                                          perfValue,
                                                                        colIndex:
                                                                          index +
                                                                          1,
                                                                        rowIndex,
                                                                        type: "shot",
                                                                        extraInfo:
                                                                          perfRow[
                                                                            perfRow.length -
                                                                              1
                                                                          ],
                                                                        isAlertColumn: true,
                                                                      }
                                                                    ),
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        ref: "commentInputRef",
                                                        refInFor: true,
                                                        class:
                                                          _vm.resolveStatusClass(
                                                            perfRow
                                                          ),
                                                        attrs: {
                                                          disabled: true,
                                                          readonly: "",
                                                        },
                                                        domProps: {
                                                          value: perfValue,
                                                        },
                                                        on: {
                                                          focus: () =>
                                                            _vm.onCommentFocused(
                                                              {
                                                                value:
                                                                  perfValue,
                                                                colIndex:
                                                                  index + 1,
                                                                rowIndex,
                                                                type: "shot",
                                                                extraInfo:
                                                                  perfRow[
                                                                    perfRow.length -
                                                                      1
                                                                  ],
                                                                isAlertColumn: true,
                                                              }
                                                            ),
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _vm.perfFields[index] ===
                                                  "Comment"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-row",
                                                      staticStyle: {
                                                        "text-align": "center",
                                                        "align-items": "center",
                                                        "justify-content":
                                                          "center",
                                                      },
                                                    },
                                                    [
                                                      _c("input", {
                                                        ref: "commentInputRef",
                                                        refInFor: true,
                                                        class:
                                                          _vm.resolveStatusClass(
                                                            perfRow
                                                          ),
                                                        attrs: {
                                                          disabled: true,
                                                          readonly: "",
                                                        },
                                                        domProps: {
                                                          value: perfValue,
                                                        },
                                                        on: {
                                                          focus: () =>
                                                            _vm.onCommentFocused(
                                                              {
                                                                value:
                                                                  perfValue,
                                                                colIndex: index,
                                                                rowIndex,
                                                                type: "shot",
                                                              }
                                                            ),
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _vm.perfFields[index] ===
                                                  "Top Depth (ftKB)"
                                                ? _c("modified-input", {
                                                    class:
                                                      _vm.resolveStatusClass(
                                                        perfRow
                                                      ) &&
                                                      _vm.highlightFailingFields(
                                                        rowIndex,
                                                        index
                                                      ),
                                                    attrs: {
                                                      modelModifiers: ["round"],
                                                      modelValue:
                                                        _vm.rowPerfVals[
                                                          rowIndex
                                                        ][index],
                                                      disabled:
                                                        _vm.gunDisabledFirstIntervalIndexes.includes(
                                                          index
                                                        ) ||
                                                        perfRow[
                                                          perfRow.length - 1
                                                        ].alertType,
                                                    },
                                                    on: {
                                                      update: (value) =>
                                                        _vm.onValueInputChange(
                                                          value,
                                                          index,
                                                          rowIndex
                                                        ),
                                                    },
                                                  })
                                                : _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.rowPerfVals[
                                                            rowIndex
                                                          ][index],
                                                        expression:
                                                          "rowPerfVals[rowIndex][index]",
                                                      },
                                                    ],
                                                    class:
                                                      _vm.resolveStatusClass(
                                                        perfRow
                                                      ) &&
                                                      _vm.highlightFailingFields(
                                                        rowIndex,
                                                        index
                                                      ),
                                                    attrs: {
                                                      disabled:
                                                        _vm.gunDisabledFirstIntervalIndexes.includes(
                                                          index
                                                        ) ||
                                                        perfRow[
                                                          perfRow.length - 1
                                                        ].alertType,
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.rowPerfVals[
                                                          rowIndex
                                                        ][index],
                                                    },
                                                    on: {
                                                      change: (value) =>
                                                        _vm.onValueInputChange(
                                                          value,
                                                          index,
                                                          rowIndex
                                                        ),
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.rowPerfVals[
                                                            rowIndex
                                                          ],
                                                          index,
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.perfValues.length > 0
                ? _c("div", { staticClass: "data-table" }, [
                    _c("div", { staticClass: "d-flex flex-1" }, [
                      _c(
                        "div",
                        {
                          ref: "perforationsTableContainer",
                          style: { "max-height": _vm.maxTableHeight + "px" },
                        },
                        [
                          _c(
                            "table",
                            { ref: "perfTable", staticClass: "reportTable" },
                            [
                              _c("thead", { ref: "perfTableHead" }, [
                                _c(
                                  "tr",
                                  {
                                    staticStyle: {
                                      "z-index": "1000",
                                      position: "relative",
                                    },
                                  },
                                  [
                                    _vm._l(
                                      _vm.perfFields,
                                      function (perfField, index) {
                                        return _c(
                                          "th",
                                          {
                                            key: index,
                                            staticClass: "secondary-bg-dark",
                                          },
                                          [_vm._v(_vm._s(perfField))]
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _vm._m(2),
                                  ],
                                  2
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(
                                  _vm.perfValues,
                                  function (perfRow, rowIndex) {
                                    return _c(
                                      "tr",
                                      {
                                        key: rowIndex,
                                        ref: "perfTableRow",
                                        refInFor: true,
                                        on: {
                                          click: () =>
                                            _vm.onRowClicked(
                                              perfRow,
                                              rowIndex,
                                              "shot"
                                            ),
                                        },
                                      },
                                      _vm._l(
                                        perfRow.slice(
                                          0,
                                          _vm.EXTRA_INFO_INDEXES * -1
                                        ),
                                        function (perfValue, index) {
                                          return _c(
                                            "td",
                                            { key: index },
                                            [
                                              _vm.perfFields[index] ===
                                              "Gun Left In Hole?"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "white",
                                                        "border-top":
                                                          "2px solid #919191",
                                                        "border-left":
                                                          "2px solid #919191",
                                                        "border-right":
                                                          "2px solid #dadada",
                                                        "border-bottom":
                                                          "2px solid #dadada",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            right: "0px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "select-component",
                                                            {
                                                              class:
                                                                _vm.resolveStatusClass(
                                                                  perfRow
                                                                ),
                                                              staticStyle: {
                                                                padding: "1px",
                                                              },
                                                              attrs: {
                                                                disabled:
                                                                  perfRow[
                                                                    perfRow.length -
                                                                      1
                                                                  ].alertType ||
                                                                  perfRow[
                                                                    perfRow.length -
                                                                      1
                                                                  ].alertType ||
                                                                  _vm.onFirstIntervalPressed,
                                                                options: [
                                                                  "Yes",
                                                                  "No",
                                                                ],
                                                                "on-change": (
                                                                  value
                                                                ) =>
                                                                  _vm.onGunDataChange(
                                                                    {
                                                                      target: {
                                                                        value:
                                                                          value,
                                                                      },
                                                                    },
                                                                    index,
                                                                    rowIndex
                                                                  ),
                                                                "init-selected":
                                                                  perfValue,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm.perfFields[index] ===
                                                    "Alert" &&
                                                  !_vm.onFirstIntervalPressed
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-row",
                                                      staticStyle: {
                                                        "text-align": "center",
                                                        "align-items": "center",
                                                        "justify-content":
                                                          "center",
                                                      },
                                                    },
                                                    [
                                                      _vm.getAlertIconStatus(
                                                        perfRow
                                                      ) === 0
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "p-1",
                                                              staticStyle: {
                                                                position:
                                                                  "absolute",
                                                                color: "black",
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                ref: "commentInputRef",
                                                                refInFor: true,
                                                                staticClass:
                                                                  "fas fa-exclamation-circle",
                                                                staticStyle: {
                                                                  cursor:
                                                                    "pointer",
                                                                },
                                                                on: {
                                                                  click: () =>
                                                                    _vm.onCommentFocused(
                                                                      {
                                                                        value:
                                                                          perfValue,
                                                                        colIndex:
                                                                          index +
                                                                          1,
                                                                        rowIndex,
                                                                        type: "shot",
                                                                        extraInfo:
                                                                          perfRow[
                                                                            perfRow.length -
                                                                              1
                                                                          ],
                                                                        isAlertColumn: true,
                                                                      }
                                                                    ),
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.getAlertIconStatus(
                                                        perfRow
                                                      ) === 1
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "p-1",
                                                              staticStyle: {
                                                                position:
                                                                  "absolute",
                                                                color: "black",
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                ref: "commentInputRef",
                                                                refInFor: true,
                                                                staticClass:
                                                                  "fas fa-check-circle",
                                                                staticStyle: {
                                                                  cursor:
                                                                    "pointer",
                                                                },
                                                                on: {
                                                                  click: () =>
                                                                    _vm.onCommentFocused(
                                                                      {
                                                                        value:
                                                                          perfValue,
                                                                        colIndex:
                                                                          index +
                                                                          1,
                                                                        rowIndex,
                                                                        type: "shot",
                                                                        extraInfo:
                                                                          perfRow[
                                                                            perfRow.length -
                                                                              1
                                                                          ],
                                                                        isAlertColumn: true,
                                                                      }
                                                                    ),
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        ref: "commentInputRef",
                                                        refInFor: true,
                                                        class:
                                                          _vm.resolveStatusClass(
                                                            perfRow
                                                          ),
                                                        attrs: {
                                                          disabled:
                                                            _vm.onFirstIntervalPressed,
                                                          readonly: "",
                                                        },
                                                        domProps: {
                                                          value: perfValue,
                                                        },
                                                        on: {
                                                          focus: () =>
                                                            _vm.onCommentFocused(
                                                              {
                                                                value:
                                                                  perfValue,
                                                                colIndex:
                                                                  index + 1,
                                                                rowIndex,
                                                                type: "shot",
                                                                extraInfo:
                                                                  perfRow[
                                                                    perfRow.length -
                                                                      1
                                                                  ],
                                                                isAlertColumn: true,
                                                              }
                                                            ),
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _vm.perfFields[index] ===
                                                  "Comment"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-row",
                                                      staticStyle: {
                                                        "text-align": "center",
                                                        "align-items": "center",
                                                        "justify-content":
                                                          "center",
                                                      },
                                                    },
                                                    [
                                                      _c("input", {
                                                        ref: "commentInputRef",
                                                        refInFor: true,
                                                        class:
                                                          _vm.resolveStatusClass(
                                                            perfRow
                                                          ),
                                                        attrs: {
                                                          disabled:
                                                            _vm.onFirstIntervalPressed,
                                                          readonly: "",
                                                        },
                                                        domProps: {
                                                          value: perfValue,
                                                        },
                                                        on: {
                                                          focus: () =>
                                                            _vm.onCommentFocused(
                                                              {
                                                                value:
                                                                  perfValue,
                                                                colIndex: index,
                                                                rowIndex,
                                                                type: "shot",
                                                              }
                                                            ),
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _vm.perfFields[index] ===
                                                    "Top Depth (ftKB)" ||
                                                  _vm.perfFields[index] ===
                                                    "Bottom Depth (ftKB)"
                                                ? _c("modified-input", {
                                                    class:
                                                      _vm.resolveStatusClass(
                                                        perfRow
                                                      ),
                                                    attrs: {
                                                      modelModifiers: ["round"],
                                                      modelValue:
                                                        _vm.perfValues[
                                                          rowIndex
                                                        ][index],
                                                      disabled:
                                                        _vm.gunDisabledIndexes.includes(
                                                          index
                                                        ) ||
                                                        perfRow[
                                                          perfRow.length - 1
                                                        ].alertType ||
                                                        _vm.onFirstIntervalPressed,
                                                    },
                                                    on: {
                                                      update: (value) =>
                                                        _vm.onGunDataChange(
                                                          value,
                                                          index,
                                                          rowIndex
                                                        ),
                                                    },
                                                  })
                                                : _c("input", {
                                                    class:
                                                      _vm.resolveStatusClass(
                                                        perfRow
                                                      ),
                                                    attrs: {
                                                      disabled:
                                                        _vm.gunDisabledIndexes.includes(
                                                          index
                                                        ) ||
                                                        perfRow[
                                                          perfRow.length - 1
                                                        ].alertType ||
                                                        _vm.onFirstIntervalPressed,
                                                    },
                                                    domProps: {
                                                      value: perfValue,
                                                    },
                                                    on: {
                                                      change: (value) =>
                                                        _vm.onGunDataChange(
                                                          value,
                                                          index,
                                                          rowIndex
                                                        ),
                                                    },
                                                  }),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm.perfValues.length < 1 && _vm.plugValues.length < 1
                ? _c("div", { staticClass: "text-center" }, [
                    _c("label", { staticClass: "mt-5 h5 text-danger" }, [
                      _vm._v("No wireline activity found with current filters"),
                    ]),
                  ])
                : _c("div", [
                    _vm._v(
                      "\n                No perforations found\n            "
                    ),
                  ]),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "pb-3" }, [
                  _vm._m(3),
                  _vm._v(" "),
                  !_vm.isFirstIntervalPlugsPresent &&
                  !_vm.onFirstIntervalPlugPressed &&
                  _vm.startStage == 1
                    ? _c("span", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary ml-2 text-left",
                            staticStyle: { height: "50%" },
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.onFirstIntervalPlugPressed = true
                              },
                            },
                          },
                          [_vm._v("Enter PBTD")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.onFirstIntervalPlugPressed
                    ? _c("span", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger ml-2 text-left",
                            staticStyle: { height: "50%" },
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.onFirstIntervalPlugPressed = false
                              },
                            },
                          },
                          [_vm._v("Cancel PBTD")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.onFirstIntervalPlugPressed
                    ? _c("span", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary ml-2 text-left",
                            staticStyle: { height: "100%" },
                            attrs: { type: "button" },
                            on: { click: _vm.saveWirelineRowPlug },
                          },
                          [_vm._v("Save")]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _vm.onFirstIntervalPlugPressed == true
                ? _c("div", { staticClass: "d-flex data-table" }, [
                    _c(
                      "div",
                      {
                        ref: "plugTableContainer",
                        style: { "max-height": _vm.maxTableHeight + "px" },
                      },
                      [
                        _c(
                          "table",
                          { ref: "plugTable", staticClass: "reportTable" },
                          [
                            _c("thead", { ref: "plugTableHead" }, [
                              _c(
                                "tr",
                                [
                                  _vm._l(
                                    _vm.updatedFirstPlugFields,
                                    function (plugField, index) {
                                      return _c(
                                        "th",
                                        {
                                          key: index,
                                          staticClass: "secondary-bg-dark",
                                        },
                                        [_vm._v(_vm._s(plugField))]
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _vm._m(4),
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(
                                _vm.rowPlugVals,
                                function (plugRow, rowIndex) {
                                  return _c(
                                    "tr",
                                    {
                                      key: rowIndex,
                                      ref: "plugTableRow",
                                      refInFor: true,
                                    },
                                    _vm._l(
                                      plugRow.slice(
                                        0,
                                        _vm.EXTRA_INFO_INDEXES * -1
                                      ),
                                      function (plugValue, index) {
                                        return _c(
                                          "td",
                                          { key: index },
                                          [
                                            _vm.plugFields[index] ===
                                            "Interval Number"
                                              ? _c("div", {
                                                  staticStyle: {
                                                    width: "10em",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.plugFields[index] === "Comments"
                                              ? _c("input", {
                                                  ref: "commentInputRef",
                                                  refInFor: true,
                                                  class: _vm.findPlugClass(
                                                    plugRow[plugRow.length - 1]
                                                  ),
                                                  attrs: {
                                                    disabled: true,
                                                    readonly: "",
                                                  },
                                                  domProps: {
                                                    value: plugValue,
                                                  },
                                                  on: {
                                                    change: (value) =>
                                                      _vm.onPlugDataChange(
                                                        value,
                                                        index,
                                                        rowIndex
                                                      ),
                                                    focus: () =>
                                                      _vm.onCommentFocused({
                                                        value: plugValue,
                                                        colIndex: index,
                                                        rowIndex,
                                                        type: "plug",
                                                        extraInfo:
                                                          plugRow[
                                                            plugRow.length - 1
                                                          ],
                                                      }),
                                                  },
                                                })
                                              : _vm.plugFields[index] ===
                                                  "Top Depth (ftKB)" ||
                                                _vm.plugFields[index] ===
                                                  "Bottom Depth (ftKB)"
                                              ? _c("modified-input", {
                                                  class:
                                                    _vm.findPlugClass(
                                                      plugRow[
                                                        plugRow.length - 1
                                                      ]
                                                    ) &&
                                                    _vm.highlightFailingFieldsPlugs(
                                                      rowIndex,
                                                      index
                                                    ),
                                                  attrs: {
                                                    modelModifiers: ["round"],
                                                    modelValue:
                                                      _vm.rowPlugVals[rowIndex][
                                                        index
                                                      ],
                                                    disabled:
                                                      _vm.plugDisabledFirstIntervalIndexes.includes(
                                                        index
                                                      ) ||
                                                      plugRow[
                                                        plugRow.length - 1
                                                      ].alertType,
                                                  },
                                                  on: {
                                                    update: (value) =>
                                                      _vm.onValueInputChangePlug(
                                                        value,
                                                        index,
                                                        rowIndex
                                                      ),
                                                  },
                                                })
                                              : _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.rowPlugVals[
                                                          rowIndex
                                                        ][index],
                                                      expression:
                                                        "rowPlugVals[rowIndex][index]",
                                                    },
                                                  ],
                                                  class:
                                                    _vm.findPlugClass(
                                                      plugRow[
                                                        plugRow.length - 1
                                                      ]
                                                    ) &&
                                                    _vm.highlightFailingFieldsPlugs(
                                                      rowIndex,
                                                      index
                                                    ),
                                                  attrs: {
                                                    disabled:
                                                      _vm.plugDisabledFirstIntervalIndexes.includes(
                                                        index
                                                      ) ||
                                                      plugRow[
                                                        plugRow.length - 1
                                                      ].alertType,
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.rowPlugVals[rowIndex][
                                                        index
                                                      ],
                                                  },
                                                  on: {
                                                    change: (value) =>
                                                      _vm.onValueInputChangePlug(
                                                        value,
                                                        index,
                                                        rowIndex
                                                      ),
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.rowPlugVals[
                                                          rowIndex
                                                        ],
                                                        index,
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.plugValues.length > 0
                ? _c("div", { staticClass: "flex-1 data-table" }, [
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "div",
                        {
                          ref: "plugTableContainer",
                          style: { "max-height": _vm.maxTableHeight + "px" },
                        },
                        [
                          _c(
                            "table",
                            { ref: "plugTable", staticClass: "reportTable" },
                            [
                              _c("thead", { ref: "plugTableHead" }, [
                                _c(
                                  "tr",
                                  [
                                    _vm._l(
                                      _vm.plugFields,
                                      function (plugField, index) {
                                        return _c(
                                          "th",
                                          {
                                            key: index,
                                            staticClass: "secondary-bg-dark",
                                          },
                                          [_vm._v(_vm._s(plugField))]
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _vm._m(5),
                                  ],
                                  2
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(
                                  _vm.plugValues,
                                  function (plugRow, rowIndex) {
                                    return _c(
                                      "tr",
                                      {
                                        key: rowIndex,
                                        ref: "plugTableRow",
                                        refInFor: true,
                                        on: {
                                          click: function ($event) {
                                            return _vm.onRowClicked(
                                              plugRow,
                                              rowIndex,
                                              "plug"
                                            )
                                          },
                                        },
                                      },
                                      _vm._l(
                                        plugRow.slice(
                                          0,
                                          _vm.EXTRA_INFO_INDEXES * -1
                                        ),
                                        function (plugValue, index) {
                                          return _c(
                                            "td",
                                            { key: index },
                                            [
                                              _vm.plugFields[index] ===
                                              "Interval Number"
                                                ? _c("div", {
                                                    staticStyle: {
                                                      width: "10em",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.plugFields[index] ===
                                              "Comments"
                                                ? _c("input", {
                                                    ref: "commentInputRef",
                                                    refInFor: true,
                                                    class: _vm.findPlugClass(
                                                      plugRow[
                                                        plugRow.length - 1
                                                      ]
                                                    ),
                                                    attrs: {
                                                      disabled:
                                                        _vm.plugDisabledIndexes.includes(
                                                          index
                                                        ) ||
                                                        _vm.onFirstIntervalPlugPressed,
                                                      readonly: "",
                                                    },
                                                    domProps: {
                                                      value: plugValue,
                                                    },
                                                    on: {
                                                      change: (value) =>
                                                        _vm.onPlugDataChange(
                                                          value,
                                                          index,
                                                          rowIndex
                                                        ),
                                                      focus: () =>
                                                        _vm.onCommentFocused({
                                                          value: plugValue,
                                                          colIndex: index,
                                                          rowIndex,
                                                          type: "plug",
                                                          extraInfo:
                                                            plugRow[
                                                              plugRow.length - 1
                                                            ],
                                                        }),
                                                    },
                                                  })
                                                : _vm.plugFields[index] ===
                                                    "Top Depth (ftKB)" ||
                                                  _vm.plugFields[index] ===
                                                    "Bottom Depth (ftKB)"
                                                ? _c("modified-input", {
                                                    class: _vm.findPlugClass(
                                                      plugRow[
                                                        plugRow.length - 1
                                                      ]
                                                    ),
                                                    attrs: {
                                                      modelModifiers: ["round"],
                                                      modelValue:
                                                        _vm.plugValues[
                                                          rowIndex
                                                        ][index],
                                                      disabled:
                                                        _vm.plugDisabledIndexes.includes(
                                                          index
                                                        ) ||
                                                        plugRow[
                                                          plugRow.length - 1
                                                        ].alertType ||
                                                        _vm.onFirstIntervalPlugPressed,
                                                    },
                                                    on: {
                                                      update: (value) =>
                                                        _vm.onPlugDataChange(
                                                          value,
                                                          index,
                                                          rowIndex
                                                        ),
                                                    },
                                                  })
                                                : _c("input", {
                                                    class: _vm.findPlugClass(
                                                      plugRow[
                                                        plugRow.length - 1
                                                      ]
                                                    ),
                                                    attrs: {
                                                      disabled:
                                                        _vm.plugDisabledIndexes.includes(
                                                          index
                                                        ) ||
                                                        plugRow[
                                                          plugRow.length - 1
                                                        ].alertType ||
                                                        _vm.onFirstIntervalPlugPressed,
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.plugValues[
                                                          rowIndex
                                                        ][index],
                                                    },
                                                    on: {
                                                      change: (value) =>
                                                        _vm.onPlugDataChange(
                                                          value,
                                                          index,
                                                          rowIndex
                                                        ),
                                                    },
                                                  }),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ])
                : !(_vm.perfValues.length < 1 && _vm.plugValues.length < 1)
                ? _c("div", [
                    _vm._v("\n                No plugs found\n            "),
                  ])
                : _vm._e(),
            ]
          )
        : _vm.errorMessage
        ? _c("div", { staticClass: "text-center" }, [
            _c(
              "label",
              { staticClass: "mt-5 h6", staticStyle: { "font-size": "1.5em" } },
              [_vm._v(_vm._s(_vm.errorMessage + ":"))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "link-primary",
                attrs: {
                  href: `/toolstring_config/${_vm.jobNumber}/${_vm.wellIndex}`,
                },
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "text-decoration": "underline",
                      "font-size": "1.5em",
                    },
                  },
                  [_vm._v("set stage 1 toolstring to proceed")]
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("cluster-spacing-config", {
        ref: "clusterConfig",
        attrs: {
          wells: _vm.wellsData,
          currentWell: _vm.selectedWellId,
          isCompanyAdmin: _vm.isCompanyAdmin,
          isAdmin: _vm.isAdmin,
          iwsUser: _vm.iwsUser,
          jobNumber: _vm.jobNumber,
          currentWellData: _vm.selectedWell,
          clusterInfo: _vm.allClusterInfo,
        },
      }),
      _vm._v(" "),
      _c("wireline-comment-modal", {
        ref: "wirelineComment",
        attrs: {
          jobNumber: _vm.jobNumber,
          value: _vm.commentInfo.currentComment,
          gunNumber: _vm.commentInfo.gunNumber,
          stageNumber: _vm.commentInfo.stageNumber,
          isTableChanged: _vm.isTableChanged,
          wellIndex: _vm.commentInfo.wellIndex,
          type: _vm.commentInfo.type,
          rowIndex: _vm.commentInfo.rowIndex,
          colIndex: _vm.commentInfo.colIndex,
          wellName: _vm.wellName,
          lastShotDepth: _vm.commentInfo.lastShotDepth,
          clusterInfo: _vm.clusterInfo,
          actualSpacing: _vm.commentInfo.actualSpacing,
          wirelineId: _vm.commentInfo.wirelineId,
          wirelineDepth: _vm.commentInfo.depth,
          isAlert: _vm.commentInfo.isAlert,
          shotSheetReferenced: _vm.commentInfo.shotSheetReferenced
            ? true
            : false,
        },
        on: { onSavePressed: _vm.onSavePressed },
      }),
      _vm._v(" "),
      _c(
        "resolve-shot-modal",
        _vm._b(
          {
            attrs: { isVisible: _vm.showShotResolveModal },
            on: {
              onDismiss: () => {
                _vm.showShotResolveModal = false
              },
              onSubmit: _vm.onShotResolved,
              onDeclineSubmit: _vm.onShotDeclined,
            },
          },
          "resolve-shot-modal",
          _vm.shotResolveModalProps,
          false
        )
      ),
      _vm._v(" "),
      _c("critical-alert-modal-component", {
        attrs: { jobNumber: _vm.jobNumber },
      }),
      _vm._v(" "),
      _c("iws-modal", {
        attrs: {
          id: "clear-alerts-modal",
          title: "Clear Missing Shots Alerts",
          "show-modal": _vm.clearAlertsModalIsOpen,
          "max-width": "800px",
          primaryButtonText: "Clear Alerts",
          secondaryButtonText: "Cancel",
          position: "top-middle",
        },
        on: {
          close: function ($event) {
            _vm.clearAlertsModalIsOpen = false
          },
          "secondary-action": function ($event) {
            _vm.clearAlertsModalIsOpen = false
          },
          "primary-action": function ($event) {
            return _vm.clearMissedShotsAlerts()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("h6", [
                  _vm._v(
                    "\n                There are total of " +
                      _vm._s(_vm.totalAlerts[0]) +
                      " missing shots alerts. Are you sure you want to clear all alerts?\n            "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("label", { staticClass: "mt-5 h5" }, [_vm._v("Perforations")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { staticClass: "secondary-bg-dark" }, [
      _vm._v("Depth Adjustment"),
      _c("br"),
      _vm._v("(ft)"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { staticClass: "secondary-bg-dark" }, [
      _vm._v("Depth Adjustment"),
      _c("br"),
      _vm._v("(ft)"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("label", { staticClass: "mt-5 h5" }, [_vm._v("Plugs")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { staticClass: "secondary-bg-dark" }, [
      _vm._v("Depth Adjustment"),
      _c("br"),
      _vm._v("(ft)"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { staticClass: "secondary-bg-dark" }, [
      _vm._v("Depth Adjustment"),
      _c("br"),
      _vm._v("(ft)"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }