import { render, staticRenderFns } from "./kpiTotal.vue?vue&type=template&id=00ed989a&"
import script from "./kpiTotal.vue?vue&type=script&lang=js&"
export * from "./kpiTotal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/project/releases/10508/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('00ed989a')) {
      api.createRecord('00ed989a', component.options)
    } else {
      api.reload('00ed989a', component.options)
    }
    module.hot.accept("./kpiTotal.vue?vue&type=template&id=00ed989a&", function () {
      api.rerender('00ed989a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/DashboardComponents/KpiItems/kpiTotal.vue"
export default component.exports