<script>
import GlobalFunctions from '../../../GlobalFunctions.js';
const { isFalsy, isNullOrEmpty } = GlobalFunctions;

import DateFunctions from '../../../DateFunctions.js';
const { convertHoursToHourAndMinutes } = DateFunctions;

import constants from './constants.js';
const { 
    TOP_PADDING_KPI,
    NO_VALUE_PLACEHOLDER,
    DEFAULT_DATE_FORMAT,
    SECONDARY_DATE_FORMAT,
    ANALYTICS_COLORS,
    chartStyle
 } = constants;

const moment = require('moment');

import { v4 as uuidv4 } from "uuid";

export default {
    props: {
        item: Object,
        job: Object,
        jobNumber: String,
        initalOptions: Object,
        customer: Object,
        editMode: Boolean,
        dashboardData: Object,
        height: Number
    },

    data: () => ({
        allowShowChart: false,
        autoUpdateInterval: null,
        data: null,
        errors: [],
        analyticsData: []
    }),

    computed: {
        _uuid: () => uuidv4(),
        triggerKpiChanges: function() {
            return `${this.kpi?.datasets?.length}_${this.kpi?.labels?.length}_${this.analyticsData?.length}`;
        },
        dayTypes: function() {
            const types = [
                'Shift start to shift start', // Default time option for all KPI types
                'Midnight to midnight'
            ];

            if (this.item.options.type !== 'kpi-progress')
                types.push('Day shift and night shift');

            if (this.item.options.type == 'kpi-pumpingHours')
                types.push('Stage');

            return types;
        },
        getTimeRangeDescription: function() {
            let type = this.item.options.dayType ? this.item.options.dayType : this.dayTypes[0];
            // If day type is 'Stage' then return 'Stage' as the time range description and exit
            if (this.dayTypes[3] && type === this.dayTypes[3]) {
                return this.dayTypes[3];
            }
            const shiftStartTime = this.getShiftStartTimes();
            let dayShiftStart = '';
            let timeRangeDescription = '';
            if (shiftStartTime.dayShift != 'Invalid date') {
                let startHour = parseInt(shiftStartTime.dayShift.split(':')[0]);
                let startTime = startHour > 12 ? shiftStartTime.nightShift : shiftStartTime.dayShift;
                startTime = startTime.slice(0, -3); // format time to remove seconds and leading 0
                startTime = startTime.replace(/^0+/, '');
                startTime = startHour === 0 ? '12' + startTime : startTime;
                let shiftTimeDescription = startHour < 12 ? 'AM' : 'PM';
                dayShiftStart = ' (' + startTime + shiftTimeDescription + ' shift change)';
            }

            if (this.dayTypes[0] && type === this.dayTypes[0]) {
                timeRangeDescription = 'Shift Start to Shift Start' + dayShiftStart;
            } else if (this.dayTypes[1] && type === this.dayTypes[1]) {
                timeRangeDescription = 'Midnight to Midnight (12:00AM to 12:00AM)';
            } else if (this.dayTypes[2] && type === this.dayTypes[2]) {
                timeRangeDescription = 'Day Shift and Night Shift' + dayShiftStart;
            } else {
                timeRangeDescription = '';
            }
            return timeRangeDescription;
        },
        totalStages: function() {
            return this.dashboardData?.wells?.reduce((_sum, item) => _sum + item.numberOfStages, 0) || 0;
        }
    },

    methods: {
        _isFalsy: (val) => isFalsy(val),
        _isNullOrEmpty: (val) => isNullOrEmpty(val),

        // Creates the shared data object taking context from the inheriting components in order to provide custom data points
        // Variables the inheriting file should provide:
        //      xAxisLabel: String
        //      yAxisLabel: String
        //      datasets: Array of Objects
        //      tooltip_callback: Function(tooltipItem, data)
        buildKpi: function(_super) {
            return {
                labels: [],
                datasets: _super.datasets
            }
        },
        buildOptions: function(_super) {
            return {
                layout: { padding: { top: TOP_PADDING_KPI }},
                annotation: {
                    annotations: []
                },
                plugins: {
                    datalabels: _super.datalabels || {
                        display: true,
                        color: chartStyle.labelFontColor,
                        font: { size: 16 },
                        formatter: null,
                        anchor: 'end',
                        align: 'top'
                    },
                    zoom: {
                        pan: {
                            enabled: false,
                            mode: 'x',
                            rangeMin: {
                                x: null
                            },
                            rangeMax: {
                                x: null
                            }
                        },
                        zoom: {
                            enabled: false,
                            drag: false
                        }
                    }
                },
                legend: _super.legend || {
                    display: false
                },
                scales: {
                    xAxes: [{
                        id: 'x-axis',
                        scaleLabel: {
                            display: true,
                            fontColor: chartStyle.labelFontColor,
                            fontSize: 14,
                            labelString: _super.xAxisLabel || ''
                        },
                        gridLines: {
                            color: 'transparent'
                        },
                        ticks: {
                            fontColor: chartStyle.labelFontColor,
                            callback: 'xTick_callback' in _super && typeof _super.xTick_callback == 'function' 
                                ? _super.xTick_callback 
                                : value => value
                        }
                    }],
                    yAxes: [{
                        scaleLabel: {
                            display: true,
                            fontColor: chartStyle.labelFontColor,
                            fontSize: 14,
                            labelString: _super.yAxisLabel || ''
                        },
                        ticks: {
                            beginAtZero: true,
                            stepSize: 'y_stepSize' in _super ? _super.y_stepSize : 15,
                            suggestedMin: 'y_suggestedMin' in _super ? _super.y_suggestedMin : 0,
                            suggestedMax: 'y_suggestedMax' in _super ? _super.y_suggestedMax : null,
                            maxRotation: 0,
                            fontColor: chartStyle.labelFontColor,
                            callback: 'yTick_callback' in _super && typeof _super.yTick_callback == 'function' 
                                ? _super.yTick_callback 
                                : value => value
                        },
                        gridLines: {
                            color: chartStyle.gridLinesColor
                        }
                    }]
                },
                tooltips: {
                    enabled: false,
                    mode: 'nearest',
                    intersect: true,
                    position: 'cursor',
                    animationDuration: 0,
                    backgroundColor: '#000',
                    callbacks: {
                        label: 'tooltip_callback' in _super && typeof _super.tooltip_callback == 'function' 
                            ? _super.tooltip_callback 
                            : tooltipItem => `${tooltipItem.label}: ${tooltipItem?.value}` || tooltipItem
                    },
                    custom: function(tooltipModel) {
                        // Tooltip Element
                        let tooltipEl = document.getElementById('chartjs-tooltip-summary-bar');

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip-summary-bar';
                            tooltipEl.innerHTML = '<table></table>';
                            document.body.appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }

                        // Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            const titleLines = tooltipModel.title || [];
                            const bodyLines = tooltipModel.body.map(getBody);

                            let innerHtml = '<thead>';

                            titleLines.forEach(function(title) {
                                const style = 'width : 10px; height: 10px; border-width : 1px;';
                                innerHtml += '<tr><th><div class="d-flex pr-3"><div class="mx-2 mt-1" style="' + style + '"></div>' + title + '</div></th></tr>';
                            });
                            innerHtml += '</thead><tbody>';


                            bodyLines.forEach(function(body, i) {
                                const colors = tooltipModel.labelColors[i];
                                if(body && body.length>0) {
                                    const style = `background: ${typeof colors.backgroundColor === 'string'? colors.backgroundColor : 'transparent'}; width : 9px; height: 9px; border-width : 1px; border-color: ${typeof colors.backgroundColor === 'string'? '#FFFFFF' : 'transparent'}; border-style: solid`;
                                    innerHtml += '<tr><td><div class="d-flex pr-3"> <div class="mx-2 mt-1" style="' + style + '"></div>' + body + ' </div></td></tr>';
                                }
                            });
                            innerHtml += '</tbody>';

                            const tableRoot = tooltipEl.querySelector('table');
                            tableRoot.innerHTML = innerHtml;
                        }

                        // `this` will be the overall tooltip
                        const position = this._chart.canvas.getBoundingClientRect();

                        //mouse position
                        let offset = tooltipModel.caretX;

                        //when the tooltip tries to render at the right edge
                        //of the screen, give it more space to the left
                        const averageTooltipWidth = 150;
                        if (tooltipModel.caretX > this._chart.width - averageTooltipWidth)
                        {offset = this._chart.width - averageTooltipWidth;}

                        // Display, position, and set styles for font
                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.position = 'fixed';
                        tooltipEl.style.left = position.left + offset + 'px';
                        tooltipEl.style.top = position.top + tooltipModel.caretY + 'px';
                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                        tooltipEl.style.padding = 2 + 'px ' + 0 + 'px';
                        tooltipEl.style.pointerEvents = 'none';
                    }
                },
                elements: {
                    point: {
                        radius: 5,
                        hoverRadius: 8
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            };
        },
        BaseKpi: function(_super) {
            return {
                kpi: this.buildKpi(_super),
                options: this.buildOptions(_super)
            };
        },

        chartContentHeight: function() {
            const refs = { ...this.$refs, ...this.$refs?.baseKpiTemplate.$refs };
            const headerHeight = 32;
            const analyticsHeight = refs?.analyticsInfo?.getBoundingClientRect()?.height || 0;
            const extraDetailsHeight = refs?.extraInfo?.getBoundingClientRect()?.height || 0;
            const errorsHeight = refs?.errorsInfo?.getBoundingClientRect()?.height || 0;
            const paddingHeight = 24;
            const extraHeight = [ 'kpi-total', 'kpi-progress', 'kpi-lateral', 'kpi-pumpingHours' ].includes(this.item.options.type) ? 15 : 0;  // Some kpis don't have an x-axis label, we can take that extra height for the chart
            
            return `${this.height+25+extraHeight - headerHeight - analyticsHeight - extraDetailsHeight - errorsHeight - paddingHeight}px`;
        },
        assignChartSize: function() {
            const chart = this.getChartRef();

            if (!isFalsy(chart?.canvas?.parentNode?.style)) {
                chart.canvas.parentNode.style.height = this.chartContentHeight();
                chart.resize();
            }
        },
        setChartSmartStepSize: function(data, roundTo, min, max, preferedSteps=10) {
            data = data.filter(_num => !isFalsy(_num)) || [];
            
            const range = Math.max(...data) - Math.min(...data);
            const smartStepSize = Math.ceil(Math.ceil(range / preferedSteps) / roundTo) * roundTo;
            const fixedSmartStepSize = Math.min(Math.max(smartStepSize, min), max) || min;
            
            if (!isFalsy(this.options?.scales?.yAxes[0]?.ticks) && !isFalsy(fixedSmartStepSize))
                for (let i=0; i < this.options.scales.yAxes.length; i++)
                    this.options.scales.yAxes[i].ticks.stepSize = fixedSmartStepSize;
            return fixedSmartStepSize;
        },
        modifyArrayWithZeroHandling: function(usedDataSet) {
            //modify dataset based on zero stage setting
            if (this.item.options.zeroStageType == 'zeroStage-nonLeading')
                return usedDataSet.slice(usedDataSet.findIndex(_value => _value != 0))
            if (this.item.options.zeroStageType == 'zeroStage-none')
                return usedDataSet.filter(val => val != 0);
            return usedDataSet;
        },
        appendZeroEntriesToEnd: function(chartData, minDisplayDates=10, timeFormat='YYYY-MM-DD HH:mm:ss') {
            if ((chartData?.labels?.length < minDisplayDates) && chartData?.datasets) {
                const datasets = chartData.datasets;
                const labelsToAdd = minDisplayDates - chartData.labels.length;
                const lastDate = moment(chartData.labels[chartData.labels.length-1]);

                _.times(labelsToAdd, ()=> {
                    const missedLabel = lastDate.add({ days: 1 }).format(timeFormat);
                    chartData.labels.push(missedLabel);
                });

                datasets.forEach(dataset => {
                    const dataToAdd = minDisplayDates - dataset.data.length;
                    _.times(dataToAdd, ()=> {
                        dataset.data = [...dataset.data, 0];
                    });
                });
            }
        },
        getShiftStartTimes: function() {
            const { hours, minutes } = this.getShiftStartTimeInHourAndMinutes();
            const shiftStartDateTime = new Date();
            shiftStartDateTime.setHours(hours, minutes, 0);

            return { //get only the shift times (not dates) as a string
                dayShift: moment(shiftStartDateTime).format('HH:mm:ss'),
                nightShift: moment(shiftStartDateTime).add(12,'hours').format('HH:mm:ss')
            };
        },
        getShiftStartTimeInHourAndMinutes: function() {
            return convertHoursToHourAndMinutes(this.job.shiftStart);
        },
        getAverage: function(dataArray) {
            return _.mean(dataArray);
        },
        getMedian: function(rawArray) {
            if (rawArray.length === 0)
                return;
            const dataArray = _.cloneDeep(rawArray);
            if (dataArray.length === 0)
                return null;

            dataArray.sort((a, b) => a - b);

            const half = Math.floor(dataArray.length / 2);

            if (dataArray.length % 2)
                return dataArray[half];
            return (dataArray[half - 1] + dataArray[half]) / 2.0;
        },
        getChartRef: function() {
            try {
                return this.$refs.kpi.$data._chart;
            } catch (error) {
                return null; //no ref found;
            }
        },
        getExportFilename: function(pre) {
            // Export filename to format as follows: <customer>_<WO number>_<pad name>_KPI-output type-filter details_<date of export>
            // Example: Aethon_FS12518-BARBO SGT PEPPER 1H 2HB_KPI-FracToWirelineWellSwapTime_FilteredLessThan45 mins_23-Jun-2023.csv
            return `${this.customer.name}_${this.jobNumber}_${this.job.padName || this.job.location}_${pre}_${moment().format('DD-MMMM-YYYY HH-mm-ss')}`;
        },
        processExportItems: function(dataSets, labels, isDayNight=false) {
            let items = [];

            for (let i = 0; i < labels.length; i++) {
                if (this.item?.options?.includePumpHoursPlotline) {
                    if (isDayNight) {
                        if (this.item.options?.isStackedByWell) {
                            const rawDatasets_stages = dataSets.filter(dataset => !dataset.type).map(dataset => dataset.data[i]);
                            let rawDayDatasets_pump = dataSets.filter((dataset)=>(dataset.label == 'day' && dataset.type === 'line'));
                            let rawNightDatasets_pump = dataSets.filter((dataset)=>(dataset.label == 'night' && dataset.type === 'line'));

                            items.push([
                                labels[i],
                                ...rawDatasets_stages,
                                rawDayDatasets_pump[0]?.data[i] ?? NO_VALUE_PLACEHOLDER,
                                rawNightDatasets_pump[0]?.data[i] ?? NO_VALUE_PLACEHOLDER
                            ]);
                        } else {
                            let rawDayDatasets_stages = dataSets.filter((dataset)=>(dataset.label == 'day' && !dataset?.type));
                            let rawNightDatasets_stages = dataSets.filter((dataset)=>(dataset.label == 'night' && !dataset?.type));
                            let rawDayDatasets_pump = dataSets.filter((dataset)=>(dataset.label == 'day' && dataset.type === 'line'));
                            let rawNightDatasets_pump = dataSets.filter((dataset)=>(dataset.label == 'night' && dataset.type === 'line'));

                            items.push([
                                labels[i],
                                rawDayDatasets_stages[0]?.data[i] ?? NO_VALUE_PLACEHOLDER,
                                rawNightDatasets_stages[0]?.data[i] ?? NO_VALUE_PLACEHOLDER,
                                rawDayDatasets_pump[0]?.data[i] ?? NO_VALUE_PLACEHOLDER,
                                rawNightDatasets_pump[0]?.data[i] ?? NO_VALUE_PLACEHOLDER
                            ]);
                        }
                    } else {
                        //works ifWellStacked is true or false
                        const rawDatasets_stages = dataSets.filter(dataset => !dataset.type).map(dataset => dataset.data[i]);
                        const rawDatasets_pump = dataSets.filter(dataset => dataset.type === 'line').map(dataset => dataset?.data[i]);
                        items.push([labels[i], ...rawDatasets_stages ?? NO_VALUE_PLACEHOLDER, ...rawDatasets_pump ?? NO_VALUE_PLACEHOLDER]);
                    }
                } else if (isDayNight) {
                    items.push([labels[i], dataSets[0].data[i] ?? NO_VALUE_PLACEHOLDER, dataSets[1].data[i] ?? NO_VALUE_PLACEHOLDER]);
                } else {
                    items.push([labels[i], dataSets[0].data[i] ?? NO_VALUE_PLACEHOLDER]);
                }
            }

            return items;
        },
        buildAnalyticsData: function(chart=this.getChartRef()) {
            if (!isNullOrEmpty(chart?.options?.annotation?.annotations)) {
                this.analyticsData = chart.options.annotation.annotations
                    .filter(an => an.legendInfo)
                    .map(annotation => {
                        return {
                            type: annotation.legendInfo.type,
                            label: annotation.legendInfo.content,
                            backgroundColor: annotation.legendInfo.backgroundColor
                        };
                    });
            } else {
                this.analyticsData = [];
            }
        },
        onAnalyticsTypeChange: function(calculationTypes) {
            const chart = this.getChartRef();
            let datasets = null;

            if (this.item.options.type === 'kpi-total' || this.item.options.type === 'kpi-lateral' || this.item.options.type === 'kpi-pumpingHours') {
                datasets = this.analyticDatasets; //datasets without appended zeroes
            } else {
                datasets = chart?.data?.datasets;
            }

            if(!datasets || !chart?.options?.annotation?.annotations) { return; }

            const isDayNight = this.item.options.dayType === 'Day shift and night shift';
            const containsAverage = calculationTypes?.includes('Average');
            const containsMedian = calculationTypes?.includes('Median');

            // remove previous analytics annotations so they can be recreated
            chart.options.annotation.annotations = chart.options.annotation.annotations.filter(anno => anno.scaleID === 'x-axis');

            const scaleID = chart?.options?.scales?.yAxes[0]?.id;
            const yAxisPumpPlotline = this.item?.options?.includePumpHoursPlotline ? chart?.options?.scales?.yAxes[1] : null;
            const scaleIDPumpPlotline = yAxisPumpPlotline ? yAxisPumpPlotline.id : scaleID;

            let rawDataPoints = this.modifyArrayWithZeroHandling(datasets[0].data);
            let dayShiftDataPoints = null;
            let nightShiftDataPoints = null;
            const rawDatasets_stages = [];
            const rawDatasets_pump = [];
            const rawDayDatasets_stages = [];
            const rawNightDatasets_stages = [];
            const rawDayDatasets_pump = [];
            const rawNightDatasets_pump = [];

            if (scaleID) {
                if (this.item.options?.isStackedByWell || isDayNight) {
                    const rawDatasets = datasets.map((dataset)=>dataset.data);
                    rawDataPoints = this.modifyArrayWithZeroHandling(_.zipWith(...rawDatasets, (...datasetValues) => _.sum(datasetValues)));
                }

                if (isDayNight) {
                    if (this.item.options?.isStackedByWell) {
                        const rawDayDatasets = datasets.filter((dataset)=>(dataset.stack == 'day')).map((dataset)=>dataset.data);
                        const rawNightDatasets = datasets.filter((dataset)=>(dataset.stack == 'night')).map((dataset)=>dataset.data);

                        dayShiftDataPoints = this.modifyArrayWithZeroHandling(_.zipWith(...rawDayDatasets, (...datasetValues) => _.sum(datasetValues)));
                        nightShiftDataPoints = this.modifyArrayWithZeroHandling(_.zipWith(...rawNightDatasets, (...datasetValues) => _.sum(datasetValues)));
                    } else {
                        dayShiftDataPoints = this.modifyArrayWithZeroHandling(datasets[0]?.data ?? 0);
                        nightShiftDataPoints = this.modifyArrayWithZeroHandling(datasets[1]?.data ?? 0);
                    }
                }

                datasets.forEach(dataset => {
                    if (this.item?.options?.includePumpHoursPlotline) {
                        if (!dataset.type)
                            rawDatasets_stages.push(dataset.data);
                        else if (dataset.type === 'line')
                            rawDatasets_pump.push(dataset.data);
                    }

                    if (!dataset.type) {
                        if (dataset.label == 'day' || dataset.stack == 'day')
                            rawDayDatasets_stages.push(dataset.data);
                        else if (dataset.label == 'night' || dataset.stack == 'night')
                            rawNightDatasets_stages.push(dataset.data);
                    } else {
                        if (dataset.label == 'day' && dataset.type === 'line')
                            rawDayDatasets_pump.push(dataset.data);
                        else if (dataset.label == 'night' && dataset.type === 'line')
                            rawNightDatasets_pump.push(dataset.data);
                    }
                });
                
                if (this.item?.options?.includePumpHoursPlotline) {
                    var rawDataPoints_stages = this.modifyArrayWithZeroHandling(_.zipWith(...rawDatasets_stages, (...datasetValues) => _.sum(datasetValues)));
                    var rawDataPoints_pump = this.modifyArrayWithZeroHandling(_.zipWith(...rawDatasets_pump, (...datasetValues) => _.sum(datasetValues)));
                }
                
                if (isDayNight) {
                    if (this.item?.options?.includePumpHoursPlotline) {
                        const dayShiftDataPoints_stages = this.modifyArrayWithZeroHandling(_.zipWith(...rawDayDatasets_stages, (...datasetValues) => _.sum(datasetValues)));
                        const nightShiftDataPoints_stages = this.modifyArrayWithZeroHandling(_.zipWith(...rawNightDatasets_stages, (...datasetValues) => _.sum(datasetValues)));
                        const dayShiftDataPoints_pump = this.modifyArrayWithZeroHandling(_.zipWith(...rawDayDatasets_pump, (...datasetValues) => _.sum(datasetValues)));
                        const nightShiftDataPoints_pump = this.modifyArrayWithZeroHandling(_.zipWith(...rawNightDatasets_pump, (...datasetValues) => _.sum(datasetValues)));


                        if (containsAverage) {
                            this.drawHorizontalLine('Day Avg. (Stages)', this.getAverage(dayShiftDataPoints_stages) || 0, scaleID, ANALYTICS_COLORS[0]);
                            this.drawHorizontalLine('Night Avg. (Stages)', this.getAverage(nightShiftDataPoints_stages) || 0, scaleID, ANALYTICS_COLORS[1]);
                            this.drawHorizontalLine('Day Avg. (Pump)', this.getAverage(dayShiftDataPoints_pump) || 0, scaleIDPumpPlotline, ANALYTICS_COLORS[2]);
                            this.drawHorizontalLine('Night Avg. (Pump)', this.getAverage(nightShiftDataPoints_pump) || 0, scaleIDPumpPlotline, ANALYTICS_COLORS[3]);
                        }

                        if (containsMedian) {
                            this.drawHorizontalLine('Day Mdn. (Stages)', this.getMedian(dayShiftDataPoints_stages) || 0, scaleID, ANALYTICS_COLORS[4]);
                            this.drawHorizontalLine('Night Mdn. (Stages)', this.getMedian(nightShiftDataPoints_stages) || 0, scaleID, ANALYTICS_COLORS[5]);
                            this.drawHorizontalLine('Day Mdn. (Pump)', this.getMedian(dayShiftDataPoints_pump) || 0, scaleIDPumpPlotline, ANALYTICS_COLORS[6]);
                            this.drawHorizontalLine('Night Mdn. (Pump)', this.getMedian(nightShiftDataPoints_pump) || 0, scaleIDPumpPlotline, ANALYTICS_COLORS[7]);
                        }
                    } else {
                        if (containsAverage) {
                            this.drawHorizontalLine('Day Average', this.getAverage(dayShiftDataPoints) || 0, scaleID, ANALYTICS_COLORS[0]);
                            this.drawHorizontalLine('Night Average', this.getAverage(nightShiftDataPoints) || 0, scaleID, ANALYTICS_COLORS[1]);
                        }

                        if (containsMedian) {
                            this.drawHorizontalLine('Day Median', this.getMedian(dayShiftDataPoints) || 0, scaleID, ANALYTICS_COLORS[2]);
                            this.drawHorizontalLine('Night Median', this.getMedian(nightShiftDataPoints) || 0, scaleID, ANALYTICS_COLORS[3]);
                        }
                    }
                } else if (this.item?.options?.includePumpHoursPlotline) {
                    if (containsAverage) {
                        this.drawHorizontalLine(`Average (Stages)`, this.getAverage(rawDataPoints_stages) || 0, scaleID, ANALYTICS_COLORS[0]);
                        this.drawHorizontalLine(`Average (Pump)`, this.getAverage(rawDataPoints_pump) || 0, scaleIDPumpPlotline, ANALYTICS_COLORS[1]);
                    }

                    if (containsMedian) {
                        this.drawHorizontalLine(`Median (Stages)`, this.getMedian(rawDataPoints_stages) || 0, scaleID, ANALYTICS_COLORS[2]);
                        this.drawHorizontalLine(`Median (Pump)`, this.getMedian(rawDataPoints_pump) || 0, scaleIDPumpPlotline, ANALYTICS_COLORS[3]);
                    }
                } else {
                    if (containsAverage)
                        this.drawHorizontalLine('Average', this.getAverage(rawDataPoints) || 0, scaleID, ANALYTICS_COLORS[0]);
                    
                    if (containsMedian)
                        this.drawHorizontalLine('Median', this.getMedian(rawDataPoints) || 0, scaleID, ANALYTICS_COLORS[1]);
                }
            }
                
            chart.update();
        },
        drawHorizontalLine: function(calculationType, value, scaleId, color, contentConverter=(value) => value.toFixed(2), allowUpdate=false) {
            if (isFalsy(value) || typeof value != 'number' || isFalsy(scaleId) || this.item.options.type === 'kpi-progress') {
                console.warn('invalid data set for annotations');
                console.warn({ calculationType, value, scaleId, color, kpiType: this.item?.options?.type });
                return;
            }

            const chart = this.getChartRef();

            if (isFalsy(chart))
                return;

            if (!('annotations' in chart.options?.annotation) || chart.options.annotation?.annotations == null)
                chart.options.annotation = { annotations: [] };

            chart.options.annotation.annotations.push({
                type: 'line',
                mode: 'horizontal',
                scaleID: scaleId,
                value: value,
                borderWidth: 1.5,
                borderColor: color,
                borderDash: [5,5],
                legendInfo: {
                    type: calculationType,
                    content: calculationType + ': ' + contentConverter(value),
                    backgroundColor: color
                }
            });

            // If we expect multiple successive calls to this function (i.e. buildData), we can delay rerenders to the chart until all are done
            if (allowUpdate)
                chart.update();
        },
        createJobTimeAnnotationLines: function() {
            const chart = this.getChartRef();
            if (!chart || (!this.job?.start && !this.job?.end)) {
                return;
            }

            //charts use different time formats for their x axis values
            const formatArray = [DEFAULT_DATE_FORMAT, SECONDARY_DATE_FORMAT, 'YYYY-MM-DD'];
            const dateTicks = chart.scales['x-axis']._ticks;
            const defaultAnnotation = {
                type: 'line',
                drawTime: 'beforeDatasetsDraw',
                mode: 'vertical',
                scaleID: 'x-axis',
                value: null,
                borderColor: 'red',
                borderWidth: 2,
                borderDash: [5,8],
                label: {
                    content: '',
                    enabled: true,
                    position: 'top',
                    backgroundColor: 'red'
                }
            };

            if (this.job?.start && dateTicks.length > 0) {
                const newAnnotation = _.cloneDeep(defaultAnnotation);
                const startDate = moment.utc(this.job.start, formatArray).add({hours: this.job.hourOffset}).format(('MMM D'));
                const startIndex = dateTicks.findIndex(tick => moment.utc(tick.value, formatArray).format('MMM D') == startDate);
                newAnnotation.value = startIndex;
                newAnnotation.id = 'job-start';
                newAnnotation.label.content = 'Job Start';
                chart.options.annotation.annotations.push(newAnnotation);
            }
            if (this.job?.end && dateTicks.length > 0) {
                const newAnnotation = _.cloneDeep(defaultAnnotation);
                const endDate = moment.utc(this.job.end,formatArray).add({hours: this.job.hourOffset}).format(('MMM D'));
                const endIndex = dateTicks.findIndex(tick => moment.utc(tick.value, formatArray).format('MMM D') == endDate);
                newAnnotation.value = endIndex;
                newAnnotation.id = 'job-end';
                newAnnotation.label.content = 'Job End';
                chart.options.annotation.annotations.push(newAnnotation);
            }
            
            // update to create the annotation elements
            chart.update();

            if (this.job?.start) {
                const element = Object.values(chart.annotation.elements).find(element => element.id == 'job-start');
                //job start annotation is right shifted so it stays within view if placed on chart's first day
                if(element) {
                    const xAdjustValue = (element._model.labelWidth / 2) - 1;
                    element.options.label.xAdjust = -1*xAdjustValue;
                }
            }
            if (this.job?.end) {
                const element = Object.values(chart.annotation.elements).find(element => element.id == 'job-end');
                //job start annotation is left shifted so it stays within view if placed on chart's last day
                if(element) {
                    const xAdjustValue = (element._model.labelWidth / 2) - 1;
                    element.options.label.xAdjust = xAdjustValue;
                }
            }

            //update to adjust annotation positions
            chart.update();
        },
        shiftDatesByStartTime: function(shiftStartTime) {
            if (isFalsy(this.dataLookup))
                return;
            
            const keys = Object.keys(this.dataLookup);
            const entriesToPrepend = [];

            keys.forEach((key, index) => {
                const value = this.dataLookup[key];
                let entriesToMove = [];
                let cleanedList = [];

                value.forEach(_value => {
                    const _endHours = _value?.endTime?.hours();

                    if (_endHours < shiftStartTime) {
                        entriesToMove.push(_value);
                    } else if (_endHours >= shiftStartTime) {
                        cleanedList.push(_value);
                    }
                });

                this.dataLookup[key] = cleanedList;

                if (!isNullOrEmpty(entriesToMove)) {
                    // handles case where we need to push days to an earlier date, push to
                    // data lookup after the loop to avoid editing arrays in place during loop
                    if (index == 0) {
                        entriesToPrepend.push(entriesToMove);
                    } else {
                        this.dataLookup[keys[index-1]] = this.dataLookup[keys[index-1]].concat(entriesToMove);
                    }
                }
            });
            
            // prepend the earlier date
            if (!isNullOrEmpty(entriesToPrepend)) {
                const firstDate = moment.utc(parseInt(keys[0]));
                const dayBefore = firstDate.subtract({days: 1});
                const dayBeforeString = dayBefore.valueOf().toString();

                this.dataLookup[dayBeforeString] = dayBeforeString in this.dataLookup
                    ? [ ...this.dataLookup[dayBeforeString], ...entriesToPrepend ]
                    : entriesToPrepend;
            }

            //TODO :
            //On some occasions due to timestamp collisions instead of getting a single stage object you may receive multiple
            //Because of this I have added handling for that case to flatten the array
            //but it points to fundamental issues in how lookup is being constructed
            //this should be fixed so that all of the index's are unique so that there is no doublepacking creating sub arrays
            for (const timestamp in this.dataLookup)
                if (Object.prototype.hasOwnProperty.call(this.dataLookup, timestamp) && Array.isArray(this.dataLookup[timestamp]))
                    this.dataLookup[timestamp] = this.dataLookup[timestamp].flat();
        },
        createLookupByDate: function(data) {
            let dataLookup = {};
            const sortDataLookup = () => {
                dataLookup = Object.keys(dataLookup).sort().reduce(
                    (obj, key) => {
                        obj[key] = dataLookup[key];
                        return obj;
                    },
                    {}
                );
            }

            data.forEach(_data => {
                const timestamp = moment(_data.endTime).startOf('day').valueOf().toString();

                if (timestamp in dataLookup) {
                    dataLookup[timestamp].push(_data);
                } else {
                    dataLookup[timestamp] = [_data];
                }
            })

            //order the keys by date
            sortDataLookup();

            if (!isNullOrEmpty(Object.keys(dataLookup))) {
                // Get all days in range between dates can't use moment-range due to issues with IOS
                const dateKeys = Object.keys(dataLookup);
                const from = moment(parseInt(dateKeys[0])).utc();
                const to = moment(parseInt(dateKeys[dateKeys.length - 1])).utc();
                const diff = to.diff(from, 'days');
                const allDays = [];

                for (let i = 0; i < diff; i++)
                    allDays.push(moment(parseInt(dateKeys[0])).utc().add(i, 'days'));

                const dateRange = allDays.map(day => day.valueOf().toString());
                let newDatesAdded = false;
                dateRange.forEach(dateKey => {
                    if (!dataLookup[dateKey]) {
                        newDatesAdded = true;
                        dataLookup[dateKey] = [];
                    }
                });

                if (newDatesAdded)
                    sortDataLookup();
            }
            
            return dataLookup;
        },
        isNotEnoughDataMsgPerKPIType: function(kpi) {
            /* This function computes if there is no data based on the kpi type.
            If 'datasets' array for that type is empty, or if all 'data' arrays
            within 'datasets' is empty, or there are multiple 'data' arrays but
            they have all falsy values like null's or zeroes, then there is not enough
            data to generate a KPI summary */
            if (kpi) {
                //if dataset is empty (length=0) then return true
                if (isNullOrEmpty(kpi?.datasets))
                    return true;

                //if data arrays for each dataset has length zero or all falsy values (null, 0, etc), then return true
                return !!kpi.datasets.every(el => isNullOrEmpty(el?.data) || el.data.every(item => Boolean(item) === false) === true);
            }

            return false;
        }
    },

    async created() {
        if (!isFalsy(this.setDefaults))
            await this.setDefaults();
        this.initKPI();

        this.autoUpdateInterval = setInterval(() => {
            this.fetchData().then(this.buildData);
        }, 60*5*1000)
    },
    beforeDestroyed() {
        clearInterval(this.autoUpdateInterval)
    }
}
</script>

<template><div></div></template>
    