import { render, staticRenderFns } from "./CameraNavigation.vue?vue&type=template&id=59935fa5&scoped=true&"
import script from "./CameraNavigation.vue?vue&type=script&lang=js&"
export * from "./CameraNavigation.vue?vue&type=script&lang=js&"
import style0 from "./CameraNavigation.vue?vue&type=style&index=0&id=59935fa5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59935fa5",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/project/releases/10508/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('59935fa5')) {
      api.createRecord('59935fa5', component.options)
    } else {
      api.reload('59935fa5', component.options)
    }
    module.hot.accept("./CameraNavigation.vue?vue&type=template&id=59935fa5&scoped=true&", function () {
      api.rerender('59935fa5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/Cameras/CameraNavigation.vue"
export default component.exports