import { render, staticRenderFns } from "./LightningChart.vue?vue&type=template&id=fa4f1742&scoped=true&"
import script from "./LightningChart.vue?vue&type=script&lang=js&"
export * from "./LightningChart.vue?vue&type=script&lang=js&"
import style0 from "./LightningChart.vue?vue&type=style&index=0&id=fa4f1742&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa4f1742",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/project/releases/10508/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fa4f1742')) {
      api.createRecord('fa4f1742', component.options)
    } else {
      api.reload('fa4f1742', component.options)
    }
    module.hot.accept("./LightningChart.vue?vue&type=template&id=fa4f1742&scoped=true&", function () {
      api.rerender('fa4f1742', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/LightningChart.vue"
export default component.exports