<template>
<div id="CameraReportingViewer">
    <div class="CameraReportingControl">
        <camera-navigation :user="user" :device="device" :work-order-id="job.jobNumber" isReporting />

        <div class="full-width mb-5" id="api-builder-row">
            <h2>
                Red Zone Reporting
            </h2>

            <span class="float-right">
                <iws-select
                    label="Device"
                    :value="device.deviceId"
                    :options="devices"
                    display-name="deviceId"
                    value-name="deviceId"
                    required="required"
                    @change="setDevice"
                />

                <iws-date-time-picker 
                    class="ml-1"
                    label="Date Range"
                    :value.sync="range"
                    type="range"
                    dateTimeType="date"
                    @input="fetchReport"
                />

                <iws-button 
                    class="ml-1"
                    style="position: relative; top: -2px;"
                    text="Download PDF"
                    type="primary"
                    :click="downloadReport"
                />
            </span>
        </div>
    </div>
    
    <div v-if="report === null" class="full-width loading">
        <div class="spinner-border light-text-color" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div v-else-if="report.error">
        <h1 class="danger-text-color">
            Error Fetching Report
        </h1>
        <h3>
            {{ report.error }}
        </h3>
    </div>
    <div v-else id="CameraReporting">
        <div class="quick-details row mb-5">
            <div class="col-sm-6">
                <div class="quick-details-title">
                    Pad Name
                </div>
                <div class="quick-details-body">
                    {{ report.padName }}
                </div>
            </div>

            <div class="col-sm-6">
                <div class="quick-details-title">
                    Reporting Period
                </div>
                <div class="quick-details-body">
                    {{ report.readableStartDate }} - {{ report.readableEndDate }}
                </div>
            </div>
        </div>

        <div class="quick-details row mb-4">
            <div class="col-xs-6 col-sm-3">
                <div class="quick-details-title">
                    Total Alerts
                </div>
                <div class="quick-details-body">
                    {{ report.numAlertsTotal }}
                </div>
            </div>

            <div class="col-xs-6 col-sm-3">
                <div class="quick-details-title">
                    Total Alarms
                </div>
                <div class="quick-details-body">
                    {{ report.numAlarmsTotal }}
                </div>
            </div>

            <div class="col-xs-6 col-sm-3">
                <div class="quick-details-title">
                    Total Warnings
                </div>
                <div class="quick-details-body">
                    {{ report.numWarningsTotal }}
                </div>
            </div>

            <div class="col-xs-6 col-sm-3">
                <div class="quick-details-title">
                    Total Alarm Time (hh:mm:ss)
                </div>
                <div class="quick-details-body">
                    {{ report.readableAlarmTime }}
                </div>
            </div>
        </div>

        <div class="quick-details row mb-5">
            <div class="col-xs-6 col-sm-3">
                <div class="quick-details-title">
                    Model Version
                </div>
                <div class="quick-details-body">
                    {{ report.aiModelVersion }}
                </div>
            </div>

            <div class="col-xs-6 col-sm-3">
                <div class="quick-details-title">
                    SiteVision Version
                </div>
                <div class="quick-details-body">
                    {{ 'buildVersion' in status ? status.buildVersion : status.fieldBuildVersion }}
                </div>
            </div>

            <div class="col-xs-6 col-sm-3">
                <div class="quick-details-title">
                    Reported False Positives
                </div>
                <div class="quick-details-body">
                    {{ report.numFalsePositivesTotal }}
                </div>
            </div>

            <div class="col-xs-6 col-sm-3">
                <div class="quick-details-title">
                    Average Confidence
                </div>
                <div class="quick-details-body">
                    {{ report.readableAverageConfidence }}%
                </div>
            </div>
        </div>

        <div class="row mb-5">
            <div class="col-xs-12 col-sm-7">
                <b class="mb-3">
                    Alarms by Shift/Zone
                </b>

                <div class="violations-by-shift-heatmap-container">
                    <div class="violations-by-shift-heatmap-labels-item">
                        <div class="heatmap-label-row">
                            Day Shift
                        </div>
                        <div class="heatmap-label-row">
                            Night Shift
                        </div>
                    </div>

                    <div v-for="shiftViolation in report.sortedShiftViolations" :key="shiftViolation.globalId" class="violations-by-shift-heatmap-data-item">
                        <div class="zone-shift-violation-data" :style="shiftViolation.dayShiftStyle">
                            {{ shiftViolation.dayShiftViolationCount }}
                        </div>

                        <div class="zone-shift-violation-data" :style="shiftViolation.nightShiftStyle">
                            {{ shiftViolation.nightShiftViolationCount }}
                        </div>

                        <div class="zone-shift-violation-label">
                            {{ shiftViolation.name }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-12 col-sm-5" id="violation-breakdown">
                <b class="mb-1">
                    Alert Breakdown
                </b>

                <ViolationBreakdownChartComponent 
                    :data="{
                        alarmCount: report.numAlarmsTotal,
                        warningCount: report.numWarningsTotal
                    }"
                />

                <div>
                    <b style="color: #f44424; display: inline" >Alarm:</b> Red Zone was occupied and pressure exceeded set thresholds*
                </div>

                <div class="mb-1">
                    <b style="color: #fcac14; display: inline">Warning:</b> Red Zone was occupied, but pressure remained below set thresholds*
                </div>

                <div class="mb-1" style="font-size: 0.75em;">
                    * Configured threshold details found at end of report
                </div>
            </div>
        </div>

        <div class="mb-5">
            <b class="mb-2">
                Alert Distribution
            </b>
            <ViolationDistributionGraphComponent id="violation-distribution-graph" :data="scatterplotData"/>
        </div>

        <div class="mb-5">
            <b class="mb-1">
                Redzone Thumbnails
            </b>

            <div v-if="!report?.redzones?.length" class="my-2 danger-text-color">
                No redzones currently configured
            </div>
            <div v-else class="redzone-flex">
                <span v-for="redzone of report.redzones" class="redzone-thumbnail mt-1">
                    <div>
                        <div>
                            <span v-if="redzone.groupName != null">{{ redzone.groupName }} - </span>{{ redzone.cameraDisplayName }} - {{ redzone.redZoneName }}
                        </div>
                        <img :src="redzone.thumbnailURL" onerror="this.onerror=null;this.className='missing-thumbnail'" style="width: 100%;">
                    </div>
                </span>
            </div>
        </div>

        <div>
            <b>
                Configured Telemetry Thresholds
            </b>
            <div class="mb-2">
                The following are the actively configured telemetry thresholds for current red zones.<br>
                Zones must reach the set threshold value before an alarm is fired and must fall below the reset value to reset the alarm.
            </div>

            <div v-if="!report?.redZoneTelemetryTriggers?.length" class="my-2 danger-text-color">
                No Telemetry Thresholds currently configured
            </div>
            <iws-table v-else
                :columns="configuredTelemetryThresholdsTableFields"
                :items="report.redZoneTelemetryTriggers"
                :sortByCol.sync="sortByCol"
                :maxPageSize="20"
            /> 
        </div>
    </div>
</div>
</template>

<script>
import DateFunctions from '../../DateFunctions.js';
const { convertSeconds } = DateFunctions;

import GlobalFunction from '../../GlobalFunctions.js';
const { isFalsy, isNullOrEmpty } = GlobalFunction;

import moment from 'moment';

import ViolationBreakdownChartComponent from './ViolationBreakdownChartComponent.vue';
import ViolationDistributionGraphComponent from './ViolationDistributionGraphComponent.vue';

const HEAT_MAP_COLOURS = [ '#fef2f2', '#fee2e2', '#fecaca', '#fca5a5', '#f87171', '#ef4444', '#dc2626', '#b91c1c', '#991b1b', '#7f1d1d' ];

export default {
    props: {
        user: {
            type: Object,
            required: true
        },
        device: {
            type: Object,
            required: true
        },
        devices: {
            type: Array,
            required: true
        },
        job: {
            type: Object,
            required: true
        },
        status: {
            type: Object,
            required: true
        }
    },
    components: {
        ViolationBreakdownChartComponent,
        ViolationDistributionGraphComponent
    },

    data: () => ({
        range: [],
        report: null,
        scatterplotData: null,

        sortByCol: 'redZoneName',
        configuredTelemetryThresholdsTableFields: [
            {
                key: 'redZoneName',
                label: 'Zone'
            }, {
                key: 'redzoneGroupName',
                label: 'Group'
            }, {
                key: 'telemetryTriggerName',
                label: 'Telemetry Trigger'
            }, {
                key: 'thresholdPressure',
                label: 'Threshold'
            }, {
                key: 'resetPressure',
                label: 'Reset'
            }
        ]
    }),

    methods: {
        setDevice: function(deviceId) {
            const device = this.devices.find(_device => _device.deviceId == deviceId);
            window.location = `/cameras/reporting/${device?.job?.jobNumber || this.job.jobNumber}/${device.deviceId}`;
        },

        downloadReport: function() {
            window.print();
        },

        populateViolationCounts: function(report) {
            let newViolationCounts = [];

            for (let shiftViolation of report.shiftViolations) {
                newViolationCounts.push(shiftViolation.dayShiftViolationCount);
                newViolationCounts.push(shiftViolation.nightShiftViolationCount);
            }

            return newViolationCounts;
        },
        normalizeViolationCounts: function(violationCounts){
            const maxValue = Math.max(...violationCounts);
            return violationCounts.reduce((a, v) => ({ ...a, [v]: v / maxValue}), {}) ;
        },
        convertNormalizedCountToColorHexCode: function(normalizedCount) {
            if (normalizedCount >= 0 && normalizedCount < 1) {
                const index = Math.ceil(normalizedCount*10);

                if (!isFalsy(index) && index in HEAT_MAP_COLOURS)
                    return HEAT_MAP_COLOURS[index];
            }

            return '#450a0a';
        },
        getHeatmapBoxColor: function(normalizedViolationCount) {
            const backgroundColor = this.convertNormalizedCountToColorHexCode(normalizedViolationCount);
            const color = normalizedViolationCount > 0.2 ? '#FFFFFF' : '#000000';
            
            return { backgroundColor, color };
        },

        prepareScatterplotData: function(alertList){
            let alarmList = [];
            let warningList = [];

            for (const alert of alertList) {
                let alertDataPoint = {
                    pressure: alert.pressure,
                    timestamp: alert.startTimestamp
                }

                if (alert.type === 'AIRedzone') {
                    alarmList.push(alertDataPoint);
                } else if (alert.type === 'AIRedzone_Warning') {
                    warningList.push(alertDataPoint);
                }
            }

            return [ alarmList, warningList ];
        },

        fetchReport: function() {
            if (this.range?.length !== 2)
                return toast({
                    title: 'Must select start and end dates',
                    variant: 'danger'
                });
                
            return axios.get(`/cameras/reporting/${this.job.jobNumber}/${this.device.deviceId}/range?startDate=${moment(this.range[0]).format('YYYY-MM-DDT00:00:00')}&endDate=${moment(this.range[1]).format('YYYY-MM-DDT00:00:00')}`).then(_res => {
                const { data } = _res;

                if (data?.error)
                    return this.report = { error: data?.message };

                const violationCounts = this.populateViolationCounts(data);
                const normalizedDict = this.normalizeViolationCounts(violationCounts);

                let groupViolations = [];
                let zoneViolations = [];
                for (const shiftViolation of data.shiftViolations) {                    
                    shiftViolation.dayShiftStyle = this.getHeatmapBoxColor(normalizedDict[shiftViolation.dayShiftViolationCount]);
                    shiftViolation.nightShiftStyle = this.getHeatmapBoxColor(normalizedDict[shiftViolation.nightShiftViolationCount]);

                    if (shiftViolation.isGroup) {
                        groupViolations.push(shiftViolation);
                    } else {
                        zoneViolations.push(shiftViolation);
                    }
                }

                const [ scatterplotAlarmData, scatterplotWarningData ] = this.prepareScatterplotData(data.alertList);
                this.scatterplotData = {
                    startDate: data.startDate,
                    endDate: data.endDate,
                    alarmData: scatterplotAlarmData,
                    warningData: scatterplotWarningData,
                    minPressureThreshold: data.minRedzonePressureThreshold,
                    maxPressureThreshold: data.maxRedzonePressureThreshold,
                };

                if (isNullOrEmpty(data?.redZoneTelemetryTriggers))
                    data.redZoneTelemetryTriggers.sort((td1, td2) => {
                        if (td1.redzoneGroupName.toLowerCase() < td2.redzoneGroupName.toLowerCase()) return -1;
                        if (td1.redzoneGroupName.toLowerCase() > td2.redzoneGroupName.toLowerCase()) return 1;
                        if (td1.telemetryTriggerName.toLowerCase() < td2.telemetryTriggerName.toLowerCase()) return -1;
                        if (td1.telemetryTriggerName.toLowerCase() > td2.telemetryTriggerName.toLowerCase()) return 1;
                        if (td1.redZoneName.toLowerCase() < td2.redZoneName.toLowerCase()) return -1;
                        if (td1.redZoneName.toLowerCase() > td2.redZoneName.toLowerCase()) return 1;
                        return 0;
                    });

                this.report = {
                    ...data,
                    readableStartDate: moment(data.startDate).format('MMM DD, YYYY'),
                    readableEndDate: moment(data.endDate).format('MMM DD, YYYY'),
                    readableAlarmTime: convertSeconds(data.alarmTimeTotalSeconds),
                    readableAverageConfidence: (data.averageConfidence * 100).toFixed(2),
                    sortedShiftViolations: [ ...groupViolations, ...zoneViolations ]
                }
            })
        }
    },

    created() {
        // Default range to the last week
        const today = moment();
        this.range.push(today.format('YYYY-MM-DDT00:00:00'));
        today.subtract(7, 'days');
        this.range.unshift(today.format('YYYY-MM-DDT00:00:00'));
        
        this.fetchReport();
    }
};
</script>

<style>
    @media print {
        .CameraReportingControl {
            display: none
        }
        #CameraReporting {
            color: black !important;
        }
    }
</style>
<style scoped>
    #CameraReportingViewer {
        width: 95%;
        max-width: 1395px;
        margin-left: auto;
        margin-right: auto;
        padding: 20px 0px;
    }

    .loading {
        padding: 10% 0px;
        text-align: center;
    }

    #api-builder-row h2 {
        display: inline-block;
        margin-top: 25px;
    }
    #api-builder-row .float-right>div,
    #api-builder-row .float-right>span,
    #api-builder-row .float-right>button {
        display: inline-block;
    }

    b {
        display: block;
    }

    .quick-details.bordered {
        border-top: 1px solid #7B8A98;
    }
    .quick-details .quick-details-title {
        font-size: 14px;
    }
    .quick-details .quick-details-body {
        font-size: 22px;
        font-weight: 700;
        line-height: 18px;
    }

    .violations-by-shift-heatmap-container {
        display: flex;
        flex-direction: row;

        margin-right: 10%;
        height: 80%;
    }
    .violations-by-shift-heatmap-labels-item {
        flex: 1;
        margin-right: 10px;
    }
    .heatmap-label-row {
        align-content: center;
        min-height: 150px;
    }
    .violations-by-shift-heatmap-data-item {
        flex: 4;
        height: 100%;
    }
    .violations-by-shift-heatmap-data-item .zone-shift-violation-data {
        font-weight: bold;
        text-align: center;
        margin-bottom: 2%;
        margin-right: 2%;
        align-content: center;
        font-size: 200%;
        min-height: 150px;
        -webkit-print-color-adjust: exact;
    }
    .violations-by-shift-heatmap-data-item .zone-shift-violation-label {
        text-align: center;
    }

    .model-specification-data {
        margin-top: 15px;
    }
    .model-specification-data-title {
        padding-right: 0px;
        margin-right: 0px;
        width: 10%;
    }
    #violation-breakdown {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }


    .redzone-flex {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .redzone-thumbnail {
        width: 48%!important;
    }
    .missing-thumbnail {
        background-color: grey; 
        padding: 15% 40%;
    }
</style>