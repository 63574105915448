var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "justify-content-center process-logs-page" },
    [
      _c("div", { staticClass: "page-title process-logs-page-title" }, [
        _vm._v("Process Logs"),
      ]),
      _vm._v(" "),
      _c("process-logs-filter", {
        attrs: {
          disabled: _vm.busy,
          processes: _vm.allProcesses,
          shifts: _vm.allShifts,
          wells: _vm.wells,
          search: _vm.search,
          filters: _vm.filters,
          sortBy: _vm.sortBy.label,
          sortDirection: _vm.sortDirection,
          jobNumber: _vm.job.jobNumber,
        },
        on: {
          "update:search": function ($event) {
            _vm.search = $event
          },
          "update:filters": function ($event) {
            _vm.filters = $event
          },
        },
      }),
      _vm._v(" "),
      _c("iws-table", {
        attrs: {
          busy: _vm.busy,
          columns: _vm.fields,
          items: _vm.filteredItems,
        },
        scopedSlots: _vm._u([
          {
            key: "loading",
            fn: function () {
              return [
                _c("i", { staticClass: "fa fa-spinner fa-spin fa-2x ml-2" }),
                _vm._v(" "),
                _c("span", { staticClass: "loading-text" }, [
                  _vm._v(" Loading... "),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "cell_name",
            fn: function ({ data }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "well-name-column",
                    style: {
                      backgroundColor: data.item.color,
                      color: _vm.nameColor(data.item),
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(data.item.name) +
                        "\n            "
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "cell_processStatus",
            fn: function ({ data }) {
              return [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          ["In Progress", "request", "bypassActuate"].includes(
                            data.item.processStatus
                          ) ||
                          (data.item.processStatus === "resolved" &&
                            data.item.events.length > 1),
                        expression:
                          "['In Progress', 'request', 'bypassActuate'].includes(data.item.processStatus) || (data.item.processStatus === 'resolved' && data.item.events.length > 1)",
                      },
                    ],
                  },
                  [_c("i", { staticClass: "fa fa-spinner fa-spin fa-2x ml-1" })]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          ["completed"].includes(data.item.processStatus) ||
                          (data.item.processStatus === "resolved" &&
                            data.item.events.length === 1),
                        expression:
                          "['completed'].includes(data.item.processStatus) || (data.item.processStatus === 'resolved' && data.item.events.length === 1)",
                      },
                    ],
                  },
                  [_c("i", { staticClass: "fa fa-check fa-2x ml-1" })]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: ![
                          "In Progress",
                          "completed",
                          "resolved",
                          "request",
                          "bypassActuate",
                        ].includes(data.item.processStatus),
                        expression:
                          "!['In Progress', 'completed', 'resolved', 'request', 'bypassActuate'].includes(data.item.processStatus)",
                      },
                    ],
                  },
                  [_c("i", { staticClass: "fa fa-times fa-2x ml-1" })]
                ),
              ]
            },
          },
          {
            key: "cell_show_details",
            fn: function ({ data }) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "mr-2",
                    attrs: { size: "sm" },
                    on: {
                      click: function ($event) {
                        return _vm.toggleDetails(data)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(!!data.item.showEmbed ? "▲" : "▼") +
                        "\n            "
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "embed",
            fn: function ({ data }) {
              return [
                _c(
                  "div",
                  { staticClass: "stepContainer" },
                  _vm._l(data.item.events, function (event, index) {
                    return _c("ProcessLogStepComponent", {
                      key: index,
                      attrs: {
                        event: event,
                        offset: _vm.job.hourOffset,
                        wells: _vm.wells,
                        processName: data.item.process,
                      },
                    })
                  }),
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }