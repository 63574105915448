<template>
    <div>
        <template>
            <template v-if="userApiToken">
                <div class="full-width position-relative" style="margin-bottom: 10px">
                    <div class="warning-text" v-if="showWarning" >
                        <i class="fas fa-info-circle"></i>
                        <span class="mx-2" id="roip-map-warning-text">Warning - Make sure you copy the below token now. We don't store it and you will not be able to see it again.</span>
                    </div>
                    <iws-input :value="userApiToken" readonly style="display: inline-block; width: calc(100% - 200px); margin-right: 5px" />
                    <iws-button
                        v-if="showWarning"
                        icon="far fa-copy"
                        type="primary"
                        :click="copyTokenToClipboard"
                        style="height: 38px; position: relative; top: -3px;"
                    />
                    <iws-button
                        v-if = "(!isIwsAdmin || isApiOnlyUser)"
                        text="Regenerate"
                        type="primary"
                        :click="regenerateApiToken"
                        style="height: 38px; position: relative; top: -3px;"
                    />
                    <iws-button  v-if = "(isIwsAdmin && !isApiOnlyUser)"
                        text="Revoke"
                        type="danger"
                        :click="revokeApiAccess"
                    />
                </div>
            </template>
            <template v-else>
                <div class="full-width text-center" v-if="(!isIwsAdmin || isApiOnlyUser)">
                    <iws-button
                        text="Generate API Token"
                        type="primary"
                        :click="regenerateApiToken"
                    />
                </div>
                <div class="full-width" v-else>
                    <div class="warning-text"  >
                        <i class="fas fa-info-circle"></i>
                        <span class="mx-2" id="roip-map-warning-text">No valid API token for this user</span>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions';

export default {
    props: {
        userId: [Number, String],
        apiToken: String, // if passed in will be used instead of fetching the users api token via ajax call
        authUser: Object,
        isIwsAdmin: {
            type: [Boolean, Number],
            default: false
        },
        userRole: Object
    },

    data: () => ({
        userApiToken: null,
        showWarning: false,
        isApiOnlyUser: false
    }),

    computed: {
        role() {
            return this.authUser?.roles[0]?.name;
        },
    },

    methods: {
        revokeApiAccess() {
            if (this.role == 'admin') {
                return axios.put(`/revokeUserApiAccess`, {
                    userId: this.userId,
                    _token: GlobalFunctions.getCSRFToken(),
                }).then(data => {
                    this.userApiToken = null;
                });
            }
        },
        getUserApiToken() {
            return axios.post(`/getUserApiToken`, {
                userId: this.userId,
                _token: GlobalFunctions.getCSRFToken(),
            }).then(data => {
                this.userApiToken = data.data;
            });
        },
        copyTokenToClipboard() {
                const str = this.userApiToken;
                const el = document.createElement('textarea');
                el.value = str;
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);

                GlobalFunctions.toast({
                    title: 'Token copied to clipboard!',
                    variant: 'success'
                });
        },
        regenerateApiToken(){
                return axios.post(`/regenerate-token`, {
                    userId: this.userId,
                    _token: GlobalFunctions.getCSRFToken(),
                }).then(data => {
                    this.userApiToken = data.data;
                    this.showWarning = true;
                    GlobalFunctions.toast({
                        title: 'New Token generated',
                        variant: 'success'
                    });
                });
        }
    },

    mounted() {
        if (GlobalFunctions.isFalsy(this.apiToken))  {
            this.getUserApiToken();
        } else {
            this.userApiToken = this.apiToken
        }
        if (this.userRole && this.userRole.name === 'apiUser') {
            this.isApiOnlyUser = true;
        }
    }
}
</script>
<style scoped>
 .warning-text {
        display: flex;
        margin-bottom:10px;
        font-size: 14px;
        padding: 5px;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid var(--Warning-Warning-500, #F79009);
    }
    .warning-text i{
        font-size: 20px;
        color: var(--Warning-Warning-500, #F79009);
    }
</style>
