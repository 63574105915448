<template>
<div>
    <library-base
        :items="guns"
        :filters="filters"
        :columns="columns.map(c => c.key)"
        :sortByCol="sortByCol"
        :sortAsc="sortAsc"
        title="Gun Library"
        createBtnTxt="Add New Gun"
        :breadCrumbPath="[{
            title: 'Home',
            link: '/home'
        }, {
            title: 'Gun Library',
            link: '/guns'
        }]"

        @create="editAction(emptyGun)"
        @openFilters="openFilters"
    >
        <template #breadcrumbs>
            <iws-breadcrumbs class="breadcrumbs-margins"
                :path="[{
                    title: 'Dashboard',
                    link: '/home'
                }, {
                    title: 'Gun Library',
                    link: '/guns'
                }]"
            />
        </template>

        <template #title>
            Gun Library
        </template>

        <template #add-item-title>
            Add New Gun
        </template>

        <template #no-item-body>
            <div class="title">Add Guns</div>
            <div class="subtitle">Start by adding a Gun. Click on the "Add New Gun" button</div>
        </template>

        <template #card="{ filteredItems: filteredGuns }">
            <div v-for="(gun, index) in filteredGuns" :key="index" class="gun-card-container">
                <div class="row fixed-width">
                    <label>Name</label>
                    <span>{{ gun.name }}</span>
                </div>

                <div class="row fixed-width">
                    <label>Description</label>
                    <span>{{ gun.description }}</span>
                </div>

                <div class="row fixed-width">
                    <label>Size</label>
                    <span>{{ gun.size }}</span>
                </div>

                <div class="row fixed-width">
                    <label>Carrier</label>
                    <span>{{ gun.carrier_make }}</span>
                </div>

                <div class="row fixed-width">
                    <label>Charge</label>
                    <span>{{ gun.charge_make }}</span>
                </div>

                <div class="row fixed-width">
                    <label>Customer</label>
                    <span>{{ gun.customerName }}</span>
                </div>

                <hr>

                <library-crud-actions v-if="enableRowActions"
                    :id="gun.id"
                    :enable-actions="{
                        edit: true,
                        view: true,
                        delete: !gun.in_use
                    }"

                    @edit="editAction(gun)"
                    @view="openAction(gun)"
                    @delete="deleteAction(gun)"
                />
            </div>
        </template>

        <template #table="{ filteredItems: filteredGuns }">
            <iws-table
                :items="filteredGuns"
                :columns="[...columns, '']"
                :sortByCol.sync="sortByCol"
                :sortAsc.sync="sortAsc"
            >
                <template #body>
                    <tr v-for="gun in filteredGuns" :key="gun.id">
                        <td>
                            {{ gun.name }}
                        </td>
                        <td>
                            {{ gun.description }}
                        </td>
                        <td>
                            {{ gun.size }}
                        </td>
                        <td>
                            {{ gun.carrier_make }}
                        </td>
                        <td>
                            {{ gun.charge_make }}
                        </td>
                        <td v-if="enableCustomerCol">
                            {{ gun.customerName }}
                        </td>
                        <td>
                            <library-crud-actions v-if="enableRowActions"
                                :id="gun.id"
                                :enable-actions="{
                                    edit: true,
                                    view: true,
                                    delete: !gun.in_use
                                }"

                                @edit="editAction(gun)"
                                @view="openAction(gun)"
                                @delete="deleteAction(gun)"
                            />
                        </td>
                    </tr>
                </template>
            </iws-table>
        </template>
    </library-base>

    <gun-library-filters-modal id="gunFiltersModal" ref="gunFiltersModal"
        :guns="guns"
        :enable-customer-col="enableCustomerCol"
    />

    <gun-library-add-edit-gun id="gunAddEdit" ref="gunAddEdit"
        :enable-customer-col="enableCustomerCol"
    />

    <gun-library-view-gun id="gunView" ref="gunView"
        :enable-customer-col="enableCustomerCol"
    />
</div>
</template>

<script>
import GlobalFunctions from '../GlobalFunctions.js';

import LibraryBase from './LibraryBase.vue';

import GunLibraryFiltersModal from './GunLibraryFiltersModal.vue';
import GunLibraryAddEditGun from './GunLibraryAddEditGun.vue';
import GunLibraryViewGun from './GunLibraryViewGun.vue';

import LibraryCrudActions from './LibraryCrudActions.vue';

export default {
    props: {
        guns: [Array, Object],
        role: String,
        customer: Object
    },

    components: {
        LibraryBase,

        GunLibraryFiltersModal,
        GunLibraryAddEditGun,
        GunLibraryViewGun,

        LibraryCrudActions
    },

    data: () => ({
        sortByCol: 'name',
        sortAsc: true,

        fields: [
            {
                key: 'name',
                label: 'Name',
                sortable: true
            },
            {
                key: 'description',
                label: 'Description',
                sortable: true
            },
            {
                key: 'size',
                label: 'Size',
                sortable: true
            },
            {
                key: 'carrier_make',
                label: 'Carrier',
                sortable: true
            },
            {
                key: 'charge_make',
                label: 'Charge',
                sortable: true
            }
        ],

        filters: {
            name: [],
            description: [],
            size: [],
            carrier_make: [],
            charge_make: [],
            customerName: []
        },

        emptyGun: {
            name: null,
            customerName: null,
            size: null,
            length: null,
            cluster_length: null,
            shot_density: null,
            shots_planned: null,
            nominal_penetration: null,
            description: null,
            estimated_hole_diameter: null,
            metallurgy: null,
            centralized: null,
            conveyance_method: null,
            carrier_description: null,
            carrier_make: null,
            carrier_type: null,
            charge_size: null,
            charge_make: null,
            explosive_type: null,
            phasing: null,
            orientation: null,
            orientation_method: null
        }
    }),

    computed: {
        enableCustomerCol() {
            return this.role == 'admin' || this.customer.name == 'IWS';
        },
        enableRowActions() {
            return this.role == 'admin' || this.role =='companyAdmin' || this.customer.name == 'IWS';
        },

        columns() {
            const columns = [...this.fields];
            if (this.enableCustomerCol)
                columns.push({
                    key: 'customerName',
                    label: 'Customer',
                    sortable: true
                });
            return columns;
        }
    },

    methods: {
        openFilters() {
            this.$refs.gunFiltersModal.open(this.filters).then($event => {
                if (!!$event)
                    this.filters = $event;
            });
        },

        editAction(gun) {
            this.$refs.gunAddEdit.open(gun);
        },
        openAction(gun) {
            this.$refs.gunView.open(gun);
        },
        deleteAction(gun) {
            if (gun.in_use)
                return;

            GlobalFunctions.iwsConfirm({
                title: 'Delete gun',
                body: `Are you sure you want to delete "${gun.name}"? This action cannot be undone!`,
                confirmColour: 'danger',
                width: '400px'
            }).then(_answer => {
                if (_answer) {
                    //Should delete the actual comment
                    $.ajax({
                        url: '/guns/' + gun.id + '/delete',
                        method: 'PUT',
                        data:
                        {
                            "_token": GlobalFunctions.getCSRFToken(),
                            id: gun.id
                        },
                        dataType: 'json'
                    }).done(() => {
                        window.location.reload();
                    }).fail(e => {
                        console.warn(e.responseText);
                    });
                }
            });
        }
    }
}
</script>

<style scoped>
    .fixed-width label {
        width: 85px;
    }
    .fixed-width span {
        width: calc(100% - 90px);
    }
</style>
