<template>
<div v-if="!!dataObject && !!options" class="position-relative">
    <span class="text-value alarm-count">
        {{ data.alarmCount }}
    </span>
    <span class="text-value warning-count">
        {{ data.warningCount }}
    </span>

    <bar-chart
        :height="200"
        :chart-data="dataObject"
        :options="options"
    />
</div>
</template>
  
<script>
import BarChart from '../../barChart2.js';

export default {
    name: "ViolationBreakdownChartComponent",
    props: [ "data" ],
    components: {
        BarChart
    },
    data() {
        return {
            dataObject: null,
            options: null
        }
    },
    watch: {
        data() {
            this.renderChart();
        }
    },
    methods: {
        renderChart() {
            if (this.data == null)
                return;

            const alarmCount = this.data.alarmCount;
            const warningCount = this.data.warningCount;

            this.dataObject = {
                labels: [`Alarms`, `Warnings`],
                datasets: [{
                    data: [ alarmCount, warningCount ],
                    backgroundColor: [ '#f44424', '#fcac14' ],
                    borderWidth: 1,
                    categoryPercentage: 1,
                    barPercentage: 0.8
                }]
            };
            this.options = {
                scales: {
                    xAxes: [{
                        id: 'x-axis',
                        beginAtZero: true,
                        display: false
                    }],
                    yAxes: [{
                        scaleLabel: {
                            display: false
                        },
                        gridLines: {
                            display: false
                        }
                    }]
                },
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false
                }
            };
        }
    },
    mounted() {
        this.renderChart();
    }
};
</script>
  
<style scoped>
    canvas {
        width: 100%;
    }
    @media print {
        canvas {
            width: 100%!important;
            height: fit-content!important;
        }
    } 

    .text-value {
        position: absolute;
        left: 70px;

        font-size: 24px;
        font-weight: 700;
        color: black;
    }
    .text-value.alarm-count {
        top: 32px;
    }
    .text-value.warning-count {
        bottom: 38px;
    }
</style>