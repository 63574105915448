<template>
    <ScatterLineChart v-if="!!dataObject && !!options"
        :chart-data="dataObject"
        :options="options"
    />
</template>
  
<script>
import ScatterLineChart from '../../scatterLineChart.js';
const moment = require('moment');

import DateFunctions from '../../DateFunctions.js';
const { compareDates } = DateFunctions;

import GlobalFunctions from '../../GlobalFunctions.js';
const { isNullOrEmpty } = GlobalFunctions;

export default {
    name: "ViolationDistributionGraphComponent",
    props: [ "data" ],
    components: {
        ScatterLineChart
    },
    data() {
        return {
            dataObject: null,
            options: null
        }
    },
    watch: {
        data() {
            this.renderChart();
        }
    },
    methods: {
        fillDatasetForDate: function(dataset, date) {
            if (isNullOrEmpty(dataset)) {
                dataset.push({ x: date, y: null });
            } else if (compareDates(date, dataset[0].x) < 0) {
                dataset.unshift({ x: date, y: null });
            } else if (compareDates(date, dataset[dataset.length-1].x) > 0) {
                dataset.push({ x: date, y: null });
            }
        },

        renderChart() {
            if (this.data == null)
                return;

            const alarmData = this.data.alarmData;
            const warningData = this.data.warningData;
            const minPressureThreshold = this.data.minPressureThreshold;
            const maxPressureThreshold = this.data.maxPressureThreshold;

            const transformedAlarmData = alarmData.map(alarm => ({
                x: new Date(alarm.timestamp).getTime(),
                y: alarm.pressure
            }));
            const transformedWarningData = warningData.map(warning => ({
                x: new Date(warning.timestamp).getTime(),
                y: warning.pressure
            }));
            
            let combinedData = [...transformedAlarmData, ...transformedWarningData];
            let pressureMax = combinedData.reduce((max, alert) => (alert.y > max ? alert.y : max), -Infinity);
            pressureMax = Math.max(pressureMax, maxPressureThreshold); // Make sure telemetry thresholds contribute to y-axis scale as well as alerts
            let pressureMaxToNearestFifty = Math.ceil(pressureMax / 50) * 50;
            if (Math.abs(pressureMax - pressureMaxToNearestFifty) <= 10) {
                pressureMaxToNearestFifty += 50;
            }

            let labels = [];
            let currentDate = moment.utc(this.data.startDate);
     
            // Chart should show x labels from the start to end date selected in api builder (not just dates that have data)
            while (compareDates(currentDate.valueOf(), this.data.endDate) >= 0) {   
                const _epoch = currentDate.valueOf();
                
                labels.push(_epoch);

                // If the current date is out of range of the actual values, bump the dataset
                this.fillDatasetForDate(transformedAlarmData, _epoch);
                this.fillDatasetForDate(transformedWarningData, _epoch);

                currentDate.add(1, 'days');
            }

            this.dataObject = {
                labels: labels,
                datasets: [{
                    label: 'Alarms',
                    data: transformedAlarmData,
                    backgroundColor: 'rgba(244, 68, 36, 0.3)',
                    borderColor: 'rgba(244, 68, 36, 0.3)',
                    borderWidth: 1
                }, {
                    label: "Warnings",
                    data: transformedWarningData,
                    backgroundColor: 'rgba(252, 172, 20, 0.3)',
                    borderColor: 'rgba(252, 172, 20, 0.3)',
                    borderWidth: 1
                }]
            };
            this.options = {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        id: 'date-axis',
                        scaleLabel: {
                            display: true
                        },
                        ticks: {
                            fontColor: '#CCCCCC',
                            callback: value => moment.utc(value).format('MMM D')
                        }
                    }],
                    yAxes: [{
                        scaleLabel: {
                            display: true,
                            fontColor: '#CCCCCC',
                            fontSize: 14,
                            labelString: 'Pressure'
                        },
                        ticks: {
                            beginAtZero: true,
                            suggestedMin: 0,
                            suggestedMax: pressureMaxToNearestFifty
                        }
                    }]
                },
                tooltips: {
                    enabled: false,
                    mode: 'nearest',
                    intersect: true,
                    position: 'cursor',
                    animationDuration: 0,
                    backgroundColor: '#000',
                    callbacks: {
                        label: (tooltipItem, data) => `${data?.datasets[tooltipItem.datasetIndex].label}: (${moment.utc(tooltipItem.xLabel).format('MMM D HH:MM:SS')}: ${tooltipItem?.value})`
                    },
                    custom: function(tooltipModel) {
                        // Tooltip Element
                        let tooltipEl = document.getElementById('chartjs-tooltip-summary-bar');

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip-summary-bar';
                            tooltipEl.innerHTML = '<table></table>';
                            document.body.appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }

                        // Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            const titleLines = tooltipModel.title || [];
                            const bodyLines = tooltipModel.body.map(getBody);

                            let innerHtml = '<thead>';

                            titleLines.forEach(function(title) {
                                const style = 'width : 10px; height: 10px; border-width : 1px;';
                                innerHtml += '<tr><th><div class="d-flex pr-3"><div class="mx-2 mt-1" style="' + style + '"></div>' + title + '</div></th></tr>';
                            });
                            innerHtml += '</thead><tbody>';


                            bodyLines.forEach(function(body, i) {
                                const colors = tooltipModel.labelColors[i];
                                if(body && body.length>0) {
                                    const style = `background: ${typeof colors.backgroundColor === 'string'? colors.backgroundColor : 'transparent'}; width : 9px; height: 9px; border-width : 1px; border-color: ${typeof colors.backgroundColor === 'string'? '#FFFFFF' : 'transparent'}; border-style: solid`;
                                    innerHtml += '<tr><td><div class="d-flex pr-3"> <div class="mx-2 mt-1" style="' + style + '"></div>' + body + ' </div></td></tr>';
                                }
                            });
                            innerHtml += '</tbody>';

                            const tableRoot = tooltipEl.querySelector('table');
                            tableRoot.innerHTML = innerHtml;
                        }

                        // `this` will be the overall tooltip
                        const position = this._chart.canvas.getBoundingClientRect();

                        //mouse position
                        let offset = tooltipModel.caretX;

                        //when the tooltip tries to render at the right edge
                        //of the screen, give it more space to the left
                        const averageTooltipWidth = 150;
                        if (tooltipModel.caretX > this._chart.width - averageTooltipWidth)
                        {offset = this._chart.width - averageTooltipWidth;}

                        // Display, position, and set styles for font
                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.position = 'fixed';
                        tooltipEl.style.left = position.left + offset + 'px';
                        tooltipEl.style.top = position.top + tooltipModel.caretY + 'px';
                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                        tooltipEl.style.padding = 2 + 'px ' + 0 + 'px';
                        tooltipEl.style.pointerEvents = 'none';
                    }
                }
            };
        }
    },
    mounted() {
        this.renderChart();
    }
};
</script>
  
<style scoped>
.bold-title {
    font-weight: bold;
    color: rgb(85, 85, 85);
}
@media print {
    ::v-deep canvas {
        width: 100%!important;
        height: fit-content!important;
    } 
} 
</style>