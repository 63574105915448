var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !!_vm.dataObject && !!_vm.options
    ? _c(
        "div",
        { staticClass: "position-relative" },
        [
          _c("span", { staticClass: "text-value alarm-count" }, [
            _vm._v("\n        " + _vm._s(_vm.data.alarmCount) + "\n    "),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "text-value warning-count" }, [
            _vm._v("\n        " + _vm._s(_vm.data.warningCount) + "\n    "),
          ]),
          _vm._v(" "),
          _c("bar-chart", {
            attrs: {
              height: 200,
              "chart-data": _vm.dataObject,
              options: _vm.options,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }