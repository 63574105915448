<template>
    <div>
        <b-overlay :show="isBusy.busy" variant="light" blur="5px" opacity="0.90">
            <div class="page-title mt-2 mb-4 px-5 pt-0">
                {{ type == "frac" ? "Frac Stage Summary" : "Wireline Stage Summary" }}
            </div>
            
            <template #overlay>
                <div class="text-center">
                    <b-spinner large class="align-middle"></b-spinner>
                    <p class="mt-2">{{ isBusy.state }}, this may take a while...</p>
                </div>
            </template>
            <div>
                <b-popover target="approve-all" triggers="hover" placement="right" :delay="{show: 0, hide: 500}">
                    <div class="btn-group-vertical">
                        <button class="btn btn-success green-button mb-2" @click="changeReportStatus(true, true)" :disabled="approvalsDisabled">
                            Approve all
                        </button>
                        <button class="btn btn-danger" @click="changeReportStatus(true, false)" :disabled="approvalsDisabled">
                            Unapprove all
                        </button>
                    </div>
                </b-popover>

                <div class="px-5 pt-0">
                    <div class="pb-3 d-flex justify-content-between">
                        <div v-if="wells.length > 0" class="col-4">
                            <div class="row">
                                <div class=" cols-2 pr-2">
                                    {{ 'Well:' }}
                                </div> 
                                <div class="col-9">
                                    <select v-model="selectedWell" @change="onWellUpdated" class="custom-select link-txt-color">
                                        <option v-for="(well, index) in wells" :key="index" :value="well" :target="well.index">{{well.nameLong}} ({{well.name}})</option> 
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 pl-5">
                            <div class="row justify-content-end">
                                <div class="col-2 px-0 text-right" style="min-width: 130px;">
                                    <b-table-export-component  :jobNumber="jobNumber" :wellIndex="selectedWell.index" :haswvxmlExport="this.type === 'frac'" :items="exportData" :fields="exportFields" :fileName="exportFileName"/>
                                    <input ref="importInput" style="display: none" type="file" accept=".csv" @change="handleImport($event)">
                                </div>
                                <div class="col-2 px-0 text-right" style="min-width: 130px;">
                                    <iws-button type="link" v-tooltip:top="'Import CSV'" class="ml-3" @click="$refs.importInput.click()">
                                        <template #text>
                                            <import-icon/> Import
                                        </template>
                                    </iws-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <b-table v-if="mapDataToTable.length" ref="stageTable" no-border-collapse striped hover dark small responsive sticky-header="80vh" :items="mapDataToTable" :fields="mapTableTitles">
                            <template #head(stage_number)="data">
                                <span id="approve-all" class="pl-4 float-left" v-if="canMassApprove && isFeatureFlagged('STAGE_SUMMARY_IMPORT')">
                                    <i class="fas fa-paper-plane clickable"></i>
                                </span>
                                <p class="float-right m-0">{{ data.label }}</p>
                            </template>
                            <template #cell(stage_number)="data">
                                <div class="container pl-0">
                                    <div>
                                        <div v-if="!sendReportDisabled(selectedWell.index, data.item.stage_number, START_REPORT)">
                                            <button @click="sendReportToFunctionApp(selectedWell.index, data.item.stage_number, START_REPORT, true)"
                                                class="mr-1 btn btn-success green-button report-button">
                                                Send Stage Start
                                            </button>
                                        </div>
                                        <div v-else>
                                            <button @click="enableSendReport(selectedWell.index, data.item.stage_number, START_REPORT)"
                                                v-tooltip:top="'Click to resend'"
                                                class="mr-1 btn btn-secondary gray-button report-button">
                                                <p v-if="!data?.item?.start_date || data?.item?.start_date=='-'">Send Stage Start</p>
                                                <p v-else>Stage Start Sent</p>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <div v-if="!sendReportDisabled(selectedWell.index, data.item.stage_number, END_REPORT)">
                                            <button @click="sendReportToFunctionApp(selectedWell.index, data.item.stage_number, END_REPORT, true)"
                                                class="mr-1 btn btn-success green-button report-button">
                                                Send Stage End
                                            </button>
                                        </div>
                                        <div v-else>
                                            <button @click="enableSendReport(selectedWell.index, data.item.stage_number, END_REPORT)"
                                                v-tooltip:top="'Click to resend'"
                                                class="mr-1 btn btn-secondary gray-button report-button">
                                                <p v-if="!data?.item?.end_date || data?.item?.end_date=='-'">Send Stage End</p>
                                                <p v-else>Stage End Sent</p>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <div v-if="isApproved(selectedWell.index, data.item.stage_number) == 'invalid'" class="d-flex">
                                            <button :disabled="approvalsDisabled"
                                                @click="changeReportStatus(false, true, data)" class="btn btn-success green-button report-button">
                                                Approve Stage
                                            </button>
                                        </div>
                                        <button v-else-if="isApproved(selectedWell.index, data.item.stage_number) == 'valid'" 
                                            disabled class="btn btn-secondary gray-button report-button">
                                            Report Sent
                                        </button>
                                        <div v-else>Error</div>
                                    </div>
                                    <div>
                                        <i v-if="isStageAtypical(selectedWell.index, data.item.stage_number) &&
                                            isApproved(selectedWell.index, data.item.stage_number) == 'invalid'" 
                                            :id="`popover-stage-${data.item.stage_number}`" class="fas fa-exclamation-triangle ml-2" 
                                            style="color:orange;"
                                            @mouseover="setPopoverAlertData(selectedWell.index, data.item.stage_number)">
                                        </i>
                                        <i v-else :id="'popover-stage-' + data.item.stage_number" class="fas fa-info-circle ml-2" 
                                            @mouseover="setInfoHoveredData(selectedWell.index, data.item.stage_number, data)">
                                        </i>
                                    </div>
                                    <div class="justify-content-between">
                                        <div v-if="!approvalsDisabled" v-tooltip:top="'Copy Row Data'" class="col-4" @click="copyRow(data)">
                                            <i class="fa fa-copy clickable"></i>
                                        </div>
                                        <div v-else v-tooltip:top="'Copy Row Data'" class="col-4">
                                            <i  class="fa fa-copy" style="color:grey;"></i>
                                        </div>
                                    </div>
                                    {{ data.value }}
                                </div>
                            </template>
                            <template #cell(start_date)="data">
                                <div v-if="isApproved(selectedWell.index, data.item.stage_number) == 'invalid'" style="min-width:150px;">
                                    <b-form-input
                                        v-model="data.value"
                                        @click="openDatePickerModal('editField-'+data.field.key+'-'+data.item.stage_number, data)"
                                        @change="saveValue(data)"
                                        style="width:150px;"
                                        class="darkmode-form">
                                    </b-form-input>
                                </div>
                                <div v-else style="min-width:130px;">
                                    {{ data.value }}
                                </div>
                            </template>
                            <template #cell(end_date)="data">
                                <div v-if="isApproved(selectedWell.index, data.item.stage_number) == 'invalid'" style="min-width:150px;">
                                    <b-form-input
                                        v-model="data.value"
                                        @click="openDatePickerModal('editField-'+data.field.key+'-'+data.item.stage_number, data)"
                                        @change="saveValue(data)"
                                        style="width:150px;"
                                        class="darkmode-form">
                                    </b-form-input>
                                </div>
                                <div v-else style="min-width:130px;">
                                    {{ data.value }}
                                </div>
                            </template>
                            <template #cell()="data">
                                <div v-if="isApproved(selectedWell.index, data.item.stage_number) == 'invalid'">
                                    <b-row>
                                        <b-col>
                                            <b-form-input v-if="data.field.entryType == 'NUMERIC'" :disabled="approvalsDisabled" v-model="data.value" :ref="'editField-'+data.field.key+'-'+data.item.stage_number" type="number" @change="saveValue(data)" style="width:100px;" class="darkmode-form"></b-form-input>
                                            <div v-else-if="data.field.entryType == 'TIME'">
                                                <input v-if="data.value != null" :disabled="approvalsDisabled" v-model="data.value" :ref="'editField-'+data.field.key+'-'+data.item.stage_number" type="datetime-local" step="1" @change="saveValue(data)" class="darkmode-form">
                                                <div v-else>-</div>
                                            </div>
                                            <b-form-input v-else :disabled="approvalsDisabled" v-model="data.value" :ref="'editField-'+data.field.key+'-'+data.item.stage_number" type="text" @click="openModal('editField-'+data.field.key+'-'+data.item.stage_number, data)" @change="saveValue(data)" style="width:120px;" class="darkmode-form"></b-form-input>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div v-else-if="data.field.entryType == 'TEXT'">
                                    <div :id="data.field.key+'-'+data.item.stage_number" @click="openModal('editField-'+data.field.key+'-'+data.item.stage_number, data, true)" class="table-text-display">{{ data.value != "" ? data.value : "-" }}</div>
                                <b-tooltip v-if="data.value != ''"  :target="data.field.key+'-'+data.item.stage_number" placement="auto" trigger="hover">
                                    <span style="color:white;">{{data.value != '' ? data.value : '-'}}</span>
                                </b-tooltip>
                                </div>
                                <div v-else-if="data.field.entryType == 'TIME'" style="width:150px;">
                                    {{ formatDate(data.value) }}
                                </div>
                                <span v-else> {{ data.value != "" ? data.value : "-" }}</span>
                            </template>
                            <template #cell(vendor)="data">
                                <div class="table-text-display" style="width: 120px;">
                                    {{ vendorName }}
                                </div>
                            </template>
                            <template #cell(uwi)="data">
                                <div class="table-text-display" style="width: 150px;">
                                    {{ selectedWell.well_UWI }}
                                </div>
                            </template>
                        </b-table> 
                        <div v-else class="alert alert-warning"> Not enough data to show </div>
                    </div>
                </div>
                <div v-for="(targetRow, index) in mapDataToTable" :key="index">
                    <b-popover custom-class="popover-approvals" :target="'popover-stage-' + targetRow.stage_number" boundary="viewport" triggers="hover" placement="bottom" :delay="{show: 0, hide: 50}">
                        <div class="mt-2 mb-2 font-weight-bold">Stage # {{targetRow.stage_number}} :</div>
                        <div v-if="isStageAtypical(selectedWell.index, targetRow.stage_number) && isApproved(selectedWell.index, targetRow.stage_number) == 'invalid'">
                            <b-row>
                                <b-col>
                                    <div style="color: black; max-height: 200px;">
                                        <b-row class="px-3">
                                            <p>{{popoverAlertData.message}}</p>
                                        </b-row>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row align-h="between" class="px-1">
                                <b-col v-if="['admin','iwsTech','companyAdmin'].some(item => userRoleNamesArray.includes(item))">
                                    <b-button variant="secondary" class="my-2 w-100" size="sm" 
                                        @click="sendReportToFunctionApp(selectedWell.index,targetRow.stage_number)">
                                        Regenerate Report
                                    </b-button>
                                </b-col>
                                <b-col v-if="['admin','iwsTech'].some(item => userRoleNamesArray.includes(item)) && category=='frac'">
                                    <b-button variant="secondary" class="my-2 w-100" size="sm"
                                        @click="runbackDataProcessor(selectedWell.index,targetRow.stage_number)">
                                        Runback Data Processor
                                    </b-button>
                                </b-col>
                            </b-row>
                        </div>
                        <div v-else>
                            <b-row>
                                <b-col>
                                    <div class="scroll-div" style="color: black; max-height: 200px;">
                                        <div v-for="(targetData, index) in hoveredStageData" :key="index">
                                            <b-row>
                                                <b-col cols="3" style="font-size: .65rem;">{{targetData["state"]}}</b-col>
                                                <b-col>{{targetData["user"]}}</b-col>
                                                <b-col cols="6">{{targetData["timestamp"]}}</b-col>
                                            </b-row>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                            <div class="text-right">
                                <button v-if="isApproved(selectedWell.index, targetRow.stage_number) == 'valid'" class="btn btn-danger mb-2 mt-3" @click="changeReportStatus(false, false, targetPopoverStageData)">
                                    Unapprove
                                </button>
                            </div>
                        </div>
                    </b-popover>
                </div>
                <!-- Modal for edits of text fields -->
                <div class="modal fade" id="editTextModal" tabindex="-1" role="dialog" aria-labelledby="editTextModalTitle" aria-hidden="true">
                    <div class="modal-dialog modal-bg" role="document">
                        <div class="modal-content" style="background-color: #393f4a!important;">
                        <div class="modal-header">
                            <h5 v-if="editModalData" class="modal-title" id="editTextModalTitle">Stage # {{ editModalData.item.stage_number }} - {{ editModalData.field.label }}</h5>
                        </div>
                        <div class="modal-body">
                            <b-form-textarea :disabled="modalEditsDisabled" id="textarea" lazy v-model="editModalText" class="darkmode-form" rows="6"></b-form-textarea>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button v-if="!modalEditsDisabled" type="button" class="btn btn-success green-button" @click="saveEditText()">Save changes</button>
                        </div>
                        </div>
                    </div>
                </div>
                <!-- Modal for edits of date fields -->
                <div class="modal fade" id="editDateModal" tabindex="-1" role="dialog" aria-labelledby="editDateModalTitle" aria-hidden="true">
                    <div class="modal-dialog modal-bg" role="document">
                        <div class="modal-content" style="background-color: #393f4a!important;">
                        <div class="modal-header">
                            <h5 v-if="editModalData" class="modal-title" id="editDateModalTitle">Editing Stage # {{ editModalData.item.stage_number }} - {{ editModalData.field.label }}</h5>
                        </div>
                        <div class="modal-body" :key=dateEditKey>
                            <iws-date-time-picker
                                :value.sync="editModalDate"
                            />
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <button v-if="!modalEditsDisabled" type="button" class="btn btn-success green-button" @click="saveEditDate(editModalData)">Save changes</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>
    </div>
</template>

<style scoped>
    .clickable { 
        border-width: 0px;
        cursor: pointer;
        color: #eeeeee;
    }    

    .clickable:hover {
        color: #59acff;
    }

    .container {
        display: flex;
    }

    .scroll-div{
        overflow: scroll;
        overflow-x: hidden;
    }

    .center {
        margin: auto;
        width: 60%;
    }

    .gray-button {
        background-color: #8e8e8e;
        min-width: 85px;
    }

    .green-button {
        background-color: #5cc55b;
        min-width: 85px;
    }
    
    .report-button {
        font-size: 0.8rem;
        width: 50px;
        height: 45px;
        padding: 2px;
    }
    .darkmode-form {
        background-color: #25232B;
        color: #FFFFFF;
        border: 1px solid #000000;
    }

    .table-text-display {
        width:100px; 
        text-overflow: ellipsis; 
        overflow: hidden; 
        white-space: nowrap;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none;
    }

    input[type="datetime-local"] {
        height: 38px;
        border-radius: 3px;
    }

    input[type="datetime-local"]::-webkit-calendar-picker-indicator {
        filter: invert(1);
        margin-left: -25px;
    }
</style>
<style>
    .popover.b-popover {
        z-index: 9999;
    }
    .popover-approvals > .popover-body {
        background-color: white !important;
        width: 450px;
        max-height: 500px;
        padding: 0.5rem 0.75rem;
        z-index:1000 !important;
    }
</style>

<script>
import _ from 'lodash';
import moment from 'moment';
import GlobalFunctions from '../GlobalFunctions';
import axios from 'axios';
import iwsDateTimePicker from "./Common/iwsDateTimePicker.vue";
const { toast } = GlobalFunctions;

const TYPE_TIME = 'TIME';
const TYPE_TEXT = 'TEXT';
const TYPE_NUMERIC = 'NUMERIC';

export default {
    components: {
        iwsDateTimePicker,
    },
    props: {
        tagsToShow: {
            type: [Array, Object],
            required: true, 
            default: ()=>[]
        },
        startEndLookup: {
            type: Object,
            required: true
        },
        reportDataByStage: {
            type: [Array, Object],
            required: true, 
            default: ()=>[]
        },
        wells: {
            type: [Array, Object],
            required: true, 
            default: ()=>[]
        },
        activeCompanyJobs: {
            type: [Array, Object],
            required: true, 
            default: ()=>[]
        },
        jobNumber: {
            type: String,
            required: true
        },
        jobHourOffset: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        userId: {
            type: String,
            required: true
        },
        userRoleNames: {
            type: String,
            required: true
        },
        canMassApprove: {
            type: [Boolean, Number],
            required: true
        },
        vendorName: {
            type: String,
            required: true
        },
        category: {
            type: String,
            required: true
        },
        approvalSetsByWell: {
            type: [Array, Object],
            required: false,
            default: ()=>[]
        },
        initWellNumber: {
            type: [Number],
            required: false,
            default: 0
        }
    },
    data() {
        return {
            decimalPlacesByTagName: [],
            fields: [],
            isBusy: {
                busy: false,
                state: null
            },
            selectedWell: this.wells[this.initWellNumber],
            hoveredStageData: null,
            editFields: new Map(),
            targetPopoverStage: null,
            targetPopoverStageData: null,
            approvalsDisabled: false,
            wellStageData: null,
            editModalData: null,
            editModalTarget: null,
            editModalText: null,
            editModalDate: '',
            modalEditsDisabled: false,
            popoverAlertData: {},
            END_REPORT: 'endReport',
            START_REPORT: 'startReport',
            newStageDate: {},
            dateEditKey: 0,
            stageDateCategories: {'Frac Start Date': 'stage_frac_startTime',
                                  'Frac End Date': 'stage_frac_endTime',
                                  'Wireline Start Date': 'stage_wireline_startTime',
                                  'Wireline End Date': 'stage_wireline_endTime'},
        };
    },
    mounted() {
        window.addEventListener('beforeunload', (event) => {
            if (this.isBusy.busy) {
                event.preventDefault();
                event.returnValue = '';
            }
        });
    },
    methods: {
        isFeatureFlagged(featureString) {
            return GlobalFunctions.isFeatureFlagged(featureString);
        },
        formatDate(datetime) {
            if (datetime) {
                let formattedTime = moment(datetime).format('M/DD/YYYY HH:mm:ss');
                return formattedTime == "Invalid date" ? "-" : formattedTime;
            } else {
                return '-';
            }
        },
        getDate: function(stageSummary, timestampFieldName){
            if (this.startEndLookup?.['wellsByIndex']?.[this.selectedWell.index]?.['stages']?.[stageSummary?.stageNumber]?.[timestampFieldName]){
                const date = moment(this.startEndLookup['wellsByIndex'][this.selectedWell.index]['stages'][stageSummary?.stageNumber][timestampFieldName]).utc();
                if(!this.dateIsManualOverride(this.startEndLookup['wellsByIndex'][this.selectedWell.index]['stages'][stageSummary?.stageNumber][timestampFieldName])){
                    date.add({ hours : this.jobHourOffset });
                }
                return date.format('M/DD/YYYY HH:mm').valueOf();
            } else {
                return "-";
            }
        },
        openModal: function(refKey, data, disableEdit=false){
            //Set if the state of the modal should be for viewing or edit
            this.modalEditsDisabled = disableEdit;

            //Open up a modal
            this.editModalData = data;
            this.editModalTarget = refKey;
            this.editModalText = data.value;
            $('#editTextModal').modal('show');
        },
        saveEditText: function(){
            this.editModalData.value = this.editModalText;

            this.saveValue(this.editModalData);
            $('#editTextModal').modal('hide');

            this.persistValue(this.editModalData, this.editModalText);

            this.editModalData = null;
            this.editModalTarget = null;
            this.editModalText = null;
        },
        openDatePickerModal: function(refKey, data){
            // Open modal to edit stage  dates
            this.editModalData = data;
            this.editModalTarget = refKey;
            this.editModalDate = null;
            this.dateEditKey += 1;
            $('#editDateModal').modal('show');
        },
        saveEditDate: function(data){
            // Gathers date info
            this.editModalData.value = this.editModalDate;
            this.newStageDate = {
                timestamp: new Date().toISOString(),
                category: this.stageDateCategories[this.editModalData.field.label],
                jobId: this.selectedWell.job_id,
                jobNumber: this.jobNumber,
                wellId: this.selectedWell.configuration_id,
                wellNumber: this.selectedWell.index,
                stageNumber: this.editModalData.item.stage_number,
                data: {value: this.editModalData.value, label: this.editModalData.field.label, manualOverrideBy: this.userId},
                _token: $('meta[name="csrf-token"]').attr('content')
            }
            // Validates date edit
            if(this.validateDate()){
                this.postDateEdit();
                this.updateTableDate();
                $('#editDateModal').modal('hide');
            } else {
                return toast({title: 'Please select a valid date before saving.', variant: 'danger'});
            }
        },
        postDateEdit(){
            // Posts edit date request
            $.post(
                '/update-stage-date',
                this.newStageDate,
                function (result) {
                    if (result?.hasOwnProperty('error') && result.error) {
                        alert(result.message);
                    }
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                const errorMessage = 'Failed to update value';
                if (jqXHR.status == 401) {
                    console.warn('Unauthorized');
                } else {
                    console.warn(errorMessage, errorThrown);
                    alert(errorMessage);
                }
            });
        },
        updateTableDate(){
            let stageData = this.mapDataToTable.find((element) => element.stage_number == this.editModalData.item.stage_number);
            stageData[this.editModalData.field.key] = this.formatTableDate();
        },
        formatTableDate(){
            let dateValues = this.editModalData.value.split(/[:T-]+/);
            let tableDate = dateValues[1] + "/" + dateValues[2] + "/" + dateValues[0] + " " + dateValues[3] + ":" + dateValues[4];
            return tableDate;
        },
        validateDate(){
            if(!this.editModalDate){
                return false;
            } else {
                return true;
            }
        },
        dateIsManualOverride(dateString){
            if(dateString.includes('+00:00')){
                return true;
            } else {
                return false;
            }
        },
        persistValue(data, value) {
            //This ugly code is responsible for updating the internal state of the b-table 
            //before it re-renders to avoid flashing the old value to the user for a couple seconds
            const valueIndex = this.wellStageData[data.item.stage_number]["data"]["values"].findIndex(val => val.tagName === data.field.key);
            if (valueIndex != -1) {
                this.wellStageData[data.item.stage_number]["data"]["values"][valueIndex]["value"] = value;
            } else {
                let arrayLength = this.wellStageData[data.item.stage_number]["data"]["values"].length;
                this.wellStageData[data.item.stage_number]["data"]["values"][arrayLength] = {
                    decimalPlaces: 0,
                    displayName: data.field.label,
                    docId: null,
                    tagName: data.field.key,
                    value
                };
            }
        },
        isApproved: function(wellNumber, stageNumber){
            let targetReport = this.reportDataByStage[wellNumber][stageNumber];
            if(targetReport.data.validatedBy && targetReport.data.validatedBy != null){
                //Validated state
                return "valid";
            }else if(targetReport.data.invalidatedBy && targetReport.data.invalidatedBy != null){
                //Invalidated State
                return "invalid";
            }else if(targetReport.data.invalidatedBy && targetReport.data.validatedBy && 
                        targetReport.data.validatedBy == null && targetReport.data.invalidatedBy == null){
                //Initial state or edited data state
                return "invalid";
            }else{
                //Legacy packet state (In this case we have special handling for creating new documents on field edits)
                return "invalid";
            }
        },
        setInfoHoveredData: function(wellIndex, stageNumber, data){
            this.hoveredStageData = this.getApprovalReadout(wellIndex, stageNumber);
            this.targetPopoverStageData = data;
        },
        getApprovalReadout: function(wellNumber, stageNumber){
            let targetWellData = this.approvalSetsByWell[wellNumber].sort(this.sortValidations);
            let returnArray = [];

            targetWellData.forEach((targetData)=>{
                if(targetData.stageNumber == stageNumber){
                    let formattedTimestamp = moment.utc(targetData.timestamp).add(this.jobHourOffset, 'h').format('YYYY-MM-DD @ HH:mm:ss');
                    if(targetData.data?.invalidatedBy != null){
                        returnArray.push({
                            state: "Unapproved",
                            user: targetData.data.invalidatedBy,
                            timestamp: formattedTimestamp
                        });
                    }else if(targetData.data?.validatedBy != null){
                        returnArray.push({
                            state: "Approved",
                            user: targetData.data.validatedBy,
                            timestamp: formattedTimestamp
                        });
                    }
                }
            });
            return returnArray;
        },
        sortValidations(first, second) {
            if ( moment(first.timestamp).isBefore(moment(second.timestamp)) ){
                return 1;
            }
            if ( moment(first.timestamp).isAfter(moment(second.timestamp)) ){
                return -1;
            }
            return 0;
        },
        onWellUpdated(event) {
            this.selectedWell = this.wells[event.target.selectedIndex];
            const urlType = this.type == "frac" ? "/frac-stage-summary/" : "/wireline-stage-summary/";
            window.history.replaceState(this.selectedWell.index, "IWS", urlType + this.jobNumber + "/" + this.selectedWell.index);
        },
        async copyRow(data) {
            const csvData = [
                Object.keys(data.item).join(', '),
                Object.values(data.item)
            ];
            const blob = new Blob([csvData.join('\n')], {type: 'text/plain'});
            const clipItem = [new ClipboardItem({ ['text/plain']: blob })];
            await navigator.clipboard.write(clipItem);
            this.$bvToast.toast('Copied!', {
                autoHideDelay: 400,
                variant: 'success',
                noCloseButton: true
            });
        },
        handleImport(event) {
            if (event?.target.files.length > 0) {
                this.isBusy = {
                    busy:true,
                    state: 'Importing report'
                };
                const file = event?.target.files[0];
                const reader = new FileReader();
                reader.readAsText(file);
                reader.onload = async () => {
                    if (await this.validateImport(reader.result.toString())) {
                        this.importCsvData(file);
                    } else {
                        this.isBusy.busy = false;
                    }
                };
                reader.onerror = () => {
                    this.isBusy.busy = false;
                    alert('An error occurred while reading the file');
                };
                // reset target value so same file can be uploaded again
                event.target.value = null;
            } else {
                this.isBusy.busy = false;
                alert('Error reading file');
            }
        },
        validateImport(csvData) {
            if (this.mapDataToTable?.length > 0) {
                const errors = [];
    
                //Buffer magic to deal with embbeded newlines in csvs
                const csv = csvData.trim();
                let allTextLines = [];
                let buffer = '';
                let inQuotes = false;
                for (let i = 0; i < csv.length; i++) {
                    const char = csv[i];
                    if (char === '\n' && !inQuotes) {
                        allTextLines.push(buffer);
                        buffer = '';
                    } else if (char === '"' && (i === 0 || csv[i - 1] !== '\\')) {
                        inQuotes = !inQuotes;
                        buffer += char;
                    } else {
                        buffer += char;
                    }
                }
                if (buffer !== '') {
                    allTextLines.push(buffer);
                }

                const csvHeaders = allTextLines[0].split(',').map( h => h.trim().toLowerCase());
                const stageType = this.type === 'frac' ? 'Frac' : 'Wireline';
                allTextLines.shift();
    
                const immutableHeaders = ['Stage', `${stageType} Start Date`, `${stageType} End Date`, `${stageType} Fleet`, 'UWI/API'].map(h => h.toLowerCase());
                const tagHeaders = csvHeaders.filter( (el) => {
                    return !immutableHeaders.includes(el);
                });
    
                // get headers
                if (this.tagsToShow.length !== tagHeaders.length) {
                    errors.push(`Number of columns mismatch - Expected count: ${this.tagsToShow.length}`);
                }

                // pull out un-match headers
                this.tagsToShow.forEach((tag, index) => {
                    if (!tagHeaders.includes(tag.displayName.toLowerCase()) && !tagHeaders.includes(tag.tagName.toLowerCase())) {
                        errors.push(`Header name non-match - missing header: ${tag.displayName}`);
                    } else if (tag.entryType === TYPE_TIME) {
                        // validate timestamp input
                        const timeDataIndex = csvHeaders.indexOf(tag.displayName.toLowerCase());
                        allTextLines.forEach((line, index) => {
                            const lineData = line.split(',');
                            let time = lineData[timeDataIndex];
                            time = time.replace(/\\\\|"|'/g, ''); // remove escape characters and quotes
                            if (time && (!moment(time, 'M/DD/YYYY HH:mm:ss').isValid() && !moment(time, 'M/DD/YYYY HH:mm').isValid())) {
                                errors.push(`Invalid time format: ${time} at row: ${index + 1} and column: ${timeDataIndex + 1}`);
                                errors.push(`Format should be: MM/DD/YYYY HH:mm:ss`);
                            }
                        });
                    }
                });

                // pull out mis-match headers
                const tagNames = this.tagsToShow.map(tag => tag.tagName).concat(this.tagsToShow.map(tag => tag.displayName)).map(t => t.toLowerCase());
                tagHeaders.forEach((header, index) => {
                    if (!tagNames.includes(header)) {
                        errors.push(`Header name mismatch - extra header: ${header}`);
                    }
                });

                // row mismatch
                if (Object.entries(this.wellStageData).length !== allTextLines.length) {
                    errors.push(`Number of rows mismatch - Expected count: ${Object.entries(this.wellStageData).length}`);
                }
    
                // uneditable field edited
                const tableData = _.reverse(_.cloneDeep(this.mapDataToTable));
                const stages = tableData.slice(0, allTextLines.length).map(data => `${data.stage_number}`);
                allTextLines = allTextLines.map(v => v.split(','));

                const headerIndex = csvHeaders.indexOf(immutableHeaders[0]);
                if (headerIndex > -1) {
                    const data = allTextLines.map(t => t[headerIndex]);
                    const difference = _.difference(stages, _.uniq(data));
                    if (difference.length > 0) {
                        errors.push(`Uneditable field edited: ${immutableHeaders[0]}`);
                    }
                }
    
                if (errors.length > 0) {
                    let outMsg = '';
                    errors.forEach((value) => {
                        outMsg = `${outMsg}${value}\n`;
                    });
                    alert(outMsg);    
                    return false;
                }
                return true;
            } else {
                return true;
            }
        },
        importCsvData(file) {
            const self = this;
            this.approvalsDisabled = true;
            const formData = new FormData();
            formData.append('stageReport', file);
            formData.append('tagsToShow', JSON.stringify(this.tagsToShow));
            const wellId = this.selectedWell.id;
            const jobId = this.selectedWell.job_id;
            const wellNumber = this.selectedWell.index;
            const url = `/stage-summary/import/${this.type}/${jobId}/${wellId}`;

            axios.post(
                url, 
                formData, 
                {
                    headers: { 
                        'content-type': 'multipart/form-data', 
                        '_token': GlobalFunctions.getCSRFToken()
                    }
                }
            ).then((data) => {
                this.isBusy.busy = false;
                window.location = `${self.stageRoute}/${self.jobNumber}/${wellNumber}`;
            }).catch(function (error) {
                alert('Error occurred while importing report.');
            }).finally(() => {
                this.isBusy.busy = false;
                this.approvalsDisabled = false;
            });
        },
        changeReportStatus(forAll, approvalStatus, data = null) {
            let stageNumbers = [];
            if (forAll) {
                this.isBusy.busy = true;
                if (approvalStatus && confirm(`Approve all stages for ${this.selectedWell.name}?`)) {
                    this.isBusy.state = 'Approving all stages';
                    stageNumbers = Object.values(this.reportDataByStage[this.selectedWell.index]).filter(report => !report.data?.validatedBy).map(r => r.stageNumber);
                } else if (!approvalStatus && confirm(`Unapprove all stages for ${this.selectedWell.name}?`)) {
                    stageNumbers = Object.values(this.reportDataByStage[this.selectedWell.index]).filter(report => report.data?.validatedBy).map(r => r.stageNumber);
                    this.isBusy.state = 'Unapproving all stages';
                }
            } else {
                const stageNumber = data.item.stage_number;
                if (approvalStatus && confirm(`Approve ${this.selectedWell.name} stage #${stageNumber}?`)) {
                    stageNumbers = [stageNumber];
                } else if(!approvalStatus && confirm(`Unapprove ${this.selectedWell.name} stage #${stageNumber}?`)) {
                    stageNumbers = [stageNumber];
                }
            }
            if (stageNumbers.length > 0) {
                this.sendReportData(stageNumbers, approvalStatus);
            } else {
                this.isBusy.busy = false;
            }
        },
        sendReportData: function(stageNumbers, approvalStatus) {
            this.approvalsDisabled = true;

            const wellNumber = this.selectedWell.index;
            const jobId = this.selectedWell.job_id;
            const url = `/stage-summary/approval/${jobId}/${wellNumber}`;
            const self = this;
            
            $.ajax({
                url: url,
                method: 'POST',
                data: {
                    stageNumbers,
                    type: this.type,
                    approvalStatus: approvalStatus,
                    _token: GlobalFunctions.getCSRFToken()
                },
                dataType: 'json'
            }).done((data) => {								
                if (data.error) {
                    alert(JSON.stringify(data.message));
                } else {
                    this.isBusy.busy = false;
                    window.location = self.stageRoute + '/' + self.jobNumber + '/' + wellNumber;
                }
            }).always(() =>{
                this.isBusy.busy = false;
                this.approvalsDisabled = false;
            });
        },
        saveValue(data) {
            const self = this;

            //Disable approvals until users edits have properly propogated to avoid race conditions
            this.approvalsDisabled = true;
            let valueToSend = data.value;
            if (data.field?.entryType == TYPE_TIME) {
                const value = moment.utc(data.value, "YYYY-MM-DDTHH:mm:ss");
                value.subtract(this.jobHourOffset, 'hours');
                valueToSend = value.toISOString();
            }

            const values = this.reportDataByStage[this.selectedWell.index][data.item.stage_number]['data']['values'];
            const tagData = values.find(val => val.tagName === data.field.key);
            const packetData = {
                newValue: valueToSend,
                '_token': $('meta[name="csrf-token"]').attr('content')
            };

            packetData["jobId"] = this.selectedWell.job_id;
            packetData["jobNumber"] = this.jobNumber;
            packetData["wellNumber"] = this.selectedWell.index;
            packetData["stageNumber"] = data.item.stage_number;
            packetData["type"] = this.type;
            packetData["approvalStatus"] = "false";
            packetData["fieldType"] = data.field.entryType;

            if(tagData?.hasOwnProperty('docId') && tagData["docId"] != null) {
                //Golden case
                packetData["docId"] = tagData['docId']['$oid'];
            } else {
                //Legacy packet or broken/uninitialized sensor case
                packetData["tagName"] = data.field.key;
            }

            this.persistValue(data, valueToSend);

            $.post(
                '/update-stage-value',
                packetData,
                function (result) {
                    if (result?.hasOwnProperty('error') && result.error) {
                        alert(result.message);
                    }
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                const errorMessage = 'Failed to update value';
                if (jqXHR.status == 401) {
                    console.warn('Unauthorized');
                } else {
                    console.warn(errorMessage, errorThrown);
                    alert(errorMessage);
                }
                data.value = data.value !== '-' ? data.value : null;
            }).always(() => {
                const valueIndex = self.wellStageData[data.item.stage_number]["data"]["values"].findIndex(val => val.tagName === data.field.key);
                self.wellStageData[data.item.stage_number]["data"]["values"][valueIndex]["value"] = valueToSend;
                self.approvalsDisabled = false;
            });
        },
        isStageAtypical(wellIndex, stageNumber) {
            let targetReport = this.reportDataByStage[wellIndex][stageNumber]
            return targetReport?.data?.stageStatus?.atypical == true
        },
        setPopoverAlertData(wellIndex, stageNumber) {
            let targetReport = this.reportDataByStage[wellIndex][stageNumber]
            let stageAtypical = targetReport?.data?.stageStatus?.atypical
            let message = targetReport?.data?.stageStatus?.message
            if (stageAtypical) {
                this.$set(this.popoverAlertData, 'stageNumber', stageNumber)
                this.$set(this.popoverAlertData, 'message', message ? message : 'No message found')
            }
        },
        sendReportToFunctionApp(wellIndex, stageNumber, reportTime = null, sendReport = false) {
            // if reportTime is null, it means regenerate report, otherwise it means send start or end report
            let stageType = this.category;
            let regenerateCondition = !reportTime && this.isApproved(wellIndex, stageNumber) == 'invalid' ? true : false
            let sendStartOrEndCondition = reportTime && !this.sendReportDisabled(wellIndex, stageNumber, reportTime) ? true : false
            let title = `Regenerate stage ${stageNumber} ${stageType} report`
            if (reportTime == this.START_REPORT) {
                title = `Send start ${stageType} report for stage ${stageNumber}`
            } else if (reportTime == this.END_REPORT) {
                title = `Send end ${stageType} report for stage ${stageNumber}`
            }
            let body = `Are you sure you want to regenerate stage ${stageNumber} ${stageType} report?
                                This will overwrite the existing ${stageType} report for stage ${stageNumber}.`
            if (reportTime) {
                body = `Are you sure you want to send ${stageType} ${reportTime==this.START_REPORT ? 'start': 'end'} report for stage ${stageNumber}?`
            }
            if (regenerateCondition || sendStartOrEndCondition) {
                let errorMessage = `Error occurred while regenerating`
                let busyState = 'Regenerating report'
                if (reportTime) {
                    errorMessage = `Error occurred while sending ${reportTime == this.START_REPORT ? 'start' : 'end'}`
                    busyState = `Sending ${reportTime == this.START_REPORT ? 'start' : 'end'} report`
                }
                errorMessage+= ` ${stageType} report for stage ${stageNumber}.`
                GlobalFunctions.iwsConfirm({
                    title,
                    body,
                    confirmColour: regenerateCondition ? 'danger': 'success',
                    width: '400px'
                }).then(_answer => {
                    if (_answer) {
                        this.isBusy = {
                            busy: true,
                            state: busyState
                        };
                        axios.post(
                            '/stage-summary/send-report-to-function-app',
                            {
                                'jobId': this.selectedWell.job_id,
                                'wellNumber': wellIndex,
                                'stageNumber': stageNumber,
                                'type': this.type,
                                'reportTime': reportTime,
                                'sendReport': sendReport,
                                '_token': GlobalFunctions.getCSRFToken()
                            },
                            {
                                headers: { 
                                    'content-type': 'application/json', 
                                }
                            }
                        ).then((data) => {
                            if (data?.data?.error) {
                                console.error(data.data.message)
                                this.isBusy = {
                                    busy: false,
                                    state: ''
                                };
                                alert(errorMessage);
                            } else {
                                if (!reportTime) {
                                    alert('Report regenerated successfully, click OK to reload the page.');
                                } else {
                                    alert(`${reportTime == this.START_REPORT ? 'Start' : 'End'} report sent successfully, click OK to reload the page.`);
                                }
                                this.isBusy = {
                                    busy: false,
                                    state: ''
                                };
                                window.location.reload();
                            }
                        }).catch(function (error) {
                            console.error(error)
                            this.isBusy = {
                                busy: false,
                                state: ''
                            };
                            alert(errorMessage);
                        })
                    }
                })
            }
        },
        runbackDataProcessor(wellIndex, stageNumber) {
            let stageType = this.category;
            if (stageType == 'frac' && this.isApproved(wellIndex, stageNumber) == 'invalid') {
                GlobalFunctions.iwsConfirm({
                    title: `Initiate stage ${stageNumber} ${stageType} report recalculations`,
                    body: `Are you sure you want to initiate stage ${stageNumber} ${stageType} report recalculations?
                                This action will overwrite the existing ${stageType} report and its source data for stage ${stageNumber}.`,
                    confirmColour: 'danger',
                    width: '400px'
                }).then(_answer => {
                    if (_answer) {
                        axios.post(
                            '/stage-summary/trigger-runback-report',
                            {
                                'jobId': this.selectedWell.job_id,
                                'wellNumber': wellIndex,
                                'stageNumber': stageNumber,
                                '_token': GlobalFunctions.getCSRFToken()
                            },
                            {
                                headers: { 
                                    'content-type': 'application/json', 
                                }
                            }
                        ).then((data) => {
                            if (data?.data?.error) {
                                alert(`Error occurred while initiating report recalculations for stage ${stageNumber} ${stageType} report.`);
                            } else {
                                alert('Report recalculations initiated successfully, it might take more than 30 minutes to finish the recalculations and generate the report.');
                            }
                        }).catch(function (error) {
                            alert(`Error occurred while initiating report recalculations for stage ${stageNumber} ${stageType} report.`);
                        })
                    }
                })
            }
        },
        sendReportDisabled(wellIndex, stageNumber, reportTime) {
            let targetReport = this.reportDataByStage[wellIndex][stageNumber]
            let isApproved = this.isApproved(wellIndex, stageNumber) == 'valid'
            let forceEnabled = reportTime == this.START_REPORT ? targetReport?.data?.startReportForcedEnabled : targetReport?.data?.endReportForcedEnabled
            let isReportSent = reportTime == this.START_REPORT ? targetReport?.data?.startReportSent : targetReport?.data?.endReportSent
            return !forceEnabled && (isReportSent || isApproved) ? true : false
        },
        enableSendReport(wellIndex, stageNumber, reportTime) {
            this.$set(this.reportDataByStage[wellIndex][stageNumber]?.data, `${reportTime}ForcedEnabled`, true)
        },
    },
    computed: {
        stageRoute() {
            return `/${this.category}-stage-summary`;
        },
        tableHeight() {
            const height = screen.height;
            return `${Math.round(height * 0.7)}px`;
        },
        exportFields() {
            return this.mapTableTitles;
        },
        exportFileName() {
            const currentTimeStamp = moment().utc().valueOf();
            const cleanName = this.selectedWell.name.replace(/[^a-zA-Z0-9,\-.!? ]/g, '').replace(' ', '_');
            return `${cleanName}_stage_summary_export_${this.jobNumber}_${currentTimeStamp}`;
        },
        mapDataToTable: function() {
            this.wellStageData = this.reportDataByStage[this.selectedWell.index];
            const resArray = [];
            if(this.wellStageData) {
                for (const [key, stageSummary] of Object.entries(this.wellStageData)) {
                    const resObject = { stage_number: stageSummary.stageNumber };
                    const decimalPlaceObject = {};
                    stageSummary.data.values.forEach(dataValue => { 
                        resObject[dataValue.tagName] = dataValue.value;
                        decimalPlaceObject[dataValue.tagName] = dataValue.decimalPlaces;
                    });
                    resObject['uwi'] = this.selectedWell.well_UWI;
                    if(this.startEndLookup?.['wellsByIndex']?.[this.selectedWell.index]?.['stages']?.[stageSummary.stageNumber]){
                        resObject['start_date'] = this.type == 'frac' ? this.getDate(stageSummary, 'fracStartTime') : this.getDate(stageSummary, 'wirelineStartTime');
                        resObject['end_date'] = this.type == 'frac' ? this.getDate(stageSummary, 'fracEndTime') : this.getDate(stageSummary, 'wirelineEndTime');
                    }else {
                        resObject['start_date'] = null;
                        resObject['end_date'] = null;
                    }

                    if(this.type === 'frac'){
                        resObject['Frac Fleet'] = stageSummary.vendorName ? stageSummary.vendorName : this.vendorName;
                    } else {
                        resObject['Wireline Fleet'] = stageSummary.vendorName ? stageSummary.vendorName : this.vendorName;
                    }
                    resArray.push(resObject);
                    this.decimalPlacesByTagName.push(decimalPlaceObject);
                }
                return _.orderBy(resArray, ['stage_number'], ['desc']);
            } else {
                return [];
            }
        },
        mapTableTitles: function() {
            let resArray = [
                { key: 'stage_number', label: 'Stage', stickyColumn: true, thClass: 'text-right' }
            ];

            if(this.type == "frac"){
                resArray = resArray.concat(
                    { key: 'start_date', label: 'Frac Start Date' },
                    { key: 'end_date', label: 'Frac End Date' },
                );
            }else{
                resArray = resArray.concat(
                    { key: 'start_date', label: 'Wireline Start Date' },
                    { key: 'end_date', label: 'Wireline End Date' },
                );
            }
            
            const tagsTitles = this.tagsToShow.map((tagName)=>{
                //Find the entry with the coresponding key
                return {
                    key: tagName['tagName'],
                    label: tagName['displayName'],
                    formatter: (value) => {
                        let decimals = this.decimalPlacesByTagName[0]? this.decimalPlacesByTagName[0][tagName['tagName']]: null; // this.decimalPlacesByTagName[0] Entry contains # of decimal places for entire dataset
                        if (tagName['entryType'] == TYPE_TIME && value?.includes('Z')) {
                            return moment.utc(value)
                                        .add(this.jobHourOffset, 'hours')
                                        .format('YYYY-MM-DD HH:mm:ss').replace(' ', 'T');
                        } else if (Number.isFinite(value) && Number.isFinite(decimals)) {
                            return GlobalFunctions.roundAccurately(value, decimals).toFixed(decimals);
                        }
                        return value != null ? value : null;
                    },
                    entryType: tagName['entryType'] != null ? tagName['entryType'] : TYPE_NUMERIC
                };
            });
            resArray = resArray.concat(tagsTitles);
            resArray = resArray.concat({ key: `${this.type === 'frac' ? 'Frac' : 'Wireline'} Fleet`, label: `${this.type === 'frac' ? 'Frac' : 'Wireline'} Fleet` , entryType: TYPE_TEXT });
            resArray = resArray.concat({ key: 'uwi', label: 'UWI/API' });
            return resArray;
        },
        exportData: function() {
            let exportData = this.mapDataToTable;
            if (exportData?.length > 0) {
                exportData.forEach(data => {
                for (const [key, value] of Object.entries(data)) {
                        const tag = this.tagsToShow.find(t => t.tagName === key);
                        if(value == '-' || value == "" || value == " "){
                            data[key] = null;
                        }
                        if (tag && tag?.entryType === TYPE_TIME) {
                            let test = data[key];
                            data[key] = value ?  moment.utc(value)
                                                .add(this.jobHourOffset, 'hours')
                                                .format('M/DD/YYYY HH:mm:ss') : null;
                            if(data[key] == "Invalid date"){
                                data[key] = null;
                            }
                        }
                    }
                })
            }
            return exportData;
        },
        userRoleNamesArray() {
            return this.userRoleNames ? JSON.parse(this.userRoleNames) : [];
        }
    }
};
</script>