var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "CameraSettings" } }, [
    _c(
      "div",
      { staticClass: "position-relative" },
      [
        _c("iws-breadcrumbs", {
          attrs: {
            path: [
              {
                title: "Devices",
                link: `/cameras/settings`,
              },
            ],
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "page-title" }, [
          _vm._v("\n            Device List\n        "),
        ]),
        _vm._v(" "),
        !_vm._isNullOrEmpty(_vm.devices)
          ? [
              _c(
                "div",
                { staticClass: "search-positioned", attrs: { id: "search" } },
                [
                  _c("iws-search", {
                    attrs: { value: _vm.searchKey },
                    on: {
                      "update:value": function ($event) {
                        _vm.searchKey = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("iws-table", {
                ref: "devicesTable",
                attrs: {
                  columns: _vm.columns,
                  items: _vm.devices,
                  filter: _vm.searchKey,
                  sortByCol: _vm.sortByCol,
                  maxPageSize: 12,
                },
                on: {
                  "update:sortByCol": function ($event) {
                    _vm.sortByCol = $event
                  },
                  "update:sort-by-col": function ($event) {
                    _vm.sortByCol = $event
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell_workOrderId",
                      fn: function ({ data }) {
                        return [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `/dashboards/${data?.item?.workOrderId}`,
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(data?.item?.workOrderId) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell_heartbeatTime",
                      fn: function ({ data }) {
                        return [
                          _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.formatHeartbeatTime(
                                    data?.item?.heartbeatTime
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell_status",
                      fn: function ({ data }) {
                        return [
                          data?.item?.status
                            ? _c("span", {
                                staticClass: "circle",
                                class: {
                                  "green-gradient":
                                    data?.item?.status == "Online",
                                  "red-gradient":
                                    data?.item?.status != "Online",
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "cell_actions",
                      fn: function ({ data }) {
                        return [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `/cameras/viewer/${data?.item?.workOrderId}`,
                              },
                            },
                            [
                              _c("iws-button", {
                                attrs: {
                                  type: "outline-primary",
                                  icon: "far fa-eye",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.iwsUser
                            ? _c(
                                "a",
                                {
                                  staticClass: "ml-2",
                                  attrs: {
                                    href: _vm.buildDeviceSettingsUrl(
                                      data?.item?.deviceId
                                    ),
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c("iws-button", {
                                    attrs: {
                                      type: "outline-light",
                                      icon: "fas fa-cog",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4271773365
                ),
              }),
            ]
          : _c("div", { staticClass: "no-items clickable" }, [
              _c("h2", { staticClass: "danger-text-color" }, [
                _vm._v(
                  "\n                No devices configured for this job\n            "
                ),
              ]),
            ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }