var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !!_vm.dataObject && !!_vm.options
    ? _c("ScatterLineChart", {
        attrs: { "chart-data": _vm.dataObject, options: _vm.options },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }