var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "CameraReportingViewer" } }, [
    _c(
      "div",
      { staticClass: "CameraReportingControl" },
      [
        _c("camera-navigation", {
          attrs: {
            user: _vm.user,
            device: _vm.device,
            "work-order-id": _vm.job.jobNumber,
            isReporting: "",
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "full-width mb-5", attrs: { id: "api-builder-row" } },
          [
            _c("h2", [
              _vm._v("\n                Red Zone Reporting\n            "),
            ]),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "float-right" },
              [
                _c("iws-select", {
                  attrs: {
                    label: "Device",
                    value: _vm.device.deviceId,
                    options: _vm.devices,
                    "display-name": "deviceId",
                    "value-name": "deviceId",
                    required: "required",
                  },
                  on: { change: _vm.setDevice },
                }),
                _vm._v(" "),
                _c("iws-date-time-picker", {
                  staticClass: "ml-1",
                  attrs: {
                    label: "Date Range",
                    value: _vm.range,
                    type: "range",
                    dateTimeType: "date",
                  },
                  on: {
                    "update:value": function ($event) {
                      _vm.range = $event
                    },
                    input: _vm.fetchReport,
                  },
                }),
                _vm._v(" "),
                _c("iws-button", {
                  staticClass: "ml-1",
                  staticStyle: { position: "relative", top: "-2px" },
                  attrs: {
                    text: "Download PDF",
                    type: "primary",
                    click: _vm.downloadReport,
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.report === null
      ? _c("div", { staticClass: "full-width loading" }, [_vm._m(0)])
      : _vm.report.error
      ? _c("div", [
          _c("h1", { staticClass: "danger-text-color" }, [
            _vm._v("\n            Error Fetching Report\n        "),
          ]),
          _vm._v(" "),
          _c("h3", [
            _vm._v("\n            " + _vm._s(_vm.report.error) + "\n        "),
          ]),
        ])
      : _c("div", { attrs: { id: "CameraReporting" } }, [
          _c("div", { staticClass: "quick-details row mb-5" }, [
            _c("div", { staticClass: "col-sm-6" }, [
              _c("div", { staticClass: "quick-details-title" }, [
                _vm._v("\n                    Pad Name\n                "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "quick-details-body" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.report.padName) +
                    "\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-6" }, [
              _c("div", { staticClass: "quick-details-title" }, [
                _vm._v(
                  "\n                    Reporting Period\n                "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "quick-details-body" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.report.readableStartDate) +
                    " - " +
                    _vm._s(_vm.report.readableEndDate) +
                    "\n                "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "quick-details row mb-4" }, [
            _c("div", { staticClass: "col-xs-6 col-sm-3" }, [
              _c("div", { staticClass: "quick-details-title" }, [
                _vm._v("\n                    Total Alerts\n                "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "quick-details-body" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.report.numAlertsTotal) +
                    "\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-6 col-sm-3" }, [
              _c("div", { staticClass: "quick-details-title" }, [
                _vm._v("\n                    Total Alarms\n                "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "quick-details-body" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.report.numAlarmsTotal) +
                    "\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-6 col-sm-3" }, [
              _c("div", { staticClass: "quick-details-title" }, [
                _vm._v(
                  "\n                    Total Warnings\n                "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "quick-details-body" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.report.numWarningsTotal) +
                    "\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-6 col-sm-3" }, [
              _c("div", { staticClass: "quick-details-title" }, [
                _vm._v(
                  "\n                    Total Alarm Time (hh:mm:ss)\n                "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "quick-details-body" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.report.readableAlarmTime) +
                    "\n                "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "quick-details row mb-5" }, [
            _c("div", { staticClass: "col-xs-6 col-sm-3" }, [
              _c("div", { staticClass: "quick-details-title" }, [
                _vm._v("\n                    Model Version\n                "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "quick-details-body" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.report.aiModelVersion) +
                    "\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-6 col-sm-3" }, [
              _c("div", { staticClass: "quick-details-title" }, [
                _vm._v(
                  "\n                    SiteVision Version\n                "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "quick-details-body" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      "buildVersion" in _vm.status
                        ? _vm.status.buildVersion
                        : _vm.status.fieldBuildVersion
                    ) +
                    "\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-6 col-sm-3" }, [
              _c("div", { staticClass: "quick-details-title" }, [
                _vm._v(
                  "\n                    Reported False Positives\n                "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "quick-details-body" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.report.numFalsePositivesTotal) +
                    "\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-6 col-sm-3" }, [
              _c("div", { staticClass: "quick-details-title" }, [
                _vm._v(
                  "\n                    Average Confidence\n                "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "quick-details-body" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.report.readableAverageConfidence) +
                    "%\n                "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-5" }, [
            _c("div", { staticClass: "col-xs-12 col-sm-7" }, [
              _c("b", { staticClass: "mb-3" }, [
                _vm._v(
                  "\n                    Alarms by Shift/Zone\n                "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "violations-by-shift-heatmap-container" },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _vm._l(
                    _vm.report.sortedShiftViolations,
                    function (shiftViolation) {
                      return _c(
                        "div",
                        {
                          key: shiftViolation.globalId,
                          staticClass: "violations-by-shift-heatmap-data-item",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "zone-shift-violation-data",
                              style: shiftViolation.dayShiftStyle,
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    shiftViolation.dayShiftViolationCount
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "zone-shift-violation-data",
                              style: shiftViolation.nightShiftStyle,
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    shiftViolation.nightShiftViolationCount
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "zone-shift-violation-label" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(shiftViolation.name) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]
                      )
                    }
                  ),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-xs-12 col-sm-5",
                attrs: { id: "violation-breakdown" },
              },
              [
                _c("b", { staticClass: "mb-1" }, [
                  _vm._v(
                    "\n                    Alert Breakdown\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("ViolationBreakdownChartComponent", {
                  attrs: {
                    data: {
                      alarmCount: _vm.report.numAlarmsTotal,
                      warningCount: _vm.report.numWarningsTotal,
                    },
                  },
                }),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _vm._m(3),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "mb-1",
                    staticStyle: { "font-size": "0.75em" },
                  },
                  [
                    _vm._v(
                      "\n                    * Configured threshold details found at end of report\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-5" },
            [
              _c("b", { staticClass: "mb-2" }, [
                _vm._v("\n                Alert Distribution\n            "),
              ]),
              _vm._v(" "),
              _c("ViolationDistributionGraphComponent", {
                attrs: {
                  id: "violation-distribution-graph",
                  data: _vm.scatterplotData,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mb-5" }, [
            _c("b", { staticClass: "mb-1" }, [
              _vm._v("\n                Redzone Thumbnails\n            "),
            ]),
            _vm._v(" "),
            !_vm.report?.redzones?.length
              ? _c("div", { staticClass: "my-2 danger-text-color" }, [
                  _vm._v(
                    "\n                No redzones currently configured\n            "
                  ),
                ])
              : _c(
                  "div",
                  { staticClass: "redzone-flex" },
                  _vm._l(_vm.report.redzones, function (redzone) {
                    return _c(
                      "span",
                      { staticClass: "redzone-thumbnail mt-1" },
                      [
                        _c("div", [
                          _c("div", [
                            redzone.groupName != null
                              ? _c("span", [
                                  _vm._v(_vm._s(redzone.groupName) + " - "),
                                ])
                              : _vm._e(),
                            _vm._v(
                              _vm._s(redzone.cameraDisplayName) +
                                " - " +
                                _vm._s(redzone.redZoneName) +
                                "\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              src: redzone.thumbnailURL,
                              onerror:
                                "this.onerror=null;this.className='missing-thumbnail'",
                            },
                          }),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("b", [
                _vm._v(
                  "\n                Configured Telemetry Thresholds\n            "
                ),
              ]),
              _vm._v(" "),
              _vm._m(4),
              _vm._v(" "),
              !_vm.report?.redZoneTelemetryTriggers?.length
                ? _c("div", { staticClass: "my-2 danger-text-color" }, [
                    _vm._v(
                      "\n                No Telemetry Thresholds currently configured\n            "
                    ),
                  ])
                : _c("iws-table", {
                    attrs: {
                      columns: _vm.configuredTelemetryThresholdsTableFields,
                      items: _vm.report.redZoneTelemetryTriggers,
                      sortByCol: _vm.sortByCol,
                      maxPageSize: 20,
                    },
                    on: {
                      "update:sortByCol": function ($event) {
                        _vm.sortByCol = $event
                      },
                      "update:sort-by-col": function ($event) {
                        _vm.sortByCol = $event
                      },
                    },
                  }),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "spinner-border light-text-color",
        attrs: { role: "status" },
      },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "violations-by-shift-heatmap-labels-item" },
      [
        _c("div", { staticClass: "heatmap-label-row" }, [
          _vm._v(
            "\n                            Day Shift\n                        "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "heatmap-label-row" }, [
          _vm._v(
            "\n                            Night Shift\n                        "
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("b", { staticStyle: { color: "#f44424", display: "inline" } }, [
        _vm._v("Alarm:"),
      ]),
      _vm._v(
        " Red Zone was occupied and pressure exceeded set thresholds*\n                "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-1" }, [
      _c("b", { staticStyle: { color: "#fcac14", display: "inline" } }, [
        _vm._v("Warning:"),
      ]),
      _vm._v(
        " Red Zone was occupied, but pressure remained below set thresholds*\n                "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-2" }, [
      _vm._v(
        "\n                The following are the actively configured telemetry thresholds for current red zones."
      ),
      _c("br"),
      _vm._v(
        "\n                Zones must reach the set threshold value before an alarm is fired and must fall below the reset value to reset the alarm.\n            "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }