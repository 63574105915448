import { render, staticRenderFns } from "./BTableExportComponent.vue?vue&type=template&id=531cbcbc&scoped=true&"
import script from "./BTableExportComponent.vue?vue&type=script&lang=js&"
export * from "./BTableExportComponent.vue?vue&type=script&lang=js&"
import style0 from "./BTableExportComponent.vue?vue&type=style&index=0&id=531cbcbc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "531cbcbc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/project/releases/10508/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('531cbcbc')) {
      api.createRecord('531cbcbc', component.options)
    } else {
      api.reload('531cbcbc', component.options)
    }
    module.hot.accept("./BTableExportComponent.vue?vue&type=template&id=531cbcbc&scoped=true&", function () {
      api.rerender('531cbcbc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/BTableExportComponent.vue"
export default component.exports