var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm.label
        ? _c("label", { staticClass: "primary-text-color" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  typeof _vm.label == "function" ? _vm.label() : _vm.label
                ) +
                "\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("CustomDatePicker", {
        attrs: {
          type: _vm.dateTimeType,
          disabled: _vm.disabled,
          placeholder: _vm.placeholder,
          "value-type": _vm.dateTimeFormat,
          use12h: "",
          clearable: "",
          range: _vm.type == "range",
        },
        on: { input: _vm.updateValue, close: _vm.onClose },
        model: {
          value: _vm.localValue,
          callback: function ($$v) {
            _vm.localValue = $$v
          },
          expression: "localValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }