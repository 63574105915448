var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "process-logs-filter-container" },
    [
      _c("div", { staticClass: "d-flex align-items-baseline" }, [
        _c(
          "div",
          { attrs: { id: "search-bar-container" } },
          [
            _c(
              "b-input-group",
              { attrs: { size: "md" } },
              [
                _c("b-form-input", {
                  staticClass: "search-box",
                  attrs: {
                    id: "search-input",
                    type: "text",
                    placeholder: "Type to Search",
                    debounce: "1000",
                  },
                  model: {
                    value: _vm.searchInput,
                    callback: function ($$v) {
                      _vm.searchInput = $$v
                    },
                    expression: "searchInput",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-outline-light",
            attrs: { id: "filters-button", disabled: _vm.disabled },
            on: {
              click: function ($event) {
                _vm.show = true
              },
            },
          },
          [
            _c("i", { staticClass: "fas fa-filter" }),
            _vm._v(" Filters\n            "),
          ]
        ),
        _vm._v(" "),
        !!_vm.sortBy
          ? _c("div", { staticClass: "sort-info" }, [
              _vm._v(
                "\n                " + _vm._s(_vm.sortInfo) + "\n            "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "ml-auto" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-outline-light",
              attrs: { href: _vm.exportURL },
            },
            [_c("i", { staticClass: "fas fa-file-export" }), _vm._v(" Export")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "filter-container selected-filter-container" },
        [
          _vm._l(_vm.selectedFilters.processes, function (option, index) {
            return _c(
              "span",
              {
                key: index,
                class: _vm.screenBadgeClass,
                attrs: { disabled: _vm.disabled },
              },
              [
                _vm._v("\n                        " + _vm._s(option) + " "),
                _c("i", {
                  staticClass: "fas fa-times pointer-hover",
                  staticStyle: { "margin-right": "10px" },
                  on: {
                    click: function ($event) {
                      return _vm.onScreenBadgeClicked(option, "processes")
                    },
                  },
                }),
              ]
            )
          }),
          _vm._v(" "),
          _vm._l(_vm.selectedFilters.wells, function (option, index) {
            return _c(
              "span",
              {
                key: index,
                class: _vm.screenBadgeClass,
                attrs: { disabled: _vm.disabled },
              },
              [
                _vm._v(
                  "\n                                    " +
                    _vm._s(option) +
                    " "
                ),
                _c("i", {
                  staticClass: "fas fa-times pointer-hover",
                  staticStyle: { "margin-right": "10px" },
                  on: {
                    click: function ($event) {
                      return _vm.onScreenBadgeClicked(option, "wells")
                    },
                  },
                }),
              ]
            )
          }),
          _vm._v(" "),
          _vm._l(_vm.selectedFilters.shifts, function (option, index) {
            return _c(
              "span",
              {
                key: index,
                class: _vm.screenBadgeClass,
                attrs: { disabled: _vm.disabled },
              },
              [
                _vm._v(
                  "\n                                    " +
                    _vm._s(option) +
                    " "
                ),
                _c("i", {
                  staticClass: "fas fa-times pointer-hover",
                  staticStyle: { "margin-right": "10px" },
                  on: {
                    click: function ($event) {
                      return _vm.onScreenBadgeClicked(option, "shifts")
                    },
                  },
                }),
              ]
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("iws-modal", {
        attrs: {
          id: "table-config",
          title: "Filters",
          showModal: _vm.show,
          maxWidth: "680px",
        },
        on: {
          close: _vm.closeFilterModal,
          "primary-action": _vm.saveFilters,
          "secondary-action": _vm.closeFilterModal,
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "filter-container modal-filter-container" },
                  [
                    _c("iws-select", {
                      attrs: {
                        label: "Process",
                        value: _vm.newFilters.processes,
                        multiselect: true,
                        "maintain-on-select": true,
                        "display-name": "label",
                        "value-name": "value",
                        "visible-options": 10,
                        options: _vm.processes,
                        placeholder: "Select Process",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onFilterChanged($event, "processes")
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "modal-selected-filter-container" },
                      _vm._l(
                        _vm.newFilters.processes,
                        function (option, index) {
                          return _c(
                            "span",
                            { key: index, staticClass: "badge badge-outline" },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(option) +
                                  " "
                              ),
                              _c("i", {
                                staticClass: "fas fa-times pointer-hover",
                                staticStyle: { "margin-right": "10px" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onModalBadgeClicked(
                                      option,
                                      "processes"
                                    )
                                  },
                                },
                              }),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                    _vm._v(" "),
                    _c("iws-select", {
                      attrs: {
                        label: "Well",
                        value: _vm.newFilters.wells,
                        multiselect: true,
                        "maintain-on-select": true,
                        "display-name": "name",
                        "value-name": "name",
                        "visible-options": 10,
                        options: _vm.wells,
                        placeholder: "Select Well",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onFilterChanged($event, "wells")
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "modal-selected-filter-container" },
                      _vm._l(_vm.newFilters.wells, function (option, index) {
                        return _c(
                          "span",
                          { key: index, staticClass: "badge badge-outline" },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(option) +
                                " "
                            ),
                            _c("i", {
                              staticClass: "fas fa-times pointer-hover",
                              staticStyle: { "margin-right": "10px" },
                              on: {
                                click: function ($event) {
                                  return _vm.onModalBadgeClicked(
                                    option,
                                    "wells"
                                  )
                                },
                              },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("iws-select", {
                      attrs: {
                        label: "Shift",
                        value: _vm.newFilters.shifts,
                        multiselect: true,
                        "maintain-on-select": true,
                        "display-name": "label",
                        "value-name": "value",
                        "visible-options": 10,
                        options: _vm.shifts,
                        placeholder: "Select Shift",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onFilterChanged($event, "shifts")
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "modal-selected-filter-container" },
                      _vm._l(_vm.newFilters.shifts, function (option, index) {
                        return _c(
                          "span",
                          { key: index, staticClass: "badge badge-outline" },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(option) +
                                " "
                            ),
                            _c("i", {
                              staticClass: "fas fa-times pointer-hover",
                              staticStyle: { "margin-right": "10px" },
                              on: {
                                click: function ($event) {
                                  return _vm.onModalBadgeClicked(
                                    option,
                                    "shifts"
                                  )
                                },
                              },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }