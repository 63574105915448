var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("LibraryAddEditBase", {
    ref: "libraryAddEditBase",
    attrs: {
      id: "libraryAddEditBase",
      item: _vm.gun,
      updateAction: _vm.saveGun,
      createAction: _vm.createGun,
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _vm._v(
              "\n        " +
                _vm._s(_vm.isEditMode(_vm.gun) ? "Edit Gun" : "Add New Gun") +
                "\n    "
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "leftBody",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "field-container" },
              [
                _c("div", { staticClass: "w-100" }, [
                  _c(
                    "label",
                    { attrs: { for: "nameField" } },
                    [
                      _vm._v("Name "),
                      !_vm.editName ? [_vm._v("(Auto Generated)")] : _vm._e(),
                      _vm._v(" *"),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  !_vm.editName
                    ? _c(
                        "span",
                        {
                          staticClass: "clickable",
                          attrs: { id: "edit-name-container" },
                          on: {
                            click: function ($event) {
                              _vm.editName = true
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-pen",
                            staticStyle: { "margin-right": "5px" },
                          }),
                          _vm._v(" Modify\n                "),
                        ]
                      )
                    : _c(
                        "span",
                        {
                          staticClass: "clickable",
                          attrs: { id: "edit-name-container" },
                          on: {
                            click: function ($event) {
                              _vm.editName = false
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fab fa-autoprefixer",
                            staticStyle: { "margin-right": "5px" },
                          }),
                          _vm._v(" Use Generated Name\n                "),
                        ]
                      ),
                ]),
                _vm._v(" "),
                _c("iws-input", {
                  ref: "nameInput",
                  attrs: {
                    value: !_vm.editName ? _vm.generatedName : _vm.gun.name,
                    placeholder: !_vm.editName
                      ? "Gun Name will auto generate as you fill in this form"
                      : "Provide a custom Gun Name",
                    disabled: !_vm.editName,
                    required: "true",
                    "error-message": "Please provide a name",
                  },
                  on: {
                    "update:value": function ($event) {
                      return _vm.$set(
                        !_vm.editName ? _vm.generatedName : _vm.gun,
                        "name",
                        $event
                      )
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.enableCustomerCol && !!_vm.customers && _vm.customers.length > 1
              ? _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-select", {
                      ref: "customerName",
                      attrs: {
                        label: "Customer Name *",
                        value: _vm.gun.customer_id,
                        options: _vm.customers,
                        "value-name": "id",
                        "display-name": "name",
                        required: "required",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.gun, "customer_id", $event)
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "sizeInput",
                      attrs: {
                        label: "Size (in) *",
                        value: _vm.gun.size,
                        required: "true",
                        "error-message": "Please provide a size",
                        type: "number",
                        min: "0",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.gun, "size", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "lengthInput",
                      attrs: {
                        label: "Gun Barrel *",
                        value: _vm.gun.length,
                        required: "true",
                        "error-message": "Please provide a gun barrel length",
                        type: "number",
                        min: "0",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.gun, "length", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "cluster_lengthInput",
                      attrs: {
                        label: "Cluster Length (in) *",
                        value: _vm.gun.cluster_length,
                        required: "true",
                        "error-message": "Please provide a cluster length",
                        type: "number",
                        min: "0",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.gun, "cluster_length", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "shot_densityInput",
                      attrs: {
                        label: "Shot Density (Shots/ft) *",
                        value: _vm.gun.shot_density,
                        required: "true",
                        "error-message": "Please provide a shot density",
                        type: "number",
                        min: "0",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.gun, "shot_density", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "shots_plannedInput",
                      attrs: {
                        label: "Number of Shots Planned *",
                        value: _vm.gun.shots_planned,
                        required: "true",
                        "error-message": "Please provide a shots planned",
                        type: "number",
                        min: "0",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.gun, "shots_planned", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "nominal_penetrationInput",
                      attrs: {
                        label: "Nominal Penetration (in) *",
                        value: _vm.gun.nominal_penetration,
                        required: "true",
                        "error-message": "Please provide a nominal penetration",
                        type: "number",
                        min: "0",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(
                            _vm.gun,
                            "nominal_penetration",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "rightBody",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "field-container" },
              [
                _c("iws-input", {
                  ref: "descriptionInput",
                  attrs: { label: "Description", value: _vm.gun.description },
                  on: {
                    "update:value": function ($event) {
                      return _vm.$set(_vm.gun, "description", $event)
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "estimated_hole_diameterInput",
                      attrs: {
                        label: "Estimated Hole Diameter (in)",
                        value: _vm.gun.estimated_hole_diameter,
                        type: "number",
                        min: "0",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(
                            _vm.gun,
                            "estimated_hole_diameter",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "metallurgyInput",
                      attrs: { label: "Metallurgy", value: _vm.gun.metallurgy },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.gun, "metallurgy", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", { attrs: { for: "customerNameField" } }, [
                    _vm._v("Centralized"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.gun.centralized,
                          expression: "gun.centralized",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.gun,
                            "centralized",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", [_vm._v("Yes")]),
                      _vm._v(" "),
                      _c("option", [_vm._v("No")]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "conveyance_methodInput",
                      attrs: {
                        label: "Conveyance Method",
                        value: _vm.gun.conveyance_method,
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.gun, "conveyance_method", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "carrier_makeInput",
                      attrs: {
                        label: "Carrier Make",
                        value: _vm.gun.carrier_make,
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.gun, "carrier_make", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "carrier_descriptionInput",
                      attrs: {
                        label: "Carrier Description",
                        value: _vm.gun.carrier_description,
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(
                            _vm.gun,
                            "carrier_description",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-4" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "charge_sizeInput",
                      attrs: {
                        label: "Charge Size",
                        value: _vm.gun.charge_size,
                        type: "number",
                        min: "0",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.gun, "charge_size", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-4" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "charge_makeInput",
                      attrs: {
                        label: "Charge Make",
                        value: _vm.gun.charge_make,
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.gun, "charge_make", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-4" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "carrier_typeInput",
                      attrs: {
                        label: "Charge Type",
                        value: _vm.gun.carrier_type,
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.gun, "carrier_type", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "explosive_typeInput",
                      attrs: {
                        label: "Explosive Type",
                        value: _vm.gun.explosive_type,
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.gun, "explosive_type", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "phasingInput",
                      attrs: {
                        label: "Phasing",
                        value: _vm.gun.phasing,
                        type: "number",
                        min: "0",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.gun, "phasing", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "orientationInput",
                      attrs: {
                        label: "Orientation",
                        value: _vm.gun.orientation,
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.gun, "orientation", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "orientation_methodInput",
                      attrs: {
                        label: "Orientation Method",
                        value: _vm.gun.orientation_method,
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.gun, "orientation_method", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "balanceInput",
                      attrs: { label: "Balance", value: _vm.gun.balance },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.gun, "balance", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "fluidTypeInput",
                      attrs: { label: "Fluid Type", value: _vm.gun.fluid_type },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.gun, "fluid_type", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }