import { render, staticRenderFns } from "./SignalRErrorModal.vue?vue&type=template&id=1ae80174&scoped=true&"
import script from "./SignalRErrorModal.vue?vue&type=script&lang=js&"
export * from "./SignalRErrorModal.vue?vue&type=script&lang=js&"
import style0 from "./SignalRErrorModal.vue?vue&type=style&index=0&id=1ae80174&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ae80174",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/project/releases/10508/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1ae80174')) {
      api.createRecord('1ae80174', component.options)
    } else {
      api.reload('1ae80174', component.options)
    }
    module.hot.accept("./SignalRErrorModal.vue?vue&type=template&id=1ae80174&scoped=true&", function () {
      api.rerender('1ae80174', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/SignalRErrorModal.vue"
export default component.exports