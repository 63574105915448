<template>
    <div>
        <b-popover :target="'export-popover'+_uid" triggers="hover" placement="left" :delay="{show: 0, hide: 500}">
            <div class="btn-group-vertical">
                <button type="button" class="btn btn-secondary grey-button mb-2" @click="onExportData('csv')">
                    Export as CSV
                </button>
                <button type="button" class="btn btn-secondary grey-button" @click="onExportData('json')">
                    Export as JSON
                </button>
            </div>
        </b-popover>
        <b-popover :target="'info-tooltip'+_uid" triggers="hover" placement="left">
            <div class="text-white">
                <div>This Summary Bar component displays the service activities of frac and wireline per well.</div>
                <br/>
                <div>The activity bar will match the well color.  Hover over an activity to display further details on the well, stage, duration and times.  Click and drag left or right to pan, use the mouse roller to zoom in and out.</div>
                <br/>
                <div>Use the quick filter buttons to promptly define a timeframe.  Once a filtered view is selected, the quick filter button will be highlighted, and the component will not be updating with live data.  Click the red return to 3Hr live view button to return to the default live view.</div>
            </div>
        </b-popover>
        <iws-modal title="Custom Filter"
            :showModal="showCustomFilterModal"
            @close="showCustomFilterModal = false"
            primaryButtonText="Filter"
            secondaryButtonText="Reset"
            :secondaryButtonDisabled="secondaryModalButtonDisabled"
            :primaryButtonDisabled="primaryModalButtonDisabled"
            @secondary-action="clearCustomFilter()"
            @primary-action="saveCustomFilter()"
            maxWidth="45em"
        >
            <template #content>
                <div class="mb-3">Select the date and time range you wish to filter for</div>
                <div class="d-flex flex-column rounded grey-border">
                    <div class="user-select-none clickable p-2 grey-border-bottom" @click="tempModalFilterType = 'custom'">
                        <div :class="`d-flex ${tempModalFilterType != 'custom'? 'align-items-center' : 'align-items-baseline'}`">
                            <input type="radio" class="mr-2" v-model="tempModalFilterType" value="custom"/>
                            <div class="d-flex flex-column">
                                <div>Define a Custom Time Range</div>
                                <div v-if="tempModalFilterType == 'custom'">
                                    <div>
                                        <div class="d-flex-column my-2">
                                            <div>
                                                <div>Start Date</div>
                                                    <calendar prompt="Select start date and time"
                                                            :min="minStartFilter"
                                                            :max="endDate"
                                                            :dateValue="tempFilterStartTime"
                                                            :utcHourOffset="jobHourOffset"
                                                            @change="value => updateTempStartFilter(value)"
                                                            :showErrorState="errors.filterStartTimeMissing"/>

                                                <div v-if="errors.filterStartTimeMissing" class="error-text">Start date and time</div>
                                            </div>
                                            <div class="mt-2">
                                                <div>End Date</div>
                                                    <calendar prompt="Select end date and time"
                                                            :min="minEndFilter"
                                                            :max="endDate"
                                                            :dateValue="tempFilterEndTime"
                                                            :utcHourOffset="jobHourOffset"
                                                            @change="value => updateTempEndFilter(value)"
                                                            :showErrorState="errors.filterEndTimeMissing"/>

                                                <div v-if="errors.filterEndTimeMissing" class="error-text">End date and time</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center user-select-none clickable p-2 grey-border-bottom" @click="tempModalFilterType = 'midnight'">
                        <input type="radio" class="mr-2" v-model="tempModalFilterType" value="midnight"/>
                        <div>Previous 24hr Day Midnight to Midnight</div>
                    </div>
                    <div class="d-flex align-items-center user-select-none clickable p-2" @click="tempModalFilterType = 'shiftStart'">
                        <input type="radio" class="mr-2" v-model="tempModalFilterType" value="shiftStart"/>
                        <div>Previous 24hr Shift Start to Shift Start</div>
                    </div>
                </div>
            </template>
        </iws-modal>
        <div v-show="disabledZoomMessage"
            :style="{zIndex: 10, position: 'absolute', bottom:'10px', right: '10px', backgroundColor: '#3490dc', fontSize: '0.8vw', padding: '10px'}"
            >Click the chart to enable scroll zoom</div>
        <div class="w-full d-flex flex-column " @mouseleave="hoveringChart=false" @mouseover="hoveringChart=true" @wheel="zoomDisabledMessage" tabindex="-1" @focus.capture="enableZoomOnFocus()" @blur="disableZoomOnBlur()">
            <div class="w-full border-bottom d-flex justify-content-end pb-1 smaller-font">
                <div class="mr-auto d-flex align-items-center">
                    <div>{{timeRangeText}}</div>
                </div>
                <div class="pt-1 d-flex align-items-center justify-content-center mr-2">
                    <div :class="`clickable d-flex align-items-center rounded ${modalFilterType != ''? 'custom-filter-active' : 'custom-filter-inactive'}`"
                        @click="showCustomFilterModal=true">
                        <img src="/images/icons/toggles.svg" class="mr-1"/>
                        <div>Custom filter</div>
                    </div>
                </div>
                <span class="mr-2 d-flex align-items-center" :id="'export-popover'+_uid">
                    <img src="/images/icons/download.svg"/>
                    <div>Export</div>
                </span>
                <span :id="'info-tooltip'+_uid">
                    <img src="/images/icons/info-circle.svg"/>
                </span>
            </div>
            <div class="w-full d-flex relative">
                <!-- overlay that covers y axis labels to prevent tooltips
                showing while hovering over them (causes bugs with tooltip display from the left) -->
                <div class="position-absolute" :style="'height:'+ labelCoveringOverlayHeight + 'px; width:'+ labelCoveringOverlayWidth+'px;'"></div>

                <div class="flex-grow-1" style="margin-right: 55px; width: 85%;">
                    <bar-chart :chart-data="summaryData" :style="{height: height + 'px', width: '99%'}" :options="summaryBarOptions" id="bar-chart" ref="barChart"></bar-chart>
                </div>
                <div class="p-2 flex-shrink-0" style="margin-left: -55px;">
                    <table class="w-full">
                        <tr v-for="filterRow,parentIndex in filters">
                            <td v-for="filter,index in filterRow"
                                @click="setFilter(filter)"
                                :key="parentIndex + ':' + index"
                                :class="`badge badge-pill ${filter == selectedFilter? 'filter-selected' : 'filter'} clickable mb-1 ${index < filterRow.length-1? 'mr-1' : ''}`"
                            > {{ filter }}
                            </td>
                        </tr>
                        <tr :class="`w-full rounded ${filterActive || !scrollOnNewData? 'return-live clickable' : 'return-live-disabled'} d-flex align-items-center justify-content-center mt-1 user-select-none`"
                            @click="followLiveData()">
                            <img src="/images/icons/undo.svg" class="mr-2"/>
                            <div>3Hr live view</div>
                        </tr>
                    </table>
                </div>
            </div>
            <div  v-if="editMode">
                <div id="optionMenu" class="input-group" >
                    <div class="input-group-prepend">
                        <label class="input-group-text" >Time Format:</label>
                    </div>
                    <select  id="inputGroupSelect02" class="form-control mr-2 " v-model="timeFormatSelected"  @change="onSelectTimeFormat($event.target.value)">
                        <option v-for="time in timeFormatOptions" >
                            {{ time }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div v-if="isLoading">
            <div class="spinner-border spinner-border-sm loading-spinner-position" role="status">
                <span class="sr-only mb-5">Loading...</span>
            </div>
        </div>
    </div>
</template>
<style>
    #chartjs-tooltip-summary-bar {
        opacity: 1;
        text-align: left;
        position: absolute;
        background: rgba(0, 0, 0, .7);
        color: white;
        border-radius: 3px;
        -webkit-transition: all .1s ease;
        transition: all .1s ease;
        pointer-events: none;
    }
</style>
<style scoped>

    #optionMenu{
        position: absolute;
        top:40px;
        width:220px;
        right: 20px;
    }
    .filter {
        font-size: .8rem;
        font-weight: 200;
        width: 4rem;
        background-color: #2A4742;
        border: 1px solid #2A4742;

    }
    .filter-selected {
        font-size: .8rem;
        font-weight: 200;
        width: 4rem;
        background-color: #027A48CC;
        border: 1px solid #12B76A;
    }
    .custom-filter-active {
        padding-left:0.5rem;
        padding-right:0.5rem;
        background-color: #027A48CC;
        border: 1px solid #12B76A;
    }
    .custom-filter-inactive {
        padding-left:0.5rem;
        padding-right:0.5rem;
        border: 1px solid transparent;
    }
    .return-live {
        height: 2rem;
        font-size: .8rem;
        font-weight: 200;
        border: 1px solid #D92D20;
        color: white;
        background-color: #79181033;
        animation: clearFilter 1s infinite;
    }
    .return-live-disabled {
        height: 2rem;
        font-size: .8rem;
        font-weight: 200;
        border: 1px solid #787E87;
        color: #787E87;
    }
    .return-live-disabled > img {
        /* simplest way to change svg color */
        /* magic number obtained through experimentation*/
        filter: brightness(0.32);
    }

    .error-text {
        color: #F04438;
    }

    .date-input {
        border-radius: 3px;
    }

    input[type="radio"] {
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        /* Remove most all native input styles */
        appearance: none;
        /* For iOS < 15 */
        background-color: var(--form-background);
        /* Not removed via appearance */
        margin: 0;

        font: inherit;
        color: currentColor;
        width: 1.15rem;
        height: 1.15rem;
        border: 1px solid #D0D5DD;
        border-radius: 50%;
        transform: translateY(-0.075em);

        display: grid;
        place-content: center;
    }

    input[type="radio"]::before {
        content: "";
        width: 0.45rem;
        height: 0.45rem;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset .7rem .7rem #29A5FF;
        /* Windows High Contrast Mode */
        background-color: CanvasText;
    }
    input[type="radio"]:checked {
        border: 1px solid #29A5FF;
    }
    input[type="radio"]:checked::before {
        transform: scale(1);
    }

    .loading-spinner-position {
        position:absolute;
        top:30px;
        right:0;
        left:0;
        margin: auto;
    }

    .grey-border {
        border: 1px solid #626770;
    }

    .grey-border-bottom {
        border-bottom: 1px solid #626770;
    }

    .smaller-font {
        font-size: 0.8rem;
    }

    @keyframes clearFilter {
        0% { background-color: #79181033; border: 1px solid #D92D20; }
        50% { background-color: #79181033; border: 1px solid #D92D20; }
        51% { background-color: #79181033; border: 1px solid #791810; }
        100% { background-color: #79181033; border: 1px solid #791810; }
    }
    @-webkit-keyframes clearFilter {
        0% { background-color: #79181033; border: 1px solid #D92D20; }
        50% { background-color: #79181033; border: 1px solid #D92D20; }
        51% { background-color: #79181033; border: 1px solid #791810; }
        100% { background-color: #79181033; border: 1px solid #791810; }
    }
</style>

<script>
import BarChart from '../barChart2.js';
import GlobalFunctions from '../GlobalFunctions.js';
const { isFalsy } = GlobalFunctions;
import moment from 'moment';
import _ from 'lodash';

Chart.Tooltip.positioners.cursor = function(chartElements, coordinates) {
    return coordinates;
};

const GENERAL_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const MILLIS_PER_HR = 3600000;
const PAN_TO_LIVE_DATA_THRESHOLD = 2000; //milliseconds
export default {

    created(){
        if (!this.item.hasOwnProperty('options')){
            this.item.options = {
                timeFormat: '12H'
            };
        }
    },
    mounted() {
        if (this.fracSystems?.length > 1) {
            this.fracSystems.sort((a, b) => a.number - b.number);
        }
        this.timeFormatSelected = this.item.options.timeFormat;
        if(this.$refs['barChart']) {
            this.$refs['barChart'].$data._chart.resize();
            this.labelCoveringOverlayWidth = this.$refs['barChart'].$data._chart?.scales['service-axis']?.right ?? '0';
            this.labelCoveringOverlayHeight = this.$refs['barChart'].$data._chart?.scales['service-axis']?.bottom ?? '0';
        }
        if(this.activityData?.length !== 0 ) {
            this.barChartData(this.activityData);
        }

        this.secondaryModalButtonDisabled = true;
    },
    watch: {
        activityData: function(newVal, oldVal) { // new data, fires when reloading chart data
            this.isLoading = false;
            this.handleNewActivities(newVal);
        },
        latestActivityData: function(newVal, oldVal) { //polling, to continuously get new data
            this.handleNewActivities(newVal);
        },
        bounds: function(newVal, oldVal) {
            const { lower, upper } = newVal;
            if (!this.filterActive)
                this.changeMinMaxValues(lower, upper);
        },
        height: {
            immediate: true,
            handler(newVal, oldVal) {
                if(this.$refs['barChart']) {
                    this.$refs['barChart'].$data._chart.resize();
                }
            }
        },
        tempModalFilterType: function(newVal, oldVal)
        {
            if (newVal)
                this.secondaryModalButtonDisabled = false;
            if (!newVal)
                this.secondaryModalButtonDisabled = true;

            this.errors.filterStartTimeMissing = false;
            this.errors.filterEndTimeMissing = false;
        }
    },
    components: {
        BarChart,
    },
    computed: {
        filterActive() {
            return this.filterStartTime != null || this.filterEndTime != null || this.modalFilterType.length;
        },
        maxStartFilter()
        {
            return this.tempFilterEndTime;
        },
        maxFilter() {
            this.endDate;
        },
        minStartFilter()
        {
            return moment.utc(this.jobStart).add({'hours': this.jobHourOffset}).toISOString().split('T')[0] + ' 00:00:00';
        },
        minEndFilter()
        {
            if (this.tempFilterStartTime
                && moment.utc(this.jobStart).add({'hours': this.jobHourOffset}).isSameOrBefore(moment(this.tempFilterStartTime)))
                return this.tempFilterStartTime;
            else
                return moment.utc(this.jobStart).add({'hours': this.jobHourOffset}).toISOString().split('T')[0] + ' 00:00:00';
        },
        timeRangeText()
        {
            let text = "Time Range: ";
            let withBrackets = true;

            if (this.selectedFilter && this.selectedFilter !== 'custom')
                text += this.filterDescriptions[this.selectedFilter];
            else if (this.modalFilterType == 'custom' && (this.filterStartTime || this.filterEndTime))
                withBrackets = false;
            else if (this.modalFilterType == 'midnight')
                text += "Midnight to Midnight";
            else if (this.modalFilterType == 'shiftStart')
                text += "Shift Start to Shift Start";
            else
                return "";

            if (withBrackets)
                text += " (";

            text += moment.utc(this.currentMinXValue).format('YYYY-MM-DD hh:mmA')
                    + " to " + moment.utc(this.currentMaxXValue).format('YYYY-MM-DD hh:mmA');

            if (withBrackets)
                text += ")";

            return text;
        }
    },
    methods: {
        onSelectTimeFormat(selectedTime){
            this.timeFormatSelected = selectedTime
            this.item.options =  {
                'timeFormat': selectedTime
            };
        },

        onExportData(type) {
            const self = this;
            const url = `/export-summary/${this.jobNumber}`;

            let startTime,endTime;
            if (this.selectedFilter) {//quick filters selected
                startTime = this.filterStartTime ? moment.utc(this.filterStartTime).subtract({'hours': this.jobHourOffset}).valueOf() : null;
                endTime = this.filterEndTime ? moment.utc(this.filterEndTime).subtract({'hours': this.jobHourOffset}).valueOf() : null;
            } else {//no quick filters, get chart range
                startTime = moment.utc(this.currentMinXValue).subtract({'hours': this.jobHourOffset}).valueOf();
                endTime = moment.utc(this.currentMaxXValue).subtract({'hours': this.jobHourOffset}).valueOf();
            }
            const data = {
                quickFilter: this.selectedFilter == 'custom' ? null : this.selectedFilter,
                startTimeMs: startTime,
                endTimeMs: endTime
            }
            $.ajax({
                type: 'GET',
                url: url,
                data: data,
                success: function (activities) {
                    if(activities.error) {
                        alert("Export failed: " + activities.message.toLowerCase());
                    } else {
                        const headers = ['Start Time', 'Start Time (ISO)', 'Start Time (UNIX)', 'End Time', 'End Time (ISO)', 'End Time (UNIX)', 'Duration (Min)', 'Duration (Sec)', 'Activity', 'Status', 'Stage Number', 'Well Name', 'Well Number', 'Well UWI', 'From Well', 'To Well'];
                        const csvData = [
                            headers.join(', ')
                        ];
                        if(type === 'csv') {
                            activities.forEach(activity => {
                                csvData.push(Object.values(activity));
                            });
                        }
                        const fileName = `summary_data_export_${self.jobNumber}_${moment().utc().valueOf()}.${type}`;
                        const downloadUrl = type === 'csv' ? window.URL.createObjectURL(new Blob([csvData.join('\n')])) :
                            `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(activities))}`;
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }
                },
            });
        },
        mapDataWithCrossOvers(activitiesByLane, crossOverLaneKeys) {
            const updatedActivitiesByLane = { ...activitiesByLane};
            crossOverLaneKeys.forEach(laneKey => {
                const laneValues = [];
                if(activitiesByLane[laneKey].length > 0) {
                    const inProgressOverlaps = activitiesByLane[laneKey].filter((activity)=> activity.endTime === null);
                    const completedOverlaps = activitiesByLane[laneKey].filter((activity)=> activity.endTime !== null);

                    if(inProgressOverlaps.length < 2) {
                        completedOverlaps.push(...inProgressOverlaps);
                    }

                    completedOverlaps.forEach((currentValue, index) => {
                        let overlap = null;
                        let valueToFix = {...currentValue};
                        const prevValue = activitiesByLane[laneKey][index-1]? activitiesByLane[laneKey][index-1] : null;
                        const nextValue = activitiesByLane[laneKey][index+1]? activitiesByLane[laneKey][index+1] : null;

                        const previousEndTime = prevValue? moment.utc(prevValue.endTime, GENERAL_TIME_FORMAT).add({hours:this.jobHourOffset}).valueOf() : null;
                        const currentStartTime = moment.utc(currentValue.startTime, GENERAL_TIME_FORMAT).add({hours:this.jobHourOffset}).valueOf();
                        const currentEndTime = moment.utc(currentValue.endTime, GENERAL_TIME_FORMAT).add({hours:this.jobHourOffset}).valueOf();
                        const nextStartTime = nextValue? moment.utc(nextValue.startTime, GENERAL_TIME_FORMAT).add({hours:this.jobHourOffset}).valueOf() : null;

                        if(nextStartTime !== null && (currentValue.endTime == null || currentEndTime > nextStartTime)) { //fixing end overlap
                            overlap = {
                                ...currentValue,
                                startTime: nextValue.startTime,
                                endTime: currentValue.endTime,
                                type: 'overlap',
                                currentValue,
                                nextValue
                            };
                            valueToFix = {
                                ...valueToFix,
                                endTime: nextValue.startTime
                            };
                        }

                        if(previousEndTime !== null && previousEndTime > currentStartTime) { //fixing front overlap
                            valueToFix = {
                                ...valueToFix,
                                startTime: prevValue.endTime
                            };
                        }

                        //don't add the current value if it is entirely overlapped
                        if(!(currentValue.endTime == null && (prevValue == null || prevValue.endTime == null))) {
                            laneValues.push(valueToFix);
                        }

                        if(overlap) {
                            laneValues.push(overlap);
                        }
                    });

                    //for a lane there will be 2 continues overlaps

                    if(inProgressOverlaps.length === 2) {
                        if(laneValues.length > 0) {
                            const lastLaneActivity = laneValues[laneValues.length - 1];
                            laneValues.push({
                                ...inProgressOverlaps[0],
                                startTime: lastLaneActivity.endTime,
                                endTime: inProgressOverlaps[1].startTime
                            });
                        } else {
                            laneValues.push({
                                ...inProgressOverlaps[0],
                                endTime: inProgressOverlaps[1].startTime
                            });
                        }

                        laneValues.push({ //overlap
                            ...inProgressOverlaps[0],
                            startTime: inProgressOverlaps[1].startTime,
                            endTime: null,
                            type: 'overlap',
                            currentValue: inProgressOverlaps[0],
                            nextValue: inProgressOverlaps[1]
                        });
                    }
                }
                updatedActivitiesByLane[laneKey] = laneValues;
            });
            return updatedActivitiesByLane;
        },
        setLoading( min, max ) {
            if(min < this.alreadyFetchedStart) {
                this.isLoading = true;
                this.alreadyFetchedStart = min;
            }

            if(max > this.alreadyFetchedEnd) {
                this.isLoading = true;
                this.alreadyFetchedEnd = max;
            }
        },
        handleNewActivities(newData) {
            if (!isFalsy(this.selectedFilter))
                this.setFilter(this.selectedFilter, false);
            
            const oldData = this.rawActivities;
            const newActivities= newData.filter((activity)=>{
                return (oldData.find((oldActivities)=>oldActivities.id== activity.id || oldActivities?._id === activity?._id)) ? false : true;
            });

            this.rawActivities = [...newActivities, ...oldData].sort((a, b) => {
                if ( moment.utc(a.startTime, GENERAL_TIME_FORMAT).valueOf() < moment.utc(b.startTime, GENERAL_TIME_FORMAT).valueOf()) {
                    return -1;
                }
                if ( moment.utc(a.startTime, GENERAL_TIME_FORMAT).valueOf() > moment.utc(b.startTime, GENERAL_TIME_FORMAT).valueOf() ) {
                    return 1;
                }
                return 0;
            });

            this.rawActivities = this.rawActivities.reduce((acc, current) => {
                const existing = acc.find(item => item.reference === current.reference);
                //if an inProgress activity was found with the same reference id
                //then it should be replaced with the complete activity
                if(existing) {
                    if(existing.subCategory == 'inProgress' && current.subCategory == 'completed') {
                        //remove existing
                        acc = acc.filter(function( obj ) {
                            return obj.reference !== existing.reference;
                        });
                    }
                    else if(current.subCategory == 'inProgress' && existing.subCategory == 'completed') {
                        //don't add current
                        return acc;
                    }
                }

                return acc.concat([current]);
            }, []);

            this.barChartData(this.rawActivities);

            if(!this.isJobCompleted && !this.filterActive) {
                this.changeMinMaxValues(this.bounds.lower, this.bounds.upper);
            }
        },
        changeMinMaxValues(lower = null, upper = null) {
            const xMin = moment.utc(lower).add({hours: this.jobHourOffset});
            const xMax = this.isJobCompleted? moment.utc(this.isJobEndTime).add({hours: this.jobHourOffset}) : moment.utc().add({hours: this.jobHourOffset});
            const chart = this.getBarChart();
            chart.options.plugins.zoom.pan.rangeMin.x = xMin.valueOf();
            chart.options.plugins.zoom.pan.rangeMax.x = xMax.valueOf();
            chart.options.plugins.zoom.zoom.rangeMin.x = xMin.valueOf();
            chart.options.plugins.zoom.zoom.rangeMax.x = xMax.valueOf();
        },
        calculateDuration(startUnix, endUnix) {
            const start = moment(startUnix);
            const end = moment(endUnix);
            const durationObject = moment.duration(start.diff(end)).abs();
            const inDays = Math.abs(durationObject.days());
            const inHours = durationObject.hours();
            const inMinutes = durationObject.minutes();
            const inSeconds = durationObject.seconds();
            return {
                inDays,
                inHours,
                inSeconds,
                inMinutes
            };
        },
        getLabels() {
            const yAxisLabels = [];

            if(this.wirelineSystems && this.wirelineSystems.length > 0) {
                const wirelineLabels = this.wirelineSystems.map((item)=>{
                    return `( Wireline ) ${item.name}`;
                });
                yAxisLabels.push(...wirelineLabels);
            } else {
                yAxisLabels.push('Wireline');
            }

            if (this.isSimuFrac) {
                const fracLabels = this.wells.map((well)=> `( Frac ) Well - ${this.truncate(well.name, 10)}`);
                yAxisLabels.push(...fracLabels);
            } else if (this.isMultiFrac && this.fracSystems && this.fracSystems.length > 0) {
                const fracLabels = this.fracSystems.map((item)=>{
                    return `( Frac ) ${item.name}`;
                });
                yAxisLabels.push(...fracLabels);
            } else {
                yAxisLabels.push('Frac');
            }

            return yAxisLabels;
        },
        truncate(str, n) {
            return (str.length > n) ? str.substr(0, n-1) + '...' : str;
        },
        renderChart: function() {
            const chart = this.getBarChart();

            this.labelCoveringOverlayWidth = chart.scales['service-axis']?.right ?? '0';
            this.labelCoveringOverlayHeight = chart.scales['service-axis']?.bottom ?? '0';

            if(this.scrollOnNewData) {
                chart.options.scales.xAxes[0].ticks.max = this.isJobEndTime? moment.utc(this.isJobEndTime, GENERAL_TIME_FORMAT).add({hours:this.jobHourOffset}).valueOf() : moment.utc().add({hours:this.jobHourOffset}).valueOf();
            }
            chart.update();
        },
        getBarChart() {
            if(this.$refs['barChart']) { //this means the component is mounted;
                return this.$refs['barChart'].$data._chart;
            } else {
                return null;
            }
        },
        formatScale: function(value) {
            const datetimeValue = moment.utc(value);
            //format scale depending on time format
            let formatString = this._data.timeFormatSelected === '12H'  ? "h:mm a" : " HH:mm"
            return datetimeValue.format(`MMM Do, ${formatString}`);
        },
        formatLabel: function(tooltipItem, data) {
            //format Label depending on time format
            let formatString = this._data.timeFormatSelected === '12H'  ? "LTS" : "HH:mm:ss"

            const tooptipData = data.datasets[tooltipItem.datasetIndex].extraData[tooltipItem.index];
            if(tooptipData?.activity?.nextValue) {
                const startTimeString =  moment.utc(tooptipData.startTime).format(formatString);
                const endTimeString =   moment.utc(tooptipData.endTime).format(formatString);
                return `Continuous frac well ${tooptipData.activity.well.name} to ${tooptipData.activity.nextValue.well.name}<br/>Duration: ${tooptipData.durationLabel}<br/>${(startTimeString? 'Start time: ' + startTimeString+'<br/>' : '')}End time: ${endTimeString}`;
            }

            if(tooptipData?.activity && tooptipData.well) {
                const startTimeString =  moment.utc(tooptipData.startTime).format(formatString);
                const endTimeString =   moment.utc(tooptipData.endTime).format(formatString);
                return `Well name: ${tooptipData.well.name}<br/>Stage number: ${tooptipData.activity.stageNumber}<br/>Duration: ${tooptipData.durationLabel}<br/>${(startTimeString? 'Start time: ' + startTimeString+'<br/>' : '')}End time: ${endTimeString}`;
            } else {
                return `Standby<br/>Duration: ${tooptipData.durationLabel}`;
            }
        },
        generateActivityBarData(activity, labelIndex, backgroundColorArray, currentData, currentExtraData) {
            const startTime = moment.utc(activity.startTime, GENERAL_TIME_FORMAT).add({hours:this.jobHourOffset}).valueOf();
            const endTime = activity.endTime? moment.utc(activity.endTime, GENERAL_TIME_FORMAT).add({hours:this.jobHourOffset}).valueOf() : moment.utc(this.endDate).add({hours:this.jobHourOffset}).valueOf();
            const well = this.wells.find((well)=>well.index===activity.wellNumber);
            if (!well) {
                return false;
            }
            backgroundColorArray[labelIndex] = well.color;
            const duration = this.calculateDuration(startTime, endTime);

            if ((startTime - endTime) !== 0) {
                const durationLabel = this.getDurationLabel(duration);

                currentData[labelIndex] = [startTime, endTime];
                let label = '';
                let color = well.color;
                if(this.isContinuousFrac && activity.type === 'overlap') {
                    const pixelOffset = 10;
                    const nextWell = this.wells.find((well)=>well.index===activity.nextValue.wellNumber);
                    activity.well = well;
                    activity.nextValue.well = nextWell;
                    const bar_ctx = this.$refs['barChart'].$refs.canvas.getContext('2d');
                    const chart = this.getBarChart();
                    const scale = chart.scales['service-axis'];
                    const midBarPixel = scale.getPixelForValue(labelIndex);
                    const background_1 = bar_ctx.createLinearGradient(0, midBarPixel-pixelOffset, 0, midBarPixel+pixelOffset);
                    background_1.addColorStop(0, well.color);
                    background_1.addColorStop(1, nextWell.color);
                    label = 'CF: '+ durationLabel;
                    backgroundColorArray[labelIndex] = background_1;
                    color = GlobalFunctions.blendColors(well.color, nextWell.color, 0.5);
                } else {
                    label = well.name +',stg' + activity.stageNumber + ' : ' + durationLabel;
                }

                currentExtraData[labelIndex] = {
                    startTime: startTime,
                    duration: duration,
                    endTime: endTime,
                    jobHourOffset: this.jobHourOffset,
                    label: label,
                    durationLabel,
                    well: well,
                    color: color,
                    activity: activity,
                };

                const barObject = {
                    data: currentData,
                    borderWidth: 1,
                    borderColor: '#000000',
                    backgroundColor: backgroundColorArray,
                    extraData: currentExtraData
                };

                return barObject;
            } else {
                return false;
            }
        },
        generateMiddleGapBarData(currentActivity, previousActivity, labelIndex, refData, refExtraData, gapBackgroundColor) {
            const gapStartTime = moment.utc(previousActivity.endTime, GENERAL_TIME_FORMAT).add({hours: this.jobHourOffset}).valueOf();
            const gapEndTime = moment.utc(currentActivity.startTime, GENERAL_TIME_FORMAT).add({hours: this.jobHourOffset}).valueOf();
            const duration = this.calculateDuration(gapStartTime, gapEndTime);
            if((gapStartTime - gapEndTime) !== 0) {
                const  durationLabel = this.getDurationLabel(duration);
                refData[labelIndex] = [gapStartTime, gapEndTime];
                refExtraData[labelIndex] = {
                    startTime: gapStartTime,
                    duration: duration,
                    endTime: gapEndTime,
                    jobHourOffset: self.jobHourOffset,
                    label: durationLabel,
                    durationLabel
                };
                const gapBarData = {
                    data: refData,
                    borderWidth: 1,
                    borderColor: '#000000',
                    backgroundColor: gapBackgroundColor,
                    extraData: refExtraData
                };
                return gapBarData;
            } else {
                return null;
            }
        },
        generateEndGapBarData(latestActivity, labelIndex, refData, refExtraData, gapBackgroundColor) {
            const gapStartTime = moment.utc(latestActivity.data.endTime, GENERAL_TIME_FORMAT).add({hours: this.jobHourOffset}).valueOf();
            const gapEndTime = this.isJobCompleted? moment.utc(this.isJobEndTime).add({hours: this.jobHourOffset}).valueOf() : moment.utc().add({hours: this.jobHourOffset}).valueOf();
            const duration = this.calculateDuration(gapStartTime, gapEndTime);
            if((gapStartTime - gapEndTime) !== 0) {
                const  durationLabel = this.getDurationLabel(duration);
                refData[labelIndex] = [gapStartTime, gapEndTime];
                refExtraData[labelIndex] = {
                    startTime: gapStartTime,
                    duration: duration,
                    endTime: gapEndTime,
                    jobHourOffset: self.jobHourOffset,
                    label: durationLabel,
                    durationLabel
                };
                const gapBarData = {
                    data: refData,
                    borderWidth: 1,
                    borderColor: '#000000',
                    backgroundColor: gapBackgroundColor,
                    extraData: refExtraData
                };
                return gapBarData;
            } else {
                return null;
            }
        },
        getDurationLabel(duration) {
            return (duration.inDays? duration.inDays +'d ' : '') + (duration.inHours? duration.inHours + 'h ': '') + (duration.inMinutes? duration.inMinutes + 'm ': '') + (duration.inSeconds? duration.inSeconds + 's' : '');
        },
        barChartData(activityData) {
                        //first divide data into wireline and frac
            let groupByActivity = _.groupBy(activityData, 'activity');

            //by any chance if activityData does not have wireline/frac data, we need to create the lanes
            if(!groupByActivity.hasOwnProperty('wireline')) {
                groupByActivity = { ...groupByActivity, wireline: []}
            }

            if(!groupByActivity.hasOwnProperty('frac')) {
                groupByActivity = { ...groupByActivity, frac: []}
            }

            let activitiesByLanes = {};
            const crossOverLaneKeys = [];
            if(this.isMultiWireline) {
                const wirelineData = _.groupBy(groupByActivity.wireline, 'data.service_alias');

                const activitiesByTruck = {};
                this.wirelineSystems.forEach(system => {
                    if (!system?.name) {
                        console.error('SummaryBarChart Error: Missing required wireline system name');
                    } else {
                        activitiesByTruck[system.name] = wirelineData[system.name] != null ? wirelineData[system.name] : [];
                    }
                });

                activitiesByLanes = { ...activitiesByTruck };
            } else {
                activitiesByLanes['wireline'] = groupByActivity.wireline ? groupByActivity.wireline : [];
            }
            if (this.isSimuFrac) {
                const fracData = _.groupBy(groupByActivity.frac, 'wellNumber');
                const activitiesByWell = {};

                this.wells.forEach(well => {
                    activitiesByWell[`frac${well.index}`] = fracData[well.index] || [];
                });

                activitiesByLanes = { ...activitiesByLanes, ...activitiesByWell };
            } 
            else if (this.isMultiFrac) {
                const fracData = _.groupBy(groupByActivity.frac, 'data.service_alias');

                const activitiesByTruck = {}
                this.fracSystems.forEach(truck => activitiesByTruck[truck.name] = fracData[truck.name] || [])
                crossOverLaneKeys.push(...Object.keys(activitiesByTruck));

                activitiesByLanes = { ...activitiesByLanes, ...activitiesByTruck };
            } else {
                activitiesByLanes.frac = groupByActivity.frac? groupByActivity.frac : [];
                crossOverLaneKeys.push('frac');
            }

            const labels = Object.keys(activitiesByLanes);

            if(this.isContinuousFrac) {
                activitiesByLanes = this.mapDataWithCrossOvers(activitiesByLanes, crossOverLaneKeys);
            }

            const dataset = [];

            const lengths = Object.values(activitiesByLanes).map(a=>a.length);
            const longestActivityArrayLength = Math.max(...lengths);
            const self = this;

            for (let index = 0; index < longestActivityArrayLength; index++) {
                let barData = null;
                let gapBarData = null;
                const data = new Array(labels.length).fill(null);
                const extraData = new Array(labels.length).fill(null);
                const backgroundColor = new Array(labels.length).fill('#c0c0c0');

                const gapData = new Array(labels.length).fill(null);
                const gapExtraData = new Array(labels.length).fill(null);
                const gapBackgroundColor = new Array(labels.length).fill('#c0c0c0');


                labels.forEach(function(label, labelIndex) {
                    if( !activitiesByLanes[label]) {
                        return;
                    }

                    if(activitiesByLanes[label][index]) {
                        //current activity bar
                        const activity = activitiesByLanes[label][index];
                        const barDataRes = self.generateActivityBarData(activity, labelIndex, backgroundColor, data, extraData);
                        if(barDataRes) {
                            barData = barDataRes;
                        }

                        if(activitiesByLanes[label][index-1]) { // activity gaps
                            const previousActivity = activitiesByLanes[label][index-1];
                            const gapBarDataRes = self.generateMiddleGapBarData(activity, previousActivity, labelIndex, gapData, gapExtraData, gapBackgroundColor);
                            if(gapBarDataRes) {
                                gapBarData = gapBarDataRes;
                            }
                        }
                    }
                });

                if(barData) {
                    dataset.push(barData);
                }

                if(gapBarData) {
                    dataset.push(gapBarData);
                }
            }
            let latestActivitiesByLane = {};
            if (this.isSimuFrac) {
                                //get latest activity in each lane for wireline system and well
                                Object.entries(activitiesByLanes).forEach((entry) => {
                    const key = entry[0];
                    const values = entry[1];
                    if(!values || values.length === 0) {
                        return;
                    }
                    const latest = values.reduce((acc, current) => {
                        if (current.startTime && current.startTime > acc.startTime) {
                            return current;
                        } else {
                            return acc;
                        }
                    });
                    latestActivitiesByLane[key] = latest;
                });
            } else {
                latestActivitiesByLane = { ...this.latestActivityBySystem.wirelineData, ...this.latestActivityBySystem.fracData }
            }
            labels.forEach(function(label, labelIndex) {
                if(latestActivitiesByLane[label] && latestActivitiesByLane[label].subCategory == 'completed') {
                    const latestActivity = latestActivitiesByLane[label];
                    const gapData = new Array(labels.length).fill(null);
                    const gapExtraData = new Array(labels.length).fill(null);
                    const gapBackgroundColor = new Array(labels.length).fill('#c0c0c0');
                    const gapBarDataRes = self.generateEndGapBarData(latestActivity, labelIndex, gapData, gapExtraData, gapBackgroundColor);
                    if(gapBarDataRes) {
                        dataset.push(gapBarDataRes);
                    }
                }
            });


            this.summaryData.labels = this.getLabels();
            this.summaryData.datasets = dataset;
            this.renderChart();
            if(this.isFirstRender) {
                this.isFirstRender = false;
                this.barChartData(activityData);
            }
        },
        getDuration(from, to) {
            const duration = this.calculateDuration(from, to);
            const days = Number.isNaN(duration.inDays) || duration.inDays === 0 ? '' : `${duration.inDays}d:`;
            const hours = Number.isNaN(duration.inHours) || duration.inHours === 0  ? '' : `${duration.inHours}h:`;
            const minutes = Number.isNaN(duration.inMinutes) || duration.inMinutes === 0  ? '' : `${duration.inMinutes}m:`;
            const seconds = Number.isNaN(duration.inSeconds) || duration.inSeconds === 0  ? '' : `${duration.inSeconds}s`;
            return `${days}${hours}${minutes}${seconds}`;
        },
        zoomDisabledMessage: function(event) {
            if (this.hoveringChart && !this.chartFocused && !this.filterActive) {
                this.disabledZoomMessage = true;
                setTimeout(() => this.disabledZoomMessage = false, 3000);
            }
        },
        enableZoomOnFocus: function() {
            const barChart = this.getBarChart();
            barChart.options.plugins.zoom.zoom.enabled = true;
            this.chartFocused = true;
            barChart.update();
        },
        disableZoomOnBlur: function() {
            const barChart = this.getBarChart();
            barChart.options.plugins.zoom.zoom.enabled = false;
            this.chartFocused = false;
            barChart.update();
        },
        filterChart(newMin, newMax)
        {
            let chart = this.getBarChart();

            //backend is expecting utc not job local
            let utcMin = moment.utc(newMin).subtract({hours: this.jobHourOffset}).valueOf();
            let utcMax = moment.utc(newMax).subtract({hours: this.jobHourOffset}).valueOf();
            this.onXAxisChange && this.onXAxisChange({min: utcMin, max: utcMax});

            //restrict panning/scrolling range
            chart.options.plugins.zoom.zoom.rangeMin.x = newMin;
            chart.options.plugins.zoom.zoom.rangeMax.x = newMax;
            chart.options.plugins.zoom.pan.rangeMin.x = newMin;
            chart.options.plugins.zoom.pan.rangeMax.x = newMax;

            //update chart and data range
            chart.options.scales.xAxes[0].ticks.min = newMin;
            chart.options.scales.xAxes[0].ticks.max = newMax;
            this.currentMinXValue = newMin;
            this.currentMaxXValue = newMax;

            this.$nextTick(() => {
                this.setLoading(newMin, newMax);
            });

            chart.update();
        },
        setFilter(filter, applyFilterChange=true) {
            if (!this.getBarChart()) {
                console.warn('Cannot filter chart, chart not mounted');
                return;
            }

            let newMin, newMax;
            const end = this.getFilterEndTime();
            const optionToHoursMap = {
                '3Hr': 3,
                '12Hr': 12,
                '24Hr': 24,
                '3Day': 72,
                '1Wk': 168
            };

            if (applyFilterChange && this.modalFilterType != "")
                this.clearCustomFilter();

            if (filter == 'Start') {
                newMin = moment.utc(this.jobStart).add({'hours': this.jobHourOffset}).valueOf();
            } else if (`${filter}` in optionToHoursMap) {
                newMin = _.cloneDeep(end).subtract({ 'hours': optionToHoursMap[filter] }).valueOf();
            } else {
                //no filter, skip filtering
                return;
            }

            newMax = end.valueOf();

            if (applyFilterChange) {
                this.selectedFilter = filter;
                this.filterChart(newMin, newMax);
            } else {
                this.currentMinXValue = newMin;
                this.currentMaxXValue = newMax;
            }

            this.scrollOnNewData = true;
        },
        followLiveData()
        {
            this.tempModalFilterType = this.modalFilterType = "";

            let chart = this.getBarChart();

            //unlock panning/scrolling
            chart.options.plugins.zoom.pan.enabled = true;
            chart.options.plugins.zoom.zoom.enabled = true;

            this.resetChartView();
            this.selectedFilter = null;
            this.tempFilterStartTime = this.filterStartTime = null;
            this.tempFilterEndTime = this.filterEndTime = null;
        },
        saveCustomFilter()
        {
            this.selectedFilter = 'custom';
            Vue.set(this, 'modalFilterType', this.tempModalFilterType);

            let valid = true;
            this.scrollOnNewData = false;

            if (this.modalFilterType == 'midnight')
            {
                let start = moment.utc().add({hours: this.jobHourOffset}).subtract({days: 1}).startOf('day');

                this.filterStartTime = start.valueOf();
                this.filterEndTime = _.cloneDeep(start).add({days: 1}).valueOf();

                this.filterChart(
                    this.filterStartTime,
                    this.filterEndTime
                );
            }
            else if (this.modalFilterType == 'shiftStart')
            {
                //convert shift start to hours/minutes
                let hours = Math.floor(this.jobShiftStart);
                let minutes = (this.jobShiftStart % 1)*60;

                let start = moment.utc().add({hours: this.jobHourOffset}).subtract({days: 1}).startOf('day').set('hours', hours).set('minutes', minutes);

                this.filterStartTime = start.valueOf();
                this.filterEndTime = _.cloneDeep(start).add({days: 1}).valueOf();

                this.filterChart(
                    this.filterStartTime,
                    this.filterEndTime
                );
            }
            else if (this.modalFilterType == 'custom')
            {
                if (!this.tempFilterStartTime)
                {
                    this.errors.filterStartTimeMissing = true;
                    valid = false;
                }
                if (!this.tempFilterEndTime)
                {
                    this.errors.filterEndTimeMissing = true;
                    valid = false;
                }

                if (this.tempFilterStartTime && this.tempFilterEndTime)
                {
                    Vue.set(this, 'filterStartTime', this.tempFilterStartTime);
                    Vue.set(this, 'filterEndTime', this.tempFilterEndTime);

                    let newMin = moment.utc(this.filterStartTime).valueOf();
                    let newMax = moment.utc(this.filterEndTime).valueOf();

                    this.errors.filterStartTimeMissing = false;
                    this.errors.filterEndTimeMissing = false;

                    this.filterChart(newMin, newMax);
                }
            }

            if (valid)
            {
                this.showCustomFilterModal = false;
            }
        },
        clearCustomFilter()
        {
            this.tempFilterStartTime = null;
            this.tempFilterEndTime = null;
            this.filterStartTime = null;
            this.filterEndTime = null;
            this.modalFilterType = "";
            this.tempModalFilterType = "";
            if (!this.filter)
                this.resetChartView();
        },
        resetChartView()
        {
            let chart = this.getBarChart();

            let end = this.getFilterEndTime();

            let newMax = end.valueOf();
            let newMin = _.cloneDeep(end).subtract({'hours':3}).valueOf();
            chart.options.scales.xAxes[0].ticks.min = newMin;
            chart.options.scales.xAxes[0].ticks.max = newMax;

            //backend is expecting utc not job local
            let utcMin = moment.utc(newMin).subtract({hours: this.jobHourOffset}).valueOf();
            let utcMax = moment.utc(newMax).subtract({hours: this.jobHourOffset}).valueOf();
            this.onXAxisChange && this.onXAxisChange({min: utcMin, max: utcMax});

            this.scrollOnNewData = true;
            this.setLoading(newMin, newMax);
            chart.update();
        },
        updateTempStartFilter(value)
        {
            this.tempFilterStartTime = value;
            if (this.tempFilterStartTime != null)
                this.errors.filterStartTimeMissing = false;
        },
        updateTempEndFilter(value)
        {
            this.tempFilterEndTime = value;
            if (this.tempFilterEndTime != null)
                this.errors.filterEndTimeMissing = false;
        },
        getFilterEndTime()
        {
            //if job is completed, use job end instead of today
            return this.isJobCompleted? moment.utc(this.isJobEndTime).add({hours: this.jobHourOffset}) : moment.utc().add({'hours': this.jobHourOffset});
        }
    },
    data() {
        return {
            timeFormatOptions: ['24H', '12H'],
            timeFormatSelected: '12H',
            filters: [['3Hr','12Hr','24Hr'],['3Day','1Wk','Start']], //must be grouped in a 3 column 2D array structure
            filterDescriptions: {'3Hr': 'Past 3 hrs', '12Hr': 'Past 12 hrs', '24Hr': 'Past 24 hrs',
                                    '3Day': 'Past 3 days', '1Wk': 'Past week', 'Start': 'Full Pad'}, //descriptions for each filter
            selectedFilter: null,
            tempFilterStartTime: null, //filter value before custom filter gets saved
            tempFilterEndTime: null,   //filter value before custom filter gets saved
            filterStartTime: null,
            filterEndTime: null,
            tempModalFilterType: "", //filter type before saving
            modalFilterType: "",
            primaryModalButtonDisabled: false,
            secondaryModalButtonDisabled: false,
            isFirstRender: true,
            labelCoveringOverlayWidth: '0',
            labelCoveringOverlayHeight: '0',
            scrollOnNewData: true,
            currentMinXValue: moment.utc().add({hours: this.jobHourOffset - this.summaryBarTimeFrame}).valueOf(),
            currentMaxXValue: moment.utc().add({hours: this.jobHourOffset}).valueOf(),
            alreadyFetchedStart: moment.utc().add({hours: this.jobHourOffset - this.summaryBarTimeFrame}).valueOf(),
            alreadyFetchedEnd: moment.utc().add({hours: this.jobHourOffset}).valueOf(),
            rawActivities: [],
            isLoading: false,
            hoveringChart: false,
            chartFocused: false,
            disabledZoomMessage: false,
            showCustomFilterModal: false,
            errors: {
                filterStartTimeMissing: false,
                filterEndTimeMissing: false
            },
            summaryData: {
                labels: [],
                datasets: [],
            },
            summaryBarOptions: {
                responsive: true,
                maintainAspectRatio: false,
                indexAxis: 'x',
                hover: { animationDuration: 0 },
                responsiveAnimationDuration: 0,
                animation: {
                    duration: 0 // general animation time
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                customLabels: {
                    afterDatasetsDraw: function(chart, options) {
                        const hPadding = 2;
                        const chartInstance = chart;
                        const ctx = chartInstance.ctx;
                        ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                        ctx.textBaseline = 'bottom';

                        chart.data.datasets.forEach(function (dataset, i) {
                            const currentMinXValue = chart?.options?.scales?.xAxes[0]?.ticks?.min;
                            const meta = chartInstance.controller.getDatasetMeta(i);
                            meta.data.forEach(function (bar, index) {
                                const startTime = dataset?.data[bar._index]?.[0];
                                if (startTime && currentMinXValue <= startTime && dataset.extraData[bar._index]) {
                                    const maxWidth = meta.data[index]._model.x - meta.data[index]._model.base - (2*hPadding);
                                    const data = dataset.extraData[bar._index].label;
                                    if(dataset?.extraData?.[bar._index]?.activity?.nextValue) {
                                        ctx.fillStyle = GlobalFunctions.getTitleColorForBG(dataset.extraData[bar._index].color);
                                    } else {
                                        ctx.fillStyle = GlobalFunctions.getTitleColorForBG(dataset.backgroundColor[bar._index]);
                                    }

                                    ctx.textAlign = 'start';
                                    ctx.fillText(data, meta.data[index]._model.base + hPadding, bar._model.y + 5, maxWidth);
                                }
                            });
                        });
                    }
                },
                plugins: {
                    zoom: {
                        pan: {
                            enabled: true,
                            mode: 'x',
                            onPan: ({chart})=>{
                                if (this.selectedFilter) {
                                    this.selectedFilter = null;
                                    chart.options.plugins.zoom.zoom.rangeMin.x = null;
                                    chart.options.plugins.zoom.zoom.rangeMax.x = null;
                                    chart.options.plugins.zoom.pan.rangeMin.x = null;
                                    chart.options.plugins.zoom.pan.rangeMax.x = null;
                                    this.modalFilterType = "";
                                    this.tempModalFilterType = "";
                                    this.filterStartTime = null;
                                    this.filterEndTime = null;
                                }
                            },
                            onPanComplete: ({chart})=>{
                                let { min, max } = chart.scales['date-axis'];
                                this.currentMinXValue = min;
                                this.currentMaxXValue = max;
                                this.setLoading(min, max);

                                let end = this.getFilterEndTime();

                                if(Math.abs(max - end.valueOf()) < PAN_TO_LIVE_DATA_THRESHOLD) {
                                    this.scrollOnNewData = true;
                                }
                                else
                                {
                                    this.scrollOnNewData = false;
                                }

                                //backend is expecting utc not job local
                                let utcMin = moment.utc(min).subtract({hours: this.jobHourOffset}).valueOf();
                                let utcMax = moment.utc(max).subtract({hours: this.jobHourOffset}).valueOf();
                                this.onXAxisChange && this.onXAxisChange({min: utcMin, max: utcMax});
                            },
                            rangeMin: {
                                x: null
                            },
                            rangeMax: {
                                x: null
                            }
                        },
                        zoom: {
                            enabled: false,
                            mode: 'x',
                            onZoom: ({chart})=>{
                                if (this.selectedFilter) {
                                    this.selectedFilter = null;
                                    chart.options.plugins.zoom.zoom.rangeMin.x = null;
                                    chart.options.plugins.zoom.zoom.rangeMax.x = null;
                                    chart.options.plugins.zoom.pan.rangeMin.x = null;
                                    chart.options.plugins.zoom.pan.rangeMax.x = null;
                                    this.modalFilterType = "";
                                    this.tempModalFilterType = "";
                                    this.filterStartTime = null;
                                    this.filterEndTime = null;
                                }
                            },
                            onZoomComplete: ({chart}) => {
                                let { min, max } = chart.scales['date-axis'];
                                this.currentMinXValue = min;
                                this.currentMaxXValue = max;
                                this.setLoading(min, max);

                                //backend is expecting utc not job local
                                let utcMin = moment.utc(min).subtract({hours: this.jobHourOffset}).valueOf();
                                let utcMax = moment.utc(max).subtract({hours: this.jobHourOffset}).valueOf();
                                this.onXAxisChange && this.onXAxisChange({min: utcMin, max: utcMax});
                            },
                            rangeMin: {
                                x: null
                            },
                            rangeMax: {
                                x: null
                            }
                        }
                    }
                },
                scales: {
                    yAxes: [{
                        id: 'service-axis',
                        gridLines: {
                            display: false,
                            color: '#fff',
                            zeroLineColor: '#fff',
                            zeroLineWidth: 0
                        },
                        ticks: {
                            fontColor: '#FFFFFF'
                        },
                        stacked: true
                    }],
                    xAxes: [{
                        id: 'date-axis',
                        type: 'linear', //time type does not work with zoom library
                        display: true,
                        ticks: {
                            align: 'inner',
                            callback: this.formatScale,
                            autoSkipPadding: 80,
                            maxRotation: 0,
                            minRotation: 0,
                            fontColor: '#FFFFFF',
                            min: moment.utc(this.endDate).add({hours: this.jobHourOffset - this.summaryBarTimeFrame}).valueOf(),
                            max: moment.utc(this.endDate).add({hours: this.jobHourOffset}).valueOf()
                        },
                    }]
                },
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: false,
                    mode: 'nearest',
                    intersect: true,
                    position: 'cursor',
                    animationDuration: 0,
                    backgroundColor: '#000',
                    callbacks: {
                        label: this.formatLabel
                    },
                    custom: function(tooltipModel) {
                        // Tooltip Element
                        let tooltipEl = document.getElementById('chartjs-tooltip-summary-bar');

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip-summary-bar';
                            tooltipEl.innerHTML = '<table></table>';
                            document.body.appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }

                        // Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            const titleLines = tooltipModel.title || [];
                            const bodyLines = tooltipModel.body.map(getBody);

                            let innerHtml = '<thead>';

                            titleLines.forEach(function(title) {
                                const style = 'width : 10px; height: 10px; border-width : 1px;';
                                innerHtml += '<tr><th><div class="d-flex pr-3"><div class="mx-2 mt-1" style="' + style + '"></div>' + title + '</div></th></tr>';
                            });
                            innerHtml += '</thead><tbody>';


                            bodyLines.forEach(function(body, i) {
                                const colors = tooltipModel.labelColors[i];
                                if(body && body.length>0) {
                                    const style = `background: ${typeof colors.backgroundColor === 'string'? colors.backgroundColor : 'transparent'}; width : 9px; height: 9px; border-width : 1px; border-color: ${typeof colors.backgroundColor === 'string'? '#FFFFFF' : 'transparent'}; border-style: solid`;
                                    innerHtml += '<tr><td><div class="d-flex pr-3"> <div class="mx-2 mt-1" style="' + style + '"></div>' + body + ' </div></td></tr>';
                                }
                            });
                            innerHtml += '</tbody>';

                            const tableRoot = tooltipEl.querySelector('table');
                            tableRoot.innerHTML = innerHtml;
                        }

                        // `this` will be the overall tooltip
                        const position = this._chart.canvas.getBoundingClientRect();

                        //mouse position
                        let offset = tooltipModel.caretX;

                        //when the tooltip tries to render at the right edge
                        //of the screen, give it more space to the left
                        const averageTooltipWidth = 150;
                        if (tooltipModel.caretX > this._chart.width - averageTooltipWidth)
                        {offset = this._chart.width - averageTooltipWidth;}

                        // Display, position, and set styles for font
                        let userId = document.querySelector('meta[name="user-id"]').getAttribute('content');
                        let tooltipFontSize = localStorage.getItem('tooltipFontSize' + userId);
                        if(tooltipFontSize) {
                            if(tooltipFontSize === 'large') {
                                tooltipEl.style.fontSize = '16px';
                            } else if(tooltipFontSize === 'x-large') {
                                tooltipEl.style.fontSize = '20px';
                            }else{
                                tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                            }
                        }


                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.position = 'fixed';
                        tooltipEl.style.left = position.left + offset + 'px';
                        tooltipEl.style.top = position.top + tooltipModel.caretY + 'px';
                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                        tooltipEl.style.padding = 2 + 'px ' + 0 + 'px';
                        tooltipEl.style.pointerEvents = 'none';
                    }
                }
            }
        };
    },
    props:
    [
        'editMode',
        'jobHourOffset',
        'latestActivityData',
        'activityData',
        'wells',
        'endDate',
        'bounds',
        'jobStart',
        'jobShiftStart',
        'jobNumber',
        'isJobCompleted',
        'onXAxisChange',
        'isMultiWireline',
        'wirelineSystems',
        'isMultiFrac',
        'fracSystems',
        'isContinuousFrac',
        'isSimuFrac',
        'isJobEndTime',
        'height',
        'summaryBarTimeFrame',
        'latestActivityBySystem',
        'item'
    ]
};
</script>
