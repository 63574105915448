<!--
    * Component Description
        A simple, re-usable form wrapper that makes validating iws forms easy

    * Slots
        #body   <- Target entire form body (list of iws fields should be put here)

    * Example Usage
        <iws-form ref="iwsForm">
            <template #body>
                InsertIwsFieldsHere
            </template>
        </iws-form>

        Call validate
        const passed = this.$refs.iwsForm.validate();
-->
<template>
    <div ref="form">
        <slot name="body" :dataItem="localData" :submit="submit">
        </slot>
    </div>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { toast, isFalsy, isNullOrEmpty } = GlobalFunctions;

export default {
    props: {
        data: Object
    },

    data: () => ({
        localData: {}
    }),

    methods: {
        resetForm(backup=this.data) {
            this.localData = Object.assign({}, this.localData, _.cloneDeep(backup));
        },

        validate(children=this.$refs?.form?.children) {
            if (!isNullOrEmpty(children)) {
                let passed = true;

                // Look for all children that are custom field components but fail validition
                // Continue running through all components even upon fail so all failure fields are represented
                for (let _input of children) {
                    // Default use case is a list of HTMLCollection objects which requires accessing via __vue__
                    // We also support taking a list of refs from the callee which can directly access validateInput
                    const func = _input?.__vue__?.validateInput || _input?.validateInput;

                    if (!isFalsy(func) && !func())
                        passed = false;
                }

                return passed;
            } else {
                toast({
                    title: 'Nothing to validate',
                    variant: 'warning'
                });
                return true
            }
        },

        submit(callback, children=this.$refs?.form?.children) {
            if (this.validate(children))
                return callback(this.localData);
        }
    },

    watch: {
        data: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                if (oldValue === undefined)
                    this.localData = Object.assign({}, this.localData, _.cloneDeep(this.data));
            }
        }
    }
};
</script>