var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "span",
      { staticClass: "dot" },
      [
        _c("b-button", {
          ref: "marker",
          staticClass: "dot-clickable",
          attrs: { id: "create-msg-target" + _vm._uid },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        class: { "low-opacity": _vm.activeDrawing },
        style: _vm.popupPosition,
        attrs: { id: "fake-popover" },
      },
      [
        _c(
          "div",
          {
            staticClass: "text-white pt-2",
            staticStyle: {
              display: "flex",
              "justify-content": "space-between",
            },
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("b-icon-arrows-move", {
                  staticStyle: {
                    width: "20px",
                    height: "20px",
                    "margin-right": "10px",
                  },
                  attrs: { id: "NPT-Event-Drag-icon" },
                }),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _vm.loadingAutoSelect
          ? [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "text-white" }, [
                _vm._v("Loading auto select data...."),
              ]),
            ]
          : [
              _c(
                "div",
                { staticClass: "input-group-sm mt-1 mb-3 white-text" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "p-0 m-1",
                      attrs: { for: "startTimePicker" },
                    },
                    [_vm._v("Start:")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "form-control form-control-sm stopPropagation",
                    style: { backgroundColor: _vm.startNPT ? "lightgrey" : "" },
                    attrs: {
                      disabled: _vm.startNPT ? true : false,
                      type: "datetime-local",
                      step: "1",
                      placeholder: _vm.startTime,
                      id: "startTimePicker",
                      max: new Date().toISOString().split(".")[0],
                    },
                    domProps: { value: _vm.startTime },
                    on: {
                      focus: function ($event) {
                        _vm.activeDateInput = "startTimePicker"
                      },
                      blur: function ($event) {
                        return _vm.dateBlurred("startTimePicker")
                      },
                      input: (event) =>
                        _vm.onCalendarChange(event.target.value, _vm.endTime),
                    },
                  }),
                  _vm._v(" "),
                  _vm.mode != "startEvent"
                    ? _c(
                        "label",
                        {
                          staticClass: "p-0 m-1",
                          attrs: { for: "endTimePicker" },
                        },
                        [_vm._v("End:")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.mode != "startEvent"
                    ? _c("input", {
                        staticClass:
                          "form-control form-control-sm stopPropagation",
                        attrs: {
                          type: "datetime-local",
                          step: "1",
                          placeholder: _vm.endTime,
                          id: "endTimePicker",
                          min: _vm.startTime,
                          max: new Date().toISOString().split(".")[0],
                        },
                        domProps: { value: _vm.endTime },
                        on: {
                          focus: function ($event) {
                            _vm.activeDateInput = "endTimePicker"
                          },
                          blur: function ($event) {
                            return _vm.dateBlurred("endTimePicker")
                          },
                          input: (event) =>
                            _vm.onCalendarChange(
                              _vm.startTime,
                              event.target.value
                            ),
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._m(2),
                  _vm._v(" "),
                  _vm.mode != "startEvent"
                    ? _c("label", { staticClass: "p-0 m-1" }, [
                        _vm._v("Duration:"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.mode != "startEvent"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row align-items-center stopPropagation",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.duration.hours,
                                expression: "duration.hours",
                              },
                            ],
                            staticClass: "form-control form-control-sm",
                            staticStyle: { width: "60px" },
                            attrs: {
                              type: "number",
                              placeholder: "hh",
                              min: "0",
                            },
                            domProps: { value: _vm.duration.hours },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.duration,
                                  "hours",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "px-1" }, [_vm._v("h")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.duration.minutes,
                                expression: "duration.minutes",
                              },
                            ],
                            staticClass: "form-control form-control-sm",
                            staticStyle: { width: "60px" },
                            attrs: {
                              type: "number",
                              min: "0",
                              max: "59",
                              placeholder: "mm",
                            },
                            domProps: { value: _vm.duration.minutes },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.duration,
                                  "minutes",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "px-1" }, [_vm._v("m")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.duration.seconds,
                                expression: "duration.seconds",
                              },
                            ],
                            staticClass: "form-control form-control-sm",
                            staticStyle: { width: "60px" },
                            attrs: {
                              type: "number",
                              min: "0",
                              max: "59",
                              placeholder: "ss",
                            },
                            domProps: { value: _vm.duration.seconds },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.duration,
                                  "seconds",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "px-1" }, [_vm._v("s")]),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              !_vm.activeDrawing
                ? _c(
                    "div",
                    [
                      _c("loading", {
                        attrs: {
                          active: _vm.loadingAutoConfig,
                          "is-full-page": false,
                          opacity: 0.1,
                        },
                        on: {
                          "update:active": function ($event) {
                            _vm.loadingAutoConfig = $event
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "input-group-sm mb-3 d-flex flex-column",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "p-0 m-1 white-text",
                              attrs: { for: "comment" },
                            },
                            [_vm._v("Comment:")]
                          ),
                          _vm._v(" "),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.commentText,
                                expression: "commentText",
                              },
                            ],
                            staticClass: "form-control w-100 stopPropagation",
                            staticStyle: { resize: "none" },
                            attrs: {
                              id: "comment",
                              rows: "5",
                              "aria-label": "With textarea",
                              placeholder: "Type your comment here...",
                            },
                            domProps: { value: _vm.commentText },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.commentText = $event.target.value
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "input-group mb-3 white-text" },
                        [
                          _c("div", { staticClass: "col-12 m-0 p-0" }, [
                            _c("div", { staticClass: "list-label" }, [
                              _vm._v(
                                "\n                            Well:\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedWell,
                                    expression: "selectedWell",
                                  },
                                ],
                                staticClass:
                                  "form-control form-control-sm stopPropagation",
                                class: { "cursor-disabled": _vm.startNPT },
                                attrs: {
                                  disabled: _vm.startNPT ? true : false,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.selectedWell = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    _vm.updateStageOptions,
                                  ],
                                },
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: { selected: "", disabled: "" },
                                    domProps: { value: null },
                                  },
                                  [_vm._v(" --No Well--")]
                                ),
                                _vm._v(" "),
                                _vm._l(_vm.wells, function (item) {
                                  return _c(
                                    "option",
                                    { key: item.id, domProps: { value: item } },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "input-group mb-3 white-text" },
                        [
                          _c("div", { staticClass: "col-12 m-0 p-0" }, [
                            _c(
                              "label",
                              {
                                staticClass: "p-0 m-1",
                                attrs: { for: "startTimePicker" },
                              },
                              [_vm._v("Stage Number:")]
                            ),
                            _vm._v(" "),
                            _vm.loadingStageNumber
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "spinner-border spinner-border-sm text-primary",
                                    attrs: { role: "status" },
                                  },
                                  [
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v("Loading..."),
                                    ]),
                                  ]
                                )
                              : _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedStage,
                                      expression: "selectedStage",
                                    },
                                  ],
                                  staticClass:
                                    "form-control form-control-sm stopPropagation",
                                  attrs: {
                                    disabled: _vm.startNPT ? true : false,
                                    placeholder: "N/A ( Well number missing )",
                                    id: "stageNumberInput",
                                    type: "number",
                                    min: "1",
                                    max: _vm.maxStage,
                                  },
                                  domProps: { value: _vm.selectedStage },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.selectedStage = $event.target.value
                                    },
                                  },
                                }),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "input-group mb-3 white-text" },
                        [
                          _c("div", { staticClass: "col-12 m-0 p-0" }, [
                            _c("div", { staticClass: "list-label" }, [
                              _vm._v(
                                "\n                            NPT Description:\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedReasonId,
                                    expression: "selectedReasonId",
                                  },
                                ],
                                staticClass:
                                  "form-control form-control-sm stopPropagation",
                                class: { "cursor-disabled": _vm.startNPT },
                                attrs: {
                                  disabled: _vm.startNPT ? true : false,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selectedReasonId = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: { selected: "", disabled: "" },
                                    domProps: { value: null },
                                  },
                                  [_vm._v(" --Not Set--")]
                                ),
                                _vm._v(" "),
                                _vm._l(_vm.stepDescriptions, function (item) {
                                  return _c(
                                    "option",
                                    {
                                      key: item.id,
                                      domProps: { value: item.id },
                                    },
                                    [_vm._v(_vm._s(item.type))]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.nptOutcomes && _vm.nptOutcomes.length > 0
                        ? _c(
                            "div",
                            { staticClass: "input-group mb-3 white-text" },
                            [
                              _c("div", { staticClass: "col-12 m-0 p-0" }, [
                                _c("div", { staticClass: "list-label" }, [
                                  _vm._v(
                                    "\n                            NPT Outcome:\n                        "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedOutcomeId,
                                        expression: "selectedOutcomeId",
                                      },
                                    ],
                                    staticClass:
                                      "form-control form-control-sm stopPropagation",
                                    class: { "cursor-disabled": _vm.startNPT },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.selectedOutcomeId = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: { selected: "" },
                                        domProps: { value: null },
                                      },
                                      [_vm._v("--Not Set--")]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.nptOutcomesSorted,
                                      function (item) {
                                        return _c(
                                          "option",
                                          {
                                            key: item.id?.toLowerCase(),
                                            domProps: {
                                              value: item.id?.toLowerCase(),
                                            },
                                          },
                                          [_vm._v(_vm._s(item.name))]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.customerVendors && _vm.customerVendors.length > 0
                        ? _c(
                            "div",
                            { staticClass: "input-group mb-3 white-text" },
                            [
                              _c(
                                "div",
                                { staticClass: "col-12 m-0 p-0" },
                                [
                                  _c("div", { staticClass: "list-label" }, [
                                    _vm._v(
                                      "\n                            NPT Responsible Vendors:\n                        "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("checkbox-list", {
                                    staticClass: "w-50 ml-1 stopPropagation",
                                    attrs: {
                                      enableSearch: true,
                                      valueKey: "friendly_name",
                                      outputKey: "id",
                                      height: 120,
                                      maxHeight: 120,
                                      options: _vm.sortedCustomerVendors,
                                      fullWidth: "",
                                      selectedOptions:
                                        _vm.selectedCustomerVendors,
                                    },
                                    on: {
                                      "update:selectedOptions": function (
                                        $event
                                      ) {
                                        _vm.selectedCustomerVendors = $event
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "input-group mb-3 white-text stopPropagation",
                        },
                        [
                          _c("iws-checkbox", {
                            attrs: {
                              value: _vm.selectedNonFracNPT,
                              label: "Non Frac NPT Event",
                            },
                            on: {
                              "update:value": function ($event) {
                                _vm.selectedNonFracNPT = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.responseMessage
                        ? _c(
                            "div",
                            {
                              staticClass: "alert",
                              class: {
                                "alert-success": !_vm.responseError,
                                "alert-danger": _vm.responseError,
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.responseMessage
                                      .charAt(0)
                                      .toUpperCase() +
                                      _vm.responseMessage.slice(1)
                                  ) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex",
                          attrs: { id: "sticky-actions" },
                        },
                        [
                          _c("div", { staticClass: "flex-grow-1" }),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary btn-sm mr-2",
                              attrs: { disabled: _vm.loading },
                              on: { click: _vm.onCancelClick },
                            },
                            [
                              _vm._v(
                                "\n                        Cancel\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary btn-sm",
                              attrs: {
                                disabled:
                                  _vm.durationError || !_vm.hasEditPermission,
                              },
                              on: { click: _vm.handleCommentButtonClick },
                            },
                            [
                              _vm.loading
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "spinner-border text-light spinner-border-sm",
                                      attrs: { role: "status" },
                                    },
                                    [
                                      _c("span", { staticClass: "sr-only" }, [
                                        _vm._v("Loading..."),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.startNPT
                                ? _c("span", [_vm._v("End NPT")])
                                : _vm.editingNPT && _vm.editingNPT.length > 0
                                ? _c("span", [_vm._v("Update NPT")])
                                : _c("span", [_vm._v("Create NPT")]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("h5", [_vm._v("Frac NPT Event")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "spinner-border text-primary", attrs: { role: "status" } },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-white" }, [
      _c("small", [
        _vm._v("( The end date cannot overlap with the next frac stage. )"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }