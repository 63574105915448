<!--
    * Component Description
    Simple modal for viewing (without editing) gun details, not meant for reuse
-->

<template>
    <div class="dark-modal modal"
        :id="`gun_view_${id}`"
        :ref="`gun_view_${id}`"
        role="document"
    >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalCenterTitle">
                        View Gun
                    </h5>

                    <button type="button" class="close" @click="hide">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="field-container">
                        <label>
                            Name
                        </label>
                        <div>
                            {{  gun.name }}
                        </div>
                    </div>

                    <hr>

                    <div v-if="enableCustomerCol" class="field-container">
                        <label>
                            Customer Name
                        </label>
                        <div>
                            {{  gun.customerName }}
                        </div>
                    </div>

                    <hr v-if="enableCustomerCol">

                    <div class="row">
                        <div class="col-6">
                            <div class="field-container">
                                <label>
                                    Size (in)
                                </label>
                                <div>
                                    {{  gun.size }}
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="field-container">
                                <label>
                                    Gun Barrel Length (in)
                                </label>
                                <div>
                                    {{  gun.length }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div class="row">
                        <div class="col-6">
                            <div class="field-container">
                                <label>
                                    Cluster Length (in)
                                </label>
                                <div>
                                    {{  gun.cluster_length }}
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="field-container">
                                <label>
                                    Shot Density (Shots/ft)
                                </label>
                                <div>
                                    {{  gun.shot_density }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div class="row">
                        <div class="col-6">
                            <div class="field-container">
                                <label>
                                    Number of Shots Planned
                                </label>
                                <div>
                                    {{  gun.shots_planned }}
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="field-container">
                                <label>
                                    Nominal Penetration (in)
                                </label>
                                <div>
                                    {{  gun.nominal_penetration }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div class="row">
                        <div class="col-6">
                            <div class="field-container">
                                <label>
                                    Description
                                </label>
                                <div>
                                    {{  gun.description }}
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="field-container">
                                <label>
                                    Estimated Hole Diameter (in)
                                </label>
                                <div>
                                    {{  gun.estimated_hole_diameter }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div class="row">
                        <div class="col-6">
                            <div class="field-container">
                                <label>
                                    Metallurgy
                                </label>
                                <div>
                                    {{  gun.metallurgy }}
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="field-container">
                                <label>
                                    Centralized
                                </label>
                                <div>
                                    {{  gun.centralized || 'No' }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div class="row">
                        <div class="col-6">
                            <div class="field-container">
                                <label>
                                    Conveyance Method
                                </label>
                                <div>
                                    {{  gun.conveyance_method }}
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="field-container">
                                <label>
                                    Carrier Description
                                </label>
                                <div>
                                    {{  gun.carrier_description }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div class="row">
                        <div class="col-6">
                            <div class="field-container">
                                <label>
                                    Carrier Make
                                </label>
                                <div>
                                    {{  gun.carrier_make }}
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="field-container">
                                <label>
                                    Charge Type
                                </label>
                                <div>
                                    {{  gun.carrier_type }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div class="row">
                        <div class="col-6">
                            <div class="field-container">
                                <label>
                                    Charge Size
                                </label>
                                <div>
                                    {{  gun.charge_size }}
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="field-container">
                                <label>
                                    Charge Make
                                </label>
                                <div>
                                    {{  gun.charge_make }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div class="row">
                        <div class="col-6">
                            <div class="field-container">
                                <label>
                                    Explosive Type
                                </label>
                                <div>
                                    {{  gun.explosive_type }}
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="field-container">
                                <label>
                                    Phasing
                                </label>
                                <div>
                                    {{  gun.phasing }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div class="row">
                        <div class="col-6">
                            <div class="field-container">
                                <label>
                                    Orientation
                                </label>
                                <div>
                                    {{  gun.orientation }}
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="field-container">
                                <label>
                                    Orientation Method
                                </label>
                                <div>
                                    {{  gun.orientation_method }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-6">
                            <div class="field-container">
                                <label>
                                    Balance
                                </label>
                                <div>
                                    {{  gun.balance }}
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="field-container">
                                <label>
                                    Fluid Type
                                </label>
                                <div>
                                    {{  gun.fluid_type }}
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: true
        },
        enableCustomerCol: Boolean
    },

    data: () => ({
        gun: {}
    }),

    methods: {
        open(gun) {
            this.gun = { ...gun };

            const modalElem = $(`#gun_view_${this.id}`);
            const ref = this.$refs[`gun_view_${this.id}`];

            modalElem.show()

            modalElem.on('click', function(event) {
                // users can click the background to cancel out
                if (ref === event.target)
                    modalElem.hide();
            });
        },
        hide() {
            $(`#gun_view_${this.id}`).hide();
        }
    }
};
</script>

<style scoped>
    .modal-dialog {
        width: 600px !important;
        max-width: 90vw !important;
    }

    .dark-modal .modal-body {
        overflow-y: auto;
        /* Limit the height to leave some space on top after accounting for the header and footer */
        max-height: calc(90vh - var(--modal-header-height));
    }

    .field-container {
        margin-bottom: 15px;
    }
    .field-container label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;

        color: #C9C9C9;

        margin-bottom: 0px !important;
    }
    .field-container>div {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: var(--primary-text-color);
    }

    hr {
        background-color: #7B8A98;
    }
</style>
