import moment from 'moment';

class DateFunctions {
    dateDisplay = (date) => {
        if (date)
            return moment(date).format('MMMM DD, YYYY');
        return '';
    }
    timeDisplay = (date, showSeconds=false) => {
		if (date) {
            if (showSeconds)
                return moment(date).format('h:mm:ss A');
            return moment(date).format('h:mm A');
        }

        return '';
	}
    // Returns human friendly date time displays
    dateTimeDisplay = (date) => {
        if (date)
            return `${this.dateDisplay(date)} ${this.timeDisplay(date)}`;
        return '';
    }

    applyTimeOffset = (date, hours=0, format='YYYY-MM-DD HH:mm:ss') => {
        return moment.utc(date).utcOffset(hours).format(format);
    }


    /*
        Will compare the two given dates (ommiting hours/minutes/seconds by default)
        Dates can be given as strings or Date objects

        Possible return values
            null: either of the dates were not set
            -1: date1 is after date2
            0: date1 === date2
            1: date2 is after date1
    */
    compareDates = (date1, date2, allowTime=false) => {
        if (date1 == null || date2 == null)
            return null;

        if (typeof date1 == 'string' || typeof date1 == 'number')
            date1 = new Date(date1);
        if (typeof date2 == 'string' || typeof date2 == 'number')
            date2 = new Date(date2);

        if (!allowTime) {
            date1.setHours(0,0,0,0);
            date2.setHours(0,0,0,0);
        }

        const time1 = date1.getTime();
        const time2 = date2.getTime();

        if (time1 == time2)
            return 0;
        if (time2 > time1)
            return 1;
        return -1;
    }

    /**
     *     converts a number representing hours with decimal into hours and minutes. Example use case for this function
     *     is parsing shift time hours which is a decimal number to hours and minutes
     */
    convertHoursToHourAndMinutes = (inputHours) => {
        const hours = Math.floor(inputHours); //get whole hour
        const minutes = Math.floor((inputHours - Math.floor(inputHours)) * 60);//get whole minutes
        return {
            hours,
            minutes
        };
    }
    convertSeconds = (inputVal) => {
        if (inputVal <= 0)
            return '00:00:00';

        const hours = Math.floor(inputVal / 3600);
        const minutes = Math.floor((inputVal % 3600) / 60);
        const seconds = inputVal % 60;

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }

    fromNow = (date) => {
        return moment.duration(moment(date).diff(moment())).humanize();
    }
    logDate = (date) => {
       return moment(date).format('YYYY-MM-DD HH:mm');
    }
}

export default new DateFunctions();
