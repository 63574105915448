<template>
    <div class="d-flex flex-column" v-if="chartConfiguration != null"
        @mousemove="setIsActiveChart(true)"
    >
        <!-- popover views start -->
        <b-popover :ref="'truckPopover' + _uid" :if="isMultiWireline && !isNotDashboard" :show.sync="showTruck" :target="'popover-target-truck-' + dashboardItem.options.configId + _uid"  :triggers="!showSuperSmallHeader()? 'hover' : 'click'" placement="bottom" :delay="{show: 0, hide: 500}">
             <div class="d-flex flex-row mt-2 align-items-center justify-content-center">
                <div class="align-self-start mx-2 algin-self-center pt-2" style="color: white;">
                    Truck:
                </div>
                <div>
                    <select class="form-control" v-model="selectedTruck" @change="onSelectedTruckChange($event.target.value)">
                        <option v-for="(truck, index) in truckOptions" :value="truck.value" :key="index" >
                            {{ truck.text }}
                        </option>
                    </select>
                </div>
            </div>
        </b-popover>

        <TimeSeriesConfigModal ref="TimeSeriesConfigModal"
            :userId="userID"
            :iwsUser="!!iwsUser"
            :isAdmin="!!isAdmin"
            :isCompanyAdmin="!!isCompanyAdmin"
            :job-number="jobNumber"
            :jobHourOffset="jobHourOffset"
            chartType="frac"

            :wells="wells"
            :dashboardData="dashboardData"
            :dashboardItem="dashboardItem"
            :defaultChartColumnCount="defaultChartColumnCount"
        />
        <TimeSeriesHistoryModal ref="TimeSeriesHistoryModal"
            :job-number="jobNumber"
            :dashboardItem="dashboardItem"
        />

        <b-popover :target="'popover-header-' + _uid" triggers="hover" placement="bottom" :delay="{show: 0, hide: 500}">
            <div class="align-items-center justify-content-center" style="color: white;">
                <div v-if="isEditable" @click="onEditChartConfiguration" class="clickable row align-items-center">
                    <i class="fas fa-cog col-1 header-icon"></i>
                    <div class="col submenu-link px-2 " style="vertical-align: middle;">Edit chart configuration</div>
                </div>
                <div @click="onChartHeaderPressed" class="clickable row align-items-center">
                    <i class="fas fa-eye col-1 header-icon" v-if="showChartHeaderConfig"></i>
                    <i class="fas fa-eye-slash col-1 header-icon" v-else></i>

                    <div class="col submenu-link px-2" v-if="showChartHeaderConfig">Show chart header</div>
                    <div class="col submenu-link px-2" v-else>Hide chart header</div>
                </div>
                <div v-if="shouldDrawGraph" @click="onAlertIconPressed" class="clickable row align-items-center">
                    <i class="fas fa-bell col-1 header-icon" v-if="enableThresholdAlerts"></i>
                    <i class="fas fa-bell-slash col-1 header-icon" v-else></i>

                    <div class="col submenu-link px-2 " v-if="enableThresholdAlerts">Show alerts in comment line</div>
                    <div class="col submenu-link px-2" v-else>Hide alerts in comment line</div>
                </div>

                <div v-if="!useCustomScaleOnDateAxis && shouldDrawGraph" @click="onExportIconPress" class="clickable row align-items-center">
                    <i class="fas fa-download col-1 header-icon"></i>
                    <div class="col submenu-link px-2">Export Chart Data</div>
                </div>
                <div v-if="shouldDrawGraph && !chartConfigEditMode" class="clickable row align-items-center"
                    @click="toggleInnerPopover('filterPopover' + _uid)"
                    :id="'popover-filter-' + dashboardItem.options.configId + _uid">
                    <i class="fas fa-filter col-1 header-icon"></i>
                    <div class="col submenu-link px-2" :class="{'font-weight-bold': innerPopoversOpened.includes('filterPopover' + _uid)}">Filtering</div>
                </div>
                <div v-if="showSuperSmallHeader() && shouldDrawGraph && ((isMultiWireline && headerStyle == 'wireline') || (isMultiFrac && headerStyle == 'frac')) && !isNotDashboard" class="clickable row align-items-center"
                    @click="toggleInnerPopover('truckPopover' + _uid)"
                    :id="'popover-target-truck-' + dashboardItem.options.configId + _uid">
                    <i class="fas fa-truck col-1 header-icon"></i>
                    <div class="col submenu-link px-2" :class="{'font-weight-bold': innerPopoversOpened.includes('truckPopover' + _uid)}">Change Truck</div>
                </div>
                <div v-if="showSuperSmallHeader() && !useCustomScaleOnDateAxis && shouldDrawGraph && !chartConfigEditMode" class="clickable row align-items-center"
                    @click="toggleInnerPopover('viewSettingsPopover' + _uid)"
                    :id="'popover-target-' + dashboardItem.options.configId + _uid">
                    <i class="fas fa-ruler-combined col-1 header-icon"></i>
                    <div class="col submenu-link px-2" :class="{'font-weight-bold': innerPopoversOpened.includes('viewSettingsPopover' + _uid)}">View settings</div>
                </div>

                <div v-if="!useCustomScaleOnDateAxis" class="clickable row align-items-center" @click="inStatsMode = !inStatsMode">
                    <i class="fas fa-chart-bar col-1 header-icon"></i>
                    <div class="col submenu-link px-2 ">Turn {{inStatsMode ? 'off' : 'on'}} statistics mode</div>
                </div>
            </div>
        </b-popover>
        <b-popover :ref="'viewSettingsPopover' + _uid" :target="'popover-target-' + dashboardItem.options.configId + _uid" :triggers="!showSuperSmallHeader()? 'hover' : 'click'" placement="bottom" :delay="{show: 0, hide: 500}" @shown="isZoomPopoverOpen($event)" @hidden="isZoomPopoverOpen($event)">
            <div v-if="!useCustomScaleOnDateAxis" class="d-flex flex-row-reverse">
                <div><button class="btn btn-sm mx-1" :class="dragToZoom ? 'btn-primary' : 'btn-light'" v-on:click="selectionZoom(true)">Drag to Zoom</button></div>
                <div><button class="btn btn-sm mx-1" :class="!dragToZoom ? 'btn-primary' : 'btn-light'" v-on:click="selectionZoom(false)">Drag to Pan, Scroll to Zoom</button></div>
                <div><button class="btn btn-light btn-sm mx-1" v-on:click="resetChartView()">Reset View</button></div>
                <input type="number" class="form-control form-control-sm" style="width: 4em;text-align: right;"
                    v-model="defaultZoomWindowHrs"
                    @change="resetZoom($refs['lineChart'].$data._chart)">
                <div class="mx-2 align-self-start small white-text">Default Window (hours):</div>
            </div>
            <div v-if="!useCustomScaleOnDateAxis" class="d-flex flex-row-reverse my-2 full-width">
                <template v-if="dragToZoom">
                    <div data-toggle="tooltip" data-placement="top" :title="syncedChartsTooltip">
                        <button class="btn btn-sm mx-1" :class="zoomMode === 'xy' ? 'btn-primary' : 'btn-light'" v-on:click="zoomLockRange('xy')" :disabled="areChartsSynced">XY</button>
                    </div>
                    <div data-toggle="tooltip" data-placement="top" :title="!chartConfiguration.isVertical ? syncedChartsTooltip : null">
                        <button class="btn btn-sm mx-1" :class="zoomMode === 'y' ? 'btn-primary' : 'btn-light'" v-on:click="zoomLockRange('y')" :disabled="areChartsSynced && !chartConfiguration.isVertical">Y</button>
                    </div>
                    <div data-toggle="tooltip" data-placement="top" :title="chartConfiguration.isVertical ? syncedChartsTooltip : null">
                        <button class="btn btn-sm mx-1" :class="zoomMode === 'x' ? 'btn-primary' : 'btn-light'" v-on:click="zoomLockRange('x')" :disabled="areChartsSynced && chartConfiguration.isVertical">X</button>
                    </div>

                    <div class="mx-2 align-self-start small white-text">Enable zoom on axis:</div>
                </template>
                <template v-else>
                    <div style="padding-right: 112px"></div>
                    <div data-toggle="tooltip" data-placement="top" :title="syncedChartsTooltip">
                        <button class="btn btn-sm mx-1" :class="panMode === 'xy' ? 'btn-primary' : 'btn-light'" :disabled="areChartsSynced" v-on:click="panLockRange('xy')">XY</button>
                    </div>
                    <div data-toggle="tooltip" data-placement="top" :title="!chartConfiguration.isVertical ? syncedChartsTooltip : null">
                        <button class="btn btn-sm mx-1" :class="panMode === 'y' ? 'btn-primary' : 'btn-light'" :disabled="areChartsSynced && !chartConfiguration.isVertical" v-on:click="panLockRange('y')">Y</button>
                    </div>
                    <div data-toggle="tooltip" data-placement="top" :title="chartConfiguration.isVertical ? syncedChartsTooltip : null">
                        <button class="btn btn-sm mx-1" :class="panMode === 'x' ? 'btn-primary' : 'btn-light'" :disabled="areChartsSynced && chartConfiguration.isVertical" v-on:click="panLockRange('x')">X</button>
                    </div>
                    <div class="mx-2 align-self-start small white-text">Enable pan on axis:</div>
                </template>
            </div>
        </b-popover>
        <b-popover :ref="'filterPopover' + _uid" :target="'popover-filter-' + dashboardItem.options.configId + _uid" triggers="click" placement="bottom" boundary="viewport" @shown="isFilteringPopoverOpen($event)" @hidden="isFilteringPopoverOpen($event)">
            <div class="d-flex flex-row mt-2">
                <div class="align-self-start mx-2" style="color: white;">Activity:</div>
                <div>
                    <select class="form-control" v-model="selectedWellStageIntervalsActivityTypeOption" @change="filterByActivity($event.target.value)">
                        <option v-for="activity in wellStageIntervalsActivityTypeOptions" :value="activity" :key="activity" >
                            {{ activity }}
                        </option>
                    </select>
                </div>
                <div class="align-self-start mx-2" style="color: white;">Well:</div>
                <div style="background-color: white; border: 6px solid white; border-radius: 4px; min-height:38px;" @click="onWellSelectClicked()">
                    <div class="dropdown">
                        <div style="display:flex; flex-direction:row; justify-content:center;">
                            <div class="mr-2 ml-2" style="font-size:16px;">{{ selectedWellFilterDisplayString }}</div>
                            <div class="mr-2 align-self-center" v-if="selectedWellFilterDisplayColor != null" :style="{ width:'25px', height:'25px', border:'solid #000000', 'marginLeft':'auto', 'marginRight':'0px', 'backgroundColor': selectedWellFilterDisplayColor }"></div>
                        </div>
                        <div class="filter-dropdown-content" style="font-size:16px;" ref="well_dropdown_test">
                            <a v-for="(well,index) in wells" style="display: flex; align-items: center" @click="filterByWell($event)" :target="well.index" :key="index">
                                <a class="mr-2 " style="display: inline-block; overflow: hidden; white-space: nowrap;" :target="well.index">{{well.name}}</a>
                                <a :style="{ width:'25px', height:'25px', border:'solid #000000', display:'inline-block', 'marginLeft':'auto', 'marginRight':'0px', 'backgroundColor': well.color }" :target="well.index"></a>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row mt-3 mb-2">
                <div class="align-self-start ml-2" style="color: white; margin-right: 18px;">Stage:</div>
                <div>
                    <select class="form-control" v-model="selectedWellStageInterval" @change="filterByStage($event)" >
                        <option v-if="selectedWellStageIntervalGroup == null" value="-1">-- No Stage Set --</option>
                        <option v-for="(interval, key,index) in selectedWellStageIntervalGroup" :value="interval" :key="index" :disabled="disableFilterOption(interval, index)">
                            {{ 'Stage #' + key + " : "
                                + (interval.startTime != null ? interval.startTime : 'Unavailable') + ' - '
                                + (interval.endTime != null ? interval.endTime : (disableFilterOption(interval, index) ? 'Unavailable' : 'Currently Ongoing')) }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex justify-content-between mt-3 mb-2">
                <div>
                    <button type="button" class="btn btn-secondary grey-button" @click.prevent="$root.$emit('bv::hide::popover')">
                        Close
                    </button>
                </div>
                <div class="d-flex flex-row-reverse">
                    <div>
                        <button type="button" class="btn btn-success green-button float-right ml-2" :disabled="isDownloadingData" @click.prevent="onSetWellStageFilter($event)">
                            Set Filter
                        </button>
                    </div>
                    <div>
                        <button type="button" class="btn btn-secondary grey-button float-right ml-2" :disabled="isDownloadingData" @click.prevent="onClearWellStageFilter($event)">
                            Clear Filter
                        </button>
                    </div>
                </div>
            </div>
        </b-popover>
        <b-popover :custom-class="isElementTransparent" :target="'popover-ghost-' + dashboardItem.options.configId + _uid" triggers="click blur" placement="bottom" :delay="{show: 0, hide: 0}" boundary="viewport" @shown="isGhostPopoverOpen($event)" @hidden="isGhostPopoverOpen($event)">
            <div class="d-flex flex-row mt-2">
                <div>
                    <select class="form-control" v-model="ghostLineWell" @change="updateGhostPlotLineWell($event);">
                        <option value="null" selected disabled>Ghost Well Select</option>
                        <option v-for="well in wells" :value="well" :key="well.index" >
                            {{ well.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex flex-row mt-2">
                <div>
                    <select class="form-control" v-model="ghostLineStage" @change="updateGhostPlotLineStage($event)">
                        <option value="null" selected disabled>Ghost Stage Select</option>
                        <option v-for="(interval,index) in selectedGhostWellStageIntervalGroupSanitized" :value="interval" :key="index">
                            {{ 'Stage #' + index + " : " + interval.startTime + ' - ' + interval.endTime }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex flex-row mt-2">
                <div style="width:100%;">
                    <checkbox-list
                        label="Ghost Channel(s) Select"
                        :maxHeight="250"
                        :options="usedTagsDisplay"
                        valueKey="priorityName"
                        outputKey="name"
                        v-bind:selectedOptions="ghostLineChannels"
                        v-on:update:selectedOptions="updateGhostPlotChannels($event)">
                    </checkbox-list>
                </div>
            </div>
            <div v-if="ghostLineStage && ghostLineStage.startTime" class="d-flex flex-row mt-2">
                <div class="align-self-center mr-2" style="color: white;">Index Ghost Plot To:</div>
                <div class="d-flex flex-row">
                    <div>
                        <i class="fas fa-arrow-circle-left clickable" style="background-color: white; border: 2px solid white; border-radius:5px;" @mousedown="stepGhostPlotIndexTime('dec', true)"></i>
                        <input class="mx-1" type="datetime-local" v-model="getGhostPlotIndexStart"   >
                        <i class="fas fa-arrow-circle-right clickable"  style="background-color: white; border: 2px solid white; border-radius:5px;" @mousedown="stepGhostPlotIndexTime('inc', true)"></i>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row mt-2">
                <div class="align-self-start mr-2" style="color: white;">Line Size :</div>
                <div>
                    <select class="form-control" v-model="ghostLineSize" @change="ghostPlotEnabled = false;">
                        <option v-for="lineSize in [1,2,4,8,16]" :value="lineSize" :key="lineSize" >
                            {{ lineSize }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div style="color: white;">Opacity :</div>
                    <vue-slider
                        class="mt-5"
                        v-model="ghostLineOpacity"
                        :options="ghostSliderOptions"
                        :order="false"
                        tooltip="always"
                        @change="ghostPlotEnabled = false;"
                        >
                    </vue-slider>
                </div>
            </div>
            <div class="d-flex flex-row-reverse mt-3 mb-2">
                <div>
                    <button type="button" class="btn btn-success green-button float-right ml-2" :disabled="isDownloadingData || ghostPlotSettingsIncomplete" @click.prevent="onSaveGhostPlot($event)">
                        Save
                    </button>
                </div>
                <div>
                    <button type="button" class="btn btn-secondary grey-button float-right ml-2" :disabled="isDownloadingData" @click.prevent="onClearGhostPlot($event)">
                        Reset Selections
                    </button>
                </div>
            </div>
        </b-popover>


        <div v-for="(chartItems, index) in usedSections" v-bind:key="sectionIndexes[index].index + '-' + dashboardItem.options.configId">
            <div v-for="(fracItem, ind) in chartItems" v-bind:key="fracItem.name + '-' + dashboardItem.options.configId" v-bind:style="chartItemColor(fracItem.name)">
                <b-popover v-if="shouldDrawGraph && chartConfigEditMode" :target="'popover-target-' + _uid + '-' + index + '-' + ind" triggers="click blur" placement="top" boundary="window">
                    <div class="row">
                        <div class="mx-2">
                            <div class="d-flex flex-row" style="color: white;">Label Font Size:</div>
                            <div class="d-flex flex-row mt-2">
                                <select class="form-control" v-model="getLabelTextOptions(index, ind).fontSize"  @change="newTextSizeSelected = true">
                                    <option value="auto">
                                        Auto
                                    </option>
                                    <option value="0.5em">
                                        Small
                                    </option>
                                    <option value="0.7em">
                                        Medium
                                    </option>
                                    <option value="1.0em">
                                        Large
                                    </option>
                                    <option value="1.2em">
                                        X-Large
                                    </option>
                                    <option value="1.4em">
                                        XX-Large
                                    </option>
                                </select>
                            </div>
                            <div class="d-flex flex-row mt-2" style="color: white;">Label Font Style:</div>
                            <div class="d-flex flex-row mt-2" style="color: white;">
                                <div class="align-self-start" style="margin-right: 7px">
                                    <i class="fas fa-bold"></i><input type="checkbox" style="margin-left: 5px" v-model="getLabelTextOptions(index, ind).bold">
                                </div>
                                <div class="align-self-start" style="margin-right: 7px">
                                    <i class="fas fa-italic"></i><input type="checkbox" style="margin-left: 5px" v-model="getLabelTextOptions(index, ind).italic">
                                </div>
                                <div class="align-self-start" style="margin-right: 7px">
                                    <i class="fas fa-underline"></i><input type="checkbox" style="margin-left: 5px" v-model="getLabelTextOptions(index, ind).underline">
                                </div>
                            </div>
                            <div class="d-flex flex-row mt-2" style="color: white;">
                                <div class="align-self-start">
                                    <input type="checkbox" style="margin-right: 5px" v-model="getLabelTextOptions(index, ind).color">Use Graph Color
                                </div>
                            </div>
                            <div class="d-flex flex-row mt-2">
                                <button
                                v-if="chartConfigEditMode"
                                type="button"
                                class="btn btn-success green-button"
                                style="font-size: small;"
                                @click="onEditChartConfiguration(ind, index)"
                                >Edit Data Source</button>
                            </div>
                        </div>
                        <div class="mx-2">
                            <div class="d-flex flex-row" style="color: white;">Data Font Size:</div>
                            <div class="d-flex flex-row mt-2">
                                <select class="form-control" v-model="getDataTextOptions(index, ind).fontSize"  @change="newTextSizeSelected = true">
                                    <option value="auto">
                                        Auto
                                    </option>
                                    <option value="0.5em">
                                        Small
                                    </option>
                                    <option value="0.7em">
                                        Medium
                                    </option>
                                    <option value="1.0em">
                                        Large
                                    </option>
                                    <option value="1.2em">
                                        X-Large
                                    </option>
                                    <option value="1.4em">
                                        XX-Large
                                    </option>
                                </select>
                            </div>
                            <div class="d-flex flex-row mt-2" style="color: white;">Data Font Style:</div>
                            <div class="d-flex flex-row mt-2" style="color: white;">
                                <div class="align-self-start" style="margin-right: 7px">
                                    <i class="fas fa-bold"></i><input type="checkbox" style="margin-left: 5px" v-model="getDataTextOptions(index, ind).bold">
                                </div>
                                <div class="align-self-start" style="margin-right: 7px">
                                    <i class="fas fa-italic"></i><input type="checkbox" style="margin-left: 5px" v-model="getDataTextOptions(index, ind).italic">
                                </div>
                                <div class="align-self-start" style="margin-right: 7px">
                                    <i class="fas fa-underline"></i><input type="checkbox" style="margin-left: 5px" v-model="getDataTextOptions(index, ind).underline">
                                </div>
                            </div>
                            <div class="d-flex flex-row mt-2" style="color: white;">
                                <div class="align-self-start">
                                    <input type="checkbox" style="margin-right: 5px" v-model="getDataTextOptions(index, ind).color">Use Graph Color
                                </div>
                            </div>
                            <div class="d-flex flex-row mt-2">
                                <button
                                v-if="chartConfigEditMode"
                                type="button"
                                class="btn btn-danger red-button"
                                style="font-size: small;"
                                @click="onDeleteChartItem(ind, index)"
                                >Delete Data Source</button>
                            </div>
                        </div>
                    </div>
                </b-popover>
                <b-popover :target="'popover-chart-axis-options-target-'+dashboardItem.i" triggers="click blur" placement="left" boundary="window">
                    <div style="color:white; " class="row">
                        <div class="col-6">
                            <div class="d-flex flex-row justify-content-center mb-2">
                                Independent Axis
                            </div>
                            <div class="mb-1 d-flex flex-row justify-content-between" v-if="!getLineChart().scales['date-axis']">
                                <label class="mr-2 pt-1">Label Font Size: </label>
                                <input class="w-50 form-control form-control-sm" style="max-width:70px;" type="number" min="2" max="50"
                                    v-model="localChartAxisOptions.independentAxis.labelFontSize"
                                    @change="setChartFontSize('independentLabel', chartConfiguration.isVertical)">
                            </div>
                            <div class="mb-1 d-flex flex-row justify-content-between">
                                <label class="mr-2 pt-1">Tick Font Size: </label>
                                <input class="w-50 form-control form-control-sm" style="max-width:70px;" type="number" min="2" max="50"
                                    v-model="localChartAxisOptions.independentAxis.tickFontSize"
                                    @change="setChartFontSize('independentTick', chartConfiguration.isVertical)">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="d-flex flex-row justify-content-center mb-2">
                                Dependant Axis
                            </div>
                            <div class="mb-1 d-flex flex-row justify-content-between">
                                <label class="mr-2 pt-1">Label Font Size: </label>
                                <input class="w-50 form-control form-control-sm" type="number" style="max-width:70px;"  min="2" max="50"
                                v-model="localChartAxisOptions.dependantAxis.labelFontSize"
                                @change="setChartFontSize('dependantLabel', chartConfiguration.isVertical)">
                            </div>
                            <div class="mb-1 d-flex flex-row justify-content-between">
                                <label class="mr-2 pt-1">Tick Font Size: </label>
                                <input class="w-50 form-control form-control-sm" style="max-width:70px;" type="number" min="2" max="50"
                                    v-model="localChartAxisOptions.dependantAxis.tickFontSize"
                                    @change="setChartFontSize('dependantTick', chartConfiguration.isVertical)">
                            </div>
                        </div>
                    </div>
                </b-popover>
            </div>
        </div>

        <div v-if="!isNotDashboard">
            <div v-for="(sectionData, index) in sectionIndexes" :key="'grid' + index + '-' + dashboardItem.options.configId">
                <b-popover :target="'manage-channels-' + dashboardItem.i + index" triggers="click blur" boundary="viewport" placement="bottom">
                    <div style="color:#FFF;" class="container">
                        <div class="row justify-content-center">Available Data Sources</div>
                        <div class="row">
                            <checkbox-list
                                label="Source Tag Name"
                                height="200"
                                :options="tagsSortedByName"
                                valueKey="priorityName"
                                outputKey="name"
                                :customToggleOnEvent="quickAddDataSource"
                                :customToggleOffEvent="quickRemoveDataSource"
                                :customData="{'sectionData':sectionData, 'index':index}"
                                :selectedOptions="selectedTags[chartConfigurationData.orderedSections[index].key]"
                                :disabledOptions="getDisabledTagsForSection(chartConfigurationData.orderedSections[index].key)"
                                v-on:update:selectedOptions="selectedTags[chartConfigurationData.orderedSections[index].key] = $event">
                                >
                            </checkbox-list>
                        </div>
                    </div>
                </b-popover>
            </div>
        </div>

        <b-modal
            v-model="showAggregationAlert"
            no-close-on-backdrop
            size="xl"
            header-class="border-bottom-0 justify-content-center  p-0"
            body-class="pb-0"
            footer-class="border-top-0 pt-0"
            content-class="border-3 modal-bg"
        >
            <template #modal-header>
                <div class="col w-100">
                    <div class="modal-header">
                        <div class="font-large w-100 text-center"> Max Interval reached! </div>
                    </div>
                </div>
            </template>
            <template #default>
                <div class="d-flex flex-row flex-wrap">
                    <div class="pr-1">Change the interval to </div>
                    <div v-for="(sInterval, index) in suggestedIntervals" :key="index" class="pr-2">
                        <b-link  class="link-txt-color" @click="()=>{ showAggregationAlert = false; onAggregationChange(sInterval.value, sInterval.index) }">{{ sInterval.name }}</b-link>
                    </div>
                </div>
            </template>
            <template #modal-footer>
                <div class="d-flex justify-content-right px-2 pb-2">
                    <button type="button" class="btn btn-secondary text-white" @click="()=>{ showAggregationAlert = false }" >Close</button>
                </div>
            </template>
        </b-modal>
        <!-- popover views end-->
        <!-- component header start -->
            <div v-bind:style="getHeaderStyle(well)"
                 class="w-100 frac-header rounded my-2"
                 @mouseover="()=>onHeaderEnter()"
                 @mouseleave="()=>onHeaderLeave()"
                 key="header-div"
                 :class="{'border border-secondary': (isWirelinePlaced && headerStyle == 'wireline') || (isFracPlaced && headerStyle == 'frac'),
                          'bg-secondary': !((isWirelinePlaced && headerStyle == 'wireline') || (isFracPlaced && headerStyle == 'frac'))}"
                >

                <!-- brand new combined header -->
                <div class="d-flex align-items-center justify-content-between p-1">
                    <div v-if="showHeader" class="text-truncate">
                        <div v-if="(isWirelinePlaced && headerStyle == 'wireline') || (isFracPlaced && headerStyle == 'frac')" style="flex: 1 0 0px;">
                            <div v-if="(headerStyle == 'frac' && isContinuousFrac)">
                                <b> {{ displayCFStatus(well) }} </b>
                            </div>
                            <div v-else-if="headerStyle == 'frac'">
                                <b>Frac: {{ wellFriendlyName(well) }} {{well.currentStage}}/{{well.numberOfStages}}</b>
                            </div>
                            <div v-if="headerStyle == 'wireline' && well && well.hasOwnProperty('index')">
                                <b>{{ well.index > -1 && well.index < 999 ? 'Perforating Well ' + wellFriendlyName(well) + ' Stage ' + well.currentStage + '/' + well.numberOfStages   : '' }}</b>
                            </div>
                        </div>

                        <div v-else class="text-white">
                            <div v-if="headerStyle == 'frac'">
                                <b>Frac not currently assigned to a well</b>
                            </div>
                            <div v-if="headerStyle == 'wireline'" >
                                <b>Wireline not currently assigned to a well</b>
                            </div>
                            <div v-if="headerStyle == 'default'">
                                <b>{{ chartConfiguration.templateName }} </b>
                            </div>
                        </div>
                    </div>

                    <div style="flex: auto;">
                        <div class="d-flex justify-content-end">
                            <div v-if="currentWellStageFilterInterval != null" style="display: flex; align-items: center; justify-content: center; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                                <div style="display: inline-block; min-width: 0px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" v-tooltip:top="'[ Well : ' + currentWellFilterDisplayString + ', Stage #' + currentSelectedStage + ' ]'">
                                    <b style="display: inline-block;">{{ '[ Well : ' + currentWellFilterDisplayString }}</b>
                                    <b class="ml-1 mr-1" v-if="currentWellFilterDisplayColor != null" :style="{ display:'inline-block', width:'16px', height:'16px', border:'3px solid #000000', 'marginLeft':'auto', 'marginRight':'0px', 'backgroundColor': currentWellFilterDisplayColor }"></b>
                                    <b style="display: inline-block;">{{', Stage #' + currentSelectedStageValue + ' ]'}}</b>
                                </div>
                            </div>
                            <span v-if="!useCustomScaleOnDateAxis && shouldDrawGraph && !chartConfigEditMode && zoomHistory.length > 1" class="align-self-center clickable pr-1 d-flex">
                                <span v-tooltip:top="'Undo Zoom'" @click="onUndoZoomPressed" :disabled="isDownloadingData || isDownloadingGhostData" class="ml-2 fake-button" :class="{'ml-2': showHeader}">
                                    <i class="fas fa-undo header-icon clickable" :class="{'icon-disabled': isDownloadingData || isDownloadingGhostData}"></i>
                                </span>
                            </span>
                            <span v-if="!showSuperSmallHeader() && headerStyle == 'wireline' && shouldDrawGraph && isMultiWireline && !isNotDashboard"  class="align-self-center clickable pr-1">
                                <div class="d-flex flex-row" v-tooltip:top="'Select Wireline Truck'">
                                    <select-component
                                        :ref="'wirelineTruckSelect-'+dashboardItem.i"
                                        class="d-flex align-items-center"
                                        :title="''"
                                        :initSelected="initSelectedTruck"
                                        :options="wirelineSystems"
                                        propValue="number"
                                        displayPropName="name"
                                        :onChange="onSelectedTruckChange"
                                        :externalInputStyles="{fontSize: '0.9rem', width: 'auto',  color: '#000000', background: 'white', height: '20px'}"
                                        :isCustomClassEnabled="true"
                                        :customClass="headerSelectStyle"
                                        :disabled="isDownloadingData"
                                    />
                                    <span>
                                    </span>
                                </div>
                            </span>
                            <span v-if="showMultiFracSelect"  class="align-self-center clickable pr-1">
                                <div class="d-flex flex-row" v-tooltip:top="'Select Frac Truck'">
                                    <!-- TODO: Should be iws-select -->
                                    <select-component
                                        :ref="'fracTruckSelect-'+dashboardItem.i"
                                        class="d-flex align-items-center"
                                        :title="''"
                                        :initSelected="initSelectedTruck"
                                        :options="fracSystems"
                                        propValue="number"
                                        displayPropName="name"
                                        :onChange="onSelectedTruckChange"
                                        :externalInputStyles="{fontSize: '0.9rem', width: 'auto',  color: '#000000', background: 'white', height: '20px'}"
                                        :isCustomClassEnabled="true"
                                        :customClass="headerSelectStyle"
                                        :disabled="isDownloadingData"
                                    />
                                    <span>
                                    </span>
                                </div>
                            </span>
                            <span class="d-flex align-self-center" :class="{'ml-1': showHeader}"
                                v-if="!showSuperSmallHeader() && !useCustomScaleOnDateAxis" v-show="!chartConfigEditMode && currentAggregationIndex !== null && aggregationOptions.length > 0">
                                <!-- TODO: Should be iws-select -->
                                <select-component
                                    :ref="'autoAggregateSelect-'+dashboardItem.i"
                                    class="d-flex align-items-center"
                                    :title="''"
                                    :initIndex="currentAggregationIndex"
                                    :options="aggregationOptions"
                                    displayPropName="name"
                                    :onChange="onAggregationChange" :externalInputStyles="{fontSize: '0.9rem', width: 'auto',  color: '#000000', background: 'white', height: '20px'}"
                                    :isCustomClassEnabled="true"
                                    :customClass="headerSelectStyle"
                                    :disabled="isDownloadingData"
                                    :useIndex="true"
                                />
                                </span>
                            <span v-show="!showSmallHeader() && !chartConfigEditMode && !useCustomScaleOnDateAxis"
                                    class="badge badge-light align-self-center my-1 ml-2" style="display: inline-block;">
                                <input type="checkbox"
                                    style="vertical-align: middle;"
                                    v-model="ghostPlotEnabled"
                                    :disabled="(ghostPlotSettingsIncomplete && !isGhostPlotDataLoaded()) "
                                    @click="ghostPlotClicked"
                                    />
                                    <a href="#" :id="'popover-ghost-' + dashboardItem.options.configId + _uid">
                                        Ghost Plot
                                        <i v-if="showPopoverGhostPlot" class="fas fa-caret-up"></i>
                                        <i v-else class="fas fa-caret-down"></i>
                                    </a>
                            </span>
                            <span v-if="shouldDrawGraph && chartConfigEditMode" class="ml-2 align-self-center">
                                <button
                                    type="button"
                                    class="btn btn-secondary btn-sm"
                                    style="font-size: small; padding-top: 1px; padding-bottom: 1px;"
                                    @click="onCancelEditChartOrderPressed"
                                >Cancel</button>
                            </span>
                            <span v-if="shouldDrawGraph && isEditable" class="ml-2 align-self-center clickable pr-1" @click="onEditChartOrderPressed">
                                <button
                                    v-if="chartConfigEditMode"
                                    type="button"
                                    class="btn btn-success btn-sm"
                                    style="font-size: small; padding-top: 1px; padding-bottom: 1px; padding-left: 1px; padding-right: 1px;"
                                    >Save</button>
                                <i class="fas fa-edit header-icon" v-else v-tooltip:top="'Edit controls options'"></i>
                            </span>

                            <span class="align-self-center clickable pr-1" @click="onChartHeaderPressed" v-show="!showSmallHeader() && !chartConfigEditMode">
                                <i class="fas fa-eye header-icon" v-if="this.showChartHeaderConfig" v-tooltip:top="'Show/Hide chart header'"></i>
                                <i class="fas fa-eye-slash header-icon" v-else v-tooltip:top="'Show/Hide chart header'"></i>
                            </span>
                            <span class="align-self-center clickable pr-1" @click="inStatsMode = !inStatsMode" v-show="!showSmallHeader() && !chartConfigEditMode && !useCustomScaleOnDateAxis">
                                <span :class="{ 'in-stats': inStatsMode }">
                                    <i class="fas fa-chart-bar header-icon" v-if="inStatsMode" v-tooltip:top="'Toggle Statistics Mode'"></i>
                                    <i class="fas fa-chart-bar header-icon" v-else v-tooltip:top="'Toggle Statistics Mode'"></i>
                                </span>
                            </span>
                            <span v-if="shouldDrawGraph" class="align-self-center clickable pr-1" @click="onAlertIconPressed" v-show="!showSmallHeader() && !chartConfigEditMode">
                                <i class="fas fa-bell header-icon" v-if="this.enableThresholdAlerts" v-tooltip:top="'Show/Hide threshold alerts in chart comment line'" ></i>
                                <i class="fas fa-bell-slash header-icon" v-else v-tooltip:top="'Show/Hide threshold alerts in chart comment line'"></i>
                            </span>
                            <span v-if="!useCustomScaleOnDateAxis && shouldDrawGraph" class="align-self-center clickable pr-1" v-show="!showSmallHeader() && !chartConfigEditMode">
                                <span v-tooltip:top="'Export Chart Data'" @click="onExportIconPress">
                                    <i class="fas fa-download header-icon"></i>
                                </span>
                            </span>
                            <span v-if="!showSmallHeader() && shouldDrawGraph && !chartConfigEditMode" :id="'popover-filter-' + dashboardItem.options.configId + _uid" class="align-self-center clickable pr-1">
                                <span>
                                    <i class="fas fa-filter header-icon" v-tooltip:top="'Filtering'"></i>
                                </span>
                            </span>

                            <span v-if="!showSuperSmallHeader() && !useCustomScaleOnDateAxis && shouldDrawGraph && !chartConfigEditMode" :id="'popover-target-' + dashboardItem.options.configId + _uid" class="align-self-center clickable pr-1">
                                <span>
                                    <i class="fas fa-ruler-combined header-icon"></i>
                                </span>
                            </span>
                            <span v-if="isEditable" @click="onEditChartConfiguration" v-tooltip:top="'Edit Chart Configuration'" v-show="!showSmallHeader() && !chartConfigEditMode" class="align-self-center clickable pr-1">
                                <i class="fas fa-cog header-icon"></i>
                            </span>
                            <span v-if="!this.jobEnd && shouldDrawGraph" class="align-self-center" style="vertical-align: middle; margin-top:-2px;">
                                <button v-if="shouldDrawGraph"
                                        v-show="!chartConfigEditMode"
                                        :disabled="isDownloadingData"
                                        type="button"
                                        class="btn btn-secondary align-self-center btn-sm ml-1 p-0"
                                        style="font-size: 13px; background-color:white; white-space:nowrap"
                                        @click="followToLatestTime">

                                    <span class="px-1 font-weight-bold" style="font-family: FontAwesome, sans-serif;">
                                        <i v-if="this.followingUpdates" class="fas fa-satellite-dish green-color" v-tooltip:top="'Live Data'"></i>
                                        <span v-if="this.followingUpdates" style="color: green;">{{ showSmallHeader()? '' : ' Latest Data'}}</span>

                                        <i v-if="!this.followingUpdates" class="fas fa-satellite-dish not-live-tracking" v-tooltip:top="'Not Following Live Data'"></i>
                                        <span v-if="!this.followingUpdates" class="not-live-tracking">{{ showSmallHeader()? '' : ' Not Following Latest Data' }}</span>
                                    </span>
                                </button>
                            </span>

                            <div v-show="(showSuperSmallHeader() || showSmallHeader()) && !chartConfigEditMode" class="ml-2" :id="'popover-header-' + _uid" v-tooltip:top="'More options'">
                                <span><i class="fas fa-bars"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <!-- component header end -->
        <!-- component body start -->
        <div :id="'chart-space-' + dashboardItem.i" :class="{
                'd-flex': true,
                'flex-row': (controlsPosition == 'left'),
                'flex-row-reverse': (controlsPosition == 'right'),
                'flex-column': (controlsPosition == 'top'),
                'flex-column-reverse': (controlsPosition == 'bottom')
            }"
            :style="{ 'height' : bodyHeight + 'px'}"
        >
            <!-- chart sections start -->
            <div v-if="!hideControlsColumn" ref="sectionsDiv"
                :style="sectionWidth"
                :class="{
                    'pr-1': (controlsPosition == 'left' || controlsPosition == 'right') && shouldDrawGraph
                }">
                <div
                    :style="readoutControlsSectionsStyle"
                    class="px-0"
                    :class="{
                        'col-12': (controlsPosition == 'left' || controlsPosition == 'right') || !shouldDrawGraph
                    }"
                >
                    <div :ref="'mainGridLayout' + _uid" :class="mainGridClass">
                        <draggable
                            v-model="sectionIndexes"
                            :class="daggableClass"
                            handle=".handle"
                        >
                            <div v-for="(sectionData, index) in sectionIndexes" :key="'grid' + index + '-' + dashboardItem.options.configId" :class="chartItemClass" :style="sectionFill">
                                <div class="row rounded bg-secondary border border-dark my-1" style="margin-left: 0px !important; margin-right: 4px !important">
                                    <div class="my-1 ml-2" style="flex-grow: 3; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                                        {{sectionLabel[sectionData.index]}}
                                    </div>
                                    <div class="my-1 mr-2 d-flex flex-row-reverse justify-content-start flex-wrap-reverse" style="flex-grow: 1;">
                                        <i :ref="'axisDataArea-' + usedSectionsIDs[sectionData.index] + '-eye'" class="fas" :class="{'fa-caret-up': !isSectionHidden(usedSectionsIDs[sectionData.index]), 'fa-caret-down': isSectionHidden(usedSectionsIDs[sectionData.index])}" @click="onExpandButtonPress(sectionData.index)"></i>
                                        <input v-if="shouldDrawGraph" type="checkbox" class="m-1 px-1" :disabled="isDisabledCheckbox" v-model="sectionChecked[sectionData.index]"  @change="sectionCheckedChanged(sectionData.index)">
                                        <i v-if="shouldDrawGraph && chartConfigEditMode" class="fas fa-grip-lines handle show-move-cursor"></i>
                                        <div v-show="shouldDrawGraph && chartConfigEditMode && !isNotDashboard" class="">
                                            <span  class="badge badge-light d-flex align-items-center mt-1 ml-1 mr-2 pr-1" style="display: inline-block; font-size:65%;">
                                                <button :id="'manage-channels-' + dashboardItem.i + index" class="fake-button">Manage Channels</button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div :ref="'axisDataArea-' + usedSectionsIDs[sectionData.index]" v-if="dataReceived" class="px-1 py-1"
                                    :style="{ display: isSectionHidden(usedSectionsIDs[sectionData.index]) ? 'none' : '', overflowY: 'auto', overflowX: 'hidden', borderRadius: '5px' }"
                                    :class="chartConfigEditMode ? 'bg-light' : null"
                                >
                                    <grid-layout
                                        v-if="chartItemLayouts[usedSectionsIDs[sectionData.index]]"
                                        :layout="chartItemLayouts[usedSectionsIDs[sectionData.index]]"
                                        :is-draggable="chartConfigEditMode"
                                        :is-resizable="chartConfigEditMode"
                                        :col-num="defaultChartColumnCount"
                                        :row-height="30"
                                        :auto-size="true"
                                        :prevent-collision="false"
                                        :vertical-compact="false"
                                        :margin="[3, 3]"
                                        @layout-mounted="onGridMounted"
                                        :use-css-transforms="true"
                                    >
                                        <grid-item
                                            v-for="(fracItem, ind) in usedSections[sectionData.index]" v-bind:key="fracItem.name + dashboardItem.options.configId"
                                            v-bind:style="chartItemColor(fracItem.name)"
                                            :id="'chartitem-' + fracItem.name"
                                            :class="setClassesForChartItems(columnCounts[sectionData.index])"
                                            style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                                            :x="chartItemLayouts[usedSectionsIDs[sectionData.index]][ind].x"
                                            :y="chartItemLayouts[usedSectionsIDs[sectionData.index]][ind].y"
                                            :w="chartItemLayouts[usedSectionsIDs[sectionData.index]][ind].w"
                                            :h="chartItemLayouts[usedSectionsIDs[sectionData.index]][ind].h"
                                            :i="chartItemLayouts[usedSectionsIDs[sectionData.index]][ind].i"
                                            drag-allow-from=".vue-draggable-handle"
                                        >
                                                <p class="mb-0 pt-1" :id="'labelFont-' + fracItem.name" :style="getChartitemStyle(getLabelTextOptions(sectionData.index, ind), fracItem, 'label', paramChecked[fracItem.name])">
                                                    {{ getDisplayTagName(fracItem.name) }}
                                                </p>
                                                <p class="mb-0 pt-1" :id="'dataFont-' + fracItem.name" :style="getChartitemStyle(getDataTextOptions(sectionData.index, ind), fracItem, 'data', paramChecked[fracItem.name])">
                                                    {{getFormattedChartValue(fracItem)}}
                                                </p>

                                                <input v-if="shouldDrawGraph && paramChecked[fracItem.name]" 
                                                    type="radio"
                                                    :disabled="isDownloadingData"
                                                    style="position:absolute; right:20px; bottom:3px;"
                                                    :checked="shadingChecked[fracItem.name]"
                                                    @click="setShadedData(fracItem)"
                                                    v-tooltip:top="`Toggle Tag Shading`"
                                                >
                                                <input v-if="shouldDrawGraph"
                                                    type="checkbox"
                                                    :disabled="isDownloadingData"
                                                    style="position:absolute; right:3px; bottom:3px;"
                                                    v-model="paramChecked[fracItem.name]"
                                                    @change="setActiveData(fracItem)"
                                                    v-tooltip:top="`Toggle Tag`"
                                                >

                                                <button v-if="shouldDrawGraph && chartConfigEditMode" :id="'popover-target-' + _uid + '-' + sectionData.index + '-' + ind"
                                                        @click="onEditChartItemPressed(fracItem.name, chartItemLayouts[usedSectionsIDs[sectionData.index]], ind)"
                                                        class="fake-button" style="position:absolute;left:3px;top:1px;color: #6c757d; padding: 1px !important">
                                                    <i class="fas fa-cog"></i>
                                                </button>

                                                <i v-if="shouldDrawGraph && chartConfigEditMode" class="fas fa-grip-lines vue-draggable-handle" style="position:absolute;right:3px;top:3px; color: #6c757d"></i>
                                        </grid-item>
                                    </grid-layout>
                                    <div v-else>
                                        No chart items in this section
                                    </div>
                                </div>
                            </div>
                        </draggable>
                    </div>
                </div>
            </div>
            <!-- chart sections end -->
            <!-- chart & comment timeline start -->
            <div v-show="shouldDrawGraph"
                :style="chartWidth"
            >
                <div v-if="chartConfigEditMode && !chartConfiguration.isVertical" id="editVerticalAxisContainer" class="w-100 d-flex justify-content-between">
                    <div>
                        <button v-for="axis in getYAxes('left').slice().reverse()" :key="axis.options.key"
                            :id="'edit-axis-target-'+axis.options.key"
                            v-tooltip:top="`Edit Axis`"
                            class="fake-button"
                            :style="`width:${axis.width}px;color: #6c757d;`">
                            <i class="fas fa-cog"></i>
                        </button>
                    </div>
                    <div>
                        <button v-for="axis in getYAxes('right')" :key="axis.options.key"
                            :id="'edit-axis-target-'+axis.options.key"
                            v-tooltip:top="`Edit Axis`"
                            class="fake-button"
                            :style="`width:${axis.width}px;color: #6c757d;`">
                            <i class="fas fa-cog"></i>
                        </button>
                    </div>
                    <b-popover v-for="axis in configurationDataYAxesClone" :key="axis.key+forceRefreshStaticKey" :target="'edit-axis-target-'+axis.key" triggers="click blur" placement="left right" boundary="window">
                        <div style="color:white" class="">
                            <div class="w-100 text-center mb-1 d-flex justify-content-center">
                                <span>{{axis.label}}</span>
                            </div>
                            <div class="w-100 mb-1 d-flex justify-content-between">
                                <label class="pr-2" >Name</label>
                                <input type="text" v-model="axis.label">
                            </div>
                            <div class="w-100 mb-1 d-flex justify-content-between">
                                <label class="pr-2" >Minimum</label>
                                <input type="number" v-model.number="axis.min">
                            </div>
                            <div class="w-100 mb-1 d-flex justify-content-between">
                                <label class="pr-2" >Maximum</label>
                                <input type="number" v-model.number="axis.max">
                            </div>
                            <div class="w-100 mb-1 d-flex justify-content-between">
                                <label class="pr-2" >Number of Ticks</label>
                                <input type="number" step="1"
                                    v-model.number="axis.ticks"
                                    @blur="validateAxisTickNumber(axis)"
                                >
                            </div>
                            <div class="w-100 mb-1 d-flex justify-content-between">
                                <label class="pr-2" >Position</label>
                                    <select v-model="axis.position" class="w-100" style="max-width:162px;"> <!-- force <select> to match width of other inputs-->
                                        <option value="left">Left</option>
                                        <option value="right">Right</option>
                                    </select>
                            </div>
                            <div class="w-100 mb-1 d-flex justify-content-between">
                                <label class="pr-2" >Display Gridlines</label>
                                    <div class="d-flex justify-content-center">
                                        <input type="checkbox" v-model="axis.displayGridlines">
                                    </div>
                            </div>
                            <div class="w-100 mb-1 d-flex justify-content-between">
                                <label class="pr-2" >Color</label>
                                <div class="w-100" >
                                        <div class="d-flex justify-content-end" >
                                            <button class="w-100"
                                                style="max-width:162px;"
                                                :style="{'backgroundColor': axis.color || 'fff', 'color': getTitleColorForBG(axis.color || 'fff')}"
                                                @click="showAxisColorPicker = !showAxisColorPicker;"
                                                >{{!showAxisColorPicker ? 'Select Color' : 'Click To Hide'}}</button>
                                            </div>
                                        <div v-if="showAxisColorPicker" class="d-flex justify-content-end">
                                            <sketch-picker
                                                :value="axis.color ? axis.color : '#FFFFFF'"
                                                :disableAlpha="true"
                                                @blur="showAxisColorPicker=false"
                                                @input="color => {axis.color = color.hex;}"
                                                :preset-colors="presetColors"/>
                                        </div>
                                    </div>
                            </div>
                            <div class="w-100 mb-1 d-flex justify-content-end mt-2">
                                <!-- Hiding the 'More Options' button for now. It is an 'extra' part of this feature that was not
                                a part of the original request. It is in a working state, however it requires some
                                extra considerations such as how values should be carried between the edit axis popover and the modal-->
                                <!-- <button class="btn btn-secondary mr-2" @click="onEditYAxisInModal(axis)"> More Options</button> -->
                                <button class="btn btn-primary " @click="applyYAxisEdits(axis)">Apply Changes</button>
                            </div>
                            <div v-show="editYAxisValidator.axisKey == axis.key">
                                <div v-show="editYAxisValidator.noAxisLabelError" class="text-danger">
                                    Axis label must be provided
                                </div>
                                <div v-show="editYAxisValidator.noMinError" class="text-danger">
                                    Axis minimum must be provided
                                </div>
                                <div v-show="editYAxisValidator.noMaxError" class="text-danger">
                                    Axis maximum must be provided
                                </div>
                                <div v-show="editYAxisValidator.minMaxError" class="text-danger">
                                    Maximum must be greater than minimum
                                </div>
                                <div v-show="editYAxisValidator.noNumberOfTicksError" class="text-danger">
                                    Axis tick number must be provided and > 2
                                </div>
                            </div>
                        </div>
                    </b-popover>
                </div>
                <div v-show="!useCustomScaleOnDateAxis && disabledZoomMessage" :style="{zIndex: 10, position: 'absolute', bottom:'10px', right: '10px', backgroundColor: '#3490dc', fontSize: '0.8vw', padding: '10px'}">Click the chart to enable scroll zoom</div>
                <div :style="chartStyle"
                :class="{'bg-light':chartConfigEditMode}" @mouseleave="hideAllTooltips(); hoveringChart = false" @mouseover="hoveringChart = true" @wheel="zoomDisabledMessage" tabindex="-1" @focus.capture="enableZoomOnFocus()" @blur="disableZoomOnBlur()">
                    <div style="position: absolute;" class="w-100">
                        <button v-show="shouldDrawGraph && chartConfigEditMode"
                            class="fake-button"
                            style="color: #6c757d; padding: 1px !important; position: absolute; top:5px; right: 5px;"
                            :id="'popover-chart-axis-options-target-'+dashboardItem.i">
                            <i class="fas fa-cog"></i>
                            </button>
                    </div>
                    <!--Legend for hover annotation labels-->
                    <transition name="fade">
                        <div v-show="wellStageLegendDisplay.show" >
                            <div ref="annotation-legend-box" style="border: 1px solid gray; border-radius:5px; background-color:#1f2227;" class="v-fade" :style="annotationHoverStyle">
                                <div class="d-flex text-center justify-content-center">
                                    <div :style="`height:10px;width:10px;margin-top:4px;background-color:${wellStageLegendDisplay.color}`" class="mr-1 ml-2"></div>
                                    <div :id="'annotation-legend-display-' + dashboardItem.i" class="mr-2"
                                        v-on:setNewAnnotation="setNewAnnotation($event)"
                                        v-on:clearAnnotationDisplay="clearAnnotation()">
                                        {{wellStageLegendDisplay.label.fullContent}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                    <div v-if="isMinWidth && chartConfiguration.isVertical && chartId !== false" class="tooltip-area-style"  :id="'tooltip-area-'+ chartId">
                    </div>
                    <div :id="'tsi-div'+_uid" class="p-1" style="position: absolute; background-color: #404040; z-index:99; visibility: hidden; overflow: auto;">
                        <div class="d-flex justify-content-between">
                            <h4 style="font-size: 1em;">Statistics</h4>
                            <span class="pt-0.5 pr-2" @click="closeTsiAnalytics">
                                <i class="fas fa-times-circle"></i>
                            </span>
                        </div>
                        <p style="font-size: 0.7em;" :id="'tsi-time'+_uid"></p>
                        <div v-if="tsiAnalyticsIsDownloading" style="font-size: 0.7em;" class="m-5">
                            <p class="text-center">Loading statistics...</p>
                        </div>
                        <div v-else style="font-size: 0.7em;" :class="{'d-flex': chartConfiguration.isVertical, 'flex-wrap': chartConfiguration.isVertical}">
                            <div class="d-flex pb-2" v-for="(analytic, index) in tsiAnalytics" :key="index">
                                <div class="ml-1" :style="{backgroundColor: analytic.color, width: '4px'}"></div>
                                <div class="ml-2">
                                    <p>
                                        {{analytic.tagName + ' ' + analytic.ghostPlotLabel}}
                                    </p>
                                    <div class="d-flex">
                                        <p>Min: <br>  {{analytic.min}}</p>
                                        <p class="ml-3">Max: <br>  {{analytic.max}}</p>
                                        <p class="ml-3">Avg: <br> {{analytic.avg}}</p>
                                        <p class="ml-3">Delta: <br> {{(analytic.last - analytic.first).toFixed(2)}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <canvas :id="'overlay-tsi-analytics'+_uid" width="600" height="400" style="position:absolute; pointer-events:none;"></canvas>
                    <canvas :id="'overlay-chart-comment'+_uid" width="600" height="400" style="position:absolute; pointer-events:none;"></canvas>
                    <scatter-chart ref="lineChart" :id="lineChartId" :chart-data="datacollection" :options="options" class="chart-style" />
                    <div ref="dashboardTimeSeriesChart" id="dashboardTimeSeriesChart" class="w-100" style="font-size:0.8rem; background-color: #fff;">
                        <notifications ref="ghostplotNotification" id="ghostplotNotification" :group="'ghostData-'+ _uid" position="top right"/>
                    </div>
                    <div v-if="isDownloadingData">
                        <div class="spinner-border spinner-border-sm" role="status" :style="getSpinnerStyle()">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
                <div v-if="editMode" class="d-flex justify-content-center" id="hideCommentsTimelineContainer">
                    <div class="badge d-flex justify-content-center bg-light">
                        <b-popover v-show="chartConfiguration.isVertical || useCustomScaleOnDateAxis" target="hideCommentsTimelineContainer"
                            triggers="hover" placement="top" custom-class="white-text">
                            <p class="white-text m-0" style="font-size: 0.9em;">Comments timeline is disabled for custom axes and vertical charts</p>
                        </b-popover>
                    </div>
                </div>
                <div v-show="!isCommentsTimelineHidden">
                    <chart-comments-timeline
                        v-if="$refs.lineChart != null"
                        :jobNumber="this.jobNumber"
                        :jobID="this.jobID"
                        :userID="this.userID"
                        :componentSpecificPermissions="componentSpecificPermissions"
                        :chartcomments="{
                            comments: chartcomments,
                            alerts: thresholdAlertsHistory,
                            flags: []
                        }"
                        :onHover="onCommentLineHover"
                        :jobHourOffset="jobHourOffset"
                        :chart="$refs.lineChart"
                        :eventReasons="eventReasons"
                        :stepDescriptions="stepDescriptions"
                        :nptOutcomes="nptOutcomes"
                        :customerVendors="customerVendors"
                        :contractors="contractors"
                        :eventActivityEventReasons="eventActivityEventReasons"
                        :wells="wells"
                        @createNPTAnnotations="createNPTMarkers"
                        @removeNPTAnnotations="removeNPTMarkers"
                        @moveChart="onScrubberBrushMoved"
                        :headerStyle="headerStyle"
                        @onChange="chartOverlayDraw"
                        ref="chartTimeline"
                        :signalRConnected="signalRConnected"
                        :wellStageIntervals="wellStageIntervals"
                        :config="chartConfiguration"
                        :timeseriesHeight="height"
                        :timeseriesWidth="width"
                    />
               </div>
                <div v-if="!isScrubberHidden" style="max-height:160px!important" :style="{ height: `${scrubberHeight}!important` }">
                    <chart-scrubber
                        v-if="$refs.lineChart != null && !isDateAxisHidden"
                        ref="chartScrubber"
                        :minDate="currentChartMin ? currentChartMin.valueOf() : 0"
                        :maxDate="currentChartMax ? currentChartMax.valueOf() : 0"
                        :rightSideMsOffset="this.rightSideMsOffset"
                        :ghostPlotXMax="this.ghostPlotXMax"
                        :jobStart="formatDBDateToMoment(dashboardData.jobStart).valueOf()"
                        :jobEnd="dashboardData.jobEnd? formatDBDateToMoment(dashboardData.jobEnd).valueOf() : null"
                        :onBrushMoved="onScrubberBrushMoved"
                        :onBrushMoving="onScrubberBrushMoving"
                        :onIsCompactChanged="onScrubberIsCompactChanged"
                        :propTSIOptions="jsonLocalStorage.chartTSIOptions"
                        :defaultResolutionHours="chartConfiguration.resolutionZoomHours"
                        :defaultZoomWindowHours="defaultZoomWindowHrs"
                        :dashboardItem="dashboardItem"
                        :enabled="!isDownloadingData"
                        :jobHourOffset="jobHourOffset"
                    />
                </div>
            </div>
            <!-- chart & comment timeline end -->
        </div>
        <!-- component body end -->
        <!-- component modals -->
        <chart-export-component
            v-if="$refs.lineChart != null"
            ref="chartExport"
            :jobNumber="jobNumber"
            :jobHourOffset="jobHourOffset"
            :jobStart="formatMomentToJobLocalTime(dashboardData.jobStart)"
            :jobStartUTC="dashboardData.jobStart"
            :rightSideMsOffset="this.rightSideMsOffset"
            :tagsArray="usedTagsDisplay"
            :minDate="formatMomentToJobLocalTime(currentChartMin)"
            :maxDate="formatMomentToJobLocalTime(currentChartMax)"
            :stageTimeData="wellStageIntervalsByActivityType"
            :wells="wells"
            :isFetching="isExportModalFetchingData"
            @dataFetchStart="isExportModalFetchingData = true"
            @dataFetchComplete="isExportModalFetchingData = false"
        />
    </div>
</template>

<style>
    .chart-style {
        padding-top: 15px;
        height: 100%;
        width: 100%;
    }

    .warn {
        background: #ffb648;
        border-left-color: #f48a06;
    }

    select.header {
        border: none;
        border: 1px solid black !important;
        border-radius: 5px;
        height: 75%;
    }

    select.header:focus {
    box-shadow: none;
    outline: none;
    }

    .tooltip-area-style {
        height:30px
    }
    .fade-leave-active {
        transition: opacity 0.5s ease;
        transition-delay: 2s;
    }
    .fade-leave-to {
        opacity: 0;
    }
    .move-to-front {
        z-index: 9999 !important;
    }
    .component-fade-enter-active, .component-fade-leave-active {
        transition: opacity .3s ease;
    }
    .component-fade-enter, .component-fade-leave-to, .component-fade-leave-active{
        opacity: 0;
    }
    select{
        font-family: FontAwesome, sans-serif;
    }
    .popover {
        max-width: 50vw !important;
        background-color: #373A3C;
        padding: 0px;
        border-style: solid;
        border-radius: 3.5px;
        border-color: #95989A;
        border-width: thin;
    }
    .white-text {
        color: white !important;
    }
    .green-color {
        color: #01923F
    }

    .in-stats {
        opacity: 1;
        border-radius: 2px;
        text-align: center;
        color: #01923F;
        background-color: white;
    }
    .chartjs-tooltip {
        opacity: 1;
        text-align: left;
        position: absolute;
        background: rgba(0, 0, 0, .7);
        color: white;
        border-radius: 3px;
        -webkit-transition: all .1s ease;
        transition: all .1s ease;
        pointer-events: none;
    }

    .chartjs-tooltip-key {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
    }

    #timeline-wrapper {
        position: relative;
        height: 2rem;
        text-align: center;
        z-index: 1;
    }

    .not-live-tracking {
        animation: blink 0.5s infinite;
        opacity: 0.25;
    }
    .see-through-30 {
        opacity: 0.3 !important;
    }
    .icon-disabled {
        opacity: 0.25;
        pointer-events: none;
    }

    @keyframes blink {
        0% { background-color: transparent; color: grey; opacity: 100%; }
        50% { background-color: transparent; color: red; opacity: 100%; }
    }
    @-webkit-keyframes blink {
        0% { background-color: transparent; color: grey; opacity: 100%; }
        50% { background-color: transparent; color: red; opacity: 100%; }
    }

    @keyframes warning {
        0% { background-color: transparent; color: white }
        50% { background-color: transparent; color: white }
        51% { background-color: #f8fc03; color: black}
        100% { background-color: #f8fc03; color: black}
    }
    @-webkit-keyframes warning {
        0% { background-color: transparent; color: white }
        50% { background-color: transparent; color: white }
        51% { background-color: #f8fc03; color: black}
        100% { background-color: #f8fc03; color: black}
    }

    @keyframes critical {
        0% { background-color: transparent }
        50% { background-color: transparent }
        51% { background-color: #fc0303 }
        100% { background-color: #fc0303 }
    }
    @-webkit-keyframes critical {
        0% { background-color: transparent }
        50% { background-color: transparent }
        51% { background-color: #fc0303 }
        100% { background-color: #fc0303 }
    }

    @keyframes failed {
        0% { background-color: transparent }
        50% { background-color: transparent }
        51% { background-color: #bababa }
        100% { background-color: #bababa }
    }
    @-webkit-keyframes failed {
        0% { background-color: transparent }
        50% { background-color: transparent }
        51% { background-color: #bababa }
        100% { background-color: #bababa }
    }

    .clickable {
      cursor: pointer;
      opacity: 1;
    }
    .clickable:hover {
      opacity: 1;
    }
    .header-icon{
      width:20px;
    }
    .show-move-cursor {
        cursor: move;
    }

    .dropdown {
        position: relative;
        display: inline-block;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: white;
        min-width: 140px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
    }

    .filter-dropdown-content {
        display: none;
        position: absolute;
        background-color: white;
        min-width: 140px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
    }

    .filter-dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

    .filter-dropdown-content a:hover {
        color: black;
        background-color: #ddd;
    }

    .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

    .dropdown-content a:hover {
        color: black;
        background-color: #ddd;
    }

    .fake-button {
        background: transparent;
        box-shadow: 0px 0px 0px transparent;
        border: 0px solid transparent;
        text-shadow: 0px 0px 0px transparent;
}

    .fake-button:hover {
        background: transparent;
        box-shadow: 0px 0px 0px transparent;
        border: 0px solid transparent;
        text-shadow: 0px 0px 0px transparent;
    }

    .fake-button:active {
        outline: none;
        border: none;
    }

    .fake-button:focus {
        outline: 0;
    }

    .show {
        display:block;
    }

    .submenu-link {
      font-size: small;
    }

    .submenu-link:hover {
      text-decoration: underline;
    }

    .frac-header {
      overflow: hidden;
      transition: all 0.6s ease;
    }

</style>

<script>
import ScatterChart from '../../scatterLineChart.js';
import moment from 'moment';
import GlobalFunctions from '../../GlobalFunctions.js';
const { isFalsy, isNullOrEmpty, isIterable, toast, timeDiff } = GlobalFunctions;
import ADXDownloadMixin from '../../mixins/ADXDownloadMixin.js';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import draggable from 'vuedraggable';
import _ from 'lodash';
import { Sketch } from 'vue-color';
import { debounce } from "debounce";
import { v4 as uuidv4 } from "uuid";

import JSONLocalStorageMixin from "../../mixins/JSONLocalStorageMixin";
import Notifications from 'vue-notification';
import TimeSeriesConfigModal from './TimeSeriesConfigModal.vue'
import TimeSeriesHistoryModal from './TimeSeriesHistoryModal.vue'

const HEADER_HEIGHT = 50;
const MILLIS_PER_HR = 3600000;
const INITIAL_WINDOW_HRS = 3; //hours of data to load on startup
const MINIMUM_DEFAULT_ZOOM_HOURS =  0.5; //minimum hours to set default chart range
const CUSTOM_X_AXIS_WINDOW_HRS = 3; //hours of data to load for custom x axis charts
const MAX_DATA_POINTS = 4000; //maximum number of data points on the graph
const MAX_DATA_POINTS_OUTSIDE_DOWNSAMPLING = 500; //maximum number of data points on the graph
const MAX_FILL_IN_PAGE_HRS = 3; //hours of data to retrieve at a time when panning or zooming out
const MIN_PAGE_SIZE_MILLIS = 5000; //minimum diff between start and end when getting more data
const MAX_SECTION_HEIGHT_PERCENTAGE = 1.2/4;
const DEFAULT_CONTROLS_SIZE= 30;
const DEFAULT_SELECTED_TRUCK = 1;
const DEFAULT_CHART_ITEM_WIDTH = 2;
const MAX_SECTION_COLUMNS = 4;
const HOVER_OUT_DELAY = 1000;
const HEADER_HEIGHT_VARIANCE = 200;
const DEFAULT_NUMBER_OF_TICKS = 10;
const MAX_INPUT_INTERVALS = 200000;
const AGGREGATION_OPTIONS = [
    { name: '1s', value: 'PT1S', durationInSec: 1 } ,
    { name: '2s', value: 'PT2S', durationInSec: 2 } ,
    { name: '5s', value: 'PT5S', durationInSec: 5 } ,
    { name: '10s', value: 'PT10S', durationInSec: 10 },
    { name: '30s', value: 'PT30S', durationInSec: 30 } ,
    { name: '1M', value: 'PT1M', durationInSec: 60 } ,
    { name: '5M', value: 'PT5M', durationInSec: 300 } ,
    { name: '10M', value: 'PT10M', durationInSec: 600 } ,
    { name: '30M', value: 'PT30M', durationInSec: 1800 }
];
const DEFAULT_AGGREGATION = '1s';
const ResizeSensor = require('css-element-queries/src/ResizeSensor');
const HEADER_HIDE_WIDTH = 1; //chart layout width where headers should be hidden
const MIN_VERTICAL_SPACE_FOR_DIV = 237;
const MIN_HORIZONTAL_SPACE_FOR_DIV = 275;

export default {
    mixins: [ADXDownloadMixin, JSONLocalStorageMixin],
    data() {
        return {
            visibleAnnotation: [],
            cancelToken: null,
            abortController: null,
            initSelectedTruck: null,
            chartCommentDragIntervalOpacity: 0.3,
            showPopoverZoom: false,
            showPopoverFiltering: false,
            showPopoverGhostPlot: false,
            isChartExportModalOpen: false,
            isChartConfigModalOpen: false,
            notificationOffsetAdded: false,
            savedJsonLocalStorage: null,
            disabledTsiStats: false,
            showAggregationAlert: false,
            suggestedIntervals: [],
            currentAggregationIndex: null,
            aggregationValue: 'PT1S',
            panZoomFetchTimeoutId: null,
            axiosCallData: [],
            seeThroughTimer: {
                timeId: null,
                value: 0
            },
            ghostPlotIndex: {
                time: null,
                diffMS: null
            },
            chartId: false,
            selectedChartItem: '',
            selectedSection: '',
            sectionIndex: 0,
            fontResizeObserver: null,
            newTextSizeSelected: false,
            isMinWidth: false,
            ghostSliderOptions: {
                dotSize: 14,
                width: 'auto',
                height: 4,
                contained: false,
                direction: 'ltr',
                data: null,
                dataLabel: 'label',
                dataValue: 'value',
                min: 0,
                max: 100,
                interval: 1,
                disabled: false,
                clickable: true,
                duration: 0.5,
                adsorb: false,
                lazy: false,
                tooltip: 'active',
                tooltipPlacement: 'top',
                tooltipFormatter: void 0,
                useKeyboard: false,
                keydownHook: null,
                dragOnClick: false,
                enableCross: true,
                fixed: false,
                minRange: void 0,
                maxRange: void 0,
                order: true,
                marks: false,
                dotOptions: void 0,
                dotAttrs: void 0,
                process: true,
                dotStyle: void 0,
                railStyle: void 0,
                processStyle: void 0,
                tooltipStyle: void 0,
                stepStyle: void 0,
                stepActiveStyle: void 0,
                labelStyle: void 0,
                labelActiveStyle: void 0
            },
            ghostLineWell: null,
            ghostLineStage: null,
            ghostLineChannels: [],
            lastLabelOverlapCalcTime:0,
            tsiAnalytics: [],
            isSelecting: false,
            isDisabledCheckbox: false,
            tsiAnalyticsIsDownloading: false,
            ghostLineSize: 1,
            ghostLineOpacity: 50,
            selectedGhostWellStageIntervalGroup: null,
            ghostPlotXMax: 0,
            ghostPlotEnabled: false,
            changeAxis: true,
            hoveringChart: false,
            chartFocused: false,
            disabledZoomMessage: false,
            inStatsMode: false,
            hideHeader: false,
            headerOffset: this.heightOffset,
            showChartHeaderConfig: true,
            sectionIndexes: [],
            sectionsHeight: 0,
            showTruck: false,
            selectedTruck: DEFAULT_SELECTED_TRUCK,
            truckOptions: [],
            currentChartMin: null,
            currentChartMax: null,
            enableThresholdAlerts: true,
            isSaving: false,
            initialized: false,
            dataReceived: false,
            chartConfiguration: null,
            chartConfigurationData: null,
            chartConfigEditMode: false,
            chartItemLayouts: {},
            localChartAxisOptions: {
                key: '',
                independentAxis: {
                    labelFontSize: 12,
                    tickFontSize: 12
                },
                dependantAxis: {
                    labelFontSize: 12,
                    tickFontSize: 12
                }
            },
            chartOptionsTempCopy: {},
            messageMarkers: [],
            datacollection: {},
            wellStageIntervals: [],
            wellStageIntervalsByActivityType: null,
            wellStageIntervalsActivityTypeOptions: [
                'Frac',
                'Wireline',
                'All'
            ],
            selectedWellStageIntervalsActivityTypeOption: 'Frac',
            selectedWellStageIntervalActivity: 'frac',
            selectedWellFilterDisplayString: '-- No Well Selected--',
            selectedWellFilterDisplayColor: null,
            selectedWellStageIntervalGroup: null,
            selectedWellStageInterval: '-1',
            wellFilterDisplayColor: null,
            currentWellStageFilterInterval: null,
            currentSelectedStage: 1,
            currentWellFilterDisplayString: null,
            currentWellFilterDisplayColor: null,

            selectedStageIndex: null,
            modalFlags: {
                editAxis: false,
                editAxisItem: null,
                editDataSource: false,
                editDataSourceItem: null
            },
            options: {
                normalized: true,
                parsing: false,
                responsive: true,
                maintainAspectRatio: false,
                animation: false,
                spanGaps: true,
                showLine: false,
                plugins: {
                    zoom: this.chartOptionsZoomPlugin(),
                    tsiAnalytics: this.chartOptionsTsiPlugin(),
                    decimation: {
                        enabled: true
                    }
                },
                annotation: {
                    annotations: [],
                    events: ['mouseover','mouseleave']
                },
                onResize: () =>
                {
                    this.handleChartLabels();
                    this.correctTimelineWidth();
                    this.currentChartWidth = this.getLineChart().width;
                },
                elements: {
                    point: {
                        radius: 0
                    }
                },
                tooltips: {
                    mode: this.getTooltipMode(),
                    enabled: false,
                    position: 'nearest',
                    intersect: false,
                    isVertical: false,
                    custom: function(tooltipModel) {
                        const isHovered = this._chart.canvas.matches(':hover');

                        // Tooltip Element
                        let tooltipEl = document.getElementById('chartjs-tooltip-' + this._chart.id);

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip-' + this._chart.id;
                            tooltipEl.classList.add('m-2');
                            tooltipEl.classList.add('chartjs-tooltip');
                            tooltipEl.innerHTML = '<table></table>';
                            document.body.appendChild(tooltipEl);
                        }



                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }

                        //Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            const titleLines = tooltipModel.title || [];
                            const bodyLines = tooltipModel.body.map(getBody);

                            let innerHtml = '<thead>';

                            titleLines.forEach(function(title) {
                                innerHtml += '<tr><th>' + title + '</th></tr>';
                            });
                            innerHtml += '</thead><tbody>';

                            //if multiple dataPoints come in with the same datasetIndex just use the first one
                            const renderedDatasets = [];
                            bodyLines.forEach(function(body, i) {
                                const datasetIndex = tooltipModel.dataPoints[i].datasetIndex;
                                if(!renderedDatasets[datasetIndex]) {
                                    const colors = tooltipModel.labelColors[i];
                                    const style = `background-color:${colors.backgroundColor};`;
                                    const span = '<span class="chartjs-tooltip-key" style="' + style + '"></span>';
                                    innerHtml += '<tr><td style="white-space:nowrap;">' + span + body + '</td></tr>';
                                    renderedDatasets[datasetIndex] = true;
                                }
                            });
                            innerHtml += '</tbody>';


                            const tableRoot = tooltipEl.querySelector('table');
                            tableRoot.innerHTML = innerHtml;
                        }

                        // `this` will be the overall tooltip
                        const position = this._chart.canvas.getBoundingClientRect();
                        const positionOffset = 10;

                        /* The following values are used to position tooltips in the actual Chart Area
                        Such that that they do not cover the axes. This is done by calculating the actual chart area,
                        which is done by calculations using chartArea and canvas positions from tooltips */

                        let chartAreaRight = position.width - this._chart.chartArea.right; //offset position right: (effectively this is the width of the axes if they are present)
                        let chartAreaLeft = this._chart.chartArea.left; //offset position of left of chart (if they are axes here)
                        let chartAreaBottom = position.height - this._chart.chartArea.bottom; //offset position of bottom of chart (if they are axes here)
                        let chartAreaTop = this._chart.chartArea.top; //offset position of top of chart (if they are axes here)

                        //width and height of chart area (the area that excludes the axes):
                        let chartAreaWidth = position.width - chartAreaRight - chartAreaLeft
                        let chartAreaHeight = position.height - chartAreaTop - chartAreaBottom

                        //HOROZONTAL CHART: if mouse position is on right side, tooltip is at top left.
                        //If mouse on left side, tooltip is at top right.
                        if (this._eventPosition.x > chartAreaWidth / 2) {
                            tooltipEl.style.right = 'unset';
                            tooltipEl.style.left = position.left + window.pageXOffset + chartAreaLeft + 'px';
                            tooltipEl.style.top =  position.top + window.pageYOffset + chartAreaTop + 'px';
                        } else {
                            tooltipEl.style.right = 'unset';
                            tooltipEl.style.left = position.right + window.pageXOffset - chartAreaRight - tooltipEl.offsetWidth + 'px';
                            tooltipEl.style.top = position.top + window.pageYOffset + chartAreaTop + 'px';
                        }

                        //VERTICAL CHART: if mouse position is at bottom, tooltip is at top right.
                        //If mouse at top, tooltip is at bottom right.
                        let isVertical = this._chart.tooltip._options.isVertical;
                        if (isVertical) {
                            if (this._eventPosition.y > chartAreaHeight / 2 ) {
                                tooltipEl.style.right = 'unset';
                                tooltipEl.style.left = position.right + window.pageXOffset  - chartAreaRight - tooltipEl.offsetWidth + 'px';
                                tooltipEl.style.top = position.top + window.pageYOffset + chartAreaTop + 'px';
                            } else {
                                tooltipEl.style.right = 'unset';
                                tooltipEl.style.left = position.right + window.pageXOffset - chartAreaRight - tooltipEl.offsetWidth + 'px';
                                tooltipEl.style.top = position.bottom + window.pageYOffset - chartAreaBottom - tooltipEl.offsetHeight + 'px';
                            }
                        }
                        let userId = document.querySelector('meta[name="user-id"]').getAttribute('content');
                        let tooltipFontSize = localStorage.getItem('tooltipFontSize' + userId);
                        if(tooltipFontSize) {
                            if(tooltipFontSize === 'large') {
                                tooltipEl.style.fontSize = '16px';
                                tooltipEl.style.padding = '8px 12px';
                            } else if(tooltipFontSize === 'x-large') {
                                tooltipEl.style.fontSize = '20px';
                                tooltipEl.style.padding = '12px 16px';
                            }else{
                                tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                                tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                            }
                        }
                        // Display, position, and set styles for font
                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                        tooltipEl.style.pointerEvents = 'none';

                        const isTooltipArea = document.getElementById('tooltip-area-'+ this._chart.id);
                        if(isTooltipArea) {
                            const positionRes = isTooltipArea.getBoundingClientRect();
                            tooltipEl.style.top = positionRes.top + window.scrollY -positionOffset  + 'px';
                            tooltipEl.style.position = 'absolute';
                            tooltipEl.style.left = positionRes.left -positionOffset + 'px';
                            tooltipEl.style.right = 'unset';
                        }
                    },
                    callbacks: {
                        label: this.formatTooltip,
                        title: this.formatTooltipTitle
                    }
                },
                scales: {
                    yAxes: [], //yAxes is built dynamically just before render
                    xAxes: []  //xAxes is built dynamically just before render
                },
                legend: {
                    display: false,
                    position: 'top',
                    onClick: function(event, legendItem) {},
                    labels: {
                        boxWidth: 2,
                        filter: this.filterLegendItem,
                        fontColor: 'white'
                    }
                }
            },
            hasAuthError: false,
            initialChartConfigData: {},
            initialChartConfiguration: {},
            initialChartItemLayouts: {},
            initialUsedSections: {},
            initialSelectedTags: {},
            activeData: [],
            shadingData: [],
            shadingChecked: {},
            paramChecked: {},
            sectionChecked: {},
            rawdata: [],
            ghostrawdata: [],
            datawindow: {},
            usedSections: {},
            usedSectionsIDs: [],
            sectionLabel: [],
            columnCounts: [],
            tagToChartIndex: {},
            ghostTagToChartIndex: {},
            refreshIntervalID: null,
            followingUpdates: true,
            isFilterPresent: false,
            downloadingDataForTagIndex: [],
            isDownloadingGhostData: false,
            isStreamingDisconnected: false,
            zoomMode: 'x', //valid options are x,y,xy
            panMode: 'x', //valid options are x,y,xy,null
            dragToZoom: true,
            defaultZoomWindowHrs: INITIAL_WINDOW_HRS, //hrs
            valueAxesHasBeenSet: false,
            customDateAxisHasBeenSet: false,
            useCustomScaleOnDateAxis: false,
            dateAxisTag: null,
            customXDirection: 0, //1 is positive slope, -1 is negative slope
            onExpandButtonPressCallCount: 0,
            downsampleWindow: null, //the timestamps between which we run the downsampling, anything after this window is not downsampled
            isActiveChart: true,
            hideCommentsTimeline: false,
            currentDataMaxTimestamp: 0,
            selectedTags: {},
            annotationHoverStyle: {
                'position': 'absolute',
                'top': '10px',
                'right': '10px',
                'font-size': '0.8rem'
            },
            stageStartMarkers: {},
            stageStartTime: null,
            ghostPlotTargetTimeStart: null,
            wellStageLegendDisplay: {
                show: false,
                label: '',
                color: '#fff'
            },
            axiosInstance: null,
            scrubberIsCompact: true,
            scrubberIsMoving: false,
            zoomHistory: [],
            zoomHistoryValueAxes: [],
            isExportModalFetchingData: false,
            editYAxisValidator: {
                minMaxError: false,
                noMinError: false,
                noMaxError: false,
                noAxisLabelError: false,
                noNumberOfTicksError: false,
                axisKey: null
            },
            forceRefreshStaticKey: 0, //increment to re-render template elements where this is part of the key
            presetColors: ['#D9251C', '#FFF500', '#281670', '#EA891B', '#01923F', '#7F2454', '#DE5F24', '#F5B900', '#69B92E', '#055D70', '#5A2064', '#A92740', '#000000', '#666666', '#AAAAAA', '#FFFFFF'],
            showAxisColorPicker: false,
            currentChartWidth: 0,
            innerPopoversOpened: [], //list of open inner popovers
            lastChartDrawTime: null,
            renderBlocked: false
        };
    },
    computed: {
        syncedChartsTooltip() {
            return this.areChartsSynced ? 'Cannot be used while sync charts is enabled' : null;
        },

        showMultiFracSelect() {
            return this.isMultiFrac && !this.showSuperSmallHeader() && this.headerStyle == 'frac' && this.shouldDrawGraph && !this.isNotDashboard;
        },
        isMultiFrac() {
            return this.dashboardData.isMultiFrac;
        },
        isAnyModalOrPopoverOpen() {
            return this.isChartExportModalOpen || this.isChartConfigModalOpen || this.showPopoverZoom || this.isFilteringPopoverOpen || this.isGhostPopoverOpen;
        },
        areAllModalsAndPopoversClosed() {
            return !this.isChartExportModalOpen && !this.isChartConfigModalOpen && !this.showPopoverZoom && !this.isFilteringPopoverOpen && !this.isGhostPopoverOpen;
        },
        showHeader: function() {
            return this.layout == null || this.layout.w > HEADER_HIDE_WIDTH;
        },
        configurationDataYAxesClone: function() {
            return _.cloneDeep(this.chartConfigurationData.chartYAxes);
        },
        headerSelectStyle: function() {
            return {
                'header': true
            };
        },
        scrubberHeight: function() {
            return this.scrubberIsCompact? '100px' : '160px';
        },
        isElementTransparent: function() {
            return this.seeThroughTimer.value > 0 ? 'see-through-30' : '';
        },
        getGhostPlotIndexStart: {
            get() {
                return moment(this.ghostPlotIndex.time,['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DDTHH:mm:ss']).format('YYYY-MM-DDTHH:mm:ss');
            },
            set(newVal) {
                this.ghostPlotIndex.time = moment(newVal, ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DDTHH:mm:ss']).format('YYYY-MM-DDTHH:mm:ss');
            }
        },
        userDashboardItemKeyId: function() {
            if (this.isNotDashboard) {//wireline-op page
                return this.dashboardData.userId+'-wireline-op-page-timeseriesChart';
            } else {
                return this.dashboardData.userId + this.dashboardData.selectedDashboard.id + this.dashboardItem.i;
            }
        },
        sectionWidth: function () {
            if (this.chartConfiguration.hideChart == 1) {
                return 'width: 100%';
            }
            const controlsSize = this.chartConfigurationData.controlsSize ? this.chartConfigurationData.controlsSize : DEFAULT_CONTROLS_SIZE;
            if(this.controlsPosition == 'top' || this.controlsPosition == 'bottom') {
                return `width:100%`;
            } else {
                return `width:${controlsSize}%`;
            }
        },
        sectionFill: function () {
            let usedSectionsCount = this.chartConfigurationData.orderedSections.length;
            usedSectionsCount = usedSectionsCount > this.MAX_SECTION_COLUMNS? this.MAX_SECTION_HEIGHT_PERCENTAGE : usedSectionsCount;
            return `min-width:${100/usedSectionsCount}%`; //min-width has to be used here to bypass shrink/grow of a flex child element
        },
        chartWidth: function () {
            if (this.chartConfiguration.hideChart == 1) {
                return 'width: 0%';
            }
            const controlsSize = this.chartConfigurationData.controlsSize ? this.chartConfigurationData.controlsSize : DEFAULT_CONTROLS_SIZE;
            if(this.controlsPosition == 'top' || this.controlsPosition == 'bottom') {
                return `width:100%`;
            } else {
                return `width:${100-controlsSize}%`;
            }
        },
        sectionsArray: {
            get() {
                return Object.values(this.usedSections);
            },
            set(value) {
                this.usedSections = Object.assign({}, value);
            }
        },
        mainGridClass: function () {
            if(this.controlsPosition == 'top' || this.controlsPosition == 'bottom') {
                return { 'd-flex': true, 'flex-row': true};
            } else {
                return {};
            }
        },
        daggableClass: function () {
            if(this.controlsPosition == 'top' || this.controlsPosition == 'bottom') {
                return {
                    'd-flex': true,
                    'flex-row': true,
                    'flex-wrap': true,
                    'w-100': true
                };
            } else {
                return { 'w-100': true };
            }
        },
        chartItemClass: function () {
            if(this.controlsPosition == 'top' || this.controlsPosition == 'bottom') {
                return { 'col-3': true,  'px-0': true};
            } else {
                return {};
            }
        },
        editMode: function() {
            if (this.isNotDashboard) {
                return false;
            }
            return this.dashboardData.editMode;
        },
        isEditable: function() {
            if (this.isNotDashboard && ( this.dashboardData.isAdmin || this.dashboardData.isCompanyAdmin) ) {
                return true;
            } else if( this.dashboardData?.selectedDashboard?.is_writable || ( this.dashboardData?.isAdmin || this.dashboardData?.isCompanyAdmin || this.dashboardData?.isIwsUser ) ) {
                return true;
            } else {
                return false;
            }
        },
        styleLeftWhenFilterSet: function() {
            return {
                'col-2': this.currentWellStageFilterInterval != null,
                'px-0': true
            };
        },
        headerStyle: function() {
            //Should check both the configs style and if any defaults are set
            return this.headerOverride != null ? this.headerOverride : (this.chartConfiguration?.header_style ?? "frac");
        },
        chartData: function() {
            return this.datacollection;
        },
        shouldDrawGraph: function() {
            if(this.isNotDashboard) {
                return true;
            } else {
                return !this.chartConfiguration.hideChart;
            }
        },
        isDateAxisHidden: function() {
            return this.chartConfiguration.isDateAxisHidden;
        },
        wirelineSystems: function() {
            return this.dashboardData.wirelineSystems || [];
        },
        fracSystems: function() {
            return this.dashboardData.fracSystems || [];
        },
        targetSystem: function() {
            switch(this.headerStyle){
                case "frac":
                    return this.fracSystems;
                case "wireline":
                    return this.wirelineSystems;
                default:
                    //No system
                    return [];
            }
        },
        controlsPosition: function() {
            return this.chartConfiguration.controlsPosition;
        },
        rightSideMsOffset: function() {
            return this.chartConfiguration.rightSideMsOffset;
        },
        pinRightSideMsOffset: function() {
            return this.chartConfiguration.pinRightSideMsOffset;
        },
        defaultZoomAction: function() {
            return this.chartConfiguration.defaultZoomAction;
        },
        wells: function() {
            return this.dashboardData.wells;
        },
        jobNumber: function() {
            return this.dashboardData.jobNumber;
        },
        jobID: function() {
            return this.dashboardData.jobId;
        },
        userID: function() {
            return this.dashboardData.userId;
        },
        componentSpecificPermissions: function() {
            return this.dashboardData.componentSpecificPermissions;
        },
        casingPressures: function() {
            return this.dashboardData.pressure.casingPressures;
        },
        frac: function() {
            const frac = JSON.parse(JSON.stringify(this.dashboardData.frac));
            frac.items = this.tags
                .reduce((obj, key) => ({ ...obj, [key]: frac.items[key] }), {});
            return frac;
        },
        jobHourOffset: function() {
            return this.dashboardData.jobHourOffset;
        },
        customer: function() {
            return this.dashboardData.customer;
        },
        thresholdAlerts: function() {
            return this.dashboardData.eventAlerts;
        },
        thresholdAlertsHistory: function() {
            if(this.enableThresholdAlerts) {
                return this.dashboardData.eventAlertHistory;
            } else {
                return [];
            }
        },
        chartcomments: function() {
            return this.dashboardData.chartcommentsList;
        },
        isAdmin: function() {
            return this.dashboardData.isAdmin;
        },
        isCompanyAdmin: function() {
            return this.dashboardData.isCompanyAdmin;
        },
        eventActivities: function() {
            return this.dashboardData.eventActivities;
        },
        eventReasons: function() {
            return this.dashboardData.eventReasons;
        },
        stepDescriptions: function() {
            return this.dashboardData.stepDescriptions;
        },
        nptOutcomes: function() {
            return this.dashboardData.nptOutcomes;
        },
        customerVendors: function() {
            return this.dashboardData.customerVendors;
        },
        contractors: function() {
            return this.dashboardData.contractors;
        },
        eventActivityEventReasons: function() {
            return this.dashboardData.eventActivityEventReasons;
        },
        jobEnd: function() {
            return this.dashboardData.jobEnd;
        },
        latestDataCollection: function() {
            return this.dashboardData.latestDataCollection;
        },
        globalChartSynced: function() {
            return this.dashboardData.globalChartSynced;
        },
        well: function() {
            if(this.headerStyle === 'wireline') {
                if (typeof this.dashboardData.indexWireline === 'function') {
                    const indexes = this.dashboardData.indexWireline(this.selectedTruck);
                    const index = indexes.length > 0 ? indexes[0] : -1;
                    return this.wells[index];
                }
                return this.wells[this.dashboardData.indexWireline];
            } else if(this.headerStyle === 'frac') {
                if (typeof this.dashboardData.indexFrac === 'function') {
                    const indexes = this.dashboardData.indexFrac(this.selectedTruck);
                    const index = indexes.length > 0 ? indexes[0] : -1;
                    return this.wells[index];
                }
                return this.wells[this.dashboardData.indexFrac];
            } else {
                return null;
            }
        },
        isWirelinePlaced: function() {
            for (let i = 0; i < this.wells.length; i++) {
                if(!this.isMultiWireline){
                    if (this.wells[i].activity == 'wireline') {
                        return true;
                    }
                }else{
                    if (this.wells[i].activity == 'wireline' && this.wells[i].activityData?.service_id == this.selectedTruck) {
                        return true;
                    }
                }
            }

            return false;
        },
        isFracPlaced: function() {
            for (let i = 0; i < this.wells.length; i++) {
                if(!this.isMultiFrac){
                    if (this.wells[i].activity == 'frac') {
                        return true;
                    }
                }else{
                    if (this.wells[i].activity == 'frac' && this.wells[i].activityData?.service_id == this.selectedTruck) {
                        return true;
                    }
                }
            }
            return false;
        },
        ghostPlotSettingsIncomplete: function() {
            return this.ghostLineWell == null || this.ghostLineStage == null || this.ghostLineChannels.length == 0;
        },
        usedTagsDisplay: function() {
            let displayTags;

            displayTags = this.usedTags.map(tag => {
                return {
                    name: tag.name,
                    priorityName: this.getDisplayTagName(tag.name)
                };
            });

            return displayTags.sort(this.sortTagsByPriorityName);
        },
        selectedGhostWellStageIntervalGroupSanitized: function() {
            //don't want user to be able to select stages that have no endTime for the ghost plot
            const retValue = {};
            for (const stageNumber in this.selectedGhostWellStageIntervalGroup) {
                if(this.selectedGhostWellStageIntervalGroup[stageNumber].endTime != null) {
                    retValue[stageNumber] = this.selectedGhostWellStageIntervalGroup[stageNumber];
                }
            }

            return retValue;
        },
        isDownloadingData: function() {
            for(const tagIndex in this.downloadingDataForTagIndex) {
                if(this.downloadingDataForTagIndex[tagIndex]) {
                    return true;
                }
            }

            this.isDisabledCheckbox = false; //enable checkbox for data sections
            return false;
        },
        chartStyle: function() {
            const commentsOffset = this.isCommentsTimelineHidden ? 0 : 30;
            const scrubberOffset = this.isScrubberHidden ? 0 : this.scrubberIsCompact ? 80 : 124;

            const margin = 10;
            let controlsOffset = 0;
            const controlsSize = this.chartConfigurationData.controlsSize ? this.chartConfigurationData.controlsSize : DEFAULT_CONTROLS_SIZE;

            //attach to the following variables so this style gets updated
            const onExpandButtonPressCallCount = this.onExpandButtonPressCallCount;
            const downloadingData = this.isDownloadingData;

            if(this.controlsPosition == 'top' || this.controlsPosition == 'bottom') {
                if(this.$refs.sectionsDiv) {
                    controlsOffset = this.$refs.sectionsDiv.offsetHeight;
                }
            } else {
                return { height: (this.height -  this.headerOffset) - (commentsOffset + scrubberOffset + margin) + 'px', width: '100%', position: 'relative'};
            }


            if(this.useCustomScaleOnDateAxis) {
                return {height: this.height - (this.headerOffset || 0) - controlsOffset + 'px', width: '100%', position: 'relative'};
            }

            let height = 0;
            //showing the comments timeline bar so use the comments offset

            if(this.sectionsHeight) {
                height = this.sectionsHeight > this.sectionsComponentHeight? (this.bodyHeight - (this.sectionsComponentHeight + commentsOffset + scrubberOffset + margin )) : ((this.bodyHeight - this.sectionsHeight)  - (commentsOffset + scrubberOffset + margin));
            } else {
                height = this.bodyHeight * ((100 - controlsSize)/100) - (commentsOffset + scrubberOffset + margin);
            }


            return {height: height + 'px', width: '100%', position: 'relative'};
        },
        bodyHeight: function() {
            return (this.height - this.headerOffset);
        },
        readoutControlsSectionsStyle: function() {
            const controlsSize = this.chartConfigurationData.controlsSize ? this.chartConfigurationData.controlsSize : DEFAULT_CONTROLS_SIZE;
            const controlWidth = this.chartConfiguration.hideChart == 1? 'width:100%' : controlsSize + 'vw';

            if(this.controlsPosition == 'left' || this.controlsPosition == 'right' || (!this.shouldDrawGraph)) {
                return { height: 'inherit', width: controlWidth,  overflowY: 'auto', maxHeight: (this.height - this.headerOffset) + 'px'};
            } else {
                return { maxHeight: this.sectionsComponentHeight+ 'px', overflowY: 'auto'};
            }
        },
        sectionsComponentHeight: function() {
            const controlsSize = this.chartConfigurationData.controlsSize ? this.chartConfigurationData.controlsSize : DEFAULT_CONTROLS_SIZE;
            return this.bodyHeight*(controlsSize/100);
        },
        areChartsSynced: function() {
            if (this.isNotDashboard) { //non-dashboard pages default to no chart syncing
                return false;
            }
            return this.dashboardData.selectedDashboard.are_charts_synced;
        },
        currentSelectedStageValue: function() {
            return this.currentSelectedStage;
        },
        areTooltipsSynced: function() {
            if (this.isNotDashboard) {
                return true;
            }
            return this.dashboardData.selectedDashboard.are_tooltips_synced;
        },
        isCommentsTimelineHidden: function() {
            if (this.chartConfiguration.isVertical || this.hideCommentsTimeline || this.useCustomScaleOnDateAxis) {
                return true;
            }
            return false;
        },
        isScrubberHidden: function() {
            if (this.chartConfiguration.isVertical || this.useCustomScaleOnDateAxis) {
                return true;
            }
            return false;
        },
        allTags: function() {
            //return all tags that have a valid tag name
            return this.dashboardData.tags.filter(tag=>tag.name.length > 0);
        },
        tagsSortedByName: function() {
            const allTagsCopy = [];

            this.allTags.forEach(tag => {
                tag.priorityName = this.getDisplayTagName(tag.name);
                allTagsCopy.push(tag);
            });

            return allTagsCopy.sort(this.sortTagsByPriorityName);
        },
        usedTags: function() {
            const self = this;
            //return an array of all channels in use on the chart, active or not
            const usedTags = [];
            this.chartConfigurationData.orderedSections.forEach(section => {
                section.orderedChartItems.forEach(chartItem => {
                    usedTags.push({
                        name: chartItem.tagName,
                        priorityName: self.getPriorityNameForTag(chartItem.tagName),
                        isActive: self.paramChecked[chartItem.tagName] || false
                    });
                });
            });
            return usedTags.sort(this.sortTagsByPriorityName);
        },
        lineChartId() {
            return 'lineChart'+uuidv4()
        }
    },
    props: {
        item: {
            type: Object,
            required: false
        },
        isContinuousFrac: {
            type: [Boolean, Number],
            default: false,
            required: false
        },
        heightOffset: {
            type: Number,
            required: true
        },
        height: {
            type: Number,
            required: true
        },
        width:{
            type: Number,
            required: true
        },
        hideControlsColumn: {
            type: Boolean,
            required: false
        },
        hideFracAssignmentRow: {
            type: Boolean,
            required: false
        },
        modalRefKey: {
            type: String,
            required: true
        },
        dashboardData: {
            type: Object,
            required: true
        },
        dashboardItem: {
            type: Object,
            required: true
        },
        layout: {
            type: Object,
            required: false
        },
        isNotDashboard: {
            type: Boolean,
            required: false,
            default: false
        },
        isMultiWireline: {
            type: Boolean,
            required: false,
            default: false
        },
        wirelineSystemNumber: {
            type: Number,
            required: false,
            default: null
        },
        headerOverride: {
            type: String,
            required: false,
            default: null
        },
        defaultChartColumnCount: {
            type: Number,
            required: false
        },
        onSyncedViewChanged: {
            type: Function,
            required: false
        },
        componentsWithTruck: {
            type: Object,
            required: false
        },
        signalRConnected: {
            type: Boolean,
            required: true
        },
        iwsUser: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        ScatterChart,
        draggable,
        VueSlider,
        'sketch-picker': Sketch,
        TimeSeriesConfigModal,
        TimeSeriesHistoryModal
    },
    created() {
        this.aggregationOptions = AGGREGATION_OPTIONS;
        this.hoverOverTime = null;
        if(this.layout?.w <= 2) {
            this.isMinWidth = true;
        }
    },
    methods: {
        hexToRgbA(hex, opacity=1) {
            if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
                let c = hex.substring(1).split('');
                if (c.length == 3)
                    c= [c[0], c[0], c[1], c[1], c[2], c[2]];
                c = '0x'+c.join('');

                return `rgba(${[(c>>16)&255, (c>>8)&255, c&255].join(',')},${opacity})`;
            }

            throw new Error('Bad Hex');
        },
        onEditChartItemPressed(fracName, selectedSectionItem, index) {
            this.selectedChartItem = fracName;
            this.selectedSection = selectedSectionItem;
            this.sectionIndex = index;
        },
        observeDataTextHeight() {
            const self = this;
            this.fontResizeObserver = new ResizeObserver(function() {
                if (!self.selectedChartItem) {
                    // no item has been selected yet
                    return;
                }

                let [chartHeight, chartWidth] = self.getDimensions('chartitem');
                let [dataHeight, dataWidth] = self.getDimensions('dataFont');
                let [labelHeight, labelWidth] = self.getDimensions('labelFont');
                let totalHeight = labelHeight + dataHeight;
                let currentSelectedItem = self.selectedSection[self.sectionIndex];
                let resize = false;
                let sectionAmount = 0;

                if ((totalHeight> chartHeight) && self.newTextSizeSelected) {
                    // check if text overflows chart vertically
                    currentSelectedItem.h += 1;
                    resize = true;
                } else if ((totalHeight < chartHeight) && self.newTextSizeSelected) {
                    // decrease height if text is too small
                    let layoutSectionHeight = Math.ceil(chartHeight/currentSelectedItem.h);
                    sectionAmount = Math.round(totalHeight/layoutSectionHeight);
                    currentSelectedItem.h = totalHeight < layoutSectionHeight ? sectionAmount : currentSelectedItem.h;
                    resize = true;
                }

                // check if text overflows chart horizontally
                let maxWidth = Math.max(dataWidth, labelWidth);
                let layoutSectionWidth = Math.ceil(chartWidth/currentSelectedItem.w);
                if ((maxWidth > chartWidth) && self.newTextSizeSelected) {
                    // calculate how many sections the text will take up
                    sectionAmount = Math.ceil(maxWidth/layoutSectionWidth);
                    currentSelectedItem.w = sectionAmount;
                    resize = true;
                }

                if (resize) {
                    // trigger resize event to update chart and prevent overlapping
                    self.newTextSizeSelected = false;
                    window.dispatchEvent(new Event('resize'));
                }
            });

            this.sectionIndexes.forEach((sectionData) => {
                this.usedSections[sectionData.index].forEach((fracItem) => {
                    const dataFontItem = document.getElementById(`dataFont-${fracItem.name}`);
                    const labelFontItem = document.getElementById(`labelFont-${fracItem.name}`);
                    if (dataFontItem && labelFontItem) {
                        this.fontResizeObserver.observe(dataFontItem);
                        this.fontResizeObserver.observe(labelFontItem);
                    }
                });
            });
        },
        getDimensions(id) {
            let item = document.getElementById(`${id}-${this.selectedChartItem}`);

            if (!item) {
                // item has not been rendered yet
                return [0, 0];
            }

            let height = item.offsetHeight;
            let width = item.scrollWidth;
            if (id === 'chartitem') {
                width = item.clientWidth;
            }

            return [height, width];
        },
        setFracItems(tags) {
            const frac = this.dashboardData.frac;
            frac.items = tags
                .reduce((obj, key) => ({ ...obj, [key]: frac.items[key] }), {});
        },
        isZoomPopoverOpen($event) {
            this.showPopoverZoom = this.isPopoverOpen($event);
        },
        isFilteringPopoverOpen($event) {
            this.showPopoverZoom = this.isPopoverOpen($event);
        },
        isGhostPopoverOpen($event) {
            this.showPopoverGhostPlot = this.isPopoverOpen($event);
        },
        isPopoverOpen($event) {
            if ($event.type === 'shown') {
                return true;
            }
            return false;
        },
        isGhostPlotDataLoaded: function() {
            let ghostPlotDataPresent = false;
            this.ghostrawdata.forEach(tag => {
                if (tag?.data && tag.data.length > 0) {
                    ghostPlotDataPresent = true;
                }
            });
            return ghostPlotDataPresent;
        },
        showNoGhostDataNotification() {
            this.$notify({
                group: `ghostData-${this._uid}`,
                title: 'No Ghost Plot Data',
                text: 'There is no ghost plot data for the selected filters. Please make new selections and try again.',
                type: 'warn',
                duration: 5000,
                closeOnClick: true,
                ignoreDuplicates: true
            });
            let offsetHeight = 50;
            this.adjustNotificationOffsetById('ghostplotNotification', offsetHeight);
        },
        adjustNotificationOffsetById(notificationId, offsetHeight) {
            const notification = this.$refs[notificationId];
            let currentTop;
            if (notification) {
                if (this.notificationOffsetAdded) {
                    // subtract offset height because it was already added previously
                    currentTop = parseInt(notification.$el.style.top, 10) - offsetHeight;
                    let newTop = currentTop + offsetHeight;
                    notification.$el.style.top = `${newTop}px`;
                } else {
                    currentTop = parseInt(notification.$el.style.top, 10);
                    let newTop = currentTop + offsetHeight;
                    notification.$el.style.top = `${newTop}px`;
                    this.notificationOffsetAdded = true;
                }
            }
        },
        chartOverlayDraw(startE, endE) {
            const chart = this.getLineChart();
            const canvas = document.getElementById(`overlay-chart-comment${this._uid}`);
            const ctx = canvas.getContext('2d');
            const element = chart.canvas.getBoundingClientRect();

            canvas.height = chart.chartArea.bottom;
            canvas.width = (chart.chartArea.right - chart.chartArea.left);
            canvas.style.left = chart.chartArea.left + 'px';
            ctx.clearRect(0, 0, canvas.width, canvas.height);


            if(startE && endE) {
                this.followingUpdates = false;
                this.disabledTsiStats = true;
                this.closeTsiAnalytics();
                chart.options.plugins.tsiAnalytics.enabled = false;
                chart.options.plugins.zoom.zoom.drag = false;
                chart.options.plugins.zoom.pan.enabled = false;
                chart.options.plugins.tsiAnalytics.clear = false;
                ctx.globalAlpha = this.chartCommentDragIntervalOpacity;
                ctx.fillStyle = 'rgb(225,225,225)';
                const width = Math.abs(startE.clientX - endE.clientX);
                ctx.fillRect((startE.clientX - (element.left + chart.chartArea.left)), 0, width, canvas.height);
            } else {
                this.disabledTsiStats = false;
                chart.options.plugins.tsiAnalytics.enabled = false;
                chart.options.plugins.zoom.zoom.drag = this.dragToZoom;
                chart.options.plugins.zoom.pan.enabled = !this.dragToZoom;
            }
        },
        setDefaultFilterStageNumber() {
            if (Object.keys(this.selectedWellStageIntervalGroup).length == 0) {
                return null;
            }
            const selectedWellStageIntervalArray = Object.values(this.selectedWellStageIntervalGroup);
            const defaultStageFilterIndex =  selectedWellStageIntervalArray.findIndex(stageFilter =>
                stageFilter?.startTime && stageFilter?.endTime && stageFilter?.stageNumber);

            this.selectedWellStageInterval = selectedWellStageIntervalArray[defaultStageFilterIndex];
            return defaultStageFilterIndex;
        },
        disableFilterOption(interval, index) {
            const last = Object.values(this.selectedWellStageIntervalGroup).findLast(obj => true);

            if (interval.startTime == null || (interval.endTime == null && interval.stageNumber !== last.stageNumber)) {
                return true;
            }
            return false;
        },
        setIsSelecting(selecting) {
            this.isSelecting = selecting;
        },
        setIsActiveChart(isActive) {
            this.isActiveChart = isActive;
            const chart = this.getLineChart();
            if(chart && chart.options.plugins.tsiAnalytics.isChartActive !== isActive) {
                chart.options.plugins.tsiAnalytics.isChartActive = isActive;
                chart.update();
            }
        },
        toggleInnerPopover(ref) {
            if (!this.innerPopoversOpened.includes(ref))
            {
                this.$refs[ref].$emit('open');
                this.innerPopoversOpened.push(ref);
            }
            else
            {
                this.$refs[ref].$emit('close');
                this.innerPopoversOpened.splice(this.innerPopoversOpened.indexOf(ref), 1);
            }
        },
        isFeatureFlagged(featureString) {
            return GlobalFunctions.isFeatureFlagged(featureString);
        },
        validateAxisTickNumber(axis) {
            if (typeof(axis.ticks) === 'number' && axis.ticks <= 2) {
                axis.ticks = 2; // min value
            } else if(!axis.ticks || axis.ticks === '') {
                axis.ticks = 10; //no value, set default
            }
        },
        getYAxes: function(position='all') {
            const chart = this.getLineChart();
            if (chart) {
                if (position === 'all') {
                    return Object.values(chart.scales).filter(scale => scale.id !== 'date-axis' && scale.id !== 'custom-date-axis' && scale.hidden);
                } else {
                    return Object.values(chart.scales).filter(
                        scale => scale.id !== 'date-axis' &&
                        scale.id !== 'custom-date-axis' && scale.position == position &&
                        scale._labelItems !== null);//only returns visible axes, skips 'auto' hidden or disabled axes
                }
            }
        },
        onAggregationChange: function(aggregation, optionIndex) {
            const selectedAggregation = this.aggregationOptions[optionIndex];
            this.currentAggregationIndex = optionIndex;
            this.aggregationValue = selectedAggregation.value;
            localStorage.setItem('aggregation', aggregation);
            //on window changed will refresh the chart.
            this.onWindowChanged(this.getLineChart());
        },
        getPriorityNameForTag: function(tagName) {
            //current priority order to return is: 1. customer Tagname, 2. friendly Tagname, 3. tagName
            const tag = this.allTags.find(tag => tag.name === tagName);

            if (!tag) { //if requested tag does not exist, just return back the given tagName
                return tagName;
            }
            //if tag does exist return a tagname in priority order
            if (tag?.isWellheadTag) {
                const chartItem = this.getChartItem(tagName);
                return (tag?.wellheadTagName?.prefix ?? '')  + ' ' + (tag?.wellheadTagName?.suffixTitle ?? '') + ' ' + (chartItem?.suffixName ?? '');
            }
            else if (tag?.customerTagname && tag?.customerTagname?.name) {
                return tag.customerTagname.name;
            } else if (tag?.friendlyTagname) {
                return tag.friendlyTagname;
            } else {
                return tag?.name;
            }
        },
        isWellheadTag(tagName) {
            const tag = this.allTags.find(tag => tag.name === tagName);
            if (tag) {
                return tag?.isWellheadTag;
            }
        },
        stepGhostPlotIndexTime(type) {
            switch(type) {
            case 'dec':
                this.ghostPlotIndex.time = moment(this.ghostPlotIndex.time).subtract(1, 'minutes').format('YYYY-MM-DDTHH:mm:ss');
                break;
            case 'inc':
                this.ghostPlotIndex.time = moment(this.ghostPlotIndex.time).add(1, 'minutes').format('YYYY-MM-DDTHH:mm:ss');
                break;
            }
        },
        zoomDisabledMessage: function(event) {
            if (this.hoveringChart && !this.chartFocused) {
                this.disabledZoomMessage = true;
                setTimeout(() => this.disabledZoomMessage = false, 3000);
            }
        },
        enableZoomOnFocus: function() {
            this.chartFocused = true;
            this.updatePanAndZoomOptions();
        },
        disableZoomOnBlur: function() {
            this.chartFocused = false;
            this.updatePanAndZoomOptions();
        },
        updatePanAndZoomOptions: function() {
            if(!this.useCustomScaleOnDateAxis) {
                const chart = this.getLineChart();
                chart.options.plugins.zoom.zoom.enabled = this.inStatsMode ? false : !this.isDownloadingData && (this.chartFocused || this.dragToZoom);
                chart.options.plugins.tsiAnalytics.enabled = this.isDownloadingData ? false : this.inStatsMode;
                chart.options.plugins.zoom.pan.enabled = this.dragToZoom || this.inStatsMode ? false : !this.isDownloadingData;
                chart.update();
            }
        },
        updateTSIOptions(options) {
            this.jsonLocalStorage.chartTSIOptions = options;
            this.jsonLocalStorage.save();
        },
        onScrubberBrushMoved(from, to, timezone) {
            this.$refs.chartScrubber.setBrushMinMax(from, to);
            if (this.areChartsSynced) {
                this.syncChartMovement(this.getLineChart().id, from, to, true);
            }
            const chart = this.getLineChart();
            if(chart) {
                this.setCurrentChartMinMax(chart);
                this.updateDownsampleWindow();
                this.closeTsiAnalytics(true);
            }
            this.refreshChartDataWithInterval(moment(from).valueOf(), moment(to).valueOf());
            this.scrubberIsMoving = false;
        },
        onScrubberBrushMoving(from, to) {
            this.scrubberIsMoving = true;
        },
        onScrubberIsCompactChanged(isCompact) {
            this.scrubberIsCompact = isCompact;
        },
        onCommentLineHover(positionCallback) {
            this.createChartHoverEvent(positionCallback.timestamp, positionCallback.onHover, positionCallback.xMousePosition);
        },
        createChartHoverEvent(timestamp, hoverStatus, xMousePosition) {
            const chart = this.getLineChart();
            const isHovered = chart.canvas.matches(':hover'); //is chart canvas hovered
            if(hoverStatus) {
                chart.options.tooltips.mode = 'x';
            } else {
                chart.options.tooltips.mode = this.getTooltipMode();
            }
            //make sure tooltip does not show on timeline if dataset domain does match timeline
            let customTooltipOpacity = document.getElementsByClassName('chartjs-tooltip')
            if (chart.tooltip?._data?.datasets[0]?.data) {
                let lengthOfTooltipData = chart.tooltip._data.datasets[0].data.length;
                if (timestamp > chart.tooltip._data.datasets[0].data[lengthOfTooltipData-1]?.x) {
                    if (customTooltipOpacity[0]) {
                        customTooltipOpacity[0].style.opacity = 0
                    }
                }
                if  (timestamp < chart.tooltip._data.datasets[0].data[0]?.x) {
                    if (customTooltipOpacity[0]) {
                        customTooltipOpacity[0].style.opacity = 0
                    }
                }
            }
            //set tooltip opacity to zero when move outside chart canvas or timeline
            if (!isHovered && !hoverStatus) {
                if (customTooltipOpacity[0]) {
                    customTooltipOpacity[0].style.opacity = 0;
                }
            }
            let dateScale = null;
            //only create a synced tooltip if x axis is valid for passed tooltip
            if (this.useCustomScaleOnDateAxis && this.chartConfiguration?.dateAxis.tagName === newValue.customAxisTagName) {
                dateScale = chart.scales['custom-date-axis'];
            } else if (!this.useCustomScaleOnDateAxis) {
                dateScale = chart.scales['date-axis'];
            }

            if(dateScale) {
                const pixel = dateScale.getPixelForValue(timestamp);
                const rectangle = chart.canvas.getBoundingClientRect();
                const xPos = this.chartConfiguration.isVertical ? rectangle.left + rectangle.width / 2 : rectangle.left + pixel;
                const yPos = this.chartConfiguration.isVertical ? rectangle.top + pixel : rectangle.top + rectangle.height / 2;
                if(this.contains(rectangle, xPos, yPos)) {
                    const mouseMoveEvent = new MouseEvent('mousemove', {
                        clientX: xPos,
                        clientY: yPos
                    });
                    chart.canvas.dispatchEvent(mouseMoveEvent);
                }

            }
        },
        fillWellNameSuffixAndPrefix(chartItem) {
            let prefix = chartItem.prefixName ? chartItem.prefixName : (chartItem.wellShortName ? chartItem.wellShortName : chartItem.wellname);
            let suffixPositionName = chartItem.suffixPositionName ?? '';
            let suffixName = chartItem.suffixName ?? '';
            let returnString = [prefix, suffixPositionName, suffixName].join(' ');
            return returnString;
        },
        setNewAnnotation(event) {
            const annotation = event.detail;
            if (this.chartConfiguration.isVertical) {
                this.annotationHoverStyle.top = (annotation.yPos - annotation.height) + 'px';
                this.annotationHoverStyle.right = '10px';
            } else {
                this.annotationHoverStyle.top = '10px';
                this.annotationHoverStyle.right = annotation.xPos + 'px';
            }
            this.wellStageLegendDisplay.label = annotation.label;
            this.wellStageLegendDisplay.show = true;
            this.wellStageLegendDisplay.color = annotation.wellColor;
        },
        clearAnnotation() {
            //when mouse leaves the stage line it will pause css transitions for 2 seconds
            //then the on chart annotation will fade out
            this.wellStageLegendDisplay.show = false;
        },
        createNPTMarkers(nptEvents) {
            if (!(this.chartConfiguration?.showNPTLines) || nptEvents == null) {
                return;
            }

            const chart = this.getLineChart();
            //clear out current NPT annotations
            chart.options.annotation.annotations = chart.options.annotation.annotations.filter(a => a.label.content != 'NPT');

            nptEvents.forEach(nptE => {
                const timestamp = nptE.subCategory == 'start'?  nptE.data.startTime : nptE.data.endTime;
                const unixTime = moment.utc(timestamp).valueOf();
                const color = nptE.subCategory == 'start'? 'green' : 'red';
                const annotation = {
                    type: 'line',
                    drawTime: 'afterDatasetsDraw',
                    id: `${nptE.reference}${nptE.subCategory}`,
                    mode: 'vertical',
                    scaleID: 'date-axis',
                    value: unixTime,
                    borderColor: color,
                    borderWidth: 2,
                    borderDash: [2, 2],
                    borderDashOffset: 5,
                    label: {
                        backgroundColor: 'rgba(0,0,0,0.8)',
                        fontFamily: "sans-serif",
                        fontSize: 12,
                        fontStyle: "bold",
                        fontColor: "#fff",
                        xPadding: 6,
                        yPadding: 6,
                        cornerRadius: 6,
                        position: "center",
                        xAdjust: 0,
                        yAdjust: 0,
                        enabled: false,
                        content: "NPT"
                    },
                    onMouseenter: function(e) {},
                    onMouseover: function(e) {},
                    onMouseleave: function(e) {},
                    onMouseout: function(e) {},
                    onMousemove: function(e) {},
                    onMousedown: function(e) {},
                    onMouseup: function(e) {},
                    onClick: function(e) {},
                    onDblclick: function(e) {},
                    onContextmenu: function(e) {},
                    onWheel: function(e) {}
                }
                chart.options.annotation.annotations.push(annotation);
            });

            chart.update();
        },
        removeNPTMarkers(nptEvents) {
            if (!(this.chartConfiguration?.showNPTLines) || nptEvents == null) {
                return;
            }

            const chart = this.getLineChart();
            chart.options.annotation.annotations = chart.options.annotation.annotations.filter(a => !nptEvents.find(e => `${e.reference}${e.subCategory}` == a.id));
            chart.update();
        },
        createWellStageMarkers(newSignalRStageData = null, createAnnotations = true) {
            const chart = this.getLineChart();

            if(!chart) {
                return;
            }

            const headerType = this.headerStyle == 'default'? 'all' : this.headerStyle;
            //if a new stage wellActivity is registered from signalR, then add just that
            //new event to the annotations collection
            if (newSignalRStageData) {
                const newStage = {
                    type: newSignalRStageData.data.activity,
                    wellNumber: newSignalRStageData.wellNumber,
                    stageNumber: newSignalRStageData.stageNumber,
                    startTime: newSignalRStageData?.data?.startTime || newSignalRStageData.timestamp,
                };
                if (newStage.startTime && (newStage.type === headerType || headerType === 'all')) {
                    let sameTypeAnnotations = headerType === 'all' ? chart.options.annotation.annotations.filter(a => a.text.includes(newStage.type)) : chart.options.annotation.annotations;
                    let stageNumber = newStage.stageNumber;
                    if (sameTypeAnnotations.some(a => a.stage === newStage.stageNumber && a.well === newStage.wellNumber)) {
                        stageNumber = stageNumber + '*';
                    }

                    if (createAnnotations) {
                        const newAnnotation = this.createStageAnnotationHelper(newStage.startTime, newStage.wellNumber, stageNumber, newStage.type, headerType);
                        if (newAnnotation) {
                            chart.options.annotation.annotations.push(newAnnotation)
                        }
                    }
                }
            }
            else { //on page load, add all currently existing stage start starts
                //to the annotation list

                //guardian to ensure that data for at least one well exists for this job
                if (this.wells.length <= 0 || this.wells[0] == null) {
                    return;
                }
                //show wells for defined header types: frac, wireline, all
                const wellsToMark = Object.values(this.stageStartMarkers[headerType]);
                wellsToMark.forEach((well, wellIndex) => {
                    const stagesToMark = Object.values(well);
                    const usedStages = {
                        'frac': [],
                        'wireline': []
                    };
                    stagesToMark.forEach((stage, stageIndex) => {
                        if (stage.startTime) {
                            let stageNumber = stage.stageNumber;
                            if (usedStages[stage.type].includes(stageNumber)) {
                                stageNumber = stageNumber + '*';
                            } else {
                                usedStages[stage.type].push(stageNumber);
                            }

                            if (createAnnotations) {
                                const startAnnotation = this.createStageAnnotationHelper(stage.startTime, stage.wellNumber, stageNumber, stage.type, headerType);
                                startAnnotation ? chart.options.annotation.annotations.push(startAnnotation) : null;
                            }
                        }
                    });
                });
            }
            chart.options.annotation.annotations.sort((a,b)=>{return +a.id - +b.id;});
            chart.update(); //refresh the chart to create the annoations
            if (this.chartConfiguration.showStageLines && this.chartConfiguration.stageLabelType === 'label') {
                //chartjs annotations 0.5.7 doesn't let us set label alignment with respect to the center line
                //work around is to adjust the label 'xAdjust' by (width/2) - 1 after creation, so it is left justified
                //for horizontal charts, and sliding it to the right chart edge for vertical charts.
                chart.options.annotation.annotations.forEach(anno =>{
                    const element = chart.annotation.elements[anno.id];
                    if(element) {
                        if (this.chartConfiguration.isVertical) {
                            element.options.label.xAdjust = (chart.width/2) - (element._model.labelWidth);
                            element.options.label.yAdjust = (-1*element._model.labelHeight / 2) - 1;
                        } else {
                            element.options.label.xAdjust = (element._model.labelWidth / 2) - 1;
                        }
                        element.options.value = anno.value; // workaround for chartjs annotation bug: https://github.com/chartjs/chartjs-plugin-annotation/issues/115
                    }
                });
                this.staggerLabelOverlaps(chart);
                chart.update();
            }
        },
        createStageAnnotationHelper(dateTimeString, wellIndex, stageIndex,type, headerType) {
            if (typeof this.wells[wellIndex] === 'undefined') {
                return null;
            }
            const defaultAnnotation = {
                type: 'line',
                drawTime: 'afterDraw',
                mode: this.chartConfiguration.isVertical? 'horizontal' : 'vertical',
                scaleID: 'date-axis',
                value: null,
                wellColor: '#fff',
                stage: stageIndex,
                well: wellIndex,
                labelType: this.chartConfiguration.stageLabelType,
                dashboardItemId: this.dashboardItem.i,
                borderColor: '#fff',
                borderWidth: 2,
                borderDash: [5,8],
                label: {
                    content: '',
                    enabled: this.chartConfiguration.stageLabelType === 'label'? true : false,
                    position: 'top',
                    backgroundColor: '#fff'
                },
                onMouseover: function(e) {
                    //pass the annotation data to the Vue app so it can be displayed and
                    //position the hover label to the left of the well stage line
                    const eventData = {
                        ...this.options,
                        xPos: this.chartInstance.width - this._view.labelX - (this._view.labelWidth/2),
                        yPos: 0 + this._view.labelY + (this._view.labelHeight/2),
                        width: this._view.labelWidth,
                        height: this._view.labelHeight
                    };
                    const event = new CustomEvent('setNewAnnotation', {detail: eventData});
                    document.querySelector('#annotation-legend-display-'+this.options.dashboardItemId).dispatchEvent(event);
                },
                onMouseleave: function(e) {
                    //tell the vue app that it can clear displayed annotation data
                    const event = new CustomEvent('clearAnnotationDisplay');
                    document.querySelector('#annotation-legend-display-'+this.options.dashboardItemId).dispatchEvent(event);
                }
            };
            const newAnnotation = defaultAnnotation;
            let text = this.wells[wellIndex].name + ' : ' + stageIndex;
            let shortText = stageIndex
            //if showing all types of stages, specify whether it is a frac or wireline stage
            if (headerType === 'all') {
                text = `${text} (${type})`;
                const capitalizedType = type.charAt(0).toUpperCase() + type.slice(1)
                shortText = `${shortText} ${capitalizedType}`
            }

            const time = moment.utc(dateTimeString,'YYYY-MM-DTHH:mm:ss.SSSZ').valueOf();
            newAnnotation.id = time.toString();
            newAnnotation.wellColor = this.wells[wellIndex].color;
            newAnnotation.borderColor = this.wells[wellIndex].color;
            const endIndex = text.indexOf('(') > 0 ? text.indexOf('(') : text.length;
            newAnnotation.label.content = shortText;
            newAnnotation.label.fullContent = text;
            newAnnotation.label.backgroundColor = this.wells[wellIndex].color;
            newAnnotation.label.fontColor = this.getTitleColorForBG(this.wells[wellIndex].color);
            newAnnotation.value = time;
            return newAnnotation;
        },
        displayCFStatus(well) {
            const overlappedWells = [];
            this.wells.forEach(well => {
                if(well.cfChangeoverActive) {
                    overlappedWells.push(well);
                }
            });

            if(overlappedWells.length > 0) {
                let fromWell = null;
                let toWell = null;
                overlappedWells.forEach(well => {
                    if(well.cfChangeoverState == 'to') {
                        toWell = well;
                    } else if(well.cfChangeoverState == 'from') {
                        fromWell = well;
                    }
                });

                if(fromWell && toWell) {
                    return `Frac: ${this.wellFriendlyName(fromWell)} ${fromWell.currentStage}/${fromWell.numberOfStages} ⮕ ${this.wellFriendlyName(toWell)} ${toWell.currentStage}/${toWell.numberOfStages}`;
                }
            } else {
                return `Frac: ${this.wellFriendlyName(well)} ${well.currentStage}/${well.numberOfStages}`;
            }
        },
        formatMomentToJobLocalTime: function(value) {
            const datetimeValue = moment.utc(value);
            datetimeValue.add({hours: this.jobHourOffset});
            return datetimeValue;
        },
        formatDBDateToMoment: function(value) {
            const datetimeValue = moment.utc(value);
            return datetimeValue;
        },
        addChartItemToLayout: function (newChartItem, sectionKey) {
            //if this is a new section with no chart items then add it to the layout
            if (!this.chartItemLayouts.hasOwnProperty(sectionKey)) {
                this.chartItemLayouts[sectionKey] = [];
                this.usedSectionsIDs.push(sectionKey);
            }

            const layoutLength = this.chartItemLayouts[sectionKey].length;
            if (layoutLength > 0) {
                const currentMaxIdentity = Math.max(...this.chartItemLayouts[sectionKey].map(o => o.i));
                let newIdentity = currentMaxIdentity + 1;
                //get the current last chart item layout in the grid (highest row and column value)
                const lastRowValue = Math.max(...this.chartItemLayouts[sectionKey].map(layout => layout.y));
                const lastRowLayouts = this.chartItemLayouts[sectionKey].filter(layout => layout.y === lastRowValue);
                const lastColumnInLastRow = Math.max(...lastRowLayouts.map(layout => layout.x));
                const lastGridItem = this.chartItemLayouts[sectionKey].find(layout => layout.y === lastRowValue && layout.x === lastColumnInLastRow);

                const gridItemObj = {};
                gridItemObj.i = newIdentity;
                gridItemObj.tagName = newChartItem.tagName;
                gridItemObj.h = lastGridItem.h;
                gridItemObj.w = lastGridItem.w;
                //determine how many columns the last grid item occupies in its row, and if there is space for the next item
                gridItemObj.x = lastGridItem.x + (lastGridItem.w - 1) + gridItemObj.w > this.defaultChartColumnCount - 1 ? 0 : lastGridItem.x + lastGridItem.w;
                //assigns the new grid item to either the current row if space exists, or to a new row
                gridItemObj.y = lastGridItem.x + (lastGridItem.w - 1) + gridItemObj.w > this.defaultChartColumnCount - 1 ? lastGridItem.y + 2 : lastGridItem.y ;
                gridItemObj.labelTextOptions = {};
                gridItemObj.dataTextOptions = {};
                this.chartItemLayouts[sectionKey].push(gridItemObj);
            }
            else { //No previous chart item layout data, so use the default grid item object for first values
                const defaultGridItemObj = {	//default column count / 3 because the 'assumed' column size is 3, but need a multiple of that to resize chart item widths
                    'x': 0,
                    'y': 0,
                    'w': this.defaultChartColumnCount / 3,
                    'h': 2,
                    'i': 0,
                    'tagName': '',
                    'labelTextOptions': {},
                    'dataTextOptions': {}
                };
                const gridItemObj = Object.assign({},defaultGridItemObj);
                gridItemObj.tagName = newChartItem.tagName;
                this.chartItemLayouts[sectionKey].push(gridItemObj);
            }
        },
        validateChartItemQuickAdd: function(newChartItem,sectionIndex=null) {
            //need to check required fields + friendlyName to ensure that they all have data
            //if a valid field does not have data, populate what you can and then pull up the config modal
            let isItemValid = true;
            //test required fields (tagname,unit,chart axis) + friendlyName
            if (newChartItem.tagName == null ||newChartItem.unit == null
                || newChartItem.chartYAxis_key == null || newChartItem.friendlyName == null ) {
                isItemValid = false;
            }
            if (!isItemValid) {
                //add the partial chart item to the config data as is
                const section = this.chartConfigurationData.orderedSections[sectionIndex];
                const chartItemIndex = section.orderedChartItems.length-1;

                //remove unwanted chart item keys before passing to chart config modal
                const selectedConfig = { ...this.chartConfiguration, data: this.chartConfigurationData};
                if (this.chartConfigurationData?.chartYAxes?.length) {
                    selectedConfig.data.chartYAxes = this.chartConfigurationData.chartYAxes.map((yaxes)=>{
                        return {
                            displayGridlines: yaxes.displayGridlines,
                            hideYAxis: yaxes.hideYAxis,
                            key: yaxes.key,
                            label: yaxes.label,
                            logarithmic: yaxes.logarithmic,
                            max: yaxes.max,
                            min: yaxes.min,
                            position: yaxes.position,
                            ticks: yaxes.ticks,
                            color: yaxes.color ? yaxes.color : 'white',
                        };
                    });
                }
                selectedConfig.data = JSON.stringify(selectedConfig.data);

                if(this.isFeatureFlagged("CHART_CONFIG_REFACTOR")){
                    this.$refs.TimeSeriesConfigModal.open({
                        chartConfiguration: selectedConfig,
                        sectionIndex: sectionIndex,
                        chartItemIndex: section.orderedChartItems.length-1
                    });
                }
                else{
                    this.$root.$emit('OPEN_CHART_CONFIG_MODAL',
                    {
                        chartConfiguration: selectedConfig,
                        dashboardItem: this.dashboardItem,
                        sectionKey: section.key,
                        chartItemIndex: chartItemIndex,
                        quickEditModalAccess: true
                    });
                }

            }
        },
        quickAddDataSource: function(event, customData) {
            const tagName = event.currentTarget.id;
            const sectionIndex = customData.index;
            const modifiedSectionKey = customData.index in this.chartConfigurationData?.orderedSections ? this.chartConfigurationData.orderedSections[customData.index]?.key : null;
            const modifiedSection = this.chartConfigurationData?.orderedSections?.find(section => section.key == modifiedSectionKey);

            if (isFalsy(modifiedSectionKey) || isFalsy(modifiedSection) || !isIterable(modifiedSection?.orderedChartItems))
                return toast({
                    title: 'Could not find index',
                    variant: 'danger'
                });

            const self = this;
            const url = '/user/config/getdefaultchartitemdata';
            $.get(
                url,
                {
                    jobId: this.dashboardData.jobId,
                    tag: event.currentTarget.id
                },
                function(result) {
                    //check if friendlyName is supplied or if it is an empty string
                    const friendlyName = result.standardTagData?.friendlyTagname == ''
                        || result.standardTagData?.friendlyTagname == null? null:result.standardTagData.friendlyTagname;
                    //create the chart item with required fields populated
                    const newChartItem = {
                        tagName: tagName,
                        friendlyName: friendlyName,
                        unit: result.measurement?.unitAbbr ?? null,
                        lineWidth: 1,
                        bgColor: null,
                        color: self.isWellheadTag(tagName) ? self.getWellColorFromWellheadTagName(tagName): '#fff',
                        chartYAxis_key: result?.defaultAxis?.id ?? null,
                        decimalPrecision: result.standardTagData.decimal_places || 2,
                        showDataPoints: false,
                        selectedByDefault: false
                    };
                    //add the chart item to the relevant fields -> chartconfig(orderedSections, yaxes), usedSections
                    modifiedSection.orderedChartItems.push(newChartItem);
                    //add the default axis to both configuration yaxes and configData yaxes
                    if (result?.defaultAxis) {
                        let defaultAxis = self.chartConfiguration.valueAxes.find(axis => axis.key == result?.defaultAxis.id);
                        if (!defaultAxis) {
                            //default axis doesn't exist yet so create it and add to config
                            defaultAxis = result.defaultAxis;
                            defaultAxis.key = defaultAxis.id;
                            defaultAxis.chartItems = [newChartItem];
                            self.chartConfiguration.valueAxes.push(defaultAxis);
                            //ensure the default axis is also saved to configData if it is not present
                            const targetIndex = self.chartConfigurationData.chartYAxes.findIndex(axis =>axis.key == result.defaultAxis.id);
                            if (targetIndex < 0 || targetIndex == null) {
                                self.chartConfigurationData.chartYAxes.push(defaultAxis);
                            }
                        } else { //axis already exists so just add the latest chart item to it
                            self.chartConfiguration.valueAxes.find(axis => axis.key == result.defaultAxis.id).chartItems.push(newChartItem);
                        }
                    }
                    //use $set to force a re-render of chart item numerical displays and add the new data source
                    self.$set(self.usedSections[sectionIndex],
                        self.usedSections[sectionIndex].length,
                        {'name': tagName,'value': 'pending save'});
                    //create layout data for the new chart item then add it where relevant
                    self.addChartItemToLayout(newChartItem, modifiedSectionKey);

                    modifiedSection.chartItemLayout = self.chartItemLayouts[modifiedSectionKey];
                    self.chartConfiguration.sections[sectionIndex] = modifiedSection;
                    //final check to ensure the chart item is valid, if not, open the config modal to fill in missing data
                    self.validateChartItemQuickAdd(newChartItem,sectionIndex);
                },
                'json'
            ).fail(function( jqXHR, textStatus, errorThrown ) {
                console.log('failed chart item quick add', errorThrown);
                if(jqXHR.status == 401) {
                    console.log('unauthorized');
                    self.hasAuthError = true;
                }
            });
        },
        quickRemoveDataSource: function(event, customData) {
            //remove the chart item from all the places that reference it
            const tagName = event.currentTarget.id;
            const sectionIndex = customData.index;
            const affectedAxis = Object.values(this.chartConfiguration.valueAxes)
                .find(axis=>axis.chartItems.find(chartItem=>chartItem.tagName==tagName));
            const modifiedSectionKey = this.chartConfigurationData.orderedSections[sectionIndex].key;
            const modifiedSection = this.chartConfigurationData.orderedSections.find(section => section.key == modifiedSectionKey);

            let targetIndex = modifiedSection.orderedChartItems.findIndex(chartItem => chartItem.tagName == tagName);
            modifiedSection.orderedChartItems.splice(targetIndex, 1); //remove chart item from ordered section chart items

            //if removal is after quickadd but before a save modSec.chartItemLayout shares a ref with this.chartItemLayouts[secKey]
            //if removal is after a save, they are separate references and the value must be removed from each
            if (modifiedSection.chartItemLayout) {
                targetIndex = modifiedSection.chartItemLayout.findIndex(item => item.tagName == tagName);
                modifiedSection.chartItemLayout.splice(targetIndex,1); //remove layout object from both modifiedSection and this.charItemLayouts
            }
            if (this.chartItemLayouts[modifiedSectionKey]) {
                targetIndex = this.chartItemLayouts[modifiedSectionKey].findIndex(chartItem => chartItem.tagName == tagName);
                if (targetIndex && targetIndex > -1) {
                    this.chartItemLayouts[modifiedSectionKey].splice(targetIndex,1);
                }
            }
            //remove chart item from axis references
            //Note that this removes it from both chartConfiguration, and chartConfigurationData
            targetIndex = affectedAxis?.chartItems.findIndex(item => item.tagName == tagName);
            if (targetIndex) {
                affectedAxis.chartItems.splice(targetIndex,1);
            }
            //remove from usedSections
            if (this.usedSections[sectionIndex]) {
                targetIndex = this.usedSections[sectionIndex].findIndex(item => item.name == tagName);
                if (targetIndex) {
                    this.usedSections[sectionIndex].splice(targetIndex, 1);
                }
            }
        },
        cancelQuickAddChartItem(chartItem) {
            //If a save action is not completed for a quickadd chart item that does not have all the needed data
            //then revert to the state before the chart config modal was opened.
            const sectionIndex = this.chartConfigurationData.orderedSections.findIndex(
                section=>section.orderedChartItems.find(item=>item.tagName===chartItem.tagName));
            const affectedAxis = Object.values(this.chartConfiguration.valueAxes)
                .find(axis=>axis.chartItems.find(item=>item.tagName==chartItem.tagName));
            const modifiedSectionKey = this.chartConfigurationData.orderedSections[sectionIndex].key;
            const modifiedSection = this.chartConfigurationData.orderedSections.find(section => section.key === modifiedSectionKey);

            //revert ordered section data
            let targetIndex = modifiedSection.orderedChartItems.findIndex(item => item.tagName === chartItem.tagName);
            modifiedSection.orderedChartItems.splice(targetIndex, 1); //remove chart item from ordered section chart items
            //revert the layout for the ordered section
            targetIndex = modifiedSection.chartItemLayout.findIndex(item => item.tagName === chartItem.tagName);
            modifiedSection.chartItemLayout.splice(targetIndex,1); //removes it from both modified section and this.chartItemLayouts
            targetIndex = this.chartItemLayouts[modifiedSectionKey].findIndex(item => item.tagName == chartItem.tagName);
            if (targetIndex > -1) {
                this.chartItemLayouts[modifiedSectionKey].splice(targetIndex,1);
            }
            //remove chart item from axis references, if it had one
            //this removes it from both chartConfiguration, and chartConfigurationData
            if (affectedAxis) {
                targetIndex = affectedAxis.chartItems.findIndex(item => item.tagName === chartItem.tagName);
                affectedAxis.chartItems.splice(targetIndex,1);
            }
            targetIndex = this.usedSections[sectionIndex].findIndex(item => item.name === chartItem.tagName);
            this.usedSections[sectionIndex].splice(targetIndex, 1);
            //remove this tag fromthe used tags list
            targetIndex = this.selectedTags[modifiedSectionKey].findIndex(tag=>tag===chartItem.tagName);
            this.selectedTags[modifiedSectionKey].splice(targetIndex, 1);
        },
        getDisabledTagsForSection: function(sectionKey) {
            //Tags cannot be duplicated in multiple sections for one chart, so this ensures that
            //if a tag is selected, it is not an available option for other sections
            const tagsInOtherSections = [];
            for(const sectionByKey in this.selectedTags) {
                if (sectionKey !== sectionByKey) {
                    tagsInOtherSections.push(...this.selectedTags[sectionByKey]);
                }
            }
            return this.allTags.filter(tag=>tagsInOtherSections.includes(tag.name)).map(tag=>tag.name);
        },
        onChartHeaderPressed: function() {
            this.showChartHeaderConfig = !this.showChartHeaderConfig;
            this.hideHeader = !this.showChartHeaderConfig;
            this.dashboardItem.options.showChartHeaderConfig = this.showChartHeaderConfig;
            this.$root.$emit('toggleShowChartHeader', this.dashboardItem);
        },
        onUndoZoomPressed: function() {
            //remove last saved zoom state from the stack, we don't need it anymore
            this.zoomHistory.pop();

            if(this.zoomHistory.length > 0) {
                const lastZoomWindow = this.zoomHistory[this.zoomHistory.length - 1]; //last zoom window for date axis

                //undo zoom for value axis (y-axis for non-vertical charts) by storing max and min values:
                const chart = this.getLineChart();
                let axisIds = [];
                if (this.chartConfiguration.isVertical) {
                    axisIds = chart.options.scales.xAxes.map(obj => obj.id);
                } else {
                    axisIds = chart.options.scales.yAxes.map(obj => obj.id);
                }
                axisIds.forEach((id) => {
                    if (this.zoomHistoryValueAxes && this.zoomHistoryValueAxes[id]?.length > 0) {
                        this.zoomHistoryValueAxes[id].pop();
                        const lastZoomWindowValues = this.zoomHistoryValueAxes[id][this.zoomHistoryValueAxes[id]?.length - 1];

                        if (lastZoomWindowValues && lastZoomWindowValues.axisMin && lastZoomWindowValues.axisMax) {
                            this.setChartValueAxisMinMax(id, lastZoomWindowValues.axisMin, lastZoomWindowValues.axisMax);
                        } else {
                            //set min and max to be be initial values for axis
                            const initialAxis =  this.chartConfiguration.valueAxes.find((axes) => 'generated-axis-'+axes.key === id);
                            this.setChartValueAxisMinMax(id, initialAxis.min, initialAxis.max);
                        }
                    }
                })

                if (this.areChartsSynced) {
                    this.syncChartMovement(this.getLineChart().id, lastZoomWindow.from, lastZoomWindow.to, true, true);
                }

                this.refreshChartDataWithInterval(lastZoomWindow.from, lastZoomWindow.to, true, false);
            }
        },
        getHeaderStyle: function(well=null) {
            const styling = {
                'opacity': this.hideHeader && !this.chartConfigEditMode ? '0' : '1',
                'max-height': this.hideHeader && !this.chartConfigEditMode ? '10px' : '33px'
            };
            if (well) {
                styling['background-color'] = well.color;
                styling['color'] = this.getTitleColorForBG(well.color);
                styling['pl-4'] = this.showHeader;
            }
            return styling;
        },
        showSmallHeader: function() {
            return this.$parent.$el.clientWidth <= 887;
        },
        showSuperSmallHeader: function() {
            return this.layout && this.layout.w < 4 && this.$parent.$el.clientWidth <= 525;
        },
        onHeaderLeave: function() {
            if(!this.showChartHeaderConfig) {
                this.hideHeader = true;
            }
        },
        onHeaderEnter: function() {
            this.hideHeader = false;
        },
        getSpinnerStyle: function() {
            let xOffset = 0;

            if(this.controlsPosition == 'left' && this.controlsPosition == 'right'){
                xOffset = 0;
            }

            return {
                position: 'absolute',
                bottom: '0',
                right: '0',
                top: '0',
                left: xOffset + 'px',
                margin: 'auto'
            };
        },
        onGridMounted: function(newLayout) {
            if(this?.$refs?.['mainGridLayout' + this._uid]) {
                const sectionElement = this.$refs['mainGridLayout' + this._uid];
                const self = this;
                new ResizeSensor(sectionElement, function() {
                    self.sectionsHeight = sectionElement.clientHeight;
                });
            }
            this.observeDataTextHeight();
        },
        chartOptionsTsiPlugin: function() {
            return {
                enabled: false,
                closeStats: this.closeTsiAnalytics,
                setIsSelecting: this.setIsSelecting,
                onSelectComplete: this.onSelectComplete,
                onRepositionStats: this.displayTsiStats,
                overlayId: 'overlay-tsi-analytics'+this._uid,
                isVertical: this.chartConfiguration?.isVertical,
                useCustomScales: this.useCustomScaleOnDateAxis ?? false,
                clear: true
            };
        },
        chartOptionsZoomPlugin: function() {
            const isVerticalChart = this.chartConfiguration?.isVertical ?? false;
            this.panMode = isVerticalChart ? 'y' : 'x';
            this.zoomMode = isVerticalChart ? 'y' : 'x';
            return {
                pan: {
                    enabled: this.useCustomScaleOnDateAxis ? !this.useCustomScaleOnDateAxis : !this.dragToZoom,
                    mode: this.panMode,
                    rangeMin: {
                        x: null,
                        y: null
                    },
                    rangeMax: {
                        x: isVerticalChart ? null : moment.utc().valueOf() + this.rightSideMsOffset,
                        y: isVerticalChart ? moment.utc().valueOf() + this.rightSideMsOffset : null
                    },
                    onPan: this.onPan,
                    onPanComplete: this.onPanComplete
                },
                zoom: {
                    enabled: false,
                    drag: this.dragToZoom,
                    mode: this.zoomMode,

                    rangeMin: {
                        x: isVerticalChart ? null : moment.utc(this.dashboardData.jobStart).valueOf() - this.rightSideMsOffset,
                        y: isVerticalChart ? moment.utc(this.dashboardData.jobStart).valueOf() - this.rightSideMsOffset : null
                    },
                    rangeMax: {
                        x: isVerticalChart ? null : moment.utc().valueOf() + this.rightSideMsOffset,
                        y: isVerticalChart ? moment.utc().valueOf() + this.rightSideMsOffset : null
                    },
                    onZoom: this.onZoom,
                    onZoomComplete: this.onZoomComplete
                }
            };
        },
        getFormattedChartValue: function (chartItem) {
            //if decimalPrecision is not defined then default to 2
            const decimals = this.getChartItem(chartItem.name)?.decimalPrecision ?? 2;
            const value = GlobalFunctions.roundAccurately(parseFloat(chartItem.value),decimals).toFixed(decimals);
            return isNaN(value) ? chartItem.value : value;	//if value is not a number this should be the default blank value
        },
        wellFriendlyName: function(well) {
            return (well.nameLong != '' && well.nameLong != 'not set')? well.nameLong : well.name;
        },
        onSelectedTruckChange: async function(newValue) {
            this.selectedTruck = parseInt(newValue);

            this.initialized = false;
            await this.downloadConfig(true);

            if (this.item && !this.isNotDashboard && (this.dashboardData.isIwsUser || this.dashboardData.isAdmin || this.dashboardData.isCompanyAdmin) && this.selectedTruck) {
                const index = this.dashboardData.selectedDashboard.items.findIndex(item => item.i == this.item.i);

                if (index >= 0) {
                    this.item.selectedTruckId = this.selectedTruck;

                    this.dashboardData.selectedDashboard.items[index].selectedTruckId = this.selectedTruck;
                    this.dashboardData.saveDashboardItems(this.dashboardData.selectedDashboard);
                }
            }
        },
        shadingDataContainsTag: function(tag) {
            return !!this.shadingData.find(data => data.name == tag);
        },
        activeDataContainsTag: function(tag) {
            return !!this.activeData.find(data => data.name == tag);
        },
        onAlertIconPressed: function() {
            this.enableThresholdAlerts = !this.enableThresholdAlerts;
            localStorage.setItem('showThresholdAlerts', this.enableThresholdAlerts);
        },
        onEditChartOrderPressed: function() {
            if(this.chartConfigEditMode) {
                this.saveChartAxisOptions();
                this.saveChartConfigOrder();
            } else {
                this.initialChartConfigData = _.cloneDeep(this.chartConfigurationData);
                this.initialChartConfiguration = _.cloneDeep(this.chartConfiguration);
                this.initialSelectedTags = _.cloneDeep(this.selectedTags);

                const self = this;
                Object.keys(this.chartItemLayouts).forEach(function(key) {
                    self.initialChartItemLayouts[key] = _.cloneDeep(self.chartItemLayouts[key]);
                });
                Object.keys(this.usedSections).forEach(function(key) {
                    self.initialUsedSections[key] = _.cloneDeep(self.usedSections[key]);
                });
            }
            this.chartConfigEditMode = !this.chartConfigEditMode;
        },
        onCancelEditChartOrderPressed: function() {
            this.chartConfigurationData = _.cloneDeep(this.initialChartConfigData);
            this.chartConfiguration = _.cloneDeep(this.initialChartConfiguration);
            this.selectedTags = this.initialSelectedTags;

            if(!this.chartConfiguration.isVertical){
                this.applyYAxisEdits(); //revert any changes to y axes
            }

            const self = this;
            Object.keys(this.initialChartItemLayouts).forEach(function(key) {
                self.chartItemLayouts[key] = _.cloneDeep(self.initialChartItemLayouts[key]);
            });
            Object.keys(this.initialUsedSections).forEach(function(key) {
                self.usedSections[key] = _.cloneDeep(self.initialUsedSections[key]);
            });
            this.chartConfigEditMode = !this.chartConfigEditMode;
            this.localChartAxisOptions = this.chartOptionsTempCopy;
            this.setChartFontSize('all', this.chartConfiguration.isVertical);
        },
        saveChartAxisOptions: function() {
            let axisOptions = this.jsonLocalStorage.chartAxisOptions.find(
                options => options.key === this.userDashboardItemKeyId);
            if (axisOptions) { //reassign storage object reference
                axisOptions = this.localChartAxisOptions;
            } else { //add to collection if new
                this.localChartAxisOptions.key = this.userDashboardItemKeyId;
                this.jsonLocalStorage.chartAxisOptions.push(this.localChartAxisOptions);
            }
            this.jsonLocalStorage.save();
        },
        applyYAxisEdits: function(axis) {
            //update the chart instance so users can review the effect of their axis changes
            const chart = this.getLineChart();
            const self = this;
            if (axis) {
                this.editYAxisValidator.axisKey = axis.key;
                if (!this.validateYAxisEdits(axis)) {
                    return;
                }
                //update the config template, then update the current chart instance
                const targetConfigIndex = this.chartConfigurationData.chartYAxes.findIndex(scaleData => scaleData.key == axis.key);
                this.chartConfigurationData.chartYAxes[targetConfigIndex] = axis;
                const targetAxis = chart.options.scales.yAxes.find(chartAxis => chartAxis.key == axis.key);
                targetAxis.options = axis;
                targetAxis.position = axis.position;
                targetAxis.ticks.min = axis.min;
                targetAxis.ticks.max = axis.max;
                targetAxis.ticks.stepSize = (axis.max - axis.min) / axis.ticks;
                targetAxis.ticks.suggestedMin = axis.min;
                targetAxis.ticks.suggestedMax = axis.max;
                targetAxis.scaleLabel.labelString = axis.label;
                targetAxis.gridLines.display = axis.displayGridlines;
                targetAxis.ticks.fontColor = axis.color;
                targetAxis.scaleLabel.fontColor = axis.color;
                targetAxis.gridLines.color = axis.color;
                targetAxis.gridLines.zeroLineColor = axis.color;
            } else {
                //on edit cancel, this resets each axis to it's previous values before they were changed.
                Object.values(chart.options.scales.yAxes).forEach(scale => {
                    const config = self.initialChartConfigData.chartYAxes.find(scaleData => scaleData.key == scale.key );
                    scale.options = config;
                    scale.position = config?.position;
                    scale.ticks.min = config?.min;
                    scale.ticks.max = config?.max;
                    scale.scaleLabel.labelString = config?.label;
                });
            }
            chart.update();
            this.forceRefreshStaticKey++; //update the popovers in case of axis position changes their target
        },
        validateYAxisEdits: function(axis) {
            this.editYAxisValidator.noMinError =  false;
            this.editYAxisValidator.noMaxError =  false;
            this.editYAxisValidator.minMaxError =  false;
            this.editYAxisValidator.noAxisLabelError =  false;
            this.editYAxisValidator.noNumberOfTicksError =  false;

            let isValid = true;
            if (isNaN(axis?.min) || axis.min === '') {
                isValid = false;
                this.editYAxisValidator.noMinError = true;
            }
            if (isNaN(axis?.min)|| axis?.max == '') {
                isValid = false;
                this.editYAxisValidator.noMaxError = true;
            }
            if (axis?.max <= axis.min) {
                isValid = false;
                this.editYAxisValidator.minMaxError = true;
            }
            if (axis?.label == null || axis.label === '') {
                isValid = false;
                this.editYAxisValidator.noAxisLabelError = true;
            }
            if (isNaN(axis?.ticks) || axis.min === '' || axis.ticks < 2) {
                isValid = false;
                this.editYAxisValidator.noNumberOfTicksError =  true;
            }

            return isValid;
        },
        saveChartConfigOrder: function() {
            this.chartConfigurationData.orderedSections.forEach((section) => {
                const orderedChartItems = [];
                if(this.chartItemLayouts[section.key]) {
                    const orderedItemLayouts = _.cloneDeep(this.chartItemLayouts[section.key]);
                    orderedItemLayouts.forEach((chartItemNode) => {
                        let chartItem = section.orderedChartItems.find(item => item.tagName == chartItemNode.tagName);
                        if (!chartItem) {
                            chartItem = section.orderedChartItems[chartItemNode.i];
                            chartItemNode.tagName = chartItem.tagName;
                        }
                        orderedChartItems.push(chartItem);
                    });
                    section.orderedChartItems = orderedChartItems;
                    section.chartItemLayout = orderedItemLayouts;
                }
            });
            let configJson = _.cloneDeep(this.chartConfigurationData);

            if(this.sectionIndexes) {
                configJson.sectionIndexes = this.sectionIndexes;
            }

            configJson.chartYAxes = configJson.chartYAxes.map((yAxis)=>{
                delete yAxis['chartItems'];
                return yAxis;
            });
            configJson = JSON.stringify(configJson);

            const self = this;
            const url = '/user/config/chartconfig/editjson';

            $.post(
                url,
                {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    id: self.chartConfiguration.id,
                    jobNumber: self.jobNumber,
                    data: configJson
                },
                function (result) {
                    if (result.error) {
                        console.warn(result.message);
                    } else {
                        location.reload();
                    }
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                const errorMessage = 'Failed to update chart configuration';
                console.warn(errorMessage, errorThrown);
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                } else {
                    //TODO: handle this
                }
            });
        },
        compareLayoutPosition: function(a,b) {
            if(a.y === b.y) {
                return a.x - b.x;
            }
            return a.y > b.y ? 1 : -1;
        },
        getSectionColumnCount: function() {
            if(this.chartConfiguration.controlsPosition === 'left' || this.chartConfiguration.controlsPosition === 'right') {
                return 1;
            }
            return Object.keys(this.usedSections).length;
        },
        getSectionMaxRows: function() {
            return (this.chartConfiguration.controlsPosition === 'top' || this.chartConfiguration.controlsPosition === 'bottom') ? 1 : Infinity;
        },
        filterByActivity: function(type) {
            if(type == 'Frac') {
                this.wellStageIntervals = this.wellStageIntervalsByActivityType?.frac?.wellStageActivityData;
            }else if(type == 'Wireline') {
                this.wellStageIntervals = this.wellStageIntervalsByActivityType?.wireline?.wellStageActivityData;
            }else{
                this.wellStageIntervals = this.wellStageIntervalsByActivityType?.all?.wellStageActivityData;
            }
            this.clearWellStageFilterSelections();
        },
        filterByWell: function(event) {
            this.selectedWellStageIntervalGroup = this.wellStageIntervals[event.target.target];
            this.selectedWellFilterDisplayString = this.wells[event.target.target].name;
            this.selectedWellFilterDisplayColor = this.wells[event.target.target].color;

            this.selectedStageIndex = this.setDefaultFilterStageNumber();
        },
        filterGhostByWell: function(event) {
            this.ghostLineStage = null;
            this.selectedGhostWellStageIntervalGroup = this.wellStageIntervals[this.ghostLineWell.index];
        },
        filterByStage: function(event) {
            this.selectedStageIndex = event.target.selectedIndex;
        },
        getFilterEndDates: function(additionalOffset = 0) {
            // additionalOffset will add padding around the start and end dates
            if (!this.currentWellStageFilterInterval?.startTime)
                return null;

            //Used to set value to current time if no end of stage is set yet (still in that stage)
            //Convert dates to ISO 8601 standard
            const targetStartTime = this.currentWellStageFilterInterval.startTime.replaceAll(' ', 'T') + 'Z';
            const targetEndTime = this.currentWellStageFilterInterval.endTime != null ? this.currentWellStageFilterInterval.endTime.replaceAll(' ', 'T') + 'Z' : null;

            const filterEndDates = {};
            filterEndDates.xMinDate = moment.utc(targetStartTime).subtract({hours: this.jobHourOffset, minutes: additionalOffset});
            filterEndDates.xMaxDate = targetEndTime ? moment.utc(targetEndTime).subtract({hours: this.jobHourOffset}).add({minutes: additionalOffset}) : moment.utc().add({ms: this.rightSideMsOffset}).add({minutes: additionalOffset});
            return filterEndDates;
        },
        onSaveGhostPlot: function(event) {
            //save ghost plot selections into local storage
            if(this.ghostLineWell) {
                this.jsonLocalStorage.ghostPlotSettings.well = this.ghostLineWell.index;
            }
            if(this.ghostLineStage) {
                this.jsonLocalStorage.ghostPlotSettings.stage = this.ghostLineStage.stageNumber;
            }

            this.jsonLocalStorage.ghostPlotSettings.channels = this.ghostLineChannels;
            this.jsonLocalStorage.ghostPlotSettings.lineSize = this.ghostLineSize;
            this.jsonLocalStorage.ghostPlotSettings.lineOpacity = this.ghostLineOpacity;

            this.jsonLocalStorage.save();

            //enable ghost plots and close the dialog
            this.ghostPlotEnabled = true;
            this.isSaving = true;

            this.$root.$emit('bv::hide::popover');
        },
        onClearGhostPlot: function(event) {
            //remove ghost plot selections from local storage
            this.jsonLocalStorage.ghostPlotSettings = {};

            this.jsonLocalStorage.save();

            //reset dialog to defaults
            this.ghostLineWell = null;
            this.selectedGhostWellStageIntervalGroup = null;
            this.ghostLineStage = null;
            this.ghostLineChannels = [];
            this.ghostLineSize = 1;
            this.ghostLineOpacity = 50;
        },
        onSetWellStageFilter: function(event) {
            //record of the state of current well-stage filter
            const args = {
                'event': event,
                'selectedWellStageInterval': this.selectedWellStageInterval,
                'selectedStageIndex': this.selectedStageIndex,
                'wellStageIntervals': this.wellStageIntervals,
                'selectedWellStageIntervalGroup': this.selectedWellStageIntervalGroup,
                'selectedWellFilterDisplayString': this.selectedWellFilterDisplayString,
                'selectedWellFilterDisplayColor': this.selectedWellFilterDisplayColor,
                'syncEvent': false
            };
            if (this.areChartsSynced && !this.useCustomScaleOnDateAxis) {
                args['syncEvent'] = true;
                this.$root.$emit('syncSetWellStageFilter', args);
            }
            else {
                this.setWellStageFilter(event, args);
            }

            this.$root.$emit('bv::hide::popover');
        },
        setWellStageFilter: function(event, wellStageFilterArgs) {
            if (wellStageFilterArgs['syncEvent']) {
                this.wellStageIntervals = wellStageFilterArgs['wellStageIntervals'];
                this.selectedWellStageIntervalGroup = wellStageFilterArgs['selectedWellStageIntervalGroup'];
                this.selectedWellFilterDisplayString = wellStageFilterArgs['selectedWellFilterDisplayString'];
                this.selectedWellFilterDisplayColor = wellStageFilterArgs['selectedWellFilterDisplayColor'];
                this.selectedWellStageInterval = wellStageFilterArgs['selectedWellStageInterval'];
            }

            const selectedStageIndex = wellStageFilterArgs['selectedStageIndex'];

            if(selectedStageIndex != null) {
                this.selectedStageIndex = selectedStageIndex;
                this.followingUpdates = false;
                this.currentWellStageFilterInterval = this.selectedWellStageInterval;

                this.currentSelectedStage = this.currentWellStageFilterInterval.stageNumber;
                this.currentWellFilterDisplayString = this.selectedWellFilterDisplayString;
                this.currentWellFilterDisplayColor = this.selectedWellFilterDisplayColor;

                const additionalMinutesOffset = 5;
                const filterEndDates = this.getFilterEndDates(additionalMinutesOffset);
                let chart = this.getLineChart();
                this.checkAnnotationCutOff(chart, filterEndDates.xMinDate.valueOf(), filterEndDates.xMaxDate.valueOf());
                this.refreshChartDataWithInterval(filterEndDates.xMinDate.valueOf(), filterEndDates.xMaxDate.valueOf(), true, true, additionalMinutesOffset);
                this.isFilterPresent = true;
            }
        },
        refreshChartDataWithInterval: function(from, to, clearData=true, saveChangeInZoomHistory=true, additionalOffset=0) {
            //calls to this function will trigger calls to download data from TSI, with the calculated interval, between the from and to dates
            this.createAggregateIntervals(from, to);
            if(saveChangeInZoomHistory) {
                this.zoomHistory.push({from, to});
            }

            if(!this.useCustomScaleOnDateAxis) {
                //if max date is now earlier than last known max, stop following the latest data
                //this check needs some wiggle room because the availability chart only updates every minute
                const snapToLatestMS = 5 * 60 * 1000;
                const now = moment.utc().valueOf();
                this.followingUpdates = to.valueOf() >= now + this.rightSideMsOffset - snapToLatestMS;

                this.setChartTimeAxisMinMax(from.valueOf(), to.valueOf());
                this.updateDownsampleWindow();
            }

            //reset filter if current chart range
            //does not match the filter range anymore
            const filterEndDates = this.getFilterEndDates(additionalOffset);
            if (filterEndDates) //returns null if no filter set
            {
                if (this.currentChartMin != filterEndDates.xMinDate.valueOf() || this.currentChartMax != filterEndDates.xMaxDate.valueOf())
                {
                    this.currentWellStageFilterInterval = null;
                    this.clearWellStageFilterSelections();
                }
            }

            if(clearData) {
                // reset and get all data with new window
                this.datawindow = {};
                this.rawdata = [];

                const self = this;
                $.each(this.tagToChartIndex, function(tagName, tagIndex) {
                    self.datacollection.datasets[tagIndex].data = [];
                });
                this.tagToChartIndex = {};

                if(!this.ghostPlotEnabled) {
                    $.each(this.ghostTagToChartIndex, function(tagName, tagIndex) {
                        self.datacollection.datasets[tagIndex].data = [];
                    });
                    this.ghostTagToChartIndex = {};
                }
            }

            if(this.$refs.chartTimeline)
            {
                this.$refs.chartTimeline.updateMessageMarkerPositions();
            }

            // calculate the interval based on minimum 1 second resolution for 3 hours of data.
            // for example:
            // 1 hour of data   = 1 second resolution = PT1S //minimum of 1 second resolution
            // 3 hours of data  = 1 second resolution = PT1S
            // 6 hours of data  = 2 second resolution = PT2S
            // 24 hours of data = 8 second resolution = PT8S
            // 5 days of data   = 40 second resolution = PT40S
            // 25 days of data  = 200 second resolution = PT3M //round down to the minute after 1 minute
            // 30 days of data  = 240 second resolution = PT4M

            let hoursDiffDuration = moment.duration(moment(to).diff(moment(from)));
            let hoursDiff = hoursDiffDuration.asHours();
            let conversionFactor = Math.round(hoursDiff / 3);
            if(conversionFactor < 1) {
                conversionFactor = 1;
            }

            let interval = 'PT' + conversionFactor + 'S';
            if(conversionFactor >= 60) {
                interval = 'PT' + Math.round(conversionFactor / 60) + 'M';
            }

            this.historicalData(from, to, interval);
            this.updateCommentTimelineData();
        },
        ghostPlotChanged: function() {
            // set values to false to indicate that chart axis should not be reset
            this.ghostPlotEnabled = false;
            this.changeAxis = false;
        },
        updateGhostPlotLineWell: function(event) {
            this.filterGhostByWell(event);
            this.ghostPlotChanged();
        },
        updateGhostPlotLineStage: function(event) {
            this.getGhostPlotIndexStart = this.ghostLineStage.startTime;
            this.ghostPlotChanged();
        },
        updateGhostPlotChannels: function(event) {
            this.ghostLineChannels = event;
            this.ghostPlotChanged();
        },
        ghostPlotClicked: function(event) {
            this.ghostPlotEnabled = !this.ghostPlotEnabled;
            if (!this.ghostPlotEnabled) {
                this.isSaving = true; // set to clear ghost plot
                this.changeAxis = false;
            }
        },
        onClearWellStageFilter: function(event) {
            if (this.areChartsSynced) {
                this.$root.$emit('syncClearWellStageFilter', event);
            }
            else {
                this.clearWellStageFilter(event);
            }
        },
        clearWellStageFilter: function(event) {
            const chart = this.getLineChart();

            this.isFilterPresent = false;
            this.followingUpdates = true;

            this.currentWellStageFilterInterval = null;

            this.clearWellStageFilterSelections();

            this.panChartToLatestTime(chart);
            this.resetZoom(chart);

            // reset and get all data with new window
            this.datawindow = {};
            this.rawdata = [];
            this.ghostrawdata = [];
            this.tagToChartIndex = {};
            this.ghostTagToChartIndex = {};

            //TODO: filter and ghost plot should be able to work together
            this.ghostPlotEnabled = false;

            this.historicalData();
        },
        clearWellStageFilterSelections: function() {
            this.selectedWellStageIntervalGroup = null;
            this.selectedWellFilterDisplayString = '-- No Well Selected--';
            this.selectedWellFilterDisplayColor = null;
            this.selectedWellStageInterval = '-1';

            this.selectedStageIndex = null;
            this.visibleAnnotation.forEach(annotation => {
                // reset all annotations that were visible to original display position (top left)
                annotation.options.label.position = "top";
                annotation.options.label.yAdjust = 0;
            });
            this.visibleAnnotation = [];
        },
        onWellSelectClicked() {
            const dropdown = this.$refs.well_dropdown_test;
            if (dropdown.classList.contains('show')) {
                dropdown.classList.remove('show');
            }else{
                dropdown.classList.add('show');
            }
        },
        fetchStageMarkerData: function() {
            const url = '/wellStageMarkers/' + this.jobNumber;
            const self = this;
            $.get(
                url,
                {},
                function (result) {
                    if (result.error) {
                        console.warn(result.message);
                    } else {
                        self.stageStartMarkers = result;
                    }
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                console.warn('fail downloadData: stageStartHandshakes', errorThrown);
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                    self.hasAuthError = true;
                } else {
                //TODO: handle this
                }
            });
        },
        fetchWellStageIntervals: function() {
            const url = '/wellStageIntervals/' + this.jobNumber;
            const self = this;
            $.get(
                url,
                {
                    getCurrentStages: true
                },
                function (result) {
                    if (result.error) {
                        console.warn(result.message);
                    } else {
                        self.wellStageIntervalsByActivityType = result;
                        const activityKey = self.selectedWellStageIntervalsActivityTypeOption.toLowerCase();
                        self.wellStageIntervals = result[activityKey]?.wellStageActivityData;

                        //update ghost plot controls with local storage if exists
                        if (self.jsonLocalStorage.ghostPlotSettings) {
                            self.ghostLineWell = self.wells.find(well => {return well.index == self.jsonLocalStorage.ghostPlotSettings.well;}) ?? null;
                            if (self.ghostLineWell) {
                                self.filterGhostByWell();
                                self.ghostLineStage = self.selectedGhostWellStageIntervalGroup[self.jsonLocalStorage.ghostPlotSettings.stage] ?? null;
                                self.ghostPlotIndex.time = self.ghostLineStage.startTime;
                            }
                        }
                    }
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                console.warn('fail downloadData: well stage interval', errorThrown);
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                    self.hasAuthError = true;
                } else {
                //TODO: handle this
                }
            });
        },
        onExportIconPress: function(chartConfiguration) {
            this.isChartExportModalOpen = true;
            if(this.$refs?.chartExport) {
                this.$refs.chartExport.changeModalVisibility(true);
            }
        },
        hideTooltipWhenModalOrPopoverOpen: function() {
            let chart = this.$refs['lineChart'].$data;
            let tooltipEl = document.getElementById('chartjs-tooltip-' + chart._chart.id);
            const isHovered = chart._chart.canvas.matches(':hover'); //is chart canvas hovered
            if (tooltipEl) {
                if (this.isAnyModalOrPopoverOpen) {
                    tooltipEl.classList.remove('move-to-front');
                }
                else if (this.areAllModalsAndPopoversClosed) {
                    if (isHovered) {
                        tooltipEl.classList.add('move-to-front');
                    }
                }
            }
        },
        setShowLegendValue: function(chartConfiguration) {
            const isShowLegend = chartConfiguration.showLegend===1? true: false;
            Vue.set(this.options.legend, 'display', isShowLegend);
        },
        generateDataSources: function(chartConfiguration) {
            //Should take the data from the config, yaxis, chartitems, and tags and create the data sources from them
            const dataSources = [];

            if(chartConfiguration && chartConfiguration.valueAxes) {
                chartConfiguration.valueAxes.forEach(function(axis) {
                    if(axis.chartItems) {
                        axis.chartItems.forEach(function(chartItem) {
                            dataSources.push({
                                name: chartItem.tagName,
                                isCasing: false,
                                description: chartItem.friendlyName,
                                color: chartItem.color,
                                sectionKey: chartItem.sectionKey,
                                lineWidth: chartItem.lineWidth,
                                showDataPoints: chartItem.showDataPoints,
                                measurement: axis.label,
                                unit: chartItem.unit,
                                decimalPrecision: chartItem.decimalPrecision,
                                yAxisID: 'generated-axis-' + chartItem.chartYAxis_key
                            });
                        });
                    }
                });
            }
            return dataSources;
        },
        getChartitemStyle: function(textOptions, fracItem, styleType, activeStatus = false) {
            const chartItem = this.getChartItem(fracItem.name);
            if (!chartItem) {
                return;
            }
            const labelColor = chartItem?.wellColor ? chartItem.wellColor : chartItem.color;
            const style = {
                fontWeight: textOptions.bold ? 'bold' : 'normal',
                fontStyle: textOptions.italic ? 'italic' : 'normal',
                textDecoration: textOptions.underline ? 'underline !important' : 'none',
                color: textOptions.color ? labelColor : (activeStatus? this.getTitleColorForBG(chartItem.bgColor) : 'white'),
                lineHeight: '1', whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'ellipsis'
            };
            if (textOptions.fontSize === 'auto') {
                const parentElement = document.getElementById('chartitem-' + fracItem.name);
                const parentDimensions = {
                    width: parentElement ? parentElement.getBoundingClientRect().width : 0,
                    height: parentElement ? parentElement.getBoundingClientRect().height : 0
                };
                if (parentDimensions.width > 0) {
                    let stringLength = 0;
                    if (styleType === 'label') {
                        stringLength = chartItem?.wellname ? chartItem.wellname.length : chartItem.friendlyName.length;
                    } else {
                        stringLength = String(this.getFormattedChartValue(fracItem)).length;
                    }
                    let fontSize = stringLength > 5 ? parentDimensions.width / stringLength : 11;
                    if (fontSize > parentDimensions.height / 3.25) {
                        fontSize = parentDimensions.height / 3.25;
                    }
                    style.fontSize = Math.floor(fontSize) + 'px';
                }
            } else {
                if (styleType == 'label') {
                    style.fontSize = textOptions.fontSize ? textOptions.fontSize : '0.7em';
                } else {
                    style.fontSize = textOptions.fontSize ? textOptions.fontSize : '1.0em';
                }
            }
            return style;
        },
        getLabelTextOptions: function(sectionIndex, chartitemIndex) {
            //data text options
            if (this.chartItemLayouts[this.usedSectionsIDs[sectionIndex]] && this.chartItemLayouts[this.usedSectionsIDs[sectionIndex]][chartitemIndex]) {
                if(Array.isArray(this.chartItemLayouts[this.usedSectionsIDs[sectionIndex]][chartitemIndex].labelTextOptions)) {
                    this.chartItemLayouts[this.usedSectionsIDs[sectionIndex]][chartitemIndex].labelTextOptions = {};
                }
                if (!this.chartItemLayouts[this.usedSectionsIDs[sectionIndex]][chartitemIndex].labelTextOptions.fontSize) {
                    this.chartItemLayouts[this.usedSectionsIDs[sectionIndex]][chartitemIndex].labelTextOptions.fontSize = '0.7em';
                }


                return this.chartItemLayouts[this.usedSectionsIDs[sectionIndex]][chartitemIndex].labelTextOptions;
            } else if (this.chartItemLayouts[this.usedSectionsIDs[sectionIndex]]) {
                console.warn('There is no chart item with index ' + chartitemIndex);
            } else if (this.usedSectionsIDs[sectionIndex]) {
                console.warn('There is no section with id ' + this.usedSectionsIDs[sectionIndex]);
            } else {
                console.warn('There is no id for section in index + ' + sectionIndex);
            }
        },
        getDataTextOptions: function(sectionIndex, chartitemIndex) {
            //data text options
            if (this.chartItemLayouts[this.usedSectionsIDs[sectionIndex]] && this.chartItemLayouts[this.usedSectionsIDs[sectionIndex]][chartitemIndex]) {
                if(Array.isArray(this.chartItemLayouts[this.usedSectionsIDs[sectionIndex]][chartitemIndex].dataTextOptions)) {
                    this.chartItemLayouts[this.usedSectionsIDs[sectionIndex]][chartitemIndex].dataTextOptions = {};
                }
                if (!this.chartItemLayouts[this.usedSectionsIDs[sectionIndex]][chartitemIndex].dataTextOptions.fontSize) {
                    this.chartItemLayouts[this.usedSectionsIDs[sectionIndex]][chartitemIndex].dataTextOptions.fontSize = '1.0em';
                }
                return this.chartItemLayouts[this.usedSectionsIDs[sectionIndex]][chartitemIndex].dataTextOptions;
            } else if (this.chartItemLayouts[this.usedSectionsIDs[sectionIndex]]) {
                console.warn('There is no chart item with index ' + chartitemIndex);
            } else if (this.usedSectionsIDs[sectionIndex]) {
                console.warn('There is no section with id ' + this.usedSectionsIDs[sectionIndex]);
            } else {
                console.warn('There is no id for section in index + ' + sectionIndex);
            }
        },
        updateChartItemsWithData: function(data, dataSources, chartItemsContainer, wells) {
            dataSources.forEach(function(dataSource) {
                if(this.tags.indexOf(dataSource.name) > -1) {
                    //Add the appropriate controls data to set them as on here
                    const dataObject = data.filter(dataObj => dataObj.tagName == dataSource.name);
                    const hasData = dataObject && dataObject.length == 1;

                    const regexWellheadTagMatch = /wellhead\_\d+\_.*/;
                    const wellheadTagMatches = dataSource.name.match(regexWellheadTagMatch);

                    //pressureWell is a special tag name and we don't need to show if not reporting
                    if(hasData || !dataSource.name.startsWith('pressureWell')) {
                        const dataValue = hasData ? parseFloat(dataObject[0].dataVal).toFixed(2) : '--';
                        const dataTimestamp = hasData ? dataObject[0].dateTimestamp : null;
                        const dataLastReportedStr = hasData ? dataObject[0].lastReportedStr : null;
                        if(chartItemsContainer.items[dataSource.name]) {
                            for(const sectionIndex in this.usedSections) {
                                const chartItems = this.usedSections[sectionIndex];
                                chartItems.forEach(function(chartItem) {
                                    if(dataSource.name == chartItem.name) {
                                        Vue.set(chartItem, 'value', dataValue);
                                        Vue.set(chartItem, 'timestamp', dataTimestamp);
                                        Vue.set(chartItem, 'lastReportedStr', dataLastReportedStr);
                                    }
                                });
                            }
                        }
                        else {
                            if(wells && dataSource.name.startsWith('pressureWell')) {
                                const wellTagSplit = dataSource.name.split('pressureWell');
                                if(wellTagSplit.length > 0) {
                                    const wellIndex = parseInt(wellTagSplit[1]);
                                    const well = wells[wellIndex];

                                    if(well) {
                                        //set fields for well
                                        dataSource['description'] = well.name;
                                        dataSource['color'] = well.color;
                                    }
                                }
                            }else if(wells && wellheadTagMatches) {
                                const regexWellheadIdMatch = /\d+/;
                                const idMatch = dataSource.name.match(regexWellheadIdMatch);
                                //wellhead indexing starts at 1 instead of 0 so need to shift by 1
                                const wellIndex = parseInt(idMatch[0]) - 1;
                                const well = wells[wellIndex];

                                if(well) {
                                    //set fields for well
                                    dataSource['description'] = well.name;
                                    dataSource['color'] = well.color;
                                }
                            }

                            dataSource['value'] = dataValue;
                            dataSource['timestamp'] = dataTimestamp;
                            dataSource['lastReportedStr'] = dataLastReportedStr;
                            if(!('yAxisID' in dataSource)) {
                                dataSource['yAxisID'] = dataSource.measurement+'-'+dataSource.unit;
                            }
                            dataSource['yAxisLabel'] = dataSource.measurement;
                            chartItemsContainer.items[dataSource.name] = dataSource;
                        }
                    }
                }
            }, this);
        },
        handleCreateCommentButtonClicked: function() {
            this.followingUpdates = false;

            this.panChartToLatestTime();
            this.$nextTick(() => {
                this.$refs['chartTimeline'].handleCreateCommentButtonClicked();
            });
        },
        setClassesForChartItems(columnCount) {
            const classObject = {
                'text-center': true,
                'border': true,
                'border-secondary': true,
                'rounded': true,
                'mb-1': true
            };

            return classObject;
        },
        followToLatestTime: function() {
            if(this.isScrubberHidden) {
                this.followingUpdates = true;
                this.panChartToLatestTime();

                if(this.useCustomScaleOnDateAxis) {
                    const now = moment.utc().valueOf();
                    this.refreshChartDataWithInterval(now - (CUSTOM_X_AXIS_WINDOW_HRS * MILLIS_PER_HR), now, true);
                }
            }
            else {
                const chart = this.getLineChart();
                let windowSize = (chart.scales['date-axis'].max - chart.scales['date-axis'].min) / MILLIS_PER_HR;

                const newXMin = moment.utc().add({hours: -windowSize}).valueOf() + this.rightSideMsOffset;
                const newXMax = moment.utc().valueOf() + this.rightSideMsOffset;
                if (this.areChartsSynced) {
                    this.syncChartMovement(chart.id, newXMin, newXMax, true);
                }

                this.refreshChartDataWithInterval(newXMin, newXMax);
            }
            this.$refs?.chartScrubber.resetScrubberBrush();
        },
        panChartToLatestTime: function() {
            const chart = this.getLineChart();
            if(this.useCustomScaleOnDateAxis || chart.scales['date-axis'] == undefined) {
                return;
            }

            if(!chart) { //need chart to be rendered to run this code ( need a reference )
                return;
            }

            let windowSize = (chart.scales['date-axis'].max - chart.scales['date-axis'].min) / MILLIS_PER_HR;

            if(this.jobEnd && this.followingUpdates) {
                let hoursInPast = moment.utc(this.jobEnd).subtract({ hours: this.defaultZoomWindowHrs }).valueOf();

                this.setChartTimeAxisMinMax(hoursInPast, moment.utc(this.jobEnd).valueOf());

                if(this.chartConfiguration.isVertical) {
                    chart.options.plugins.zoom.pan.rangeMin.y = null;
                    chart.options.plugins.zoom.pan.rangeMax.y = moment.utc(this.jobEnd).valueOf();
                    chart.options.plugins.zoom.zoom.rangeMin.y = moment.utc(this.dashboardData.jobStart).valueOf() - this.rightSideMsOffset;
                    chart.options.plugins.zoom.zoom.rangeMax.y = moment.utc(this.jobEnd).valueOf();
                }
                else {
                    chart.options.plugins.zoom.pan.rangeMin.x = null;
                    chart.options.plugins.zoom.pan.rangeMax.x = moment.utc(this.jobEnd).valueOf();
                    chart.options.plugins.zoom.zoom.rangeMin.x = moment.utc(this.dashboardData.jobStart).valueOf() - this.rightSideMsOffset;
                    chart.options.plugins.zoom.zoom.rangeMax.x = moment.utc(this.jobEnd).valueOf();
                }
            } else {
                this.setChartTimeAxisMinMax(moment.utc().add({hours: -windowSize}).valueOf() + this.rightSideMsOffset, moment.utc().valueOf() + this.rightSideMsOffset);

                const newXMax = Math.max(this.ghostPlotXMax, moment.utc().valueOf());

                if(this.chartConfiguration.isVertical) {
                    chart.options.plugins.zoom.pan.rangeMin.y = null;
                    chart.options.plugins.zoom.pan.rangeMax.y = newXMax + this.rightSideMsOffset;
                    chart.options.plugins.zoom.zoom.rangeMin.y = moment.utc(this.dashboardData.jobStart).valueOf() - this.rightSideMsOffset;
                    chart.options.plugins.zoom.zoom.rangeMax.y = newXMax + this.rightSideMsOffset;
                }
                else {
                    chart.options.plugins.zoom.pan.rangeMin.x = null;
                    chart.options.plugins.zoom.pan.rangeMax.x = newXMax + this.rightSideMsOffset;
                    chart.options.plugins.zoom.zoom.rangeMin.x = moment.utc(this.dashboardData.jobStart).valueOf() - this.rightSideMsOffset;
                    chart.options.plugins.zoom.zoom.rangeMax.x = newXMax + this.rightSideMsOffset;
                }
            }

            chart.update();
        },
        setChartTimeAxisMinMax(min, max) {
            const chart = this.getLineChart();
            if(this.chartConfiguration.isVertical) {
                chart.options.scales.yAxes[0].ticks.min = min;
                chart.options.scales.yAxes[0].ticks.max = max;
            }
            else {
                chart.options.scales.xAxes[0].ticks.min = min;
                chart.options.scales.xAxes[0].ticks.max = max;
            }

            this.currentChartMin = min;
            this.currentChartMax = max;
        },
        setChartValueAxisMinMax(id, min, max) {
            const chart = this.getLineChart();
            let index = null;
            if(this.chartConfiguration.isVertical) {
                index = chart.options.scales.xAxes.findIndex(obj => obj.id === id);
                chart.options.scales.xAxes[index].ticks.min = min;
                chart.options.scales.xAxes[index].ticks.max = max;
            } else {
                index = chart.options.scales.yAxes.findIndex(obj => obj.id === id);
                chart.options.scales.yAxes[index].ticks.min = min;
                chart.options.scales.yAxes[index].ticks.max = max;
            }
        },
        updateDownsampleWindow() {
            const chart = this.getLineChart();

            if(this.chartConfiguration.isVertical) {
                const min = chart.options.scales.yAxes[0].ticks.min;
                const max = Math.min(moment.utc().valueOf(), chart.options.scales.yAxes[0].ticks.max - this.rightSideMsOffset);
                this.downsampleWindow = {min, max};
            }
            else {
                const min = chart.options.scales.xAxes[0].ticks.min;
                const max = Math.min(moment.utc().valueOf(), chart.options.scales.xAxes[0].ticks.max - this.rightSideMsOffset);
                this.downsampleWindow = {min, max};
            }
        },
        getLineChart() {
            if(this.$refs['lineChart']) { //this means the component is mounted;
                return this.$refs['lineChart'].$data._chart;
            } else {
                return null;
            }
        },
        onExpandButtonPress: function(index) {
            const sectionID = this.usedSectionsIDs[index];
            const areaRefName = 'axisDataArea-' + sectionID;
            const barsButtonRef = 'axisDataArea-' + sectionID + '-eye';

            const visibleState = this.toggleVisible(areaRefName);
            const ref = this.$refs[barsButtonRef][0];

            if(visibleState) {
                ref.classList.remove('fa-caret-down');
                ref.classList.add('fa-caret-up');
            }else{
                ref.classList.remove('fa-caret-up');
                ref.classList.add('fa-caret-down');
            }

            // update local storage values
            if(visibleState) {
                this.jsonLocalStorage.visibleSections.push(sectionID);
            } else {
                const index = this.jsonLocalStorage.visibleSections.indexOf(sectionID);

                if(index > -1) {
                    this.jsonLocalStorage.visibleSections.splice(index, 1);
                }
            }

            this.jsonLocalStorage.save();
            this.onExpandButtonPressCallCount++; //used to trigger a computed style

        },
        isSectionHidden(id) {
            return this.jsonLocalStorage.visibleSections.indexOf(id) === -1;
        },
        toggleVisible: function(targetRefName) {
            const ref = this.$refs[targetRefName][0];

            if(ref.style.display == '') {
                ref.style.display = 'none';
                return false;
            }else{
                ref.style.display = '';
                return true;
            }
        },
        setChartFontSize: function(target, isVerticalChart) {
            const independentAxis = isVerticalChart? 'yAxes':'xAxes';
            const dependantAxis = isVerticalChart? 'xAxes':'yAxes';
            const chart = this.getLineChart();
            switch(target) {
            case 'independentLabel':
                chart.options.scales[independentAxis].forEach(axis => {
                    axis.scaleLabel.fontSize = this.localChartAxisOptions.independentAxis.labelFontSize;
                });
                break;
            case 'independentTick':
                chart.options.scales[independentAxis].forEach(axis => {
                    axis.ticks.fontSize = this.localChartAxisOptions.independentAxis.tickFontSize;
                });
                break;
            case 'dependantLabel':
                chart.options.scales[dependantAxis].forEach(axis => {
                    axis.scaleLabel.fontSize = this.localChartAxisOptions.dependantAxis.labelFontSize;
                });
                break;
            case 'dependantTick':
                chart.options.scales[dependantAxis].forEach(axis => {
                    axis.ticks.fontSize = this.localChartAxisOptions.dependantAxis.tickFontSize;
                });
                break;
            case 'all':
                chart.options.scales[independentAxis].forEach(axis => {
                    axis.scaleLabel.fontSize = this.localChartAxisOptions.independentAxis.labelFontSize;
                });
                chart.options.scales[independentAxis].forEach(axis => {
                    axis.ticks.fontSize = this.localChartAxisOptions.independentAxis.tickFontSize;
                });
                chart.options.scales[dependantAxis].forEach(axis => {
                    axis.scaleLabel.fontSize = this.localChartAxisOptions.dependantAxis.labelFontSize;
                });
                chart.options.scales[dependantAxis].forEach(axis => {
                    axis.ticks.fontSize = this.localChartAxisOptions.dependantAxis.tickFontSize;
                });
                break;
            }
            chart.update();
        },
        dateAxisGenerated: function() {
            const axisDef = this.chartConfiguration.dateAxis;
            return {
                id: 'custom-date-axis',
                type: axisDef.logarithmic ? 'logarithmic' : 'linear',
                display: this.isDateAxisHidden? false:true,
                position: axisDef.position,
                scaleLabel: {
                    display: true,
                    labelString: axisDef.label,
                    fontColor: axisDef.color ? axisDef.color : 'white',
                    fontSize: this.localChartAxisOptions.independentAxis.labelFontSize
                },
                ticks: {
                    min: axisDef.min,
                    max: axisDef.max,
                    autoSkipPadding: 5,
                    maxRotation: 0,
                    callback: GlobalFunctions.commarize,
                    fontColor: axisDef.color ? axisDef.color : 'white',
                    reverse: this.chartConfiguration.isVertical,
                    fontSize: this.localChartAxisOptions.independentAxis.tickFontSize
                },
                gridLines: {
                    display: this.chartConfiguration.showVerticalGridlines,
                    color: this.chartConfiguration.verticalGridlinesColor
                }
            };
        },
        valueAxesGenerated: function() {
            const axesDef = [];
            const primaryAxis = this.chartConfiguration.valueAxes.find((axes)=>axes.isPrimaryAxis);

            let numberOfTicks = DEFAULT_NUMBER_OF_TICKS;
            if(primaryAxis && primaryAxis.ticks) {
                numberOfTicks = primaryAxis.ticks;
            }

            for(const axisIndex in this.chartConfiguration.valueAxes) {
                let isPrimary = false;
                if (this.chartConfiguration.valueAxes[axisIndex].isPrimaryAxis) {
                    isPrimary = true;
                }
                if(this.chartConfiguration.valueAxes[axisIndex].chartItems.length == 0) {
                    continue;
                }
                const axisDef = this.chartConfiguration.valueAxes[axisIndex];

                const hideYAxis = axisDef.hideYAxis == 1 ? true : false;
                const range = axisDef.max - axisDef.min;
                if (axisDef.ticks) {
                    numberOfTicks= axisDef.ticks;
                } else if(numberOfTicks && !axisDef.alignToPrimaryAxis) {
                    numberOfTicks = DEFAULT_NUMBER_OF_TICKS;
                }
                const stepSize = range/(numberOfTicks);
                let axisColor = axisDef.useCustomGridlinesColor ?
                    axisDef.customGridlinesColor : (axisDef?.color || 'fff'); //default white if no color
                if (axisColor.length === 9) { //if alpha value exists in hex colour for opacity, remove it so colour is opaque
                    axisColor = axisColor.slice(0, -2);
                }

                const showAxisLine = this.chartConfiguration.showVerticalGridlines ? false : true;
                const self = this;

                axesDef.push({
                    id: 'generated-axis-' + axisDef.key,
                    isPrimaryAxis: isPrimary ? true : false,
                    alignToPrimaryAxis: axisDef.alignToPrimaryAxis,
                    isStreamingDisconnected: this.isStreamingDisconnected,
                    isFilterPresent: this.isFilterPresent,
                    key: axisDef.key,
                    type: axisDef.logarithmic ? 'logarithmic' : 'linear',
                    display: (!hideYAxis)? 'auto': false,
                    position: axisDef.position,
                    scaleLabel: {
                        fontSize: this.localChartAxisOptions.dependantAxis.labelFontSize,
                        display: true,
                        labelString: axisDef.label,
                        fontColor: axisDef.color ? axisDef.color : 'white'
                    },
                    ticks: {
                        min: axisDef.min,
                        max: axisDef.max,
                        stepSize,
                        suggestedMin: axisDef.min,
                        suggestedMax: axisDef.max,
                        autoSkipPadding: 5,
                        callback: GlobalFunctions.commarize,
                        fontColor: axisDef.color ? axisDef.color : 'white',
                        fontSize: this.localChartAxisOptions.dependantAxis.tickFontSize
                    },
                    beforeBuildTicks: function(axis) {
                        self.$nextTick(() => {
                            let mainAxis = '';
                            if (axis.chart.scales['date-axis']) {
                                mainAxis = 'date-axis';
                            } else if (axis.chart.scales['custom-date-axis']) {
                                mainAxis = 'custom-date-axis';
                            } else {
                                console.error('Chart main axis not found');
                                return;//missing main axis
                            }
                            //gated so interval matching only occurs if a primary axis is set
                            let primaryAxis = false;
                            for (const scaleName in axis.chart.scales) {
                                const scale = axis.chart.scales[scaleName];
                                if (primaryAxis) { //found primary axis, no need to search anymore
                                    break;
                                } else if (scale.options?.isPrimaryAxis) {
                                    primaryAxis = scale;
                                }
                            }

                            // if primary axis does not exist, exit now
                            // if not following live updates, allow the chart axes to update
                            if (!primaryAxis || !self.followingUpdates)
                                return;

                            const calculateMinMax =  (stepSize, min, max) => {
                                const newMin = min - stepSize;
                                const newMax  = max + stepSize;
                                return [newMin, newMax];
                            };

                            if (axis.options.isPrimaryAxis && axis.ticksAsNumbers != null && axis.ticksAsNumbers.length > 0) {
                                let stepSize = 0;

                                axis.ticksAsNumbers.reduce((prevValue, nextValue)=> {
                                    const calculatedStepSize = Math.abs(prevValue - nextValue);
                                    if(stepSize < calculatedStepSize) {
                                        stepSize = calculatedStepSize;
                                    }
                                    return nextValue;
                                });
                                if(!axis.isStreamingDisconnected && !axis.isFilterPresent) {
                                    const axisReversed = axis.chart.scales[mainAxis].options.ticks?.reverse == true ?? false;
                                    const minBeforeLast = axisReversed? axis.ticksAsNumbers[1]:axis.ticksAsNumbers[axis.ticksAsNumbers.length-2];
                                    const maxBeforeLast = axisReversed? axis.ticksAsNumbers[axis.ticksAsNumbers.length-2]:axis.ticksAsNumbers[1];
                                    const newMinMax = calculateMinMax(stepSize, minBeforeLast, maxBeforeLast);
                                    axis.options.ticks.min = newMinMax[0];
                                    axis.options.ticks.max = newMinMax[1];
                                    axis.options.ticks.stepSize = stepSize;
                                }
                            } else if(axis.options.alignToPrimaryAxis &&  axis.ticksAsNumbers != null && axis.ticksAsNumbers.length > 0) {
                                const divideAmount = primaryAxis.ticksAsNumbers.length-1;

                                const stepSize = Math.ceil(axis._valueRange/(divideAmount));
                                axis.options.ticks.max = axis.options.ticks.min + stepSize*divideAmount;
                                axis.options.ticks.stepSize = stepSize;
                            }
                        })
                    },
                    gridLines: {
                        display: axisDef.displayGridlines,
                        drawBorder: showAxisLine,
                        color: axisColor,
                        zeroLineColor: axisColor
                    }
                });
            }
            return axesDef;
        },
        setAxisDisplay: function(axisName, displayState) {
            const axes = this.valueAxesGenerated();
            const targetAxis = axes.find(function(axis) {
                return axis.id === axisName;
            });

            targetAxis.display = displayState;
        },
        getAxisVisible: function(axisName) {
            const axes = this.valueAxesGenerated();
            const targetAxis = axes.find(function(axis) {
                return axis.id === axisName;
            });

            if(targetAxis.display == 'false') {
                return false;
            }else{
                return true;
            }
        },
        onAddChartAxis() {
            //STUB NEED TO REIMPLEMENT THIS
        },
        onEditYAxisInModal(axis) {
            const selectedConfig = { ...this.chartConfiguration, data: this.chartConfigurationData};

            selectedConfig.data.chartYAxes = this.chartConfigurationData.chartYAxes.map((yaxes)=>{
                if (yaxes.key === axis.key) {
                    return {
                        ...axis,
                        color: yaxes.color ? yaxes.color : 'white'
                    };
                } else {
                    return {
                        ...yaxes,
                        color: yaxes.color ? yaxes.color : 'white'
                    };
                }
            });
            selectedConfig.data = JSON.stringify(selectedConfig.data);

            this.$root.$emit('OPEN_CHART_CONFIG_MODAL',
                {
                    chartConfiguration: selectedConfig,
                    dashboardItem: this.dashboardItem,
                    selectedYAxis: axis,
                    quickEditModalAccess: true
                });
        },
        onEditChartConfiguration(chartItemIndex = null, sectionIndex = null) {
            const sectionKey = sectionIndex !== null ? this.usedSectionsIDs[sectionIndex] : null;

            //update configuration data so it is current in the chart modal as well.

            //remove unwanted chart item keys before passing to chart config modal
            const selectedConfig = {
                ...this.chartConfiguration,
                data: this.chartConfigurationData
            };

            if (this.chartConfigurationData?.chartYAxes?.length) {
                selectedConfig.data.chartYAxes = this.chartConfigurationData.chartYAxes.map((yaxes)=>{
                    return {
                        displayGridlines: yaxes.displayGridlines,
                        hideYAxis: yaxes.hideYAxis,
                        key: yaxes.key,
                        label: yaxes.label,
                        logarithmic: yaxes.logarithmic,
                        max: yaxes.max,
                        min: yaxes.min,
                        position: yaxes.position,
                        ticks: yaxes.ticks,
                        color: yaxes.color ? yaxes.color : 'white',
                        isPrimaryAxis: yaxes.isPrimaryAxis,
                        alignToPrimaryAxis: yaxes.alignToPrimaryAxis
                    };
                });
            }

            selectedConfig.data = JSON.stringify(selectedConfig.data);

            if(this.isFeatureFlagged("CHART_CONFIG_REFACTOR")){
                    this.$refs.TimeSeriesConfigModal.open({
                    chartConfiguration: selectedConfig
                });
            }
            else{
                this.$root.$emit('OPEN_CHART_CONFIG_MODAL',
                {
                    chartConfiguration: selectedConfig,
                    dashboardItem: this.dashboardItem,
                    sectionKey: sectionKey,
                    chartItemIndex: chartItemIndex,
                });
                this.isChartConfigModalOpen = true;
            }


        },
        onDeleteChartItem(chartItemIndex, sectionIndex) {
            let key = this.chartConfigurationData.orderedSections[sectionIndex].key;
            const removedItemIndex = this.chartItemLayouts[key][chartItemIndex].i;
            this.chartItemLayouts[key].splice(chartItemIndex, 1);
            this.chartConfigurationData.orderedSections[sectionIndex].orderedChartItems.splice(removedItemIndex, 1);
            this.usedSections[sectionIndex].splice(removedItemIndex, 1);
            // shift all of the index references to make them point to the correct item in orderedChartItems
            this.chartItemLayouts[key].forEach(chartItemNode => {
                if(chartItemNode.i > removedItemIndex) chartItemNode.i--;
            });
        },
        getWellboreWell: function(tagName) {
            const targetTag = '_pressure_wellbore';
            if(tagName) {
                if(tagName.substr(-(targetTag.length)) === targetTag) {
                    const index = (tagName.length - (targetTag.length+1));
                    const wellNumber = tagName.charAt(index) - 1;
                    const well = this.wells.find((well)=>well.index == wellNumber);
                    return well;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        chartItemColor: function (tagName) {
            const chartItem = this.getChartItem(tagName);
            if(chartItem) {
                const well = this.getWellboreWell(chartItem.tagName);
                const lineColor = well? (chartItem.customLineColor == true ? chartItem.color : well.color) : chartItem.color;
                const borderColor = this.paramChecked[tagName] || !this.shouldDrawGraph ?  lineColor + ' !important' : null;
                const backgroundColor = this.paramChecked[tagName] || !this.shouldDrawGraph ? chartItem.bgColor + ' !important' : null;
                const textColor = (this.paramChecked[tagName] || !this.shouldDrawGraph) && chartItem.bgColor ? this.getTitleColorForBG(chartItem.bgColor) : null;

                let chartItemStyle = {
                    color: textColor,
                    borderColor: borderColor,
                    backgroundColor: backgroundColor
                };

                const eventAlert = this.thresholdAlerts.find(alert => alert.tagName == tagName);
                if (eventAlert) {
                    switch (eventAlert.alertLevel) {
                    case 'warning':
                        chartItemStyle = {
                            ...chartItemStyle,
                            '-webkit-animation-name': 'warning',
                            '-webkit-animation-iteration-count': 'infinite',
                            '-webkit-animation-duration': '2s'
                        };
                        break;
                    case 'critical':
                        chartItemStyle = {
                            ...chartItemStyle,
                            '-webkit-animation-name': 'critical',
                            '-webkit-animation-iteration-count': 'infinite',
                            '-webkit-animation-duration': '2s'
                        };
                        break;
                    case 'failed':
                        chartItemStyle = {
                            ...chartItemStyle,
                            '-webkit-animation-name': 'failed',
                            '-webkit-animation-iteration-count': 'infinite',
                            '-webkit-animation-duration': '2s'
                        };
                        break;
                    }
                }

                return chartItemStyle;
            }
        },
        chartItemValue: function(chartItem) {
            return chartItem.isCasing ? this.casingPressures[chartItem.index] : chartItem.value;
        },
        filterLegendItem: function(legendItem, chartData) {
            const isGhostData = legendItem.datasetIndex >= Object.keys(this.tagToChartIndex).length;

            return !legendItem.hidden && !isGhostData;
        },
        formatScaleWithJobLocalTime: function(value, index, ticks) {
            // remove the first and last label to prevent overlap
            if (index === 0) return;
            if (index === (ticks.length - 1)) return;
            let xAxisAgeHrs = INITIAL_WINDOW_HRS;
            const chart = this.getLineChart();

            if(chart) {
                //if the min is older than INITIAL_WINDOW_HRS * 2, show the full date
                xAxisAgeHrs = (moment.utc().valueOf() - chart.scales['date-axis'].min) / MILLIS_PER_HR;
            }

            const datetimeValue = moment(value).utc();
            datetimeValue.add({hours: this.jobHourOffset});
            if(this.jobEnd != null) {
                return datetimeValue.format('MMM Do, h:mm a');
            }else if(xAxisAgeHrs <= INITIAL_WINDOW_HRS * 2) {
                return datetimeValue.format('h:mm a');
            }else {
                return datetimeValue.format('MMM Do, h:mm a');
            }
        },
        formatTooltip: function(tooltipItem) {
            const isGhostData = tooltipItem.datasetIndex >= Object.keys(this.tagToChartIndex).length;

            let label = this.datacollection.datasets[tooltipItem.datasetIndex].label;
            if(isGhostData) {
                label = label + ' (ghost)';
            }

            const decimals = this.getChartItem(this.datacollection.datasets[tooltipItem.datasetIndex].tagName)?.decimalPrecision ?? 2;

            let dateValue = tooltipItem.label;
            let yValue = tooltipItem.value;
            if(isNaN(yValue)) {
                return label + this.addTextSpace() + "loading...";
            }

            if(this.chartConfiguration.isVertical) {
                dateValue = tooltipItem.value;
                yValue = tooltipItem.label;
            }

            let retValue = label + this.addTextSpace() + GlobalFunctions.roundAccurately(parseFloat(yValue),decimals).toFixed(decimals);
            if(!this.useCustomScaleOnDateAxis) {
                const datetimeValue = moment(parseInt(dateValue)).utc();
                datetimeValue.add({hours: this.jobHourOffset});
                if(!isGhostData) {
                    let friendlyDateFormat = 'MMMM Do h:mm:ss a';
                    let durationSelected = (this.currentChartMax - this.currentChartMin) / MILLIS_PER_HR;
                    if (durationSelected) {
                        if (Math.round(durationSelected) > 24) {
                            retValue = retValue + this.addTextSpace() +'at ' + datetimeValue.format(friendlyDateFormat);
                        } else {
                            retValue = retValue + this.addTextSpace() +'at ' + datetimeValue.format('h:mm:ss a');
                        }
                    } else {
                        retValue = retValue + this.addTextSpace() +'at ' + datetimeValue.format('h:mm:ss a');
                    }
                }
            }
            else {
                if(!isGhostData) {
                    retValue = retValue + this.addTextSpace() + 'at ' + dateValue;
                }
            }
            return retValue;
        },
        addTextSpace() {
            return ' ';
        },
        formatTooltipTitle: function(tooltipItems) {
            //THIS IS A HACK
            //we are using this callback to grab the tooltip location and
            //share it with other charts to sync tooltips
            if(this.areTooltipsSynced && this.isActiveChart && tooltipItems.length > 0) {
                const chart = this.getLineChart();
                const scale = this.useCustomScaleOnDateAxis ? chart.scales['custom-date-axis'] : chart.scales['date-axis'];
                if (!scale) {return;} //scale must exist
                const tooltipEventPos = chart.tooltip._eventPosition;
                const chartSourceId = chart.id;
                const tooltipX = this.chartConfiguration.isVertical ?
                    scale.getValueForPixel(tooltipEventPos.y) : scale.getValueForPixel(tooltipEventPos.x);
                const customAxisTagName = this.chartConfiguration.dateAxis == null? null:this.chartConfiguration.dateAxis.tagName;
                this.onSyncedViewChanged && this.onSyncedViewChanged({chartSourceId, tooltipX, customAxisTagName});
            }
            //END HACK
            if (this.aggregationOptions[this.currentAggregationIndex]) {
                let aggregationName = this.aggregationOptions[this.currentAggregationIndex].name;
                return "Values Snap to Nearest " + aggregationName + " Time Interval";
            }
            return null;
        },
        hideAllTooltips: function() {
            if(this.areTooltipsSynced && this.isActiveChart) {
                const chartSourceId = this.getLineChart().id;
                const hideTooltips = true;
                this.onSyncedViewChanged && this.onSyncedViewChanged({chartSourceId, hideTooltips});
            }
        },
        syncChartMovement(id, chartMinimum, chartMaximum, moveComplete=false, popZoomHistory=false) {
            const chartGlobalObj = {
                chartSourceId: id,
                panXMin: chartMinimum,
                panXMax: chartMaximum,
                moveComplete: moveComplete,
                popZoomHistory: popZoomHistory
            };
            this.onSyncedViewChanged && this.onSyncedViewChanged(chartGlobalObj);
        },
        compareCurrentToGhostDuration() {
            if (!this.ghostPlotEnabled || !this.ghostPlotXMax || this.currentDataMaxTimestamp >= this.ghostPlotXMax ) {
                return true; //current duration is longer than ghost stage
            }
            return false;
        },
        onPan: function(chartEvent) {
            if (this.isDownloadingData || this.inStatsMode || this.dragToZoom || this.useCustomScaleOnDateAxis) {return;}
            this.followingUpdates = false;
            this.setCurrentChartMinMax(chartEvent.chart);
            if (this.areChartsSynced) {
                this.syncChartMovement(this.getLineChart().id, this.currentChartMin, this.currentChartMax);
            }
        },
        onPanComplete: function(chartEvent) {
            if (this.isDownloadingData || this.inStatsMode || this.dragToZoom || this.useCustomScaleOnDateAxis) {return;}
            this.setCurrentChartMinMax(chartEvent.chart);
            this.updateDownsampleWindow();
            if (this.areChartsSynced) {
                this.syncChartMovement(this.getLineChart().id, this.currentChartMin, this.currentChartMax, true);
            }

            this.onWindowChanged(chartEvent.chart);
            this.setValueAxesZoomHistory(chartEvent.chart);
        },
        setValueAxesZoomHistory: function(chart) {
            // Get the number of value axes
            let numValueAxes = null;
            if (this.chartConfiguration.isVertical) {
                numValueAxes = chart.options.scales.xAxes.length;
            } else {
                numValueAxes = chart.options.scales.yAxes.length;
            }

            // Loop through each value axis and push them to zoomHistoryValueAxes
            for (let i = 0; i < numValueAxes; i++) {
                let valueAxis = null;
                if (this.chartConfiguration.isVertical) {
                    valueAxis = chart.options.scales.xAxes[i];
                } else {
                    valueAxis = chart.options.scales.yAxes[i];
                }

                if (!this.zoomHistoryValueAxes.hasOwnProperty(valueAxis.id)) {
                    this.zoomHistoryValueAxes[valueAxis.id] = [];
                }

                this.zoomHistoryValueAxes[valueAxis.id].push({
                    axisMax: chart.scales[valueAxis.id].max,
                    axisMin: chart.scales[valueAxis.id].min
                });
            }
        },
        checkAnnotationCutOff: function(chart, from = null, to = null) {
            const axis = chart.scales['date-axis'] ?? chart.scales['custom-date-axis'];
            if(!axis) {
                return;
            }
            let chartHeight = axis.bottom;
            const visibleMin = from ?? axis.min;
            const visibleMax = to ?? axis.max;

            //get visible chart annotations, reset the rest
            Object.values(chart.annotation.elements).forEach(annotation =>{
                if (annotation.options.value > visibleMin && annotation.options.value < visibleMax) {
                    annotation.options.label.position = "right";
                    annotation.options.label.yAdjust = chartHeight/-4; // sets annotation to the top of the chart and negative moves it upward
                    this.visibleAnnotation.push(annotation);
                }
            });
        },
        staggerLabelOverlaps: function(chart, forceUpdate=false, from = null, to = null) {
            const currentTime = Date.now();
            const timeSinceLastCall = currentTime - this.lastLabelOverlapCalcTime;

            if (timeSinceLastCall < 1000 && this.lastLabelOverlapCalcTime != 0) {
                // Less than 1 second has passed since the last label overlap call, break
                return;
            }
            this.lastLabelOverlapCalcTime = currentTime;

            const axis = chart.scales['date-axis'] ?? chart.scales['custom-date-axis'];
            if(!axis) {
                return;
            }

            const visibleMin = from ?? axis.min;
            const visibleMax = to ?? axis.max;

            const visibleAnnos = [];

            //get visible chart annotations, reset the rest
            //this needs to be in $nextTick, since if a large zoom out operation occurs, the initial x/y position
            //values will not be updated until the next render and will be from where the chart was, not where the chart moved to.
            this.$nextTick(()=>{
                Object.values(chart.annotation.elements).forEach(anno =>{
                    if (anno.options.value > visibleMin && anno.options.value < visibleMax) {
                        visibleAnnos.push(anno);
                    }
                        if (this.chartConfiguration.isVertical) {
                            anno.options.label.yAdjust = (-1*anno._model.labelHeight / 2) - 1;
                            anno.options.label.xAdjust = (chart.width/2) - (anno._model.labelWidth);
                        } else {
                            anno.options.label.yAdjust = 0;
                        }
                });
                let staggerCount = 1;
                //if annotations overlap, stagger them
                for(let index = 0; index < visibleAnnos.length - 1; index++) {
                    if (this.chartConfiguration.isVertical ) {
                        //vertical chart overlaps will stagger annotation labels alternating left right
                        const nextLabelHeight = visibleAnnos[index+1]._model.labelHeight;
                        const nextLabelYPos = visibleAnnos[index+1]._model.y1;
                        if (nextLabelYPos - nextLabelHeight < visibleAnnos[index]._model.y1 && Boolean(staggerCount % 2)) {
                            visibleAnnos[index+1].options.label.xAdjust = (chart.width/-2) + (visibleAnnos[index+1]._model.labelWidth);
                            staggerCount++;
                        } else {
                            staggerCount = 1;
                        }
                    } else {
                        //horizontal charts overlaps will move the overlapping annotation down by one height
                        //this effect chains if there are more than 1 overlap, so it makes a 'step'
                        const nextLabelWidth = visibleAnnos[index+1]._model.labelWidth;
                        const nextLabelXPos = visibleAnnos[index+1]._model.x1;
                        if(staggerCount === 6) {
                            staggerCount = 1;
                            visibleAnnos[index+1].options.label.yAdjust = 0;
                        } else {
                            if (nextLabelXPos - nextLabelWidth < visibleAnnos[index]._model.x1) {
                                visibleAnnos[index+1].options.label.yAdjust = visibleAnnos[index+1]._view.labelHeight * staggerCount;
                                staggerCount++;
                            } else {
                                staggerCount = 1;
                            }
                        }
                    }
                }
                this.handleChartLabels(from, to); //determines wether to show labels or not and in what mode
                //if triggered from pan/zoomCcomplete, update the chart now to move a`nnotations
                if (forceUpdate) {
                    chart.update();
                }
            });
        },
        handleChartLabels(from = null, to = null) {
            if(this.$refs['lineChart'] && !this.customDateAxisHasBeenSet) {
                const chart = this.$refs['lineChart'].$data._chart;
                const visibleMin = from ?? chart.scales['date-axis'].min;
                const visibleMax = to ?? chart.scales['date-axis'].max;
                const isThreeDays = (visibleMax - visibleMin) <= (86400000 * 3);
                if(chart.width > 1000 && isThreeDays) {
                    this.toggleLabelsHoverMode(chart, false, visibleMin, visibleMax);
                } else if((chart.width > 700 && chart.width < 1000)) {
                    this.toggleLabelsHoverMode(chart, false, visibleMin, visibleMax);
                } else {
                    this.toggleLabelsHoverMode(chart, true, visibleMin, visibleMax);
                }
            }
        },
        toggleLabelsHoverMode(chart, enableHover, minTime, maxTime) {
            Object.values(chart.annotation.elements).forEach((annotation) => {
                //Check if the object is even in the view. No need to enable it if it isn't.
                //Might cause latency to deal with off canvas draw of many points labels
                let targetTimestamp = annotation.id ?? null;
                //Null Check
                if(targetTimestamp == null){
                    return;
                }

                //Timestamp in view check
                if(minTime <= parseInt(targetTimestamp) && parseInt(targetTimestamp) <= maxTime){
                    if(enableHover) {
                        annotation.options.labelType = 'hover';
                        annotation.options.label.enabled = false;
                    } else {
                        this.clearAnnotation();
                        annotation.options.labelType = 'label';
                        annotation.options.label.enabled = true;
                    }
                }
            });
            chart.update();
        },
        onZoom: function(chartEvent) {
        },
        onZoomComplete: function(chartEvent) {
            if(this.isDownloadingData) { return; }

            this.setCurrentChartMinMax(chartEvent.chart);
            this.updateDownsampleWindow();
            if (this.areChartsSynced) {
                this.syncChartMovement(this.getLineChart().id, this.currentChartMin, this.currentChartMax, true);
            }

            this.onWindowChanged(chartEvent.chart);
            this.setValueAxesZoomHistory(chartEvent.chart);
        },
        onSelectComplete: function(chartEvent) {
            const isVertical = !!this.chartConfiguration?.isVertical;
            // if selection w is negative, the user selected from right to left and if h was negative the user selected from bottom to top
            // thus the min becomes the max and vice versa
            const isNegativeSelection = isVertical ? chartEvent.selectionRect.h > 0 : chartEvent.selectionRect.w > 0;
            const min = isNegativeSelection ? chartEvent.minDate : chartEvent.maxDate;
            const max = isNegativeSelection ? chartEvent.maxDate : chartEvent.minDate;
            const from = moment.utc(min).valueOf();
            const to = moment.utc(max).valueOf();
            const tags = [];
            const ghostTags = [];
            this.chartData.datasets.filter((dataset) => {
                return this.activeData.find(data => data.name === dataset.tagName);
            }).forEach((dataset) => {
                const data = dataset.data.filter((d) => {
                    const x = isVertical ? d.y : d.x;
                    return x > min && x < max;
                });
                if(data.length > 0) {
                    const tag = dataset.tagName;
                    const tagName = this.getChartItem(tag) == null
                        ?  tag : this.getChartItem(tag).wellname ? this.fillWellNameSuffixAndPrefix(this.getChartItem(tag))
                            : this.getChartItem(tag).friendlyName;

                    // separate ghost tags from active data to prevent duplicate tagnames
                    if (dataset.hasOwnProperty('ghostData') && dataset.ghostData) {
                        ghostTags.push({tag, color: dataset.borderColor, tagName});
                    } else {
                        tags.push({tag, color: dataset.borderColor, tagName});
                    }
                }
            });
            if(tags.length > 0 || ghostTags.length > 0) {
                // disable stats mode and set number of calls to be made
                const chart = this.getLineChart();
                chart.options.plugins.tsiAnalytics.enabled = false;
                chart.update();
                this.downloadStatsDirectFromADX(tags, ghostTags, from, to, chartEvent);
            } else {
                this.closeTsiAnalytics();
            }
        },
        displayTsiStats(fromDateUtc, toDateUtc, chartEvent) {
            if(this.disabledTsiStats) {
                return;
            }

            const isVertical = !!this.chartConfiguration?.isVertical;
            const stats = document.getElementById('tsi-div'+this._uid);
            if(fromDateUtc && toDateUtc) {
                const from = moment.utc(fromDateUtc).add({hours: this.jobHourOffset});
                const to = moment.utc(toDateUtc).add({hours: this.jobHourOffset});
                const duration = timeDiff(fromDateUtc, toDateUtc, true);
                stats.style.visibility = 'visible';
                const timeRange = document.getElementById('tsi-time'+this._uid);
                timeRange.innerHTML =  `From: ${from.format('MM/DD/YYYY h:mm:ss A')} <br> To: ${to.format('MM/DD/YYYY h:mm:ss A')} <br> Duration: ${duration}`;
            }
            let position = 0;
            if(isVertical) {
                position = chartEvent.selectionRect.h > 0 ?
                    chartEvent.tsiChart.chartArea.top + chartEvent.selectionRect.y + chartEvent.selectionRect.h + 5
                    : chartEvent.selectionRect.y + 5;
                if(chartEvent.tsiChart.height - position < MIN_VERTICAL_SPACE_FOR_DIV) {
                    position = 20;
                }
                stats.style.left = `${chartEvent.tsiChart.chartArea.left}px`;
            } else {
                // if chartEvent.selectionRect.w is negative it means they user dragged from right to left
                // so we add that negative number to the start position to push it to the left instead of the right
                position = chartEvent.selectionRect.w > 0 ? chartEvent.selectionRect.x + chartEvent.selectionRect.w : chartEvent.selectionRect.x;
                // if when the chart is displayed it will go past the width of the chart we switch position to 260 from the selection rect
                // starting point
                const overlayLeft = chartEvent.overlayLeft;
                const leftPosition = chartEvent.selectionRect.w > 0 ? chartEvent.selectionRect.x : chartEvent.selectionRect.x + chartEvent.selectionRect.w;
                const rightSpaceRemaining = chartEvent.tsiChart.width - position;

                if ((position + overlayLeft + MIN_HORIZONTAL_SPACE_FOR_DIV > chartEvent.tsiChart.width && leftPosition > MIN_HORIZONTAL_SPACE_FOR_DIV) ||
                    (leftPosition < MIN_HORIZONTAL_SPACE_FOR_DIV && rightSpaceRemaining < MIN_HORIZONTAL_SPACE_FOR_DIV && this.controlsPosition === 'left' )) {
                    // this takes care of making sure the x is always at the left of the selection rect
                    // checks to see if there is enough space on the left to display the div
                    // if there is not enough space on either side for a div, but the controls are on the left, we display the div on the left
                    stats.style.left = 'unset';
                    stats.style.right = `${Math.ceil((chartEvent.tsiChart.width - leftPosition - overlayLeft) + 3)}px`;
                } else {
                    stats.style.right = 'unset';
                    stats.style.left = `${Math.ceil(position + overlayLeft + 5)}px`;
                }
            }
            stats.style.top = isVertical ? `${position}px` : '8px';
            stats.style.maxHeight = isVertical ? '35%' : `${chartEvent.selectionRect.h}px`;
        },
        closeTsiAnalytics(clearRect = true) {
            const chart = this.getLineChart();
            if(!chart) { return; }
            this.tsiAnalytics = [];
            // set plugin option clear to true so it clears the rectangle
            const stats = document.getElementById('tsi-div'+this._uid);
            if (stats.style.visibility !== 'hidden') { // stats div is being displayed
                stats.style.visibility = 'hidden'; //hide the stats div
                chart.options.plugins.tsiAnalytics.clear = clearRect;
                chart.update();
            }
            if (this.inStatsMode && !chart.options.plugins.tsiAnalytics?.enabled) {
                chart.options.plugins.tsiAnalytics.enabled = true;
                chart.update();
            }
        },
        setCurrentChartMinMax(chart) {
            if(chart?.scales['date-axis']) {
                this.currentChartMin = moment(chart.scales['date-axis'].min);
                this.currentChartMax = moment(chart.scales['date-axis'].max);
            }
        },
        onWindowChanged: function(chart, clearData=true) {
            if(this.useCustomScaleOnDateAxis) {
                const now = moment.utc().valueOf();
                this.refreshChartDataWithInterval(now - (CUSTOM_X_AXIS_WINDOW_HRS * MILLIS_PER_HR), now, clearData);
                return;
            }
            this.refreshChartDataWithInterval(this.currentChartMin.valueOf(), this.currentChartMax.valueOf(), clearData);
        },
        renderChart: function() {
            this.lastChartDrawTime = new Date().getTime();
            this.renderBlocked = false;
            const chart = this.getLineChart();

            if(!chart) { //need a chart reference to run this code
                return;
            }

            if(this.jobEnd) {
                this.updateChartMinMaxOptions(chart);
            }

            this.setCurrentChartMinMax(chart);

            const self = this;
            let dataPointsOutsideDownsampleWindow = 0;
            const totalLines = (this.ghostPlotEnabled) ? this.activeData.length + 1 : this.activeData.length;
            $.each(this.tagToChartIndex, function(tagName, tagIndex) {
                //TODO: currently custom scale X axis always uses the window that is defined on load

                let currentDownsampleWindow = [];
                let outsideDownsampleWindow = [];
                if(self.useCustomScaleOnDateAxis) {
                    const customScale = chart.scales['custom-date-axis'];
                    currentDownsampleWindow = self.rawdata[tagIndex].data.filter((dataPoint) => {
                        const dateValue = self.chartConfiguration.isVertical ? dataPoint.y : dataPoint.x;
                        return dateValue >= customScale.min && dateValue <= customScale.max;
                    });
                }
                else {
                    currentDownsampleWindow = self.rawdata[tagIndex].data.filter((dataPoint) => {
                        const dateValue = self.chartConfiguration.isVertical ? dataPoint.y : dataPoint.x;
                        return dateValue >= self.downsampleWindow.min && dateValue <= self.downsampleWindow.max;
                    });
                    outsideDownsampleWindow = self.rawdata[tagIndex].data.filter((dataPoint) => {
                        const dateValue = self.chartConfiguration.isVertical ? dataPoint.y : dataPoint.x;
                        return dateValue >= self.downsampleWindow.max && dateValue <= self.currentChartMax;
                    });
                }

                const downsampledData = self.downsample(currentDownsampleWindow, Math.round(MAX_DATA_POINTS / totalLines));
                const rawDataCopy = {...self.rawdata[tagIndex]};
                rawDataCopy.data = [...downsampledData, ...outsideDownsampleWindow];

                dataPointsOutsideDownsampleWindow += outsideDownsampleWindow.length;

                //set chartItem custom values
                const chartItem = self.getChartItem(tagName);
                if (chartItem) {
                    if (self.isWellheadTag(tagName)) {
                        rawDataCopy.label = self.getPriorityNameForTag(tagName);
                        rawDataCopy.borderColor = chartItem?.customLineColor ? chartItem.color : self.getWellColorFromWellheadTagName(tagName);
                    } else {
                        rawDataCopy.label = chartItem.friendlyName;
                        rawDataCopy.borderColor = chartItem?.color || '#fff';
                    }

                    // If an opacity is set, add a background to the line graph with the set opacity
                    if (self.shadingChecked[tagName] && 'line_opacity' in self.chartConfiguration && self.chartConfiguration.line_opacity > 0) {
                        rawDataCopy.fill = true;
                        rawDataCopy.backgroundColor = self.hexToRgbA(rawDataCopy.borderColor, self.chartConfiguration.line_opacity/100);
                    }

                    rawDataCopy.tagName = chartItem?.tagName;
                    rawDataCopy.borderWidth = chartItem.lineWidth;
                    rawDataCopy.pointRadius = chartItem.showDataPoints ? chartItem.lineWidth / 2 : 0;
                }
                
                self.datacollection.datasets[tagIndex] = rawDataCopy;
            });

            if(this.ghostPlotEnabled) {
                $.each(this.ghostTagToChartIndex, function(tagName, tagIndex) {
                    const currentWindow = self.ghostrawdata[tagIndex].data.filter((dataPoint) => {
                        const dateValue = self.chartConfiguration.isVertical ? dataPoint.y : dataPoint.x;
                        return true; //return all ghost plot points
                    });

                    // adjust ghost plot date value (x: horizontal, y: vertical) based on set index
                    currentWindow.forEach(dataPoint => {
                        if (self.chartConfiguration.isVertical) {
                            dataPoint.y = dataPoint.originalTime + self.ghostPlotIndex.diffMS;
                        }
                        else {
                            dataPoint.x = dataPoint.originalTime + self.ghostPlotIndex.diffMS;
                        }
                    });

                    const downsampledData = self.downsample(currentWindow, Math.round(MAX_DATA_POINTS / totalLines));
                    const ghostrawDataCopy = {...self.ghostrawdata[tagIndex]};
                    ghostrawDataCopy.data = downsampledData;


                    //set chartItem custom values
                    const chartItem = self.getChartItem(tagName);
                    ghostrawDataCopy.tagName = chartItem.tagName;

                    const regexWellheadTagMatch = /wellhead\_\d+\_.*/;
                    const wellheadTagMatches = chartItem.tagName.match(regexWellheadTagMatch);

                    if(chartItem.tagName.startsWith('pressureWell')) {
                        ghostrawDataCopy.label = self.getWellNameFromPressureTag(chartItem.tagName);
                    }else if(wellheadTagMatches) {
                        const regexWellheadIdMatch = /\d+/;
                        const idMatch = chartItem.tagName.match(regexWellheadIdMatch);
                        //wellhead indexing starts at 1 instead of 0 so need to shift by 1
                        const wellIndex = parseInt(idMatch[0]) - 1;
                        const selectedWell = self.wells.filter((well)=>well.index==wellIndex);

                        ghostrawDataCopy.label = selectedWell[0] ? selectedWell[0].name : null;
                    }else {
                        ghostrawDataCopy.label = chartItem.friendlyName;
                    }

                    //convert ghostLineOpacity from decimal to hex between 00 and FF
                    const decimalGhostLineOpacity = Math.round(self.ghostLineOpacity*255/100);
                    const hexGhostLineOpacity = decimalGhostLineOpacity < 7 ? '0' + decimalGhostLineOpacity.toString(16) : decimalGhostLineOpacity.toString(16);

                    ghostrawDataCopy.borderWidth = self.ghostLineSize;
                    ghostrawDataCopy.borderColor = (chartItem?.wellColor ? chartItem.wellColor : chartItem.color) + hexGhostLineOpacity;
                    ghostrawDataCopy.backgroundColor = (chartItem?.wellColor ? chartItem.wellColor : chartItem.color) + hexGhostLineOpacity;
                    ghostrawDataCopy.pointRadius = chartItem.showDataPoints ? chartItem.lineWidth / 2 : 0;

                    self.datacollection.datasets[Object.keys(self.tagToChartIndex).length + tagIndex] = ghostrawDataCopy;
                });
            }

            if(dataPointsOutsideDownsampleWindow > MAX_DATA_POINTS_OUTSIDE_DOWNSAMPLING) {
                //next time the render function is called it will use the new downsampling window
                this.updateDownsampleWindow();
            }


            let dateAxis = chart.options.scales.xAxes[0];
            if(this.chartConfiguration.isVertical) {
                dateAxis = chart.options.scales.yAxes[0];
                dateAxis.gridLines.display = self.chartConfiguration.showVerticalGridlines? true : false;
            }


            if(this.followingUpdates && this.pinRightSideMsOffset && !this.useCustomScaleOnDateAxis) {
                if(this.jobEnd) {
                    dateAxis.ticks.max = moment.utc(this.jobEnd).valueOf();
                }else if(this.ghostPlotXMax == 0) {
                    //don't change the view if ghost plot is active
                    const timeWindow = dateAxis.ticks.max - dateAxis.ticks.min;
                    dateAxis.ticks.max = moment.utc().valueOf() + this.rightSideMsOffset;
                    dateAxis.ticks.min = moment.utc().valueOf() + this.rightSideMsOffset - timeWindow;
                }
            }

            if (this.chartConfiguration.showStageLines && this.chartConfiguration.stageLabelType == 'label') {
                this.staggerLabelOverlaps(chart);
            }

            this.datacollection.datasets = this.normalizeDataSets(this.datacollection.datasets);

            chart.update();
            this.correctTimelineWidth();
            this.chartId = chart.id;
        },
        normalizeDataSets: function(datasets=this.datacollection.datasets) {
            // Chart.js is fastest if you provide data with indices that are unique, sorted, and consistent across datasets
            return datasets.map(_dataset => {
                // Convert the data to a map to filter out any repeated dates
                const uniqueKeys = this.chartConfiguration.isVertical
                    ? new Map(_dataset.data.map(obj => [obj.y, obj.x]))
                    : new Map(_dataset.data.map(obj => [obj.x, obj.y]));

                // Sort the dates to make sure they are in order
                const sortedKeys = Array.from(uniqueKeys.keys()).sort();

                // Return the data to it's original format
                _dataset.data = this.chartConfiguration.isVertical
                    ? sortedKeys.map(_key => { return { x: uniqueKeys.get(_key), y: _key }; })
                    : sortedKeys.map(_key => { return { x: _key, y: uniqueKeys.get(_key) }; });

                return _dataset;
            });
        },
        renderChartWithTimeout: function(drawLimit=500) {
            if (
                // A minimum time between chart draws must be met to avoid too many renders happening too quickly
                !GlobalFunctions.isFalsy(this.lastChartDrawTime) && new Date().getTime() - this.lastChartDrawTime < drawLimit

                // No need to trigger chart drawing if window isn't in view (we do still render if not focused but is visible)
                || this.isPageHidden()

                // No need to trigger chart drawing if the chart is out of viewport (via scrolling)
                || this.isElementHidden(document.getElementById(this.lineChartId))
            ) {
                this.renderBlocked = true;
                return;
            }

            this.renderChart();
        },
        isPageHidden: function() {
            return !!(document.hidden || document.msHidden || document.webkitHidden || document.mozHidden);
        },
        isElementHidden: function(element) {
            const rect = element.getBoundingClientRect();
            return rect.bottom < 0 || rect.top > (window.innerHeight || document.documentElement.clientHeight);
        },
        onPageFocusOrScroll: function() {
            if (this.renderBlocked)
                this.renderChart();
        },
        getChartItem: function(tagName) {
            for(const axisIndex in this.chartConfiguration.valueAxes) {
                const axisDef = this.chartConfiguration.valueAxes[axisIndex];
                const chartItem = axisDef.chartItems.find((item) => item.tagName == tagName);

                if(chartItem) {
                    const regexWellheadTagMatch = /wellhead\_\d+\_*.*/;
                    const wellheadTagMatches = chartItem.tagName.match(regexWellheadTagMatch);

                    if(this.wells && chartItem.tagName.startsWith('pressureWell')) {
                        chartItem.wellname = this.getWellNameFromPressureTag(chartItem.tagName);
                        return chartItem;
                    }else if(this.wells && wellheadTagMatches) {
                        const regexWellheadIdMatch = /\d+/;
                        const idMatch = chartItem.tagName.match(regexWellheadIdMatch);
                        //wellhead indexing starts at 1 instead of 0 so need to shift by 1
                        const wellIndex = parseInt(idMatch[0]) - 1;
                        const selectedWell = this.wells.filter((well)=>well.index==wellIndex);
                        chartItem.wellname = selectedWell[0] ? selectedWell[0].name : null;
                        chartItem.wellColor = selectedWell[0] ? selectedWell[0]?.color : null;
                        return chartItem;
                    }

                    return chartItem;
                }
            }

            return null;
        },
        getDisplayTagName(tag) {
            let chartItem = this.getChartItem(tag);
            const tagData = this.allTags.find(tagData => tagData.name == tag);
            if (chartItem == null || tagData?.isWellheadTag)
                return this.getPriorityNameForTag(tag);
            if (chartItem.friendlyName === "NOT SET") {
                return tagData.friendlyTagname;
            }

            return chartItem?.friendlyName || '';
        },
        getWellNameFromPressureTag(pressureTag) {
            //These tags are now considered legacy. Handled alternative case for new pressureWell tags where this method is currently being used.
            if(this.wells && pressureTag.startsWith('pressureWell')) {
                const wellTagSplit = pressureTag.split('pressureWell');
                if(wellTagSplit.length > 0) {
                    const wellIndex = parseInt(wellTagSplit[1]);
                    const selectedWell = this.wells.filter((well)=>well.index==wellIndex);
                    if(selectedWell.length>0) {
                        return selectedWell[0].name;
                    }
                }
            }
        },
        getWellColorFromWellheadTagName(tagName) {
            const regex = /(wellhead_)(\d)(_pressure|_valvePosition|_handlePosition)/;
            if (this.wells && regex.test(tagName)) {
                const matches = tagName.match(regex);
                let wellIndex;
                if (matches && matches[2]) {
                    wellIndex = Number(matches[2]) - 1; //index of well is 0 based instead of 1 based
                }
                if (!isNaN(wellIndex) && wellIndex >= 0) {
                    const foundWell = this.wells.find(well => well.index == wellIndex);
                    if (foundWell) {
                        return foundWell?.color || 'fff';
                    }
                }
            }
            return 'fff';
        },
        correctTimelineWidth: function() {
            this.$nextTick(() => {
                if(this.$refs.chartTimeline)
                {this.$refs.chartTimeline.correctTimelineWidth(this.$refs.lineChart.$data._chart);}
            });
        },
        updateCommentTimelineData: function() {
            this.$nextTick(() => {
                if(this.$refs.chartTimeline)
                {this.$refs.chartTimeline.updateCommentTimelineData(this.$refs.lineChart.$data._chart);}
            });
        },
        downloadConfig() {
            let url = '';
            if((this.isMultiWireline == true || this.isMultiFrac) && this.selectedTruck != -1) {
                url = `/user/config/chartconfig/${this.dashboardItem.options.configId}/${this.jobNumber}/${this.selectedTruck}`;
            }else{
                url = `/user/config/chartconfig/${this.dashboardItem.options.configId}/${this.jobNumber}`;
            }

            const self = this;
            return $.get(
                url,
                {},
                function(result) {
                    if(result.error) {
                        console.warn(result.message);
                    } else {
                        if (!isNullOrEmpty(result?.errorMessages))
                            result.errorMessages.forEach(_error => {
                                toast({
                                    title: _error,
                                    variant: 'danger'
                                });
                            })

                        self.saveDownloadConfig(result);
                        self.fetchStageMarkerData();
                    }
                },
                'json'
            ).fail(function( jqXHR, textStatus, errorThrown ) {
                console.warn('fail downloadData: download chartConfig', errorThrown);
                if(jqXHR.status == 401) {
                    console.warn('unauthorized');
                    self.hasAuthError = true;
                }
                else {
                    //template not found load the default template
                    self.getDefaultTemplate(self.jobNumber);
                }
            });
        },
        getDefaultTemplate(jobNumber) {
            const self = this;
            const url = '/user/config/defaultchartconfig/' + jobNumber;
            $.get(
                url,
                {},
                function(result) {
                    self.saveDownloadConfig(result);
                },
                'json'
            ).fail(function( jqXHR, textStatus, errorThrown ) {
                console.log('fail downloadData: default chart config', errorThrown);
                if(jqXHR.status == 401) {
                    console.log('unauthorized');
                    self.hasAuthError = true;
                }
            });
        },
        saveDownloadConfig(config) {
            // This function extracts config.data into separate keys
            // after this function don't use this.chartConfiguration.data
            this.chartConfiguration = config;
            this.$emit('onChartLoadComplete');
            //Need to capitalize this as it's used for an option in an input that is human readable
            if(this.headerStyle == 'wireline') {
                this.selectedWellStageIntervalsActivityTypeOption = 'Wireline';
            }else{
                this.selectedWellStageIntervalsActivityTypeOption = 'Frac';
            }
            this.hideCommentsTimeline = config.hideCommentsTimeline === 1 ? true : false;

            // set drag to zoom based on chart config setting if users hasn't set zoom action locally
            if (this.defaultZoomAction === 'dragToZoom' && this.dragToZoom === null) {
                this.dragToZoom = true;
            } else if (this.dragToZoom === null) {
                this.dragToZoom = false;
            }

            this.chartConfigurationData = typeof config.data === 'string'? JSON.parse(config.data) :  config.data;
            const yaxes = [...this.chartConfigurationData.chartYAxes];
            this.chartConfigurationData.orderedSections.forEach(section => {
                yaxes.forEach(yAxis => {
                    //pull updated chart items from sections and add data to relevant y axis
                    section.orderedChartItems.forEach(chartItem => {
                        if(chartItem?.chartYAxis_key) {
                            //save chartItem info and section key to temp item
                            let tempChartItem = {};
                            tempChartItem = Object.assign(tempChartItem, chartItem);
                            tempChartItem.sectionKey = section.key;
                            //if chart item has correct yAxis key add it to the axis chart item list
                            if(chartItem.chartYAxis_key === yAxis.key) {
                                //if no items exist populate with empty array
                                if(typeof yAxis.chartItems === 'undefined') {
                                    yAxis.chartItems = [];
                                }
                                yAxis.chartItems.push(tempChartItem);
                            }
                        }
                    });
                });
            });

            yaxes.forEach(yAxis => {
                if(typeof yAxis.chartItems === 'undefined') {
                    yAxis.chartItems = [];
                }
            });

            this.chartConfiguration.valueAxes = yaxes;
            this.chartConfiguration.dateAxis = this.chartConfigurationData.chartXAxis;
            // Set pointers so that the Chart Config Modal can still use x and y axis as storage names.
            this.chartConfiguration.yaxes = this.chartConfiguration.valueAxes;
            this.chartConfiguration.xAxis = this.chartConfiguration.dateAxis;

            this.filterByActivity(this.selectedWellStageIntervalsActivityTypeOption);
            this.setShowLegendValue(config);

            this.tags = [];
            for (const yAxisIndex in this.chartConfiguration.valueAxes) {
                const yAxis = this.chartConfiguration.valueAxes[yAxisIndex];
                for (const chartItemIndex in yAxis.chartItems) {
                    const chartItem = yAxis.chartItems[chartItemIndex];
                    if (!this.tags.includes(chartItem.tagName)) {
                        this.tags.push(chartItem.tagName);
                    }
                }
            }
            this.setFracItems(this.tags);
            // below are things we only want to do once
            if(this.initialized) {
                return;
            }
            if(this.chartConfigurationData.sectionIndexes) {
                this.sectionIndexes = this.chartConfigurationData.sectionIndexes;
            }

            if (this.sectionIndexes.length !== this.chartConfigurationData.orderedSections.length) {
                const sectionOrder = this.chartConfigurationData?.orderedSections;
                if(sectionOrder?.length) {
                    sectionOrder.forEach((section, index) => {
                        const sectionFound = this.sectionIndexes.find((section)=> section.index == index);
                        if(!sectionFound) {
                            this.sectionIndexes.push({
                                index: index,
                                value: section.orderedChartItems.map((chartItems)=> {
                                    return {
                                        name: chartItems.tagName,
                                        value: '--'
                                    };
                                })
                            });
                        }
                    });
                }
            }

            delete this.chartConfiguration.data;
            this.$nextTick(() => {
                this.init();
                this.initialized = true;
                this.dataReceived = true;
            });
        },
        downloadGhostData() {
            const self = this;


            let activeTags = [];
            for(const index in this.ghostLineChannels) {
                const tagObject = this.ghostLineChannels[index];
                const tagName = tagObject.name;
                if(this.activeDataContainsTag(tagName)) {
                    activeTags.push(tagName);
                }
            }

            if(activeTags.length == 0) {
                //don't hit the server if none of the ghost plots are in active data
                return;
            }

            //get the stage intervals in UTC Timestamp, ISO 8601 standard
            const targetStartTime = this.ghostLineStage.startTime.replaceAll(' ', 'T') + 'Z';
            const targetEndTime = this.ghostLineStage.endTime.replaceAll(' ', 'T') + 'Z';

            const selectedIntervalStartTime = moment.utc(targetStartTime).subtract({hours: this.jobHourOffset}).valueOf();
            const selectedIntervalEndTime = moment.utc(targetEndTime).subtract({hours: this.jobHourOffset}).valueOf();
            const startTimeOffset = this.ghostPlotTargetTimeStart - selectedIntervalStartTime;

            //every time we try to get data from ADX, first go to the web server and renew the auth token
            //this may be overkill, if it becomes a problem we can track the token expiry and only update as needed
            $.get(
                '/adxConfig/' + this.jobNumber,
                {},
                (result) => {
                    const adxAccessToken = result.adxAccessToken;
                    const adxURL = result.adxUrl;
                    const adxDBName = result.adxDBName;
                    for(const index in activeTags) {
                        const tagName = activeTags[index];
                        const tagIndex = self.ghostTagToChartIndex[tagName];
                        this.isDownloadingGhostData = true;
                        Vue.set(this.downloadingDataForTagIndex, tagIndex, true);
                    }

                    self.downloadDataDirectFromADX(adxDBName, activeTags, self.jobNumber, null, null, adxURL, adxAccessToken, selectedIntervalStartTime, selectedIntervalEndTime, this.aggregationValue, self.directADXGhostDataHandler, startTimeOffset);

                    //set the xaxis window to the ghost plot stage size
                    self.ghostPlotXMax = selectedIntervalEndTime + startTimeOffset;
                    const newXMax = Math.max(self.ghostPlotXMax, moment.utc().valueOf());
                    if (this.areChartsSynced) {
                        this.syncChartMovement(this.getLineChart().id, this.ghostPlotTargetTimeStart, newXMax + this.rightSideMsOffset, true);
                    }

                    this.setChartTimeAxisMinMax(this.ghostPlotTargetTimeStart, newXMax + this.rightSideMsOffset);
                },
                'json'
            ).fail(function( jqXHR, textStatus, errorThrown ) {
                console.warn('ADX config failure', errorThrown, jqXHR.responseText);
            });
        },
        createAggregateIntervals(fromDate, toDate) {
            const aggSelectRef = 'autoAggregateSelect-' + this.dashboardItem.i;

            const range = toDate - fromDate;
            let scaleFactor = range / (INITIAL_WINDOW_HRS * MILLIS_PER_HR);//default for window hours is 3
            scaleFactor = scaleFactor < 1 ? 1 : Math.floor(scaleFactor);// 3 hour scaling is the minimum

            let usedAgOptionIndex = this.currentAggregationIndex;//temp copy of selected ag index in case it needs auto adjustment
            let newAggregateIntervals = [];
            //Scale the aggregate intervals to fit the current window size
            if (scaleFactor > 1) {
                //add a copy of the 1s interval back into the options list in case the user wants to reselect that resolution
                newAggregateIntervals.push(_.cloneDeep(AGGREGATION_OPTIONS[0]));
                //copy the rest of the aggregate intervals and scale them to the window
                _.cloneDeep(AGGREGATION_OPTIONS).forEach(agOp => {
                    const newDurationSeconds = agOp.durationInSec * scaleFactor;
                    //second interval scaling
                    if (newDurationSeconds < 60) {
                        newAggregateIntervals.push({
                            name: this.roundToNextNiceNumber(newDurationSeconds) + 's',
                            value: `PT${this.roundToNextNiceNumber(newDurationSeconds)}S`,
                            durationInSec: newDurationSeconds
                        });
                    //minute interval scaling
                    } else if (newDurationSeconds < 3600) {
                        const minuteDuration = newDurationSeconds/60;
                        newAggregateIntervals.push({
                            name: this.roundToNextNiceNumber(minuteDuration) + 'M',
                            value: `PT${this.roundToNextNiceNumber(minuteDuration)}M`,
                            durationInSec: newDurationSeconds
                        });
                    //hour interval scaling
                    } else if (newDurationSeconds >= 3600) {
                        const hoursDuration = newDurationSeconds/3600;
                        newAggregateIntervals.push({
                            name: this.roundToNextNiceNumber(hoursDuration) + 'H',
                            value: `PT${this.roundToNextNiceNumber(hoursDuration)}H`,
                            durationInSec: newDurationSeconds
                        });
                    }
                });
                //if the users were on 1s resolution scaling before, then automatically adjust them to the next interval size up
                if (this.currentAggregationIndex == 0) {
                    usedAgOptionIndex = 1;
                }
                //change the auto aggregate select programatically to the new index value
                if (this.$refs[aggSelectRef]) {
                    this.$refs[aggSelectRef].setSelectedIndex(usedAgOptionIndex);
                }
            } else {
                //reset the aggregat intervals back to the baseline resolution, and update the selected index to the user selected value
                newAggregateIntervals = _.cloneDeep(AGGREGATION_OPTIONS);
                if (this.$refs[aggSelectRef]) {
                    this.$refs[aggSelectRef].setSelectedIndex(this.currentAggregationIndex);
                }
            }
            this.aggregationOptions = newAggregateIntervals;
            this.aggregationValue = this.aggregationOptions[usedAgOptionIndex].value;
        },
        roundToNextNiceNumber(value) {
            if (value == 1) {
                return 1;
            } else if (value > 1 && value < 10) { //single digits, round to next even number
                return 2 * Math.ceil(value / 2);
            } else if (value >= 10 && value <= 50) {// multi-digit between 10-50 round to the next 5
                return 5 * Math.ceil(value / 5);
            } else { //multi-digit > 50 round to the next multiple of 10
                return 10 * Math.ceil(value / 10);
            }
        },
        downloadStatsDirectFromADX(tags, ghostTags, fromDate, toDate, chartEvent) {
            const self = this;
            $.get(
                '/adxConfig/' + this.jobNumber,
                {},
                (result) => {
                    const adxAccessToken = result.adxAccessToken;
                    const adxURL = result.adxUrl;
                    const adxDBName = result.adxDBName;

                    self.tsiAnalyticsIsDownloading = true;
                    self.displayTsiStats(fromDate, toDate, chartEvent);

                    if (self.ghostPlotEnabled && ghostTags.length > 0) {
                        // get stat data for ghost plots
                        const targetStartTime = this.ghostLineStage.startTime.replaceAll(' ', 'T') + 'Z';
                        const targetEndTime = this.ghostLineStage.endTime.replaceAll(' ', 'T') + 'Z';
                        const selectedIntervalEndTime = moment.utc(targetEndTime).subtract({hours: this.jobHourOffset}).valueOf();
                        const selectedIntervalStartTime = moment.utc(targetStartTime).subtract({hours: this.jobHourOffset}).valueOf();
                        const timeDiffStartToFrom = fromDate - this.ghostPlotTargetTimeStart;
                        const selectedDuration = toDate - fromDate;
                        const ghostFromDate = selectedIntervalStartTime + timeDiffStartToFrom;
                        const ghostToDate = ghostFromDate + selectedDuration;
                        if (ghostFromDate <= selectedIntervalEndTime && ghostToDate > selectedIntervalStartTime) {
                            // get ghost data for the selected time range if it is within ghost data range
                            self.getStatisticsFromADX(adxDBName, ghostTags, self.jobNumber, adxURL, adxAccessToken, ghostFromDate, ghostToDate, self.directADXStatsDataHandler, true);
                        }
                    }
                    if (tags.length > 0) {
                        // get stat data for active data
                        self.getStatisticsFromADX(adxDBName, tags, self.jobNumber, adxURL, adxAccessToken, fromDate, toDate, self.directADXStatsDataHandler, false);
                    }
                },
                'json'
            ).fail(function( jqXHR, textStatus, errorThrown ) {
                console.warn('ADX config failure', errorThrown, jqXHR.responseText);
            });
        },
        directADXStatsDataHandler(result, tags, isGhostData) {
            const self = this;
            self.tsiAnalyticsIsDownloading = false;
            if(result.status != 200) {
                console.warn(result.statusText);
                this.closeTsiAnalytics();
                const chart = self.getLineChart();
                chart.options.plugins.tsiAnalytics.enabled = true;
                chart.update();
            } else if(result.data) {
                let copiedData = null;
                const resultData = result.data;
                const allTagData = this.adxStatsDataParser(tags, resultData, isGhostData);

                for(const tagNameIndex in allTagData) {
                    // clone to prevent overwriting the original data between ghost data and actual data
                    copiedData = _.cloneDeep(allTagData[tagNameIndex]);
                    self.tsiAnalytics.push(copiedData);
                }

                const chart = self.getLineChart();
                if (!chart?.options.plugins.tsiAnalytics.enabled) {
                    chart.options.plugins.tsiAnalytics.enabled = true;
                    chart.update();
                }
            }
        },
        downloadData(tags, xMinDate, endDate = null, interval = null) {
            const self = this;
            if(endDate == null && this.jobEnd) {
                endDate = moment.utc(this.jobEnd).subtract({hours: this.jobHourOffset});
            }

            // Cancel any previous request
            if (this.cancelToken) {
                this.cancelToken.cancel('cancel outstanding adx config download');
            } else if (this.abortController) {
                this.abortController.abort();
            }

            // Create a new cancelToken object and abortController instance
            this.cancelToken = axios.CancelToken.source();
            this.abortController = new AbortController();

            //every time we try to get data from ADX, first go to the web server and renew the auth token
            //this may be overkill, if it becomes a problem we can track the token expiry and only update as needed
            axios.get('/adxConfig/' + this.jobNumber, {
                cancelToken: this.cancelToken.token,
                signal: this.abortController.signal
            })
            .then((response) => {
                const result = response.data;
                const adxAccessToken = result.adxAccessToken;
                const adxURL = result.adxUrl;
                const adxDBName = result.adxDBName;

                if (this.useCustomScaleOnDateAxis) {
                    interval = "PT1S";
                    tags.push(this.dateAxisTag);
                }

                for (const index in tags) {
                    const tagName = tags[index];
                    const tagIndex = self.tagToChartIndex[tagName];
                    Vue.set(this.downloadingDataForTagIndex, tagIndex, true);
                }

                let aggregationInterval = this.aggregationValue ? this.aggregationValue: interval;

                if (response) {
                    self.downloadDataDirectFromADX(adxDBName, tags, self.jobNumber, null, null, adxURL, adxAccessToken, xMinDate, endDate, aggregationInterval, self.useCustomScaleOnDateAxis ? self.customAxisADXDownloadDataHandler : self.directADXDownloadDataHandler, 0, true);
                }
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    console.warn('ADX config failure', error);
                }

            })
            .finally(() => {
                // reset cancelToken and abortController
                this.cancelToken = null;
                this.abortController = null;
            });

        },
        checkDataDirectionOnCustomAxis: function(dateAxisData) {
            //returns the index where the direction changes, ignore nulls
            let lastDataVal = null, directionChangeIndex = null;
            let lastDataDirection, dataDirection = this.customXDirection; //1 is positive slope, -1 is negative slope
            for(let i = 0; i < dateAxisData.length; i++) {
                const dateAxisDataVal = dateAxisData[i].dataVal;
                if(dateAxisDataVal) {
                    if(lastDataVal) {
                        if(lastDataVal < dateAxisDataVal) {
                            dataDirection = 1;
                        }
                        else if(lastDataVal > dateAxisDataVal) {
                            dataDirection = -1;
                        }

                        if(dataDirection != lastDataDirection) {
                            //direction has changed
                            directionChangeIndex = i;
                        }
                    }
                    else {
                        //first time through the loop
                        directionChangeIndex = i;
                    }

                    lastDataVal = dateAxisDataVal;
                    lastDataDirection = dataDirection;
                }
            }

            //keep track of the current direction
            this.customXDirection = lastDataDirection;

            return directionChangeIndex;
        },
        customAxisADXDownloadDataHandler: function(result, tags, jobNumber, wellName, stageNumber, startDate, endDate, interval, startTimeOffset = 0) {
            //the custom axis has certain limitations:
            // - x scale is locked to the min and max values defined in the config, no pan or zoom away from this is allowed
            // - there must be a unique value for every x position
            const self = this;
            if(result.status != 200) {
                $.each(tags, function(i, tagName) {
                    const tagIndex = self.tagToChartIndex[tagName];
                    Vue.set(self.downloadingDataForTagIndex, tagIndex, false);
                });
                console.warn(result);
            }else if(result.data) {

                const resultData = result.data;

                const allTagData = this.adxDataParser(tags, resultData);

                //first search through the response for the custom x axis tag data
                //to find the most recent window where the values are continually increasing or decreasing
                //keep track of which way the data is going so
                //we will know if the direction has flipped
                const dateAxisData = allTagData[self.dateAxisTag];
                if(dateAxisData && dateAxisData.length > 0) {
                    const directionChangeIndex = self.checkDataDirectionOnCustomAxis(dateAxisData);
                    for(const tagName in allTagData) {
                        let data = allTagData[tagName];
                        const tagIndex = self.tagToChartIndex[tagName];
                        if(tagName != self.dateAxisTag) {
                            for(let i=data.length-1;i>=directionChangeIndex;i--) {
                                //insert values in reverse order
                                if (data[i].dateTimestamp && data[i].dataVal != null) {
                                    const matchingElement = dateAxisData.find(element => element.dateTimestamp == data[i].dateTimestamp);
                                    if(matchingElement) {
                                        const dateValue = matchingElement.dataVal;
                                        const datavalue = self.filterData(tagName, data[i].dataVal);

                                        if(dateValue != null && datavalue != null) {
                                            if(self.chartConfiguration.isVertical) {
                                                self.rawdata[tagIndex].data.unshift({x: datavalue, y: dateValue});
                                            }
                                            else {
                                                self.rawdata[tagIndex].data.unshift({x: dateValue, y: datavalue});
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        if(data.length > 0) {
                            //record the time of the last value for use when looking at latest data
                            const max = data[data.length - 1].dateTimestamp;
                            self.datawindow[tagIndex] = {min: 0, max: max};
                        }

                        Vue.set(self.downloadingDataForTagIndex, tagIndex, false);
                    }

                    self.renderChartWithTimeout();
                }
                else {
                    console.warn('There was a problem reading the custom x-axis data.');
                }
            }
        },
        customAxisLatestDataHandler: function(result) {
            const self = this;
            const chart = this.getLineChart();
            const dateAxisData = result.find(element => element.tagName == self.dateAxisTag);
            if(dateAxisData && dateAxisData.dateTimestamp && self.rawdata.length > 0) {
                const dateAxisRawData = self.rawdata[0].data;
                let directionChanged = false;
                if(dateAxisRawData.length > 0) {
                    const lastdateAxisData = dateAxisRawData[dateAxisRawData.length - 1];
                    let lastDate = lastdateAxisData.x;
                    if(self.chartConfiguration.isVertical) {
                        lastDate = lastdateAxisData.y;
                    }
                    if(this.customXDirection < 0) {
                        if(lastDate < dateAxisData.dataVal) {
                            directionChanged = true;
                            this.customXDirection = -1;
                        }
                    }
                    else if(this.customXDirection > 0) {
                        if(lastDate > dateAxisData.dataVal) {
                            directionChanged = true;
                            this.customXDirection = 1;
                        }
                    }
                }

                //if the x axis data has changed direction the previous data can no longer be
                //plotted on the same line as the new data so we will wipe the previous data
                if(directionChanged) {
                    $.each(this.tagToChartIndex, function(tagName, tagIndex) {
                        self.rawdata[tagIndex].data = [];
                    });
                }

                let totalDataCount = 0;
                result.forEach((latestDataEntry) => {
                    const tagIndex = self.tagToChartIndex[latestDataEntry.tagName];
                    if(tagIndex != undefined) {
                        if(latestDataEntry.tagName != self.dateAxisTag) {
                            totalDataCount += 1;
                            //push values onto end of data series
                            if (latestDataEntry.dateTimestamp && latestDataEntry.dataVal != null) {
                                const timevalue = latestDataEntry.dateTimestamp;
                                //ignore any values that are less than the max for this data series
                                if(self.datawindow[tagIndex] && timevalue > self.datawindow[tagIndex].max) {
                                    const matchingElement = dateAxisData.dateTimestamp == latestDataEntry.dateTimestamp ? dateAxisData : null;
                                    if(matchingElement) {
                                        const dateValue = matchingElement.dataVal;
                                        const datavalue = self.filterData(latestDataEntry.tagName, latestDataEntry.dataVal);

                                        if(dateValue != null && datavalue != null) {
                                            if(self.chartConfiguration.isVertical) {
                                                self.rawdata[tagIndex].data.push({x: datavalue, y: dateValue});
                                            }
                                            else {
                                                self.rawdata[tagIndex].data.push({x: dateValue, y: datavalue});
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    //keep the max value of the datawindow updated
                    const max = latestDataEntry.dateTimestamp;
                    self.datawindow[tagIndex] = {min: 0, max: max};
                });

                if(totalDataCount > 0) {
                    chart && self.updateChartMinMaxOptions(chart);
                    self.renderChartWithTimeout();
                }
            }
        },
        directADXDownloadDataHandler(result, tags, jobNumber, wellName, stageNumber, startDate, endDate, interval, startTimeOffset = 0) {
            const self = this;
            if(result.status != 200) {
                $.each(tags, function(i, tagName) {
                    const tagIndex = self.tagToChartIndex[tagName];
                    Vue.set(self.downloadingDataForTagIndex, tagIndex, false);
                });
                console.warn(result);
            }else if(result.data) {

                const resultData = result.data;

                const allTagData = this.adxDataParser(tags, resultData);

                for(let tagNameIndex in tags) {
                    let tagName = tags[tagNameIndex];
                    let data = allTagData[tagName];
                    const tagIndex = self.tagToChartIndex[tagName];

                    //min and max are used to track the datawindow that is kept in memory
                    //to make it easier to add data on either side
                    let prevMin = null;
                    let prevMax = null;
                    let newMin = startDate;
                    let newMax = null;
                    if(self.datawindow[tagIndex]) {
                        prevMin = self.datawindow[tagIndex].min;
                        newMin = startDate < prevMin ? startDate : prevMin;
                        prevMax = self.datawindow[tagIndex].max;
                        newMax = self.datawindow[tagIndex].max;
                    }

                    let lastTimevalue = Number.POSITIVE_INFINITY;
                    for(let i=data.length-1;i>=0;i--) {
                        //insert values in reverse order
                        if (data[i].dateTimestamp) {
                            const timevalue = data[i].dateTimestamp;

                            //ignore any values that are greater than the prevMin for this data series
                            if(prevMin == null || timevalue < prevMin) {
                                const datavalue = self.filterData(tagName, data[i].dataVal);
                                if(datavalue != null) {
                                    //TODO: revisit the following line, it is deliberately ignoring data that comes back out of order
                                    //the proper solution is for the data to be in order at the source and be ordered by the time it gets here
                                    if(timevalue < lastTimevalue) {
                                        if(self.chartConfiguration.isVertical) {
                                            self.rawdata[tagIndex].data.unshift({x: datavalue, y: timevalue});
                                        }
                                        else {
                                            self.rawdata[tagIndex].data.unshift({x: timevalue, y: datavalue});
                                        }

                                        lastTimevalue = timevalue;

                                        if(newMax == null || newMax < timevalue) {
                                            newMax = timevalue;
                                            if (newMax > self.currentDataMaxTimestamp) {
                                                self.currentDataMaxTimestamp = newMax;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    self.datawindow[tagIndex] = {min: newMin, max: newMax};
                    Vue.set(self.downloadingDataForTagIndex, tagIndex, false);
                }
                self.renderChart();
            }
        },
        directADXGhostDataHandler: function(result, tags, jobNumber, wellName, stageNumber, startDate, endDate, interval, startTimeOffset = 0) {
            const self = this;
            if(result.status != 200) {
                $.each(tags, function(i, tagName) {
                    const tagIndex = self.tagToChartIndex[tagName];
                    Vue.set(self.downloadingDataForTagIndex, tagIndex, false);
                });
                console.warn(result);
            }else if(result.data) {

                const resultData = result.data;

                const allTagData = this.adxDataParser(tags, resultData);

                let dataValsArray = [];
                for(let tagNameIndex in tags) {
                    let tagName = tags[tagNameIndex];
                    let data = allTagData[tagName];
                    const tagIndex = self.ghostTagToChartIndex[tagName];

                    let lastTimevalue = Number.POSITIVE_INFINITY;
                    for(let i=data.length-1;i>=0;i--) {
                        //insert values in reverse order
                        if (data[i].dateTimestamp) {
                            const timevalue = data[i].dateTimestamp;

                            //ignore any values that are greater than the prevMin for this data series
                            dataValsArray.push(data[i].dataVal);
                            const datavalue = self.filterData(tagName, data[i].dataVal);
                            if(datavalue != null) {
                                //TODO: revisit the following line, it is deliberately ignoring data that comes back out of order
                                //the proper solution is for the data to be in order at the source and be ordered by the time it gets here
                                if(timevalue < lastTimevalue) {
                                    if(self.chartConfiguration.isVertical) {
                                        self.ghostrawdata[tagIndex].data.unshift({x: datavalue, y: timevalue + startTimeOffset, originalTime: timevalue + startTimeOffset});
                                    }
                                    else {
                                        self.ghostrawdata[tagIndex].data.unshift({x: timevalue + startTimeOffset, y: datavalue, originalTime: timevalue + startTimeOffset});
                                    }

                                    lastTimevalue = timevalue;
                                }
                            }
                        }
                    }

                    Vue.set(self.downloadingDataForTagIndex, tagIndex, false);
                }
                const isNoGhostData = dataValsArray.every(element => !element);
                if (isNoGhostData) {
                    this.showNoGhostDataNotification();
                }
                self.renderChartWithTimeout();
            }
        },
        dateAxisLatestDataHandler: function(result) {
            if (!isNullOrEmpty(result)) {
                result.forEach((latestDataEntry) => {
                    const tagIndex = this.tagToChartIndex[latestDataEntry.tagName];

                    //push value onto end of data series
                    if (!isFalsy(tagIndex) && !isFalsy(latestDataEntry?.dateTimestamp)) {
                        const timevalue = latestDataEntry.dateTimestamp;

                        //ignore any values that are less than the max for this data series
                        if (this.datawindow[tagIndex] && timevalue > this.datawindow[tagIndex].max) {
                            const datavalue = this.filterData(latestDataEntry.tagName, latestDataEntry.dataVal);

                            if (datavalue != null) {
                                if (this.chartConfiguration.isVertical) {
                                    this.rawdata[tagIndex].data.push({x: datavalue, y: timevalue});
                                } else {
                                    this.rawdata[tagIndex].data.push({x: timevalue, y: datavalue});
                                }

                                if (this.datawindow[tagIndex].max == null || this.datawindow[tagIndex].max < timevalue) {
                                    this.datawindow[tagIndex].max = timevalue;
                                    if (timevalue > this.currentDataMaxTimestamp)
                                        this.currentDataMaxTimestamp = timevalue;
                                }
                            }
                        }
                    }
                });

                const chart = this.getLineChart();

                chart && this.updateChartMinMaxOptions(chart);
                this.renderChartWithTimeout();
            }
        },
        assignYAxisId: function(generatedYAxes, tagName) {
            //get the key of the y axis this tag is assigned to
            const axisKey = this.chartConfiguration.valueAxes.find(axis =>
                axis.chartItems.find(chartItem => chartItem.tagName === tagName)).key;

            //return the generated ID associated with the found axis label
            return generatedYAxes.find(axis => axis.key === axisKey).id;
        },
        historicalData: function(from, to, interval) {
            let filterEndDates = {};
            if(from && to) {
                filterEndDates.xMinDate = from;
                filterEndDates.xMaxDate = to;
            }

            //this function builds the graph as new tags are added to activeData

            const self = this;
            const chart = this.getLineChart();
            // console.log("### Active Data", self.activeData);

            //set each line to hidden, they will be marked as unhidden after data arrives from server
            $.each(self.rawdata, function(index, value) {
                value.hidden = true;
            });

            // If download data failed.
            if (this.datacollection.datasets?.length > 0) {
                $.each(this.tagToChartIndex, function(tagName, tagIndex) {
                    self.datacollection.datasets[tagIndex].hidden = true;
                });
            }

            let needToDownloadData = false;
            if(self.activeData.length > 0) {
                const tags = [];
                const yAxes = self.valueAxesGenerated();
                $.each(self.activeData, function(index,value) {
                    //each time a tag is added it gets an index in the graph, that index is static until page reload
                    //when a tag is removed from active data, it is marked as hidden, but it keeps the same index
                    if(self.tagToChartIndex[value.name] === undefined) {
                        tags.push(value.name);
                        needToDownloadData = true; //new tag found
                        self.tagToChartIndex[value.name] = Object.keys(self.tagToChartIndex).length;
                        const tagIndex = self.tagToChartIndex[value.name];
                        //setup data labels first time
                        self.rawdata[tagIndex] = {
                            data: [],
                            label: value.description,
                            borderColor: value?.wellColor ? value.wellColor : value.color,
                            backgroundColor: value?.wellColor ? value.wellColor : value.color,
                            pointRadius: 0,
                            pointBorderWidth: 1,
                            pointBorderColor: '#FFFFFF',
                            pointStyle: 'cross',
                            pointHitRadius: 6,
                            showLine: true,
                            lineTension: 0.1,
                            borderWidth: value.lineWidth,
                            cubicInterpolationMode: 'monotone',
                            fill: false,
                            hidden: false
                        };

                        if(self.chartConfiguration.isVertical) {
                            self.rawdata[tagIndex]['xAxisID'] = self.assignYAxisId(yAxes,value.name);
                            self.rawdata[tagIndex]['yAxisID'] = self.useCustomScaleOnDateAxis ? 'custom-date-axis' : 'date-axis';
                        }
                        else {
                            self.rawdata[tagIndex]['yAxisID'] = self.assignYAxisId(yAxes,value.name);
                            self.rawdata[tagIndex]['xAxisID'] = self.useCustomScaleOnDateAxis ? 'custom-date-axis' : 'date-axis';
                        }

                        //setup data for ghost plots
                        if (!self.ghostTagToChartIndex.hasOwnProperty(value.name)) {
                            //only set up each ghost plot property once
                            self.ghostTagToChartIndex[value.name] = Object.keys(self.ghostTagToChartIndex).length;
                        }

                        const ghostTagIndex = self.ghostTagToChartIndex[value.name];
                        //setup data labels first time
                        if (self.ghostrawdata[ghostTagIndex] == undefined) {
                            self.ghostrawdata[ghostTagIndex] = {
                                data: [],
                                label: value.description,
                                borderColor: value.color,
                                backgroundColor: value.color,
                                ghostData: true,
                                pointRadius: 0,
                                pointBorderWidth: 1,
                                pointBorderColor: '#FFFFFF',
                                pointStyle: 'cross',
                                showLine: true,
                                lineTension: 0.1,
                                borderWidth: value.lineWidth,
                                cubicInterpolationMode: 'monotone',
                                fill: false,
                                hidden: false
                            };
                        }


                        if(self.chartConfiguration.isVertical) {
                            self.ghostrawdata[ghostTagIndex]['xAxisID'] = self.assignYAxisId(yAxes,value.name);
                            self.ghostrawdata[ghostTagIndex]['yAxisID'] = self.useCustomScaleOnDateAxis ? 'custom-date-axis' : 'date-axis';
                        }
                        else {
                            self.ghostrawdata[ghostTagIndex]['yAxisID'] = self.assignYAxisId(yAxes,value.name);
                            self.ghostrawdata[ghostTagIndex]['xAxisID'] = self.useCustomScaleOnDateAxis ? 'custom-date-axis' : 'date-axis';
                        }
                    }
                    else {
                        const tagIndex = self.tagToChartIndex[value.name];
                        self.rawdata[tagIndex].hidden = false;
                    }
                });

                if(filterEndDates.xMinDate == null) {
                    filterEndDates.xMinDate = moment.utc().add({hours: -INITIAL_WINDOW_HRS});
                }

                if(chart) {
                    let xAxis = chart.options.scales.xAxes[0];
                    if(this.chartConfiguration.isVertical) {
                        xAxis = chart.options.scales.yAxes[0];
                    }

                    if(!this.useCustomScaleOnDateAxis && xAxis.ticks.min < filterEndDates.xMinDate) {
                        filterEndDates.xMinDate = xAxis.ticks.min;
                    }
                }

                if(needToDownloadData) {
                    this.downloadData(tags, filterEndDates.xMinDate, filterEndDates.xMaxDate, interval);
                }
            } else {
                //update chart to remove lines
                chart && chart.update();
            }
        },
        filterData: function(tagName, dataValue) {
            if(dataValue > 1000000000) {
                //filter out any data points above 1 billion
                return null;
            }

            return tagName == 'frac_pumpdownRate' && dataValue > 25 ? 25 : dataValue;
        },
        selectionZoom: function(enable) {
            const chart = this.getLineChart();

            if(!chart) {
                return;
            }
            this.dragToZoom = enable;
            this.panMode = enable ? null : chart.options.plugins.zoom.pan.mode;

            chart.options.plugins.zoom.pan.enabled = !enable;
            chart.options.plugins.zoom.zoom.drag = enable;
            chart.update();

            if (this.dragToZoom) {
                this.updatePanAndZoomOptions();
            }

            this.jsonLocalStorage.dragToZoomEnabled = this.dragToZoom;
            this.jsonLocalStorage.save();
        },
        zoomLockRange: function(directions) {
            const chart = this.getLineChart();
            if(!chart) {
                return;
            }
            this.zoomMode = directions;
            chart.options.plugins.zoom.zoom.mode = directions;
            chart.update();
        },
        panLockRange: function(directions) {
            const chart = this.getLineChart();
            if(!chart) {
                return;
            }
            this.panMode = directions;
            chart.options.plugins.zoom.pan.mode = directions;
            chart.update();
        },
        resetScrubber: function (min, max) {
            this.currentChartMin = min;
            this.currentChartMax = max;
        },
        resetZoom: function(chart) {
            chart.resetZoom();
            let newXMin, newXMax;

            this.followingUpdates = true;

            if (this.defaultZoomWindowHrs < MINIMUM_DEFAULT_ZOOM_HOURS) {
                this.defaultZoomWindowHrs = MINIMUM_DEFAULT_ZOOM_HOURS;
            }

            newXMax = Math.max(this.ghostPlotXMax, moment.utc().valueOf()) + this.rightSideMsOffset;
            newXMin = newXMax - (this.defaultZoomWindowHrs * MILLIS_PER_HR);

            if (this.areChartsSynced) {
                this.syncChartMovement(chart.id, newXMin, newXMax, true);
            }
            this.resetScrubber(newXMin, newXMax);
            this.refreshChartDataWithInterval(newXMin, newXMax);
            let updatedWindow = {'resetZoomWindowHours': this.defaultZoomWindowHrs};
            this.updateTSIOptions(updatedWindow);
        },
        updateChartMinMaxOptions: function(chart) {
            if(this.useCustomScaleOnDateAxis || this.isFilterPresent) {
                return;
            }

            let windowSize = (chart.scales['date-axis'].max - chart.scales['date-axis'].min) / MILLIS_PER_HR;

            if(this.jobEnd && this.followingUpdates) {
                const threeHoursWindow = moment.utc(this.jobEnd).subtract({ hours: 3 }).valueOf();

                this.setChartTimeAxisMinMax(threeHoursWindow, moment.utc(this.jobEnd).valueOf());

                if(this.chartConfiguration.isVertical) {
                    chart.options.plugins.zoom.pan.rangeMin.y = null;
                    chart.options.plugins.zoom.pan.rangeMax.y = moment.utc(this.jobEnd).valueOf();
                    chart.options.plugins.zoom.zoom.rangeMin.y = moment.utc(this.dashboardData.jobStart).valueOf() - this.rightSideMsOffset;
                    chart.options.plugins.zoom.zoom.rangeMax.y = moment.utc(this.jobEnd).valueOf();
                }
                else {
                    chart.options.plugins.zoom.pan.rangeMin.x = null;
                    chart.options.plugins.zoom.pan.rangeMax.x = moment.utc(this.jobEnd).valueOf();
                    chart.options.plugins.zoom.zoom.rangeMin.x = moment.utc(this.dashboardData.jobStart).valueOf() - this.rightSideMsOffset;
                    chart.options.plugins.zoom.zoom.rangeMax.x = moment.utc(this.jobEnd).valueOf();
                }
                chart.update();
                return;
            }

            const newXMax = Math.max(this.ghostPlotXMax, moment.utc().valueOf());

            if(this.followingUpdates) {
                // get max x axis value of raw data
                const rawMaxList = [];
                // look through all the tags in our raw data and collect the latest dates
                for(let i = 0; i < this.rawdata.length; i++)
                {
                    // optimization to only search at most the last 5 stored values
                    for(let j = Math.max(0, this.rawdata[i].data.length-5); j < this.rawdata[i].data.length; j++)
                    {
                        if(this.chartConfiguration.isVertical) {
                            rawMaxList.concat(this.rawdata[i].data[j].y);
                        }
                        else {
                            rawMaxList.concat(this.rawdata[i].data[j].x);
                        }
                    }
                }

                // the most recent unix timestamp date in our raw data
                const rawMax = moment.max(rawMaxList).valueOf();
                if(rawMax > chart.scales['date-axis'].max)
                {
                    if(this.chartConfiguration.isVertical) {
                        chart.options.scales.yAxes[0].ticks.min = moment.utc().add({hours: -windowSize}).valueOf() + this.rightSideMsOffset;
                        chart.options.scales.yAxes[0].ticks.max = moment.utc().valueOf() + this.rightSideMsOffset;

                        chart.options.plugins.zoom.pan.rangeMin.y = null;
                        chart.options.plugins.zoom.pan.rangeMax.y = newXMax + this.rightSideMsOffset;
                        chart.options.plugins.zoom.zoom.rangeMin.y = moment.utc(this.dashboardData.jobStart).valueOf() - this.rightSideMsOffset;
                        chart.options.plugins.zoom.zoom.rangeMax.y = newXMax + this.rightSideMsOffset;
                    }
                    else {
                        chart.options.scales.xAxes[0].ticks.min = moment.utc().add({hours: -windowSize}).valueOf() + this.rightSideMsOffset;
                        chart.options.scales.xAxes[0].ticks.max = moment.utc().valueOf() + this.rightSideMsOffset;

                        chart.options.plugins.zoom.pan.rangeMin.x = null;
                        chart.options.plugins.zoom.pan.rangeMax.x = newXMax + this.rightSideMsOffset;
                        chart.options.plugins.zoom.zoom.rangeMin.x = moment.utc(this.dashboardData.jobStart).valueOf() - this.rightSideMsOffset;
                        chart.options.plugins.zoom.zoom.rangeMax.x = newXMax + this.rightSideMsOffset;

                        this.setChartTimeAxisMinMax(moment.utc().add({hours: -windowSize}).valueOf() + this.rightSideMsOffset, moment.utc().valueOf() + this.rightSideMsOffset);
                    }
                }

                chart.update();
            }
            else {
                if(this.chartConfiguration.isVertical) {
                    chart.options.plugins.zoom.pan.rangeMin.y = null;
                    chart.options.plugins.zoom.pan.rangeMax.y = newXMax + this.rightSideMsOffset;
                    chart.options.plugins.zoom.zoom.rangeMin.y = moment.utc(this.dashboardData.jobStart).valueOf() - this.rightSideMsOffset;
                    chart.options.plugins.zoom.zoom.rangeMax.y = moment.utc(chart.scales['date-axis'].max).valueOf();
                }
                else {
                    chart.options.plugins.zoom.pan.rangeMin.x = null;
                    chart.options.plugins.zoom.pan.rangeMax.x = newXMax + this.rightSideMsOffset;
                    chart.options.plugins.zoom.zoom.rangeMin.x = moment.utc(this.dashboardData.jobStart).valueOf() - this.rightSideMsOffset;
                    chart.options.plugins.zoom.zoom.rangeMax.x = moment.utc(chart.scales['date-axis'].max).valueOf();
                }

                chart.update();
            }
        },
        downsample: function(data, threshold) {
            // this function is from flot-downsample (MIT), with modifications

            const dataLength = data.length;
            if (threshold >= dataLength || threshold <= 0) {
                return data; // nothing to do
            }

            const sampled = [];
            let sampledIndex = 0;

            // bucket size, leave room for start and end data points
            const every = (dataLength - 2) / (threshold - 2);

            let a = 0,  // initially a is the first point in the triangle
                maxAreaPoint,
                maxArea,
                area,
                nextA;

            // always add the first point
            sampled[sampledIndex++] = data[a];

            for (let i = 0; i < threshold - 2; i++) {
                // Calculate point average for next bucket (containing c)
                let avgX = 0,
                    avgY = 0,
                    avgRangeStart = Math.floor(( i + 1 ) * every) + 1,
                    avgRangeEnd = Math.floor(( i + 2 ) * every) + 1;
                avgRangeEnd = avgRangeEnd < dataLength ? avgRangeEnd : dataLength;

                const avgRangeLength = avgRangeEnd - avgRangeStart;

                for (; avgRangeStart < avgRangeEnd; avgRangeStart++) {
                    avgX += data[avgRangeStart].x * 1; // * 1 enforces Number (value may be Date)
                    avgY += data[avgRangeStart].y * 1;
                }
                avgX /= avgRangeLength;
                avgY /= avgRangeLength;

                // Get the range for this bucket
                let rangeOffs = Math.floor((i + 0) * every) + 1;
                const rangeTo = Math.floor((i + 1) * every) + 1;

                // Point a
                const pointAX = data[a].x * 1, // enforce Number (value may be Date)
                    pointAY = data[a].y * 1;

                maxArea = area = -1;

                for (; rangeOffs < rangeTo; rangeOffs++) {
                    // Calculate triangle area over three buckets
                    area = Math.abs(( pointAX - avgX ) * ( data[rangeOffs].y - pointAY ) -
                                ( pointAX - data[rangeOffs].x ) * ( avgY - pointAY )
                    ) * 0.5;
                    if (area > maxArea) {
                        maxArea = area;
                        maxAreaPoint = data[rangeOffs];
                        nextA = rangeOffs; // Next a is this b
                    }
                }

                sampled[sampledIndex++] = maxAreaPoint; // Pick this point from the bucket
                a = nextA; // This a is the next a (chosen b)
            }

            sampled[sampledIndex] = data[dataLength - 1]; // Always add last

            return sampled;
        },
        sectionCheckedChanged: function(section) {
            this.isDisabledCheckbox = true; // disable checkbox while chart is being updated
            for(const sectionID in this.usedSections) {
                if(sectionID == section) {
                    const sectionItems = this.usedSections[sectionID];
                    if(this.sectionChecked[sectionID]) {
                        //set all items to on
                        for(const sectionItemIndex in sectionItems) {
                            const sectionItem = sectionItems[sectionItemIndex];
                            const paramChecked = this.paramChecked[sectionItem.name];
                            if(!paramChecked) {
                                this.paramChecked[sectionItem.name] = true;
                                this.setActiveData(sectionItem, true);
                            }
                        }
                    }
                    else {
                        //set all items to off
                        for(const sectionItemIndex in sectionItems) {
                            const sectionItem = sectionItems[sectionItemIndex];
                            const paramChecked = this.paramChecked[sectionItem.name];
                            if(paramChecked) {
                                this.paramChecked[sectionItem.name] = false;
                                this.setActiveData(sectionItem, true);
                            }
                        }
                    }
                }
            }

            // check if there are have active data sections
            let activeData = false;
            for (section in this.paramChecked) {
                if (this.paramChecked[section]) {
                    activeData = true;
                    break;
                }
            }
            if (!activeData) {
                // if all data tags are unselected, enable checkboxes
                this.isDisabledCheckbox = false;
            }

            this.closeTsiAnalytics();
            this.onWindowChanged(this.getLineChart());
        },
        onNewFrac(wellNumber) {
            if(this.ghostLineWell) {
                const currentWellIndex = this.ghostLineWell.index;
                if(currentWellIndex !== wellNumber) { //catch frac well changes
                    this.ghostPlotEnabled = false;
                    this.ghostLineWell = this.wells.find((well)=>well.index==wellNumber); //find new stage well;
                    this.$nextTick(()=>{
                        this.ghostPlotEnabled = true;
                    });
                }
            }
        },
        onStageCompleted() {
            this.fetchWellStageIntervals();
        },
        updateSectionCheckboxes: function() {
            for(const sectionID in this.usedSections) {
                const sectionItems = this.usedSections[sectionID];
                let foundUnselected = false;
                for(const sectionItemIndex in sectionItems) {
                    const sectionItem = sectionItems[sectionItemIndex];
                    const paramChecked = this.paramChecked[sectionItem.name];
                    if(!paramChecked) {
                        foundUnselected = true;
                        break;
                    }
                }
                this.sectionChecked[sectionID] = !foundUnselected;
            }
        },
        setShadedData: function(obj, shouldRenderChart=true) {
            this.shadingChecked[obj.name] = !this.shadingChecked[obj.name];
            const active = this.shadingChecked[obj.name];

            // update local storage object
            const sectionId = obj.sectionKey;
            const section = this.jsonLocalStorage.shadingData.find((s) => s.sectionId === sectionId);
            const sectionIndex = this.jsonLocalStorage.shadingData.indexOf(section);

            if (sectionIndex > -1) {
                const index = section.tags.indexOf(obj.name);
                if(active && index < 0) {
                    section.tags.push(obj.name);
                } else if(!active && index > -1) {
                    section.tags.splice(index, 1);
                    if(section.tags.length === 0) {
                        this.jsonLocalStorage.shadingData.splice(sectionIndex, 1);
                    }
                }
            } else {
                if(active) {
                    this.jsonLocalStorage.shadingData.push({ sectionId, tags: [obj.name]});
                }
            }

            this.jsonLocalStorage.save();

            if (shouldRenderChart) {
                this.$forceUpdate(); // Always feels gross using forceUpdate but this isn't a common action and should affect the entire component anyway
                this.renderChart();
            }
        },
        setActiveData: function(obj, skipHistoric=false, shouldRenderChart=true) {
            //whenever active data changes, clear out the ghost plot to avoid issues
            this.ghostPlotEnabled = false;

            const active = this.paramChecked[obj.name];
            if(active) {
                if(this.activeData.indexOf(obj)===-1) {
                    this.activeData.push(obj);
                }
            }
            else {
                for(let i=0;i<this.activeData.length;i++) {
                    if (obj.name === this.activeData[i].name) {
                        this.activeData.splice(i,1);
                        //if a datasource is set inactive, it's data is hidden on the chart
                        const tagIndex = this.tagToChartIndex[obj.name];
                        this.datacollection.datasets[tagIndex].hidden = true;
                        //the rawdata for the datasource must be set to hidden also, or the chart line
                        //reappears on the next chart render
                        this.rawdata[tagIndex].hidden = true;
                    }
                }
            }

            this.closeTsiAnalytics();
            this.updateSectionCheckboxes();

            // update local storage object
            const sectionId = obj.sectionKey;
            const section = this.jsonLocalStorage.activeData.find((s) => s.sectionId === sectionId);
            const sectionIndex = this.jsonLocalStorage.activeData.indexOf(section);

            if(sectionIndex > -1) {
                const index = section.tags.indexOf(obj.name);
                if(active && index < 0) {
                    section.tags.push(obj.name);
                } else if(!active && index > -1) {
                    section.tags.splice(index, 1);
                    if(section.tags.length === 0) {
                        this.jsonLocalStorage.activeData.splice(sectionIndex, 1);
                    }
                }
            } else {
                if(active) {
                    this.jsonLocalStorage.activeData.push({ sectionId, tags: [obj.name]});
                }
            }

            this.jsonLocalStorage.save();

            //does the actual chart loading
            if(!skipHistoric && active) {
                this.onWindowChanged(this.getLineChart(), false);
            }

            if(shouldRenderChart) {
                this.renderChartWithTimeout();
            }
        },
        getTitleColorForBG: function(bgColor) {
            return GlobalFunctions.getTitleColorForBG(bgColor);
        },
        findTagByTagName: function(tagName) {
            for (const targetAxisIndex in this.chartConfiguration.valueAxes) {
                const targetAxis = this.chartConfiguration.valueAxes[targetAxisIndex];
                const targetChartItem = targetAxis.chartItems.find(target => target.tagName == tagName);
                
                if (targetChartItem != null)
                    return targetChartItem;
            }

            return null;
        },
        isChartItemSelectedByDefault: function(tagName) {
            return !!this.findTagByTagName(tagName)?.selectedByDefault;
        },
        isChartItemShadedByDefault: function(tagName) {
            return !!this.findTagByTagName(tagName)?.lineShading;
        },
        init() {
            const hasSavedState = this.jsonLocalStorage.hasSavedState();
            const emptySectionsInfo = [];
            this.usedSections = {};
            this.activeData = [];
            this.shadingData = [];
            for(const tagName in this.frac.items) {
                const latestDataValue = this.latestDataCollection.find((latestData => latestData.tagName === tagName))?.dataVal ?? '--';
                const fracItem = {
                    name: tagName,
                    value: latestDataValue
                };
                let itemLayout = {};
                let sectionID = '';
                let sectionName = '';
                let sectionIndex = '';
                let itemIndex = '';
                let columnCount = this.sectionDefaultWidthTopBottom;
                let self = this;
                this.chartConfiguration.sections.forEach(function(section, tempSectionIndex) {
                    if(section?.orderedChartItems?.length) {
                        section.orderedChartItems.forEach(function(chartItem, tempItemIndex) {
                            if (tagName === chartItem.tagName) {
                                sectionID = section.key;
                                sectionName = section.name;
                                sectionIndex = tempSectionIndex;
                                itemIndex = tempItemIndex;
                                if (section.columnCount) {
                                    const itemWidth = 12 / section.columnCount;
                                    columnCount = section.columnCount;
                                    if (section.chartItemLayout && section.chartItemLayout[itemIndex].tagName == chartItem.tagName) {
                                        itemLayout = section.chartItemLayout.find(layout => layout.tagName == chartItem.tagName);
                                    } else if (section.chartItemLayout && section.chartItemLayout[itemIndex].tagName != chartItem.tagName) {
                                        itemLayout = section.chartItemLayout[itemIndex];
                                        itemLayout.tagName = chartItem.tagName;
                                    }
                                    else {
                                        itemLayout.x = (itemIndex % columnCount) * itemWidth;
                                        itemLayout.y = Math.floor(itemIndex / columnCount);
                                        itemLayout.h = 2;
                                        itemLayout.w = itemWidth;
                                        itemLayout.i = itemIndex;
                                        itemLayout.labelTextOptions = {};
                                        itemLayout.dataTextOptions = {};
                                        itemLayout.tagName = chartItem.tagName;
                                    }
                                } else {
                                    if (section.chartItemLayout) {
                                        itemLayout = section.chartItemLayout[tempItemIndex];
                                    } else {
                                        itemLayout.x = 0;
                                        itemLayout.y = itemIndex * 2;
                                        itemLayout.h = 2;
                                        itemLayout.w = 2;
                                        itemLayout.i = itemIndex;
                                        itemLayout.labelTextOptions = {};
                                        itemLayout.dataTextOptions = {};
                                    }
                                }
                            }
                        });
                    } else {
                        const emptySection = {
                            sectionID: section.key,
                            sectionName: section.name,
                            sectionIndex: tempSectionIndex
                        };
                        if (emptySectionsInfo.filter(section => section.sectionID === emptySection.sectionID).length === 0) {
                            emptySectionsInfo.push(emptySection);
                            self.usedSections[tempSectionIndex] = [];
                        }
                    }
                });

                if(sectionID == '') {
                    console.warn(`A chart item for ${tagName} has no section ID and will not be rendered. Please make sure chart items have a valid section ID.`);
                    continue;
                }
                if(!this.usedSections[sectionIndex]) {
                    Vue.set(this.usedSections, sectionIndex, []);
                    Vue.set(this.usedSectionsIDs, sectionIndex, '');
                    Vue.set(this.chartItemLayouts, sectionID, []);
                    this.usedSectionsIDs[sectionIndex] = sectionID;
                    this.usedSections[sectionIndex] = [];
                    this.sectionLabel[sectionIndex] = '';
                }
                this.usedSections[sectionIndex].push(fracItem);
                this.chartItemLayouts[sectionID].push(itemLayout);
                this.sectionLabel[sectionIndex] = sectionName;
                this.columnCounts[sectionIndex] = columnCount;
            }
            //if all sections have any chart items this sets up the sections
            if (Object.keys(this.frac.items).length == 0 || this.frac.items == null) {
                const self = this;
                this.chartConfiguration.sections.forEach(function(section, index) {
                    self.sectionLabel[index] = section.name;
                    self.usedSectionsIDs[index] = section.key;
                    self.usedSections[index] = [];
                });
            }

            emptySectionsInfo.forEach(emptySection => {
                this.sectionLabel[emptySection.sectionIndex] = emptySection.sectionName;
            });

            const chart = this.$refs['lineChart'].$data._chart;
            this.currentChartWidth = chart.width;

            const dateAxis = this.chartConfiguration.dateAxis;
            if(dateAxis) {
                //custom x axis
                this.useCustomScaleOnDateAxis = true;
                this.dateAxisTag = dateAxis.tagName;
            }

            chart.options.plugins.zoom = this.chartOptionsZoomPlugin();
            chart.options.plugins.tsiAnalytics = this.chartOptionsTsiPlugin();

            if(this.chartConfiguration.isVertical) {
                const style = this.dashboardData.selectedDashboard?.chart_tooltip_mode ?? 'nearest';
                chart.options.tooltips.mode = style === 'all'? 'y':'nearest';
                chart.options.tooltips.isVertical = this.chartConfiguration.isVertical //for tooltip positioning
            }

            // update state based on local storage values

            const keys = Object.keys(this.usedSections);
            let flattenedDataSet = []; // usedSections is an object rather than an array, flatten its values into an array to search through more easily
            for(let i = 0; i < keys.length; i++) {
                flattenedDataSet = flattenedDataSet.concat(this.usedSections[i]);
            }

            for(let i = 0; i < this.jsonLocalStorage.shadingData.length; i++) {
                for(let j = 0; j < this.jsonLocalStorage.shadingData[i].tags.length; j++) {
                    const tag = this.jsonLocalStorage.shadingData[i].tags[j];
                    const data = flattenedDataSet.find(d => d.name === tag);
                    if(data) {
                        this.$set(this.shadingChecked, tag, true);
                    } else {
                        this.$set(this.shadingChecked, tag, false);
                    }
                }
            }
            for(let i = 0; i < this.jsonLocalStorage.activeData.length; i++) {
                for(let j = 0; j < this.jsonLocalStorage.activeData[i].tags.length; j++) {
                    const tag = this.jsonLocalStorage.activeData[i].tags[j];
                    const data = flattenedDataSet.find(d => d.name === tag);
                    if(data) {
                        this.$set(this.paramChecked, tag,true);
                        this.setActiveData(data, true, false);
                    }
                    else {
                        this.$set(this.paramChecked, tag,false);
                    }
                }
            }
            this.jsonLocalStorage.defaultData = this.jsonLocalStorage.defaultData ? this.jsonLocalStorage.defaultData : [];
            if(!hasSavedState) {
                // expand all sections by default
                for(const key in this.usedSectionsIDs) {
                    if(this.jsonLocalStorage.visibleSections.indexOf(this.usedSectionsIDs[key]) === -1) {
                        this.jsonLocalStorage.visibleSections.push(this.usedSectionsIDs[key]);
                    }
                }

                this.jsonLocalStorage.save();

                let selectedByDefaultUsed = false;
                for(let i = 0; i < flattenedDataSet.length; i++) {
                    if(this.isChartItemSelectedByDefault(flattenedDataSet[i].name)) {
                        this.jsonLocalStorage.defaultData.push(flattenedDataSet[i].name);
                        this.paramChecked[flattenedDataSet[i].name] = true;
                        this.setActiveData(flattenedDataSet[i], true, false);
                        selectedByDefaultUsed = true;
                    }

                    if (this.isChartItemShadedByDefault(flattenedDataSet[i].name)) {
                        this.shadingChecked[flattenedDataSet[i].name] = false; // set false, setShadedData will flip it to true
                        this.setShadedData(flattenedDataSet[i], false);
                    }
                }

                if(!selectedByDefaultUsed) {
                    const validFallbackDefault = flattenedDataSet.find((obj) => { return obj.value != '--'; });
                    if(validFallbackDefault != null) {
                        this.paramChecked[validFallbackDefault.name] = true;
                        this.setActiveData(validFallbackDefault, true, false);
                    }
                }
            } else {
                flattenedDataSet.forEach((data) => {
                    let isSelectedDefault = this.isChartItemSelectedByDefault(data.name);
                    let isDefaultData = this.jsonLocalStorage.defaultData.find((obj) => {return obj === data.name;});

                    if (isSelectedDefault && isDefaultData === undefined) {
                        // add recently selectedByDefault tags to local storage
                        this.paramChecked[data.name] = true;
                        this.setActiveData(data, true, false);
                        this.jsonLocalStorage.defaultData.push(data.name);
                        this.jsonLocalStorage.save();
                    } else if (!isSelectedDefault && isDefaultData) {
                        // remove recently unselectedByDefault tags from local storage
                        this.paramChecked[data.name] = false;
                        let index = this.jsonLocalStorage.activeData[0].tags.indexOf(data.name);
                        this.jsonLocalStorage.activeData[0].tags.splice(index, 1);
                        index = this.jsonLocalStorage.defaultData.indexOf(data.name);
                        this.jsonLocalStorage.defaultData.splice(index, 1);
                        this.jsonLocalStorage.save();
                    }

                    // shadingData is new
                    // if local storage data exists but does not contain shadingData, default to shaded by default options
                    if (!('shadingData' in this.savedJsonLocalStorage)) {
                        if (this.isChartItemShadedByDefault(data.name)) {
                            this.shadingChecked[data.name] = false; // set false, setShadedData will flip it to true
                            this.setShadedData(data, false);
                        }
                    }
                });
            }

            //setup xAxis
            if(!this.customDateAxisHasBeenSet) {
                let xAxes = [];
                if(this.useCustomScaleOnDateAxis) {
                    xAxes = [this.dateAxisGenerated()];
                    this.customDateAxisHasBeenSet = true;
                }
                else {
                    let showAxisLine = true;
                    for(const axisIndex in this.chartConfiguration.valueAxes) {
                        if (this.chartConfiguration.valueAxes[axisIndex].displayGridlines) {
                            showAxisLine = false;
                        }
                    }

                    xAxes =  [{
                        id: 'date-axis',
                        type: 'linear', //time type does not work with zoom library
                        display: this.isDateAxisHidden? false:true,
                        ticks: {
                            callback: this.formatScaleWithJobLocalTime,
                            autoSkipPadding: 5,
                            maxRotation: 0,
                            min: moment.utc().add({hours: -this.defaultZoomWindowHrs}).valueOf()+this.rightSideMsOffset,
                            max: moment.utc().valueOf()+this.rightSideMsOffset,
                            fontColor: 'white',
                            fontSize: this.localChartAxisOptions.independentAxis.tickFontSize,
                            reverse: this.chartConfiguration.isVertical
                        },
                        gridLines: {
                            display: this.chartConfiguration.showVerticalGridlines,
                            color: this.chartConfiguration.verticalGridlinesColor,
                            zeroLineColor: this.chartConfiguration.verticalGridlinesColor,
                            drawBorder: showAxisLine
                        }
                    }];
                }

                if(this.chartConfiguration.isVertical) {
                    chart.options.scales.yAxes = xAxes;
                }
                else {
                    chart.options.scales.xAxes = xAxes;
                }
            }
            //setup yAxes
            if(!this.valueAxesHasBeenSet) {
                if(this.chartConfiguration.isVertical) {
                    chart.options.scales.xAxes = this.valueAxesGenerated();
                }
                else {
                    chart.options.scales.yAxes = this.valueAxesGenerated();
                }
                this.valueAxesHasBeenSet = true;
            }

            chart.update();

            this.setCurrentChartMinMax(chart);

            this.updateDownsampleWindow();

            if(this.shouldDrawGraph) {
                this.panChartToLatestTime();

                this.onWindowChanged(chart);

                this.correctTimelineWidth();
            }
            this.chartConfigurationData.orderedSections.forEach(section => {
                this.selectedTags[section.key] = section.orderedChartItems.map(item=>item.tagName);
            });
            this.hideHeader = !this.showChartHeaderConfig;
        },
        mountChartListeners: function () {
            //listen for set well stage filter events
            this.$root.$on('syncSetWellStageFilter', args => {
                if (!this.useCustomScaleOnDateAxis) {
                    this.setWellStageFilter(args['event'],args);
                }
            });
            //listen for well stage filter clear events
            this.$root.$on('syncClearWellStageFilter', event => {
                if (!this.useCustomScaleOnDateAxis) {
                    this.clearWellStageFilter(event);
                }
            });
        },
        getTooltipMode: function() {
            if (this.isNotDashboard) { //non-dashboard pages default to show all at index
                return 'x';
            }
            const style = this.dashboardData.selectedDashboard?.chart_tooltip_mode  ?? 'nearest';
            if (style === 'all') {
                return 'x';
            }
            return 'nearest';
        },
        contains: function (rect, x, y) {
            return rect.x <= x && x <= rect.x + rect.width &&
                rect.y <= y && y <= rect.y + rect.height;
        },
        sortTagsByName: function (a,b) {
            const nameA = (a?.customerTagname?.name || a?.friendlyTagname || a.name).toLowerCase();
            const nameB = (b?.customerTagname?.name || b?.friendlyTagname || b.name).toLowerCase();
            if (nameA > nameB) {return 1;};
            if (nameA < nameB) {return -1;};
            return 0;
        },
        sortTagsByPriorityName: function(a,b) {
            if (a.priorityName.toLowerCase() > b.priorityName.toLowerCase()) {return 1;};
            if (a.priorityName.toLowerCase() < b.priorityName.toLowerCase()) {return -1;};
            return 0;
        },
        resetChartView() {
            this.resetZoom(this.$refs?.lineChart.$data._chart);
            this.$refs?.chartScrubber.resetScrubberBrush();
        }
    },
    watch: {
        'selectedTruck': {
            handler: function (newValue, oldValue) {
                this.initSelectedTruck = this.targetSystem.find(truck => truck.number == newValue) || null;
            }
        },
        'isAnyModalOrPopoverOpen': {
            handler: function (newValue, oldValue) {
                this.hideTooltipWhenModalOrPopoverOpen();
            }
        },
        'areAllModalsAndPopoversClosed': {
            handler: function (newValue, oldValue) {
                this.hideTooltipWhenModalOrPopoverOpen();
            }
        },
        'dashboardData.latestStageMarkerData': {
            handler: function (newValue, oldValue) {
                if (newValue !== null && this.chartConfiguration.showStageLines) {
                    this.createWellStageMarkers(newValue);
                }
            }
        },
        'ghostPlotIndex.time': {
            handler: function (newValue, oldValue) {
                const self = this;
                //if user is changing the ghost plot index manually, make the popover transparent
                //for a short period so they can see the ghostplot on the underlying chart
                if (this.ghostPlotEnabled) {
                    clearInterval(self.seeThroughTimer.timeId);
                    this.seeThroughTimer.value = 2;
                    this.seeThroughTimer.timeId = setInterval(function() {
                        self.seeThroughTimer.value--;
                        if (self.seeThroughTimer.value <= 0) {
                            clearInterval(self.seeThroughTimer.timeId);
                        }
                    },1000);
                }
                //if this is the first load, set the time to the correct format for datetime-local input
                if (oldValue == null) {
                    this.ghostPlotIndex.time = moment(newValue,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss');
                }
                const originalIndex = moment.utc(this.ghostLineStage.startTime,'YYYY-MM-DD HH:mm:ss');
                const currentIndex = moment.utc(newValue, ['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DD HH:mm:ss']);

                this.ghostPlotIndex.diffMS = currentIndex.diff(originalIndex);
                this.renderChartWithTimeout(true);
            }
        },
        'dashboardData.latestWellSummary': {
            handler: function (newValue, oldValue) {
                if(newValue.length > 0) {
                    const lastWellSummary = newValue[newValue.length-1];
                    const newFracWell = lastWellSummary.wells.filter((well)=>well.statusString === 'Fracing');
                    const stageCompleteWell = lastWellSummary.wells.filter((well)=>well.statusString === 'Stage Complete');
                    if(newFracWell) {
                        const wellNumber = this.wells.find((well)=> well.name == newFracWell.name);
                        this.onNewFrac(wellNumber);
                    }
                    if(stageCompleteWell) {
                        this.onStageCompleted();
                    }
                }
            },
            deep: true
        },
        inStatsMode: {
            handler(newValue, oldValue) {
                const chart = this.getLineChart();
                if(!chart) {return;}
                if(newValue) {
                    chart.options.plugins.zoom.zoom.drag = false;
                    chart.options.plugins.zoom.pan.enabled = false;
                    chart.options.plugins.tsiAnalytics.clear = false;
                    chart.options.plugins.zoom.zoom.enabled = false;
                    chart.options.plugins.tsiAnalytics.enabled = true;
                    chart.options.plugins.tsiAnalytics.jobHourOffset = this.jobHourOffset;
                    chart.options.plugins.tsiAnalytics.isChartActive = this.isActiveChart;
                    chart.options.plugins.tsiAnalytics.useCustomScales = this.useCustomScaleOnDateAxis;
                    chart.options.plugins.tsiAnalytics.overlayId = `overlay-tsi-analytics${this._uid}`;
                    chart.options.plugins.tsiAnalytics.isVertical = this.chartConfiguration?.isVertical;
                    chart.options.plugins.tsiAnalytics.dateTimeId = `dateTimeDisplay${this.dashboardItem.i}`;
                } else {
                    this.closeTsiAnalytics();
                    chart.options.plugins.tsiAnalytics.enabled = false;
                    chart.options.plugins.zoom.zoom.drag = this.dragToZoom;
                    chart.options.plugins.zoom.pan.enabled = !this.dragToZoom;
                    chart.options.plugins.zoom.zoom.enabled = true;
                }
                chart.update();
            }
        },
        currentChartMax: {
            handler(newValue, oldValue) {
                const chart = this.getLineChart();
                if(chart && newValue) {
                    const data = Object.values(this?.datawindow).map(d => d.max);
                    const chartMax = this.currentChartMax.valueOf();
                    const dataMax = _.max(data);
                    // update the tsiAnalytics chartMin so it allows selection to the min drawn data point
                    chart.options.plugins.tsiAnalytics.chartMin = this.currentChartMin.valueOf();
                    // update the tsiAnalytics chartMax so it allows selection to the last drawn data point
                    chart.options.plugins.tsiAnalytics.chartMax = dataMax > chartMax ? dataMax : chartMax;
                    chart.update();
                }
            }
        },
        usedSections: {
            handler(newValue, oldValue)
            {
                if(this.sectionIndexes.length === 0) {
                    const sectionKeys = Object.keys(newValue);
                    const sectionIndexes = [];
                    for (const key of sectionKeys) {
                        sectionIndexes.push({
                            index: key,
                            value: newValue[key]
                        });
                    }
                    this.sectionIndexes = sectionIndexes;
                }
            }
        },
        wirelineSystems: {
            immediate: true,
            handler(newValue, oldValue)
            {
                if(newValue && Array.isArray(newValue) && newValue.length > 0) {
                    const systems = _.orderBy(newValue, ['number'], ['asc']);
                    this.truckOptions = systems.map((truck) => {
                        return {
                            text: truck.name,
                            value: truck.number
                        };
                    });
                }
            }
        },
        latestDataCollection: {
            handler(newValue, oldValue)
            {
                if(!this.chartConfiguration || this.isSelecting) {
                    return;
                }

                const dataSources = this.generateDataSources(this.chartConfiguration);
                this.tags = dataSources.map(d => d.name);

                this.updateChartItemsWithData(newValue, dataSources, this.frac, this.wells);

                if(!(this.isDownloadingData || this.isFilterPresent || this.isWaitingOnReconnect || this.scrubberIsMoving)) {
                    if(this.useCustomScaleOnDateAxis) {
                        this.customAxisLatestDataHandler(newValue);
                    }
                    else {
                        this.dateAxisLatestDataHandler(newValue);
                    }
                }
            }
        },
        height: {
            immediate: true,
            handler(newVal, oldVal) {
                if(this.$refs['lineChart']) {
                    this.$refs['lineChart'].$data._chart.resize();
                }
            }
        },
        globalChartSynced: {
            handler(newValue, oldValue)
            {
                const chart = this.getLineChart();
                if(newValue.chartSourceId != undefined && newValue.chartSourceId != chart.id) {
                    this.setIsActiveChart(false);
                    //ignore sync updates if they came from this chart
                    if(!this.useCustomScaleOnDateAxis && newValue.panXMin != undefined && newValue.panXMax != undefined) {
                        if (newValue.moveComplete) {
                            this.$refs.chartScrubber.setBrushMinMax(newValue.panXMin.valueOf(), newValue.panXMax.valueOf())

                            let saveChangeInZoomHistory = true;
                            if(newValue.popZoomHistory && this.zoomHistory) {
                                this.zoomHistory.pop();
                                saveChangeInZoomHistory = false;
                            }

                            if (this.inStatsMode) {
                                // clears all the drawn boxes in the synced charts
                                this.closeTsiAnalytics();
                            }
                            this.refreshChartDataWithInterval(newValue.panXMin.valueOf(), newValue.panXMax.valueOf(), true, saveChangeInZoomHistory);
                            this.$nextTick(() => {//wait for next tick so that the annotations are created, then stagger if needed
                                this.staggerLabelOverlaps(chart, true);
                            });
                        }
                    }
                    else if(newValue.tooltipX != undefined) {
                        //fake mouse motion to the location of the tooltip on synced charts
                        let dateScale = null;
                        //only create a synced tooltip if x axis is valid for passed tooltip
                        if (this.useCustomScaleOnDateAxis && this.chartConfiguration?.dateAxis.tagName === newValue.customAxisTagName) {
                            dateScale = chart.scales['custom-date-axis'];
                        } else if (!this.useCustomScaleOnDateAxis) {
                            dateScale = chart.scales['date-axis'];
                        }

                        if(dateScale) {
                            const pixel = dateScale.getPixelForValue(newValue.tooltipX);

                            const rectangle = chart.canvas.getBoundingClientRect();
                            const xPos = this.chartConfiguration.isVertical ? rectangle.left + rectangle.width / 2 : rectangle.left + pixel;
                            const yPos = this.chartConfiguration.isVertical ? rectangle.top + pixel : rectangle.top + rectangle.height / 2;
                            if(isFinite(xPos) && isFinite(yPos)) {
                                const mouseMoveEvent = new MouseEvent('mousemove', {
                                    clientX: xPos,
                                    clientY: yPos
                                });

                                if(this.contains(rectangle, xPos, yPos)) {
                                    chart.canvas.dispatchEvent(mouseMoveEvent);
                                }
                            }
                        }
                    }
                    else if(newValue.hideTooltips != undefined) {
                        chart.canvas.dispatchEvent(new MouseEvent('mouseout'));
                    }
                }
            }
        },
        ghostPlotEnabled: {
            handler(newValue, oldValue)
            {
                if(newValue) {
                    const fracStageIntervals = this.wellStageIntervalsByActivityType?.frac?.wellStageActivityData;
                    //find the time of the latest frac start
                    let latestStage = null;
                    for(const wellIndex in fracStageIntervals) {
                        const wellFracStageIntervals = fracStageIntervals[wellIndex];
                        if (wellFracStageIntervals == null || wellFracStageIntervals?.length == 0) {//omit wells with no data
                            continue;
                        }
                        const latestStageNumber = Math.max(...Object.keys(wellFracStageIntervals));

                        if(latestStage == null || latestStage.startTime < wellFracStageIntervals[latestStageNumber].startTime) {
                            latestStage = wellFracStageIntervals[latestStageNumber];
                        }
                    }

                    const targetStartTime = latestStage.startTime.replaceAll(' ', 'T') + 'Z';
                    const targetStartTimeUTC = moment.utc(targetStartTime).subtract({hours: this.jobHourOffset}).valueOf();
                    this.ghostPlotTargetTimeStart = targetStartTimeUTC;
                    this.downloadGhostData();
                } else {
                    this.followingUpdates = true;
                    this.followToLatestTime();
                }
                if (this.isSaving) {
                    //clearing out data
                    $.each(this.ghostrawdata, function(index, rawdata) {
                        rawdata.data = [];
                    });

                    //clear datasets from the chart
                    this.datacollection.datasets.splice(Object.keys(this.tagToChartIndex).length, Object.keys(this.ghostTagToChartIndex).length);

                    //reset the chart axis if necessary
                    if (this.changeAxis) {
                        // if user selects different well, stage, or channel, do not reset chart axis
                        this.ghostPlotXMax = 0;
                        const chart = this.getLineChart();
                        this.updateChartMinMaxOptions(chart);

                        if(moment.utc().valueOf() + this.rightSideMsOffset < moment.utc(chart.scales['date-axis'].max).valueOf()) {
                            //chart is drawing into the future and needs to be reset back to standard view
                            let windowSize = (chart.scales['date-axis'].max - chart.scales['date-axis'].min) / MILLIS_PER_HR;

                            this.setChartTimeAxisMinMax(moment.utc().add({hours: -windowSize}).valueOf() + this.rightSideMsOffset, moment.utc().valueOf() + this.rightSideMsOffset);
                        }
                    }

                    // reset variables after altering the graph
                    this.isSaving = false;
                    this.changeAxis = true;
                }
            }
        },
        stageStartMarkers: {
            handler(newValue, oldValue) {
                if (newValue !== null && this.chartConfiguration.showStageLines) {
                    this.createWellStageMarkers();
                }
            }
        },
        downloadingDataForTagIndex: {
            handler(newValue, oldValue) {
                this.updatePanAndZoomOptions();

                if(!this.isDownloadingData && this.isDownloadingGhostData) {
                    this.isDownloadingGhostData = false;

                    // reset and get all data with new window
                    this.onWindowChanged(this.getLineChart());
                }
            }
        },
        signalRConnected: function(newValue, oldValue)
        {
            if (newValue && !oldValue) {
                // streaming data has come back, we probably need to redraw the graph so clear out and get the data for the current window
                // if there is a filter on the current window then there is no reason to do this
                // also check to see if there was previously a disconnect event, that will avoid running this on init
                if(this.isStreamingDisconnected && !this.isFilterPresent) {
                    // reset and get all data with appropriate data
                    if(this.followingUpdates) {
                        this.followToLatestTime();
                    }
                    else {
                        this.onWindowChanged(this.getLineChart());
                    }
                }

                this.isStreamingDisconnected = false;
            }
            else if (!newValue && oldValue) {
                //streaming data has failed, mark it so we know the connection event is a reconnect
                this.isStreamingDisconnected = true;
            }
        }
    },
    mounted() {
        if (this.item?.selectedTruckId)
            this.selectedTruck = parseInt(this.item.selectedTruckId);

        this.downloadConfig().then(_res => {
            this.initSelectedTruck = this.targetSystem.find(truck => truck.number == this.selectedTruck) || null;
        });

        //computed properties in this component (i.e. this.frac) rely on 'tags' to NOT be reactive
        //to achieve that, 'tags' is added to the vue instance on mount, and is excluded from data()
        this.tags = [];
        this.axiosInstance =  axios.create();
        delete this.axiosInstance.defaults.headers.common['X-CSRF-TOKEN'];

        this.fetchWellStageIntervals();
        this.enableThresholdAlerts = localStorage.getItem('showThresholdAlerts') == 'false'? false : true;
        const currentAggregation = localStorage.getItem('aggregation')? localStorage.getItem('aggregation') : DEFAULT_AGGREGATION;
        this.currentAggregationIndex = AGGREGATION_OPTIONS.findIndex((agg)=>agg.name == currentAggregation);
        if(this.currentAggregationIndex < 0) {
            this.currentAggregationIndex = 0;
        }
        this.aggregationValue = AGGREGATION_OPTIONS[this.currentAggregationIndex].value;

        this.showChartHeaderConfig =
            this.dashboardItem.options.showChartHeaderConfig == 'false' ||
            this.dashboardItem.options.showChartHeaderConfig == null?  false : true; //if undefined, show header

        if(!this.isNotDashboard) {
            this.jsonLocalStorage.key = this.dashboardData.jobNumber + '.' + this.dashboardItem.options.configId + '.' + this.dashboardData.selectedDashboard.id + '.' + this.dashboardItem.i;
        }
        else {
            this.jsonLocalStorage.key = this.dashboardData.jobNumber + '.wireline-op';
        }

        this.savedJsonLocalStorage = this.jsonLocalStorage.load(this.jsonLocalStorage.key);

        if (this.savedJsonLocalStorage) {
            // set saved values
            this.jsonLocalStorage.activeData = this.savedJsonLocalStorage.activeData;
            this.jsonLocalStorage.shadingData = this.savedJsonLocalStorage.shadingData || [];
            this.jsonLocalStorage.visibleSections = this.savedJsonLocalStorage.visibleSections;
            this.jsonLocalStorage.ghostPlotSettings = this.savedJsonLocalStorage.ghostPlotSettings;
            this.jsonLocalStorage.chartAxisOptions = this.savedJsonLocalStorage.chartAxisOptions;
            this.jsonLocalStorage.chartTSIOptions = this.savedJsonLocalStorage.chartTSIOptions;
            this.jsonLocalStorage.dragToZoomEnabled = this.savedJsonLocalStorage.dragToZoomEnabled;
            this.jsonLocalStorage.defaultData = this.savedJsonLocalStorage.defaultData;
        } else {
            // set default values
            this.jsonLocalStorage.activeData = [];
            this.jsonLocalStorage.shadingData = [];
            this.jsonLocalStorage.visibleSections = [];
            this.jsonLocalStorage.ghostPlotSettings = {};
            this.jsonLocalStorage.chartAxisOptions = [];
            this.jsonLocalStorage.chartTSIOptions = {};
            this.jsonLocalStorage.dragToZoomEnabled = null;
            this.jsonLocalStorage.defaultData = [];
        }

        this.dragToZoom = this.jsonLocalStorage.dragToZoomEnabled;

        if (this.jsonLocalStorage.ghostPlotSettings) {
            this.ghostLineChannels = this.jsonLocalStorage.ghostPlotSettings.channels ?? this.ghostLineChannels;
            this.ghostLineSize = this.jsonLocalStorage.ghostPlotSettings.lineSize ?? this.ghostLineSize;
            this.ghostLineOpacity = this.jsonLocalStorage.ghostPlotSettings.lineOpacity ?? this.ghostLineOpacity;
        }
        if (this.jsonLocalStorage.chartAxisOptions.length > 0) {
            const self = this;
            const chartOptions = this.jsonLocalStorage.chartAxisOptions.find((optionsObj) => {
                return optionsObj.key == self.userDashboardItemKeyId;
            });
            if (chartOptions) {
                //Check if dependent and indepenent axis information exists yet - either one missing is sufficient to know both are missing
                //Just add in the stub data
                if(!chartOptions?.independentAxis) {
                    this.localChartAxisOptions = {
                        ...chartOptions,
                        independentAxis: {
                            labelFontSize: 12,
                            tickFontSize: 12
                        },
                        dependantAxis: {
                            labelFontSize: 12,
                            tickFontSize: 12
                        }
                    };
                }else{
                    //If dependent and independent axis information exists then just assign it to local options
                    this.localChartAxisOptions = chartOptions;
                }
            }
        }
        if (this.jsonLocalStorage?.chartTSIOptions && this.jsonLocalStorage.chartTSIOptions?.resetZoomWindowHours) {
            this.defaultZoomWindowHrs = this.jsonLocalStorage.chartTSIOptions.resetZoomWindowHours;
        }

        this.chartOptionsTempCopy = _.cloneDeep(this.localChartAxisOptions); //redundant copy of options to return to if changes are cancelled
        this.hideCommentsTimeline = false; //set false if undefined
        this.$nextTick(() => {
            this.downloadConfig();
        });

        if (this.areChartsSynced) {
            this.mountChartListeners();
        }
        //listen for quickAddCancel from the config modal
        const self = this;
        this.$root.$on('REMOVE_UNSAVED_CHARTITEM', cancelData => {
            if (self.dashboardItem.i === cancelData.dashboardItem.i) {
                self.cancelQuickAddChartItem(cancelData.chartItem);
            }
        });

        //prevent header popover from closing if inner popover(s) opened
        this.$root.$on('bv::popover::hide', bvEventObj => {
            if(bvEventObj.target.id == 'popover-header-' + this._uid)
            {
                if (this.innerPopoversOpened.length > 0)
                    bvEventObj.preventDefault();
            }
        });

        document.defaultView.window.addEventListener('focus', this.onPageFocusOrScroll);
        document.defaultView.window.addEventListener('scroll', debounce(this.onPageFocusOrScroll, 200));
    },
    unmounted() {
        document.defaultView.window.removeEventListener('focus', this.onPageFocusOrScroll);
        document.defaultView.window.removeEventListener('scroll', debounce(this.onPageFocusOrScroll, 200));

        // disconnect resize observers
        this.fontResizeObserver.disconnect();
    }
};
</script>
